import { useStore } from "../store/RootContext";
import styled from "styled-components";

/**
 * Olennaisuus (OM) - materiality
 * Työskentelyolennaisuus (PM) - workingMateriality
 * Yksittäisen virheen olennaisuusraja (AMPT) - singleErrorMateriality
 */

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin: 0 0 0 0;
`;

const Field = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  font-weight: bold;
  margin-right: 10px;
  white-space: nowrap;
  width: 50px;
  text-align: right;
`;

const ReadOnlyInput = styled.input`
  flex: 1;
  padding: 5px;
  border: 1px solid #22025E;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-sizing: border-box;
`;

export function MaterialityField() {
  const {
    auditingStore: { getAuditingSection, materialityComputeds },
  } = useStore();

  const materialitySection = getAuditingSection("materiality")?.form;
  if (!materialitySection) return null;

  const materialities = materialityComputeds(materialitySection);
  let { materiality, workingMateriality, singleErrorMateriality } = materialities;

    // Replace 'undefined' with '0 €' if the value is undefined
    if (materiality === undefined) materiality = 0; 
    if (workingMateriality === undefined) workingMateriality = 0;
    if (singleErrorMateriality === undefined) singleErrorMateriality = 0;

  return (
    <Container>
      <p
        style={{
          display: "block",
          color: "#333333",
          fontSize: "0.875rem",
          textAlign: "left",
          fontWeight: "bold",
          position: "relative",
        }}
      >Olennaisuudet</p>
      <Field>
        <Label>OM</Label>
        <ReadOnlyInput type="text" readOnly value={`${materiality} €`} />
      </Field>
      <Field>
        <Label>PM</Label>
        <ReadOnlyInput type="text" readOnly value={`${workingMateriality} €`} />
      </Field>
      <Field>
        <Label>AMPT</Label>
        <ReadOnlyInput type="text" readOnly value={`${singleErrorMateriality} €`} />
      </Field>
    </Container>
  );
}