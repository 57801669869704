import styled from 'styled-components';

export interface StyledTableProps {
  disableSort?: boolean;
  variant?: 'default' | 'plain';
}

export const StyledTable = styled.table<StyledTableProps>`
  margin-top: ${p => p.theme.spacing.md};
  border-collapse: collapse;
  width: 100%;
  ${p => p.theme.font.size[14]};

  ${p => {
    switch (p.variant) {
      case 'plain':
        return `
          thead > tr > th {
            background-color: ${p.theme.color.grey100};
          }
          tbody > tr > td {
            .editable-column-overlay {
              background: ${p.theme.color.primary100};
            }
          }
        `;
      default:
        return `
          thead > tr > th {
            background-color: ${p.theme.color.grey200};
          }
          tbody > tr > td {
            .editable-column-overlay {
              background: ${p.theme.color.primary200};
            }
          }
          tbody > tr:nth-child(odd) {
            background-color: ${p.theme.color.grey100};
          }
        `;
    }
  }}

  th,
  td {
    &.width-min {
      width: 1%;
    }
    &.width-10 {
      width: 10%;
    }
    &.width-15 {
      width: 15%;
    }
    &.width-20 {
      width: 20%;
    }
    &.width-30 {
      width: 30%;
    }
    &.width-40 {
      width: 40%;
    }
    &.width-half {
      width: 50%;
    }
    &.width-max {
      width: 100%;
    }
    &.nowrap {
      whitespace: nowrap;
    }
    &.nowrap-vertical {
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
      overflow: hidden;

      input {
        whitespace: normal;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
      }
    }
    &.small-text {
      ${p => p.theme.font.size[12]};
    }

    &.no-cell-padding {
      padding: 2px;
      > div {
        padding: 0;
      }
    }
  }

  thead {
    user-select: none;
    cursor: ${p => (p.disableSort ? undefined : 'pointer')};
    text-align: left;

    tr {
      &:last-child {
        border-bottom: 1px solid ${p => p.theme.color.grey300};
      }
      th {
        &:first-child {
          border-radius: ${p => p.theme.borderRadius.sm} 0 0 0;
        }
        &:last-child {
          border-radius: 0 ${p => p.theme.borderRadius.sm} 0 0;
        }

        &.grouping-cell {
          padding: ${p => p.theme.spacing.xsm} 0;
          border-bottom: 1px solid ${p => p.theme.color.grey300};
          color: ${p => p.theme.color.grey400};
          text-align: center;
          letter-spacing: 0.5rem;
        }

        > div {
          position: relative;
          padding: ${p => p.theme.spacing.md};
          .sort-icon {
            position: absolute;
            top: 14px;
            z-index: 1;
            margin-left: ${p => p.theme.spacing.xsm};
          }
          .context-info-container {
            display: inline-block;
            margin-left: ${p => p.theme.spacing.xsm};
          }
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid ${p => p.theme.color.grey200};
      td {
        position: relative;
        padding: 0 ${p => p.theme.spacing.md};
        height: 48px;

        &:has(input[type='number']) {
          min-width: 100px;
        }

        input:not(input[type='checkbox']),
        .editable-column-overlay {
          position: absolute;
          top: 0;
          left: 0;
          overflow: hidden;
          height: 100%;
          width: 100%;
        }

        input:not(input[type='checkbox']) {
          z-index: 1;
          background: ${p => p.theme.color.transparent};
          border: none;
          border-left: 1px dashed ${p => p.theme.color.primary100};
          border-right: 1px dashed ${p => p.theme.color.primary100};
          padding: ${p => p.theme.spacing.md};
        }

        .editable-column-overlay {
          opacity: 0.1;
        }
      }
    }
  }
`;
