import React, { useEffect, useState } from 'react';
import AuditingTable from '../../../../components/table/AuditingTable';
import { TableColumnDefs } from '../../../../components/table/Table';
import { filterGeneralLedgerByClassKeyAndAccount, getAccountMap, getGeneralLedger } from '../../analyzesAndScoping/generalLedgerUtils';

interface Props {
  classKey: AccountClassKey;
  auditing?: Auditing;
  addCommentField?: boolean;
  formState: any;
  patchFormState: Function;
  showChange?: boolean;
}

const O1Form2: React.FC<Props> = ({
  auditing,
  classKey,
  addCommentField,
  formState,
  patchFormState,
  showChange,
}) => {
  /*
    Using this function requires you to create a variable named O1FormData in the defaultFormState of where you use it, like this:
    
      const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
        O1Form2Data: {},
      })
  */

  const [data, setData] = useState([
    {
      label: 'Käyttöomaisuuskirjanpito alkusaldo ',
      value: 0,
    },
    {
      label: 'Kirjanpidon alkusaldo',
      value: 0,
    },
    {
      label: 'Käyttöomaisuuskirjanpito loppusaldo',
      value: 0,
    },
    {
      label: 'Kirjanpito loppusaldo ',
      value: 0,
    },
  ]);

  useEffect(() => {
    formState && setData(formState.O1Form2Data);
  }, [formState]);

  function handleEdit(props: any) {
    var duplicateFormState = [...formState.O1Form2Data];
    duplicateFormState.find(item => item.id === props.itemId).value =
      props.value;
    patchFormState({
      ...formState,
      O1Form2Data: duplicateFormState,
    });
  }

  var tableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      className: 'width-min',
      header: () => null,
    },
    {
      accessorKey: 'value',
      header: () => null,
      className: 'width-min text-center nowrap',
      onNumberEdit: handleEdit,
    },
  ];

  return (
    <>
      <AuditingTable
        data={data.slice(0, 2)}
        columns={tableColumns}
        showGlobalFilter={false}
      />
      <p style={{ margin: 5 }}>
        <b>Erotus: {data[0].value - data[1].value} </b>
      </p>
      <AuditingTable
        data={data.slice(2, 4)}
        columns={tableColumns}
        showGlobalFilter={false}
      />
      <p style={{ margin: 5 }}>
        <b>Erotus: {data[2].value - data[3].value} </b>
      </p>
    </>
  );
};

function getInitialValues(auditing: Auditing, classKey: string) {

  const generalLedger = getGeneralLedger(auditing);

  if (!auditing || !generalLedger || generalLedger.length === 0) return null;

  const accountMap = getAccountMap(auditing);

  if (!accountMap) return null;

  const existingData = filterGeneralLedgerByClassKeyAndAccount(accountMap, generalLedger, classKey);

  let data = [];

  if (existingData) {
    for (var i = 0; i < existingData?.length; i++) {
      let object: GroupedGeneralLedgerItem & {[key: string]: any} = existingData[i];

      const undefinedHandler = (item: number | undefined) => {
        if (item === undefined) {
          return 0;
        } else return item;
      };
      object.changeInNumbers =
        undefinedHandler(object.currentYear) -
        undefinedHandler(object.priorYear);
      object.changeInPercent = Math.round(
        ((undefinedHandler(object.currentYear) -
          undefinedHandler(object.priorYear)) /
          undefinedHandler(object.priorYear)) *
          100
      );
      object.deletion = object.changeInNumbers;
      object.addition = 0;
      object.subtraction = 0;
      object.removalPercent =
        (undefinedHandler(object.priorYear) + object.addition - object.subtraction) /
        undefinedHandler(object.priorYear);
      if (object.currentYear === undefined || object.priorYear === undefined) {
        object.changeInPercent = undefined;
      } else {
        object.changeInPercent = Math.round(
          ((object.currentYear - object.priorYear) / object.priorYear) * 100
        );
      }

      data.push(object);
    }
  }
  return data;
}

export { O1Form2, getInitialValues };
