import { AuditorInformation } from '../../../components/auditing/PermanentData.AuditorInformation';
import { CompanyInformation } from '../../../components/auditing/PermanentData.CompanyInformation';
import { CompanyPerceptionInformation } from '../../../components/auditing/PermanentData.CompanyPerceptionInformation';
//import { TaxRegisterInformation } from '../../../components/auditing/PermanentData.TaxRegisterInformation';
import { getAddress } from '../../../utils';
import {
  CustomerActivityMonitoring,
  initialAuditorInformationItems,
  PartOfTheGroup,
  visibleData,
  /*
  ApplicableLaws,
  FailureToNotify,
  FinancialStatementsBasis,
  MunicipalApplicableLaws,
  MunicipalFinancialStatementsBasis,
  IndustrySpecificRegulation,
  TaxDebtInformation,
  */
} from './permanentDataUtils';
import { FormFieldBuilder } from '../FormFieldBuilder';

type SectionFormType = PermanentDataForm;

const sectionKey: AuditingSectionKey = 'permanentData';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  // PART I
  auditorInformation: initialAuditorInformationItems({ auditing }),

  // PART II
  isFirstAuditing: null,
  isCustomerApprovalAndCustomerIdentificationCompleted: null,
  isMattersThatAffectAssignment: null,
  mattersThatAffectAssignment: '',
  areTermsAgreedAndUpToDate: null,
  registeredOwnersMatchUnderstanding: null,
  properIdentificationOfRepresentatives: null,
  properIdentificationOfRepresentativesDetails: '',
  customerActivityMonitoring: null,
  customerActivityMonitoringDetails: '',

  // PART III
  companyInformation: {
    id: auditing.customerCompany?.id ?? -1,
    name: auditing.customerCompany?.name ?? '',
    nameSource: '',
    businessId: auditing.customerCompany?.businessId ?? '',
    businessIdSource: '',
    address: auditing.customerCompany
      ? getAddress(auditing.customerCompany)
      : '',
    financialPeriod: '',
    selectedAuditor: '',
    inspectionTeamMembers: '',
    contactEmail: '',
    accountant: '',

    addressSource: '',
    registeredOffice: auditing.customerCompany?.city ?? '',
    registeredOfficeSource: '',
    industry: '',
    industrySource: '',
    population: '',
    populationSource: '',
    councilChairman: '',
    councilChairmanSource: '',
    mayor: '',
    mayorSource: '',
    administrativeDirector: '',
    administrativeDirectorSource: '',
    chiefFinancialOfficer: '',
    chiefFinancialOfficerSource: '',
    accountantsContactInformation: '',
    accountantsContactInformationSource: '',
    payrollAccountantsContactInformation: '',
    payrollAccountantsContactInformationSource: '',

    capitalStock: '',
    capitalStockUpToDate: null,
    capitalStockSource: '',
    numberOfStocks: '',
    numberOfStocksUpToDate: null,
    numberOfStocksSource: '',
    boardChairman: '',
    boardChairmanUpToDate: null,
    boardChairmanSource: '',
    boardMember1: '',
    boardMember1UpToDate: null,
    boardMember1Source: '',
    boardMember2: '',
    boardMember2UpToDate: null,
    boardMember2Source: '',
    boardMember3: '',
    boardMember3UpToDate: null,
    boardMember3Source: '',
    boardDeputyMember: '',
    boardDeputyMemberUpToDate: null,
    boardDeputyMemberSource: '',
    ceo: '',
    ceoUpToDate: null,
    ceoSource: '',
    deputyCeo: '',
    deputyCeoUpToDate: null,
    deputyCeoSource: '',

    administrativeRule: '',
    administrativeRuleUpToDate: null,
    administrativeRuleSource: '',
    municipalStrategy: '',
    municipalStrategyUpToDate: null,
    municipalStrategySource: '',
    groupInstruction: '',
    groupInstructionUpToDate: null,
    groupInstructionSource: '',
    internalControlAndRiskManagementInstruction: '',
    internalControlAndRiskManagementInstructionUpToDate: null,
    internalControlAndRiskManagementInstructionSource: '',
    purchaseInstruction: '',
    purchaseInstructionUpToDate: null,
    purchaseInstructionSource: '',
    riskManagementGuidelines: '',
    riskManagementGuidelinesUpToDate: null,
    riskManagementGuidelinesSource: '',
  },
  isEquityNegativityRegistered: '',
  isEquityNegativityRegisteredInfo:
    'Anna tarvittaessa lisätietoja omistajien negatiivisesta pääomasta.',
  hasOnlyOneOwner: null,
  upToDateListsOfSharesAndShareholders: null,
  isShareholderAgreementDone: null,
  isPartOfTheGroup: null,
  isPartOfTheGroupDetails: '',
  articlesOfAssociationHasRelevantRequirements: null,
  articlesOfAssociationHasRelevantRequirementsDetails: '',

  // PART IV
  applicableLaws: {
    limitedCompanyAct: null,
    accountingAct: null,
    taxLaws: null,
    housingCompanyAct: null,
    other: null,
  },
  applicableLawsDetails: '',
  industrySpecificRegulation: {
    regulatedIndustry: null,
    politicalRegulation: null,
    numerousRegulations: null,
    noImportantPoliticalRegulation: null,
  },
  industrySpecificRegulationDetails: '',
  financialStatementsBasis: {
    accountingRegulation: null,
    smallBusinessRegulation: null,
    ifrs: null,
    other: null,
  },
  financialStatementsBasisDetails: '',
  municipalApplicableLaws: {
    municipalLaw: null,
    administrativeLaw: null,
    accountingLaw: null,
    taxLaw: null,
    stateContributionsRegulations: null,
    purchaseLaw: null,
    other: null,
  },
  municipalApplicableLawsDetails: '',
  municipalFinancialStatementsBasis: {
    municipalLaw: null,
    decreeOnMunicipalitysFinancialStatements: null,
    accountingRegulation: null,
    municipalKilaGeneralInstructions: null,
  },

  // PART V
  hasPropertyRentalActivities: null,
  taxRegisterFetchedDate: null,
  tradeRegisterDate: null,
  tradeRegister: null,
  taxAdministrationInformationDate: null,
  taxAdministrationInformation: null,
  advanceCollectionRegisterDate: null,
  advanceCollectionRegister: null,
  liableForVATOnBusinessDate: null,
  liableForVATOnBusiness: null,
  liableForVATOnBusinessDetails: '',
  liableForVATOnPropertyDate: null,
  liableForVATOnProperty: null,
  liableForVATOnPropertyDetails: '',
  employerRegisterDate: null,
  employerRegister: null,
  taxDebtInformation: null,
  failureToNotify: null,
  legalSituation: null,
  possibleInformationAboutTheSuspensionOfBusiness: '',
});

const formFields: FormFields<SectionFormType> = ({ formState }) => [
  // PART I
  formBuilder.accordionGroup({
    titleKey: 'auditorInformation.title',
    customRemarkTriggers: ['auditorInformation.title'],
    items: [formBuilder.custom(props => <AuditorInformation {...props} />)],
  }),

  // PART II
  formBuilder.accordionGroup({
    titleKey: 'continuationOfTheAssignment',
    items: [
      formBuilder.boolean({
        accessor: 'isFirstAuditing',
        options: ['no', 'yes'],
      }),

      formBuilder.boolean({
        accessor: 'isCustomerApprovalAndCustomerIdentificationCompleted',
        options: ['no', 'yes'],
        hidden: !formState.isFirstAuditing,
      }),

      /*
      formBuilder.boolean({
        accessor: 'isMattersThatAffectAssignment',
        showContextInfo: 'bottom-left',
        options: ['no', 'yes'],
        hidden: formState.isFirstAuditing !== false,
        detailsAccessor: 'mattersThatAffectAssignment',
        detailsHidden: !formState.isMattersThatAffectAssignment,
      }),
      */

      formBuilder.boolean({
        accessor: 'areTermsAgreedAndUpToDate',
        options: ['no', 'yes'],
      }),

      formBuilder.boolean({
        accessor: 'registeredOwnersMatchUnderstanding',
        options: ['no', 'yes'],
      }),

      /*
      formBuilder.boolean({
        accessor: 'properIdentificationOfRepresentatives',
        options: ['no', 'yes'],
        detailsAccessor: 'properIdentificationOfRepresentativesDetails',
      }),
      */

      formBuilder.radioGroup({
        accessor: 'customerActivityMonitoring',
        options: Object.values(CustomerActivityMonitoring),
        detailsAccessor: 'customerActivityMonitoringDetails',
        detailsHidden:
          formState.customerActivityMonitoring !==
          CustomerActivityMonitoring.other,
      }),
    ],
  }),

  // PART III - Company
  formBuilder.accordionGroup({
    titleKey: 'companyInformation.title',
    customRemarkTriggers: [
      'companyInformation.capitalStockUpToDate',
      'companyInformation.numberOfStocksUpToDate',
      'companyInformation.boardChairmanUpToDate',
      'companyInformation.boardMember1UpToDate',
      'companyInformation.boardMember2UpToDate',
      'companyInformation.boardMember3UpToDate',
      'companyInformation.boardDeputyMemberUpToDate',
      'companyInformation.ceoUpToDate',
      'companyInformation.deputyCeoUpToDate',
    ],
    /* Hides if the Template is set to "Public" (Julkishallinto) */
    hiddenInTemplate: 'public',
    items: [
      formBuilder.custom(props => (
        <CompanyInformation
          visibleData={visibleData.companyInformationVisibleData}
          {...props}
        />
      )),
      formBuilder.custom(props => (
        <CompanyPerceptionInformation
          visibleData={visibleData.companyPerceptionInformationVisibleData}
          {...props}
        />
      )),
      formBuilder.group({
        items: [
          formBuilder.radioGroup({
            accessor: 'isEquityNegativityRegistered',
            options: ['na', 'no', 'yes'],
          }),
          formBuilder.textArea({
            accessor: 'isEquityNegativityRegisteredInfo',
            hidden: formState.isEquityNegativityRegistered !== 'no',
          }),
          formBuilder.boolean({
            accessor: 'hasOnlyOneOwner',
            options: ['no', 'yes'],
          }),
        ],
      }),
      formBuilder.boolean({
        accessor: 'upToDateListsOfSharesAndShareholders',
        options: ['no', 'yes'],
      }),
      formBuilder.boolean({
        accessor: 'isShareholderAgreementDone',
        options: ['no', 'yes'],
      }),
      formBuilder.radioGroup({
        accessor: 'isPartOfTheGroup',
        options: Object.values(PartOfTheGroup),
        detailsAccessor: 'isPartOfTheGroupDetails',
        showDetailsContextInfo: 'top-right',
        detailsHidden: !(
          formState.isPartOfTheGroup === PartOfTheGroup.yesIsParentCompany ||
          formState.isPartOfTheGroup === PartOfTheGroup.yesIsSubsidiary
        ),
      }),
      formBuilder.boolean({
        accessor: 'articlesOfAssociationHasRelevantRequirements',
        options: ['no', 'yes'],
        detailsAccessor: 'articlesOfAssociationHasRelevantRequirementsDetails',
        detailsHidden: !formState.articlesOfAssociationHasRelevantRequirements,
      }),
    ],
  }),

  // PART III - Municipality
  formBuilder.accordionGroup({
    titleKey: 'companyInformation.title',
    customRemarkTriggers: [
      'companyInformation.administrativeRuleUpToDate',
      'companyInformation.municipalStrategyUpToDate',
      'companyInformation.groupInstructionUpToDate',
      'companyInformation.internalControlAndRiskManagementInstructionUpToDate',
      'companyInformation.purchaseInstructionUpToDate',
    ],
    hiddenInTemplate: 'private',
    items: [
      formBuilder.custom(props => (
        <CompanyInformation
          visibleData={visibleData.municipalityInformationVisibleData}
          {...props}
        />
      )),
      formBuilder.custom(props => (
        <CompanyPerceptionInformation
          titleAccessor="mostImportantInstructions"
          hintAccessor="upToDateInfoPublic"
          visibleData={
            visibleData.municipalityMostImportantInstructionsVisibleData
          }
          {...props}
        />
      )),
      formBuilder.custom(props => (
        <CompanyPerceptionInformation
          titleAccessor="otherRelevantRulesAndAgreements"
          hintAccessor="upToDateInfoPublic"
          visibleData={
            visibleData.municipalityOtherRelevantInstructionsVisibleData
          }
          {...props}
        />
      )),
      formBuilder.radioGroup({
        accessor: 'isPartOfTheGroup',
        options: Object.values(PartOfTheGroup),
        detailsAccessor: 'isPartOfTheGroupDetails',
        showDetailsContextInfo: 'top-right',
        detailsHidden: !(
          formState.isPartOfTheGroup === PartOfTheGroup.yesIsParentCompany ||
          formState.isPartOfTheGroup === PartOfTheGroup.yesIsSubsidiary
        ),
      }),
    ],
  }),

  /*
  // PART IV - Company
  formBuilder.accordionGroup({
    titleKey: 'legalAndRegulatoryNorms',
    hiddenInTemplate: [AuditingTemplate.public],
    items: [
      formBuilder.checkboxGroup({
        accessor: 'applicableLaws',
        options: Object.values(ApplicableLaws),
        detailsAccessor: 'applicableLawsDetails',
        detailsHidden: !formState.applicableLaws?.other,
      }),

      formBuilder.checkboxGroup({
        accessor: 'industrySpecificRegulation',
        options: Object.values(IndustrySpecificRegulation),
        showContextInfo: 'top-right',
        detailsAccessor: 'industrySpecificRegulationDetails',
        detailsHidden: !(
          formState.industrySpecificRegulation?.regulatedIndustry ||
          formState.industrySpecificRegulation?.politicalRegulation
        ),
      }),

      formBuilder.checkboxGroup({
        accessor: 'financialStatementsBasis',
        options: Object.values(FinancialStatementsBasis),
        detailsAccessor: 'financialStatementsBasisDetails',
        detailsHidden: !formState.financialStatementsBasis?.other,
      }),
    ],
  }),

  // PART IV - Municipality
  formBuilder.accordionGroup({
    titleKey: 'legalAndRegulatoryNorms',
    hiddenInTemplate: [AuditingTemplate.private],
    items: [
      formBuilder.checkboxGroup({
        accessor: 'municipalApplicableLaws',
        options: Object.values(MunicipalApplicableLaws),
        detailsAccessor: 'municipalApplicableLawsDetails',
        detailsHidden: !formState.municipalApplicableLaws?.other,
      }),

      formBuilder.checkboxGroup({
        accessor: 'industrySpecificRegulation',
        options: Object.values(IndustrySpecificRegulation),
        showContextInfo: 'top-right',
        detailsAccessor: 'industrySpecificRegulationDetails',
        detailsHidden: !(
          formState.industrySpecificRegulation?.regulatedIndustry ||
          formState.industrySpecificRegulation?.politicalRegulation
        ),
      }),

      formBuilder.checkboxGroup({
        accessor: 'municipalFinancialStatementsBasis',
        options: Object.values(MunicipalFinancialStatementsBasis),
      }),
    ],
  }),

  // PART VI - Company
  formBuilder.accordionGroup({
    titleKey: 'taxRegisterInformation',
    hiddenInTemplate: [AuditingTemplate.public],
    items: [
      formBuilder.boolean({
        accessor: 'hasPropertyRentalActivities',
        options: ['no', 'yes'],
      }),

      formBuilder.custom(props => <TaxRegisterInformation
          visibleData={[
            'tradeRegister',
            'taxAdministrationInformation',
            'advanceCollectionRegister',
            'liableForVATOnBusiness',
            'liableForVATOnProperty',
            'employerRegister',
          ]}
          {...props}
        />
      ),

      formBuilder.radioGroup({
        accessor: 'taxDebtInformation',
        options: Object.values(TaxDebtInformation),
      }),

      formBuilder.radioGroup({
        accessor: 'failureToNotify',
        options: Object.values(FailureToNotify),
      }),

      formBuilder.textInput({
        accessor: 'possibleInformationAboutTheSuspensionOfBusiness',
      }),
    ],
  }),

  // PART VI - Municipality
  formBuilder.accordionGroup({
    titleKey: 'taxRegisterInformation',
    hiddenInTemplate: [AuditingTemplate.private],
    items: [
      formBuilder.boolean({
        accessor: 'hasPropertyRentalActivities',
        options: ['no', 'yes'],
      }),

      formBuilder.custom(props => <TaxRegisterInformation
          visibleData={[
            'taxAdministrationInformation',
            'advanceCollectionRegister',
            'liableForVATOnBusiness',
            'liableForVATOnProperty',
            'employerRegister',
          ]}
          {...props}
        />
      ),

      formBuilder.radioGroup({
        accessor: 'taxDebtInformation',
        options: Object.values(TaxDebtInformation),
      }),

      formBuilder.radioGroup({
        accessor: 'failureToNotify',
        options: Object.values(FailureToNotify),
      }),

      formBuilder.textInput({
        accessor: 'possibleInformationAboutTheSuspensionOfBusiness',
      }),
    ],
  }),
  */
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
