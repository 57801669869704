import { useEffect, useState } from "react";
import { deepEqual } from "../../../../utils/deepEqual";

export const useCopyFromIncomeStatementAndBalance = ({
  formState,
  patchFormState,
  store
}: FormFieldProps<PreliminaryAnalyticForm>) => {
  const incomeStatementAndBalanceForm = store.auditingStore?.auditing?.incomeStatementAndBalance?.form;
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  useEffect(() => {
    const copy = () => {

      const defaultDateValues = {
        dateIncome: 'Ajanjakso 1.1-31.12.202X.',
        dateBalance: 'Ajanjakso 1.1-31.12.202X.',
        dateLiabilities: 'Ajanjakso 1.1-31.12.202X.',
      };

      const ifExistsCheckForEquality = (value1?: string, value2?: string) => {
        if (value1 !== undefined && value2 !== undefined) {
          return value1 === value2;
        }
        return true;
      };

      if (incomeStatementAndBalanceForm) {
        const areFormsEqual = [
          deepEqual(formState.incomeStatement, incomeStatementAndBalanceForm.incomeStatement),
          deepEqual(formState.balanceAssets, incomeStatementAndBalanceForm.balanceAssets),
          deepEqual(formState.balanceLiabilities, incomeStatementAndBalanceForm.balanceLiabilities),
          // defaultFormState values are not saved to the formState
          // hence the undefined check
          ifExistsCheckForEquality(formState.dateBalance, incomeStatementAndBalanceForm.dateBalance),
          ifExistsCheckForEquality(formState.dateIncome, incomeStatementAndBalanceForm.dateIncome),
          ifExistsCheckForEquality(formState.dateLiabilities, incomeStatementAndBalanceForm.dateLiabilities)
        ];

        if (areFormsEqual.some((value, index) => {
          return !value;
        })) {
          const patch = {
            ...formState,

            // Keep modified comments
            incomeStatement: incomeStatementAndBalanceForm.incomeStatement.map((item, index) => {
              return {
                ...item,
                statementDescription: formState.incomeStatement[index]?.statementDescription ?? item.statementDescription,
              }
            }),

            balanceAssets: incomeStatementAndBalanceForm.balanceAssets.map((item, index) => {
              return {
                ...item,
                statementDescription: formState.balanceAssets[index]?.statementDescription ?? item.statementDescription,
              }
            }),

            balanceLiabilities: incomeStatementAndBalanceForm.balanceLiabilities.map((item, index) => {
              return {
                ...item,
                statementDescription: formState.balanceLiabilities[index]?.statementDescription ?? item.statementDescription,
              }
            }),

            dateBalance: incomeStatementAndBalanceForm.dateBalance ?? defaultDateValues.dateBalance,
            dateIncome: incomeStatementAndBalanceForm.dateIncome ?? defaultDateValues.dateIncome,
            dateLiabilities: incomeStatementAndBalanceForm.dateLiabilities ?? defaultDateValues.dateLiabilities,
          }

          patchFormState(patch);
        }
      }
    };

    // Run immediately on first load
    if (firstLoad) {
      setFirstLoad(false);
      copy();
    };

    // Run every 5 seconds
    // Probably not needed since copy() should run on every mount, but it doesn't hurt
    //const interval = setInterval(copy, 5000);
    //return () => clearInterval(interval);
  }, [formState, incomeStatementAndBalanceForm, patchFormState, firstLoad]);
}