import { AuditingTemplate } from '../../../utils';
import { defaultForms, accountingEstimatesDefaultForms, describePeriodDefaultForms, innerCircleDefaultForms, forms } from './formStringMappings.forms';

export const mapActionKeyToFormStrings = (
  actionKey: string | undefined,
  template: AuditingTemplate | undefined,
  type: procedureType
): Record<string, string> => {

  let batchDefaultForms: Record<string, string>;
  switch (type) {
    case 'accountingEstimates':
      batchDefaultForms = accountingEstimatesDefaultForms;
      break;
    case 'describePeriod':
      batchDefaultForms = describePeriodDefaultForms;
      break;
    case 'innerCircle':
      batchDefaultForms = innerCircleDefaultForms;
      break;
    default:
      batchDefaultForms = defaultForms;
  }

  let matchedFormStrings: Mapping | undefined = {};
  Object.keys(forms).forEach(key => {
    if (actionKey === key) {
      // Default to private template if undefined;
      matchedFormStrings = forms[key][template ?? AuditingTemplate.private];
    }
  });

  /* Return defaults, if something matches override the default .*/
  return { ...batchDefaultForms, ...matchedFormStrings };
};
