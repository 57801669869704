import React from 'react';
import ProcedureAccounts from './ProcedureAccounts';
import ProcedureAccountsManual from './ProcedureAccountsManual';
import { filterGeneralLedgerByClassKeyAndAccount, getAccountMap, getGeneralLedger } from '../../analyzesAndScoping/generalLedgerUtils';

interface Props {
  auditing?: Auditing;
  addCommentField?: boolean;
  addReferenceField?: boolean;
  formState?: any;
  patchFormState?: Function;
  batchString: string;
  showChange?: boolean;
  customAccountTitle?: string;
  showTitles?: boolean;
  elementTitle?: string;
}

export const ProcedureAccountsElement: React.FC<Props> = ({
  auditing,
  addCommentField = false,
  addReferenceField = false,
  formState,
  patchFormState,
  batchString,
  showChange,
  customAccountTitle,
  showTitles = true,
  elementTitle,
}) => {
  /*
    Using this function requires you to create a variable named procedureAccountsData in the defaultFormState of where you use it, like this:
    
      const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
        procedureAccountsData: {},
      })
  */

  const generalLedger = getGeneralLedger(auditing);

  if (!generalLedger || generalLedger.length === 0) return null;

  const accountMap = getAccountMap(auditing);

  if (!accountMap) return null;

  const existingData = filterGeneralLedgerByClassKeyAndAccount(accountMap, generalLedger, batchString);

  if (existingData && existingData.length === 0) {
    return (
      <ProcedureAccountsManual
        customAccountTitle={customAccountTitle}
        showChange={showChange}
        formState={formState}
        patchFormState={patchFormState}
        auditing={auditing}
        addCommentField={addCommentField}
        addReferenceField={addReferenceField}
      />
    );
  }

  return (
    <ProcedureAccounts
      accounts={existingData}
      showTitles={showTitles}
      customAccountTitle={customAccountTitle}
      showChange={showChange}
      formState={formState}
      patchFormState={patchFormState}
      addCommentField={addCommentField}
      addReferenceField={addReferenceField}
      elementTitle={elementTitle}
    />
  );
};
