import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import { SamplingTableColumn } from '../../../components/auditing/MaterialsAndServices.SamplingTable';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { hidePartBasedOnItAndProcesses } from '../../../utils/auditing';
import { FormFieldBuilder } from '../FormFieldBuilder';
import {
  innerCircleElement,
  innerCircleElementDFS,
} from './reUsables/innerCircle';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { ProcessDescriptionTable } from './reUsables/ProcessDescription';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';
import { t } from '../../../i18n';
import { AuditingHelpInfoBox } from '../../../components/auditing/AuditingHelpInfoBox';
import { RentTable } from './notReUsables/otherOperatingExpenses.rentTable';
import { exampleData } from './notReUsables/otherOperatingExpenses.tablesUtils';

type SectionFormType = UseInnerCircle<OtherOperatingExpensesForm>;
const sectionKey: AuditingSectionKey = 'otherOperatingExpenses';
export const tBase = `auditing:form.${sectionKey}`;

const samplingLargestUnits = t(`${tBase}.samplingTable.largestUnits`);
const samplingRandomUnits = t(`${tBase}.samplingTable.randomUnits`);
const monetarySampling = t(`${tBase}.samplingTable.monetary`);
const otherSamplingProcedure = t(
  `${tBase}.samplingTable.otherSamplingProcedure`
);

const vocabulary = auditingJson?.form?.otherOperatingExpenses || {};
//const batch = FinancialStatementBatch['KB-otherOperatingExpenses'];
const batchString = 'KB-otherOperatingExpenses';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  const processTextData =
    auditing?.materialsAndServices?.form.processText || '';
  const processDateData =
    auditing?.materialsAndServices?.form.processDate || '';
  const processValueData =
    auditing?.materialsAndServices?.form.processValue || '';
  return {
    procedureAccountsManualData: [
      {
        currentYear: 0,
        priorYear: 0,
        accountName: '0000...',
        comment: '',
      },
    ],
    procedureAccountsData: {},
    firstTable: [
      {
        id: '1',
        account: '',
        CY: 0,
        PY: 0,
        other: '',
      },
    ],
    tableColumnData: [
      {
        left: 'Muihin kuluihin kirjatut tapahtumat ovat toteutuneet ja kuuluvat yhteisölle.',
        right: auditingJson.TAP,
      },
      {
        left: 'Kaikki tapahtumat, jotka olisi tullut kirjata kuluksi on kirjattu.',
        right: auditingJson.TAY,
      },
      {
        left: 'Kulut on kirjattu oikeassa määrässä.',
        right: auditingJson.OIK_ARV,
      },
      {
        left: 'Tilikaudelle on kirjattu vain suoriteperusteisesti sille kuuluvat kuluerät.',
        right: auditingJson.KAT,
      },
      {
        left: 'Muiden kulujen alle on luokiteltu ainoastaan sinne kuuluvia eriä.',
        right: auditingJson.LUOK,
      },
      {
        left: 'Muiden kulujen tapahtumat on yhdistelty ja jaoteltu oikein / ne on kuvattu selvästi, ja/tai niihin liittyvät tiedot tilinpäätöksessä ovat relevantteja ja ymmärrettäviä sovellettavan tilinpäätösnormiston mukaan.',
        right: auditingJson.ESI,
      },
    ],
    textArea:
      'Suoritetuilla tarkastustoimenpiteillä on saatu riittävä evidenssi liiketoiminnan muiden kulujen oikeellisuudesta ja tarkastuksen tavoitteet on saavutettu. Suoritetun tarkastuksen perusteella liiketoiminnan muut kulut ovat olennaisin osin oikein.',
    // Muiden kulujen analyyttinen tarkastus
    inspectionConclusionReview: '1',

    // Muiden kulujen katko
    samplingTable: [
      {
        id: '1',
        label: samplingLargestUnits,
        one: false,
        more: false,
      },
      {
        id: '2',
        label: samplingRandomUnits,
        one: false,
        more: false,
      },
      {
        id: '3',
        label: monetarySampling,
        one: false,
        more: false,
      },
      {
        id: '4',
        label: otherSamplingProcedure,
        one: false,
        more: false,
      },
    ],

    // Vuokrakulujen tarkastus
    useRentTable: false,
    useRentTableLabel: 'Käytä laskentataulukkoa vuokrien korotusten laskemiseen',
    rentTablesData: exampleData,

    // Muiden kulujen aineistotarkastus
    largestUnitsTextField:
      'Valitaan tarkastukseen olennaiset, yli PM:n ylittävät yksittäiset kulutapahtumat ja -tilit. Tarkastukseen valitut tapahtumat kattavat yhteensä XX eur eli X % liiketoiminnan muista kuluista.',
    randomUnitsTextField:
      'Kuvaa, millä perustein otoskoko on katsottu riittäväksi. Muiden kulujen oikeellisuudesta on saatu evidenssiä analyyttisen tarkastelun perusteella. Tarkastuksen perusteella muissa kuluissa ei ole havaittu poikkeavia saldoja tai sellaisia muutoksia, joille ei ole loogista selitystä. Muut kulut koostuvat tavanomaisista kulueristä. Muut suoritetut toimenpiteet ja niiden kautta saatu evidenssi vähentävät tarvetta aineistotarkastustoimenpiteille. Tarkastetaan pistokokein tapahtumia XX kpl. Tarkastus kattaa yhteensä XX eur eli X % liiketoiminnan muista kuluista. Ammatillisen harkinnan perusteella arvioimme kattavuuden riittäväksi.',
    otherSamplingProcedureTextField:
      'Tarkastettavat satunnaiset yksiköt on valittu monetary unit sampling -otannalla. Otannan perusteet ja määrittelyt ks. KBX.X MUS // Otoskoon määrittelyyn on käytetty otantatyökalua ks. otoskoon määrittely KBX.X',

    processText: processTextData,
    processDate: processDateData,
    processValue: processValueData,
    useSheetTab: false,
    useSheet: null,
    sheetdata1: [
      {
        cellData: [],
      },
    ],
    useSheet2: null,
    sheetdata2: [
      {
        cellData: [],
      },
    ],
    useSheet3: null,
    sheetdata3: [
      {
        cellData: [],
      },
    ],
    useSheet4: null,
    sheetdata4: [
      {
        cellData: [],
      },
    ],
    ...innerCircleElementDFS,
  };
};

const formFields: FormFields<SectionFormType> = ({
  formState,
  auditing,
  store,
}) => [
    formBuilder.custom(
      <AuditingHelpInfoBox />
    ),
    formBuilder.group({
      items: [
        formBuilder.boolean({
          accessor: 'useSheetTab',
          plainLabel: 'Käytä laskenta taulukko osiota',
        }),
        formBuilder.custom(props => (
          <ProcedureAccountsElement
            auditing={auditing}
            {...props}
            batchString={batchString}
            addCommentField
          />
        )),
        formBuilder.custom(props => (
          <TableColumn
            title="Mahdollinen virheellisyyden riski muissa kuluissa"
            headerText="Olennaisen virheellisyyden riskin kannanotto, johon tarkastustoimenpiteillä vastataan   (ISA 315.11)"
            {...props}
          />
        )),
        formBuilder.textArea({
          accessor: 'textArea',
        }),
      ],
    }),

    formBuilder.accordionGroup({
      title: auditingJson.form.itAndProcesses.processDescript,
      items: [
        formBuilder.custom(props => (
          <ProcessDescriptionTable
            processText={auditing?.materialsAndServices?.form.processText ?? ''}
            processDate={auditing?.materialsAndServices?.form.processDate ?? ''}
            processValue={auditing?.materialsAndServices?.form.processValue ?? ''}
            {...props}
          />
        )),
      ],
      hidden: hidePartBasedOnItAndProcesses(auditing, 'purchases'),
    }),

    ...displayAuditingProcedures(store, batchString, formBuilder, [
      {
        // Muiden kulujen analyyttinen tarkastus
        accordionId: 'analyticalReview',
        upperElements: [
          formBuilder.checkboxGroup({
            accessor: 'inspectionConclusionReview',
            options: Object.keys(
              vocabulary?.inspectionConclusionReviewOptions || {}
            ),
          }),
        ],
        lowerElements: [
          formBuilder.boolean({
            accessor: 'useSheet',
            plainLabel: auditingJson.useSheet,
          }),
          formBuilder.custom(props =>
            formState.useSheet ? <SpreadSheet id="sheetdata1" {...props} /> : null
          ),
        ],
      },
      {
        // Muiden kulujen katko
        accordionId: 'interruptionExpenses',
        lowerElements: [
          formBuilder.boolean({
            accessor: 'useSheet3',
            plainLabel: auditingJson.useSheet,
          }),
          formBuilder.custom(props =>
            formState.useSheet3 ? (
              <SpreadSheet id="sheetdata3" {...props} />
            ) : null
          ),
        ],
      },
      {
        // Muiden kulujen aineistotarkastus
        accordionId: 'materialInspection',
        upperElements: [
          formBuilder.custom(props => <SamplingTableColumn {...props} />),
          formBuilder.textArea({
            accessor: 'largestUnitsTextField',
            hidden: !formState.samplingTable.some(
              item =>
                item.label === samplingLargestUnits && (item.one || item.more)
            ),
          }),
          formBuilder.textArea({
            accessor: 'randomUnitsTextField',
            hidden: !formState.samplingTable.some(
              item =>
                item.label === samplingRandomUnits && (item.one || item.more)
            ),
          }),
          formBuilder.textArea({
            accessor: 'otherSamplingProcedureTextField',
            hidden: !formState.samplingTable.some(
              item =>
                (item.label === monetarySampling ||
                  item.label === otherSamplingProcedure) &&
                (item.one || item.more)
            ),
          }),
        ],
        lowerElements: [
          formBuilder.boolean({
            accessor: 'useSheet4',
            plainLabel: auditingJson.useSheet,
          }),
          formBuilder.custom(props =>
            formState.useSheet4 ? (
              <SpreadSheet id="sheetdata4" {...props} />
            ) : null
          ),
        ],
      },
      // Vuokrakulujen tarkastus
      {
        accordionId: 'rentalExpensesReview',
        lowerElements: [
          formBuilder.group({
            items: [
              formBuilder.custom(props => <RentTable {...props}/>),
            ]
          })
        ]
      },
      {
        // Lakikulut
        accordionId: 'legalFees',
        lowerElements: [
          formBuilder.boolean({
            accessor: 'useSheet2',
            plainLabel: auditingJson.useSheet,
          }),
          formBuilder.custom(props =>
            formState.useSheet2 ? (
              <SpreadSheet id="sheetdata2" {...props} />
            ) : null
          ),
        ],
      },
      {
        // Lähipiiri
        useDefault: false,
        accordionId: 'innerCircle',
        upperElements: innerCircleElement(formBuilder),
      },
    ]),
  ];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
