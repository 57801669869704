import { useStore } from '../../../../store/RootContext';
import { Button } from '../../../../components/inputs';
import { useEffect, useState } from 'react';
import { deepEqual } from '../../../../utils/deepEqual';

export function CopyRisksData() {
  const {
    auditingStore: {
      copyIncomeStatementAndBalanceToScoping,
      isAuditingSectionFinished,
    },
  } = useStore();

  const hideButton = isAuditingSectionFinished(
    'incomeStatementAndBalanceScoping'
  );

  const handleCopyToScopingClick = () => {
    copyIncomeStatementAndBalanceToScoping();
  };

  return (
    <>
      {!hideButton && (
        <Button
          onClick={handleCopyToScopingClick}
          text="Kopio riskit kaudenaikaisesta tarkastuksesta"
        />
      )}
    </>
  );
}

export function CopyAccountMapData() {
  const {
    auditingStore: { copyAccountMapToScoping, isAuditingSectionFinished },
  } = useStore();

  const hideButton = isAuditingSectionFinished('accountMapScoping');

  return (
    <>
      {!hideButton && (
        <Button
          onClick={copyAccountMapToScoping}
          text="Kopio tilikartta tilikaudenaikaisesta tarkastusosiosta."
        />
      )}
    </>
  );
}

export const useCopyFromIncomeStatementAndBalanceScoping = ({
  formState,
  patchFormState,
  store
}: FormFieldProps<PreliminaryAnalyticForm>) => {
  const incomeStatementAndBalanceScopingForm = store.auditingStore?.auditing?.incomeStatementAndBalanceScoping?.form;
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  useEffect(() => {
    const copy = () => {

      const defaultDateValues = {
        dateIncome: 'Ajanjakso 1.1-31.12.202X.',
        dateBalance: 'Ajanjakso 1.1-31.12.202X.',
        dateLiabilities: 'Ajanjakso 1.1-31.12.202X.',
      };

      const ifExistsCheckForEquality = (value1?: string, value2?: string) => {
        if (value1 !== undefined && value2 !== undefined) {
          return value1 === value2;
        }
        return true;
      };

      if (incomeStatementAndBalanceScopingForm) {
        const areFormsEqual = [
          deepEqual(formState.incomeStatement, incomeStatementAndBalanceScopingForm.incomeStatement),
          deepEqual(formState.balanceAssets, incomeStatementAndBalanceScopingForm.balanceAssets),
          deepEqual(formState.balanceLiabilities, incomeStatementAndBalanceScopingForm.balanceLiabilities),
          // defaultFormState values are not saved to the formState
          // hence the undefined check
          ifExistsCheckForEquality(formState.dateBalance, incomeStatementAndBalanceScopingForm.dateBalance),
          ifExistsCheckForEquality(formState.dateIncome, incomeStatementAndBalanceScopingForm.dateIncome),
          ifExistsCheckForEquality(formState.dateLiabilities, incomeStatementAndBalanceScopingForm.dateLiabilities)
        ];

        if (areFormsEqual.some((value, index) => {
          return !value;
        })) {
          const patch = {
            ...formState,

            // Keep modified comments
            incomeStatement: incomeStatementAndBalanceScopingForm.incomeStatement.map((item, index) => {
              return {
                ...item,
                statementDescription: formState.incomeStatement[index]?.statementDescription ?? item?.statementDescription,
              }
            }),

            balanceAssets: incomeStatementAndBalanceScopingForm.balanceAssets.map((item, index) => {
              return {
                ...item,
                statementDescription: formState.balanceAssets[index]?.statementDescription ?? item?.statementDescription,
              }
            }),

            balanceLiabilities: incomeStatementAndBalanceScopingForm.balanceLiabilities.map((item, index) => {
              return {
                ...item,
                statementDescription: formState.balanceLiabilities[index]?.statementDescription ?? item?.statementDescription,
              }
            }),

            dateBalance: incomeStatementAndBalanceScopingForm.dateBalance ?? defaultDateValues.dateBalance,
            dateIncome: incomeStatementAndBalanceScopingForm.dateIncome ?? defaultDateValues.dateIncome,
            dateLiabilities: incomeStatementAndBalanceScopingForm.dateLiabilities ?? defaultDateValues.dateLiabilities,
          }

          patchFormState(patch);
        }
      }
    };

    // Run immediately on first load
    if (firstLoad) {
      setFirstLoad(false);
      copy();
    };

  }, [formState, incomeStatementAndBalanceScopingForm, patchFormState, firstLoad]);
}