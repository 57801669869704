import { t } from '../../../i18n';
import { exists } from 'i18next';
import { AuditingTemplate, uuid } from '../../../utils';
import { AccountClassKey, AccountKey } from '../planning/accountMapKeys';
import { Risk } from '../analyzesAndScoping/interim/incomeStatementAndBalance';
import { mapActionKeyToFormStrings } from './formStringMappings';
import { RootStore } from '../../../store/RootStore'; 
import { tabSettings } from '../../../projectConfig';
import { RootStore } from '../../../store/RootStore'; 

export enum AuditingProcedureResult {
  notStarted = 'notStarted',
  inProgress = 'inProgress',
  treatedGood = 'treatedGood',
  treatedModerately = 'treatedModerately',
  shortcomings = 'shortcomings',
  noEvidenceReceived = 'noEvidenceReceived',
}

export const hasShortcomings = ({ auditingResult }: AuditingProcedure) =>
  auditingResult === AuditingProcedureResult.shortcomings;

export const auditingProcedureResultToNumber = (
  auditingProcedureResult: AuditingProcedureResult | null
) => {
  switch (auditingProcedureResult) {
    case AuditingProcedureResult.notStarted:
      return 0;
    case AuditingProcedureResult.inProgress:
      return 6;
    case AuditingProcedureResult.treatedGood:
      return 1;
    case AuditingProcedureResult.treatedModerately:
      return 2;
    case AuditingProcedureResult.shortcomings:
      return 3;
    case AuditingProcedureResult.noEvidenceReceived:
      return 4;
    case null:
      return 5;
  }
};

/**
 * Helper to look if a given income statement items have risks
 */
export const incomeStatementAndBalanceItemHasNoRisks =
  (auditing?: Auditing) =>
  (...incomeStatementAndBalanceKeys: string[]) => {
    const { incomeStatement, balanceAssets, balanceLiabilities } =
      // Takes from incomeStatementAndBalance form (Tuloslaskelma ja tase - Tarkastus) Risk
      auditing?.incomeStatementAndBalance?.form ?? {};
    // Add a new incomeStatementAndBalanceScoping form (Tuloslaskelma ja tase - Päätös) Risk
    const {
      incomeStatement: incomeStatementScoping,
      balanceAssets: balanceAssetsScoping,
      balanceLiabilities: balanceLiabilitiesScoping,
    } = auditing?.incomeStatementAndBalanceScoping?.form ?? {};

    const keyValueHasSomeRisk = (item: IncomeStatementAndBalanceItem) =>
      incomeStatementAndBalanceKeys.includes(
        item.classKey ? AccountClassKey[item.classKey] : AccountKey[item.key]
      ) &&
      item.risk &&
      [Risk.significant, Risk.limitedRisk].includes(item.risk);

    const itemsHavingSomeRisk = [
      ...(incomeStatement ?? []).filter(keyValueHasSomeRisk),
      ...(balanceAssets ?? []).filter(keyValueHasSomeRisk),
      ...(balanceLiabilities ?? []).filter(keyValueHasSomeRisk),
      ...(incomeStatementScoping ?? []).filter(keyValueHasSomeRisk),
      ...(balanceAssetsScoping ?? []).filter(keyValueHasSomeRisk),
      ...(balanceLiabilitiesScoping ?? []).filter(keyValueHasSomeRisk),
    ];
    const noRisksFound = !itemsHavingSomeRisk.length;

    return noRisksFound;
  };

// If you add new batch, remember to add it to the 'batchToFileNameMap' in handleExcelUtils.tsx
export enum FinancialStatementBatch {
  'REMARK' = 'REMARK',
  'B-commonActions' = 'B-commonActions',
  'F-financialStatement' = 'F-financialStatement',
  'F8-manualExports' = 'F8-manualExports',
  'F9-continuityOfOperation' = 'F9-continuityOfOperation',
  'F1-assignmentsFirstTime' = 'F1-assignmentsFirstTime',
  'F3-presentationAndContent' = 'F3-presentationAndContent',
  'G-management' = 'G-management',
  'G4.4-innerCircle' = 'G4.4-innerCircle',
  'J-operatingIncome' = 'J-operatingIncome',
  'JB-otherOperatingIncome' = 'JB-otherOperatingIncome',
  'J1-turnover' = 'J1-turnover',
  'J2-accountsReceivable' = 'J2-accountsReceivable',
  'K-materialsAndServices' = 'K-materialsAndServices',
  'KB-otherOperatingExpenses' = 'KB-otherOperatingExpenses',
  'K1-materialsAndServices' = 'K1-materialsAndServices',
  'K2-tradeCreditors' = 'K2-tradeCreditors',
  'L-personelCosts' = 'L-personelCosts',
  'M-governmentTransfersAndTaxRevenues' = 'M-governmentTransfersAndTaxRevenues',
  'M-governmentTransfers' = 'M-governmentTransfers',
  'N-taxes' = 'N-taxes',
  'OA-deprecations' = 'OA-deprecations',
  'OB-financialStatementTransfers' = 'OB-financialStatementTransfers',
  'O1-fixedAssets' = 'O1-fixedAssets',
  'O3-investments' = 'O3-investments',
  'O4-funding' = 'O4-funding',
  'P-inventories' = 'P-inventories',
  'P1-warehouses' = 'P1-warehouses',
  'Q1-otherReceivables' = 'Q1-otherReceivables',
  'Q2-cashEquivalents' = 'Q2-cashEquivalents',
  'Q3-loanReceivables' = 'Q3-loanReceivables',
  'RA-subordinatedLoans' = 'RA-subordinatedLoans',
  'RB-provisions' = 'RB-provisions',
  'R1-ownCapital' = 'R1-ownCapital',
  'R2-capitalLoans' = 'R2-capitalLoans',
  'S1-financialInstitutions' = 'S1-financialInstitutions',
  'T-otherCreditors' = 'T-otherCreditors',
  'T1-otherLiabilities' = 'T1-otherLiabilities',
  'T2-advancePayments' = 'T2-advancePayments',
  'U-assignmentAssetsAndLiabilities' = 'U-assignmentAssetsAndLiabilities',
  'V-reserves' = 'V-reserves',
  'XJ-financialIncome' = 'XJ-financialIncome',
  'XK-financialExpenses' = 'XK-financialExpenses',
}

export type FinancialStatementBatchKey = keyof typeof FinancialStatementBatch;

export const initialBatches: FinancialStatementBatch[] = [
  FinancialStatementBatch['B-commonActions'],
  FinancialStatementBatch['F-financialStatement'],
  FinancialStatementBatch['F3-presentationAndContent'],
  FinancialStatementBatch['G-management'],
];

export const isDefaultBatch = (batch: FinancialStatementBatch) =>
  initialBatches.includes(batch);

export const isRemarkBatch = ({ batch }: { batch: FinancialStatementBatch }) =>
  batch === FinancialStatementBatch.REMARK;

export const isNotRemarkBatch = ({
  batch,
}: {
  batch: FinancialStatementBatch;
}) => batch !== FinancialStatementBatch.REMARK;

/**
 * Check if auditing procedure has either "period" or "tp" (or both) value set to true.
 */
export const hasPeriodOrTP = (auditingProcedure: AuditingProcedure) => {
  return !!auditingProcedure.period || !!auditingProcedure.tp;
};

/**
 * Check if auditing procedure has inExcel value set to true.
 */
export const hasInExcel = (auditingProcedure: AuditingProcedure) => {
  if (auditingProcedure.inExcel) {
    auditingProcedure.period = null;
    auditingProcedure.tp = null;
  }
  return !!auditingProcedure.inExcel;
};

/**
 * Checks conditions and updates auditing procedures results.
 * @param auditingProcedures AuditingProcedure[]
 * @param store The store containing the auditing data
 */
export const checkAndUpdateAuditingProcedures = (
  auditingProcedures: AuditingProcedure[],
  store: RootStore
) => {
  const presentationAndContent = store.auditingStore.getAuditingSection(
    'presentationAndContent'
  );
  const manualExports = store.auditingStore.getAuditingSection('manualExports');
  const theOpeningBalance = presentationAndContent?.form.theOpeningBalance;
  const theOpeningBalanceMore =
    presentationAndContent?.form.theOpeningBalanceMore;
  const theClosingBalance = presentationAndContent?.form.theClosingBalance;
  const theClosingBalanceMore =
    presentationAndContent?.form.theClosingBalanceMore;
  const unusualShortcuts = manualExports?.form.unusualShortcuts;

  const conditions = [
    {
      condition: unusualShortcuts?.one === true,
      batch: FinancialStatementBatch['F-financialStatement'],
      actionKeys: ['manualExports'],
      result: AuditingProcedureResult.treatedGood,
    },
    {
      condition: theOpeningBalance?.one === true,
      batch: FinancialStatementBatch['F-financialStatement'],
      actionKeys: ['auditBalanceAndFinancialStatements'],
      result: AuditingProcedureResult.treatedGood,
    },
    {
      condition: theOpeningBalanceMore?.one === true,
      batch: FinancialStatementBatch['F-financialStatement'],
      actionKeys: ['auditBalanceAndFinancialStatements'],
      result: AuditingProcedureResult.treatedModerately,
    },
    {
      condition: theOpeningBalanceMore?.two === true,
      batch: FinancialStatementBatch['F-financialStatement'],
      actionKeys: ['auditBalanceAndFinancialStatements'],
      result: AuditingProcedureResult.shortcomings,
    },
    {
      condition: theClosingBalance?.one === true,
      batch: FinancialStatementBatch['F-financialStatement'],
      actionKeys: ['generalLedgerVsSubLedgerMatchings'],
      result: AuditingProcedureResult.treatedGood,
    },
    {
      condition: theClosingBalanceMore?.one === true,
      batch: FinancialStatementBatch['F-financialStatement'],
      actionKeys: ['generalLedgerVsSubLedgerMatchings'],
      result: AuditingProcedureResult.treatedModerately,
    },
    {
      condition: theClosingBalanceMore?.two === true,
      batch: FinancialStatementBatch['F-financialStatement'],
      actionKeys: ['generalLedgerVsSubLedgerMatchings'],
      result: AuditingProcedureResult.shortcomings,
    },
  ];

  // Update auditing procedures results based on conditions
  return auditingProcedures.map(procedure => {
    const matchingCondition = conditions.find(
      condition =>
        condition.batch === procedure.batch &&
        condition.actionKeys.includes(procedure.actionKey)
    );
    // If there is a matching condition, update the auditing result
    if (matchingCondition) {
      procedure.auditingResult = matchingCondition.result
        ? matchingCondition.result
        : AuditingProcedureResult.notStarted;
    }

    return procedure;
  });
};

/**
 * Filter auditing proceduder that should be visible in "auditing" & "reporting" phases
 */
export const pruneAuditingProcedures =
  (auditing?: Auditing) => (auditingProcedure: AuditingProcedure) => {
    const { batch } = auditingProcedure;

    const batchHasSomeRisks =
      !incomeStatementAndBalanceItemHasNoRisks(auditing)(batch);

    const isValidBatch = isDefaultBatch(batch) || batchHasSomeRisks;

    return (
      (isValidBatch && hasPeriodOrTP(auditingProcedure)) ||
      (isValidBatch && hasInExcel(auditingProcedure))
    );
  };

export const batchName = (batch: FinancialStatementBatch) =>
  t(`auditing:accountClass.${batch}`);

export enum FinancialStatementEntirety {
  financialStatementAndAccounting = 'financialStatementAndAccounting',
  internalControlAndManagement = 'internalControlAndManagement',
  businessIndicators = 'businessIndicators',
  lawAndTaxation = 'lawAndTaxation',
  inspectionGeneralAndOthers = 'inspectionGeneralAndOthers',
}

// Use shortened Batch & Entirety collections internally (just to keep lines more compact)
const Batch = { ...FinancialStatementBatch };
const Entirety = {
  ...FinancialStatementEntirety,
  // Below is just simple shorthands for entirety enum values:
  '1': FinancialStatementEntirety.financialStatementAndAccounting,
  '2': FinancialStatementEntirety.internalControlAndManagement,
  '3': FinancialStatementEntirety.businessIndicators,
  '4': FinancialStatementEntirety.lawAndTaxation,
  '5': FinancialStatementEntirety.inspectionGeneralAndOthers,
};
type EntiretyKey = '1' | '2' | '3' | '4' | '5';

export const makeAuditingProcedure = (
  props: Partial<AuditingProcedure> & { batch: FinancialStatementBatch },
  store?: RootStore
): AuditingProcedure => {
  const id = uuid();

  const template = store?.auditingStore.auditingTemplate;
  const procedureType = getProcedureType(props.actionKey);

  let auditingReference = '';
  let auditingComments = '';
  let auditingProcedureGoals = '';
  let auditingProcedurePerceptions = '';
  let inspectionBackground = '';
  let inspectionDescription = '';

  if (procedureType === 'accountingEstimates') {
    ({
      auditingReference,
      auditingComments,
      auditingProcedureGoals,
      inspectionBackground,
      inspectionDescription
    } = mapActionKeyToFormStrings(props.actionKey, template, procedureType));

  } else if (props.actionKey === 'describePeriod') {
    auditingProcedurePerceptions = t(
      'auditing:form.auditingProcedures.auditingProcedurePerceptionsValue'
    );

  } else if (props.actionKey === 'innerCircle') {
    ({ auditingReference, auditingComments, auditingProcedureGoals } =
      mapActionKeyToFormStrings(props.actionKey, template, procedureType));

    props.innerCircleAuditingResult = {
      handlingInAccounting: null,
      demonstratedData: null,
      effectOnPicture: null,
      abuseRisks: null,
    };

  } else {
    ({ auditingReference, auditingComments, auditingProcedureGoals } =
      mapActionKeyToFormStrings(props.actionKey, template, procedureType));
  }

  return {
    id,
    actionKey: `action-${id}`,
    action: '',
    type: procedureType,
    entirety: null,
    period: null,
    tp: null,
    inExcel: null,
    auditor: '',
    auditingResult: null,
    auditingReference: auditingReference,
    auditingComments: auditingComments,
    auditingProcedureGoals: auditingProcedureGoals,
    auditingProcedureNatureAndScopeAndExpectations: '',
    auditingProcedurePerceptions: auditingProcedurePerceptions,
    inspectionBackground: inspectionBackground,
    inspectionDescription: inspectionDescription,
    impactOnIncomeStatement: null,
    impactOnBalanceSheet: null,
    impactOnEquity: null,
    conclusion: '',
    fixed: null,
    ...props,
  };
};

export const makeNewControl = (
  props: Partial<AuditingProcedure> & { batch: FinancialStatementBatch }
): AuditingProcedure => {
  const id = uuid();
  return {
    id,
    actionKey: `control-${id}`,
    action: '',
    entirety: null,
    period: null,
    tp: null,
    inExcel: null,
    auditor: '',

    controlTitle: t(
      'auditing:form.auditingProcedures.controlTitleValue'
    ),
    controlWorksEfficiently: null,
    referenceC: t(
      'auditing:form.auditingProcedures.referenceCValue'
    ),
    descriptionOfControl: t(
      'auditing:form.auditingProcedures.descriptionOfControlValue'
    ),
    controlIsAutomatic: null,
    descriptionOfPlannedActions: t(
      'auditing:form.auditingProcedures.descriptionOfPlannedActionsValue'
    ),
    ideasGoalsAndCompletedWorkControl: t(
      'auditing:form.auditingProcedures.ideasGoalsAndCompletedWorkControlValue'
    ),
    notesAndComments: t(
      'auditing:form.auditingProcedures.notesAndCommentsValue'
    ),

    auditingResult: null,
    auditingReference: '',
    auditingComments: t('auditingCommentsValue'),
    auditingProcedureGoals: '',
    auditingProcedureNatureAndScopeAndExpectations: '',
    auditingProcedurePerceptions: '',
    impactOnIncomeStatement: null,
    impactOnBalanceSheet: null,
    impactOnEquity: null,
    conclusion: '',
    fixed: null,
    ...props,
  };
};

export const transformRemarkToAuditingProcedure = (
  remark: AuditingRemark
): AuditingProcedure =>
  makeAuditingProcedure({
    id: remark.key,
    batch: FinancialStatementBatch.REMARK,
    action: remark.label,
    period: false,
    tp: false,
    auditingResult: AuditingProcedureResult.shortcomings,
    auditingReference: t(`route:auditing.${remark.sectionKey}`),
  });

type Action = string;
type Period = boolean | null;
type TP = boolean | null;
type AuditingProcedureTuple = [Action, EntiretyKey, Period, TP];

const castToTupleArrays = (array: (string | boolean | null)[][]) =>
  array as AuditingProcedureTuple[];

export const getProcedureType = (actionKey?: string): procedureType => {
  let bType: procedureType;

  if(actionKey?.startsWith('accountingEstimates')) {
    bType = 'accountingEstimates';
  }
  else if(actionKey?.startsWith('innerCircle')) {
    bType = 'innerCircle'
  }
  else if(actionKey === 'describePeriod') {
    bType = 'describePeriod'
  }
  else {
    bType = 'default'
  }
  return bType;
}

const toAuditingProcedure =
  (batch: FinancialStatementBatch, store?: RootStore) =>
  ([actionKey, entiretyKey, period, tp]: AuditingProcedureTuple) => {
    const action = (store?.auditingStore?.auditingTemplate === AuditingTemplate.public
      && exists(`auditing:auditingProcedureAction.${batch}.${actionKey}-public`))
        ? t(`auditing:auditingProcedureAction.${batch}.${actionKey}-public`)
        : t(`auditing:auditingProcedureAction.${batch}.${actionKey}`);
  
    return makeAuditingProcedure({
      batch,
      actionKey,
      action: action,
      entirety: Entirety[entiretyKey],
      period,
      tp: tabSettings.displayAllParts ? true : tp,
      auditingResult: AuditingProcedureResult.notStarted,
    }, store);
  }

/** @description
 * Array of default auditing procedures. These are the tabs on the left-hand side, starting from "Auditing" dropdown. */
export const defaultAuditingProcedures = (store?: RootStore, auditing?: Auditing): AuditingProcedure[] => {
  const uncheck = (procedure: Array<string | boolean | null>) => {
    let newProcedure = [...procedure];
    if (auditing?.template === AuditingTemplate.public) {
      newProcedure = procedure.map((item, index) => {
        if (index === 2 || index === 3) {
          return null;
        }
        return item;
      }
    )};
    return newProcedure;
  };

  const uncheckIfPublicSector = (procedure: Array<string | boolean | null>) =>
    auditing?.template === AuditingTemplate.public ? uncheck(procedure) : procedure;

  return [
  /************************************************************************** /
   * B - Tarkastuksen yleiset toimet
   ***************************************************************************/
  ...castToTupleArrays([
    ['discussionsWithManagement', '5', true, true],
    ['auditPlan', '5', null, true],
    ['confirmationLetter', '5', null, true],
    ['dataRequests', '5', null, true],
    ['reportingInterimReportAndOtherPossibleReports', '5', null, true],
  ]).map(toAuditingProcedure(Batch['B-commonActions'], store)),

  /************************************************************************** /
   * F - Tilinpäätös ja kirjanpito
   ***************************************************************************/

  ...castToTupleArrays([
    ['assignmentsFirstTime', '1', null, null],
    ['manualExports', '1', null, true],
    ['auditBalanceAndFinancialStatements', '1', null, true],
    ['analyticalReviewOfIncomeStatementAndBalanceSheet', '1', null, true],
    ['consolidationAudit', '1', null, true],
    ['generalLedgerVsSubLedgerMatchings', '1', null, true],
    ['continuityOfOperation', '1', null, null],

    //['gitcUserRights', '2', true, null],
    //['auditOfOpeningBalance', '1', true, null],
    //['reviewOfOpeningBalanceAndPreviousFinancialStatements', '1', true, null],
    //['reviewOfFinancialStatementPresentationAndContent', '1', null, true],
    //['reviewOfManagementReportContent', '1', null, true],
    //['auditOfEventsAfterTheBalanceSheetDate', '1', null, true],
    //['reviewOfFinancialStatementSignatures', '1', null, true],
  ]).map(toAuditingProcedure(Batch['F-financialStatement'], store)),

  /************************************************************************** /
   * F3 - Tilinpäätöksen prikkaus ja sisältö
   ***************************************************************************/
  ...castToTupleArrays([
    ['appropriatenessOfFinancial', '1', true, null],
    ['summaryOfTheFinancial', '1', true, null],
    ['inspectionOfTheConsolidated', '1', null, null],
    ['summaryAndContent', '1', null, null],
    ['inspectionOfResponsibilities', '1', null, null],
  ]).map(toAuditingProcedure(Batch['F3-presentationAndContent'], store)),

  /************************************************************************** /
   * P1 - Vaihto-omaisuus
   * *************************************************************************/
  ...castToTupleArrays([
    ['describePeriod-P1', '1', null, null],
    ['reconciliationOfInventory', '1', null, true],
    ['inventoryChangeReconciliation', '1', null, true],
    ['costTesting', '1', null, true],
    ['comparingTheInventory', '1', null, true],
    ['dataAnalysis', '1', null, null],
    ['participationInInventory', '1', null, true],
    ['checkingStock', '1', null, null],
    ['accountingEstimates-P1', '1', null, null],
  ]).map(toAuditingProcedure(Batch['P1-warehouses'], store)),

  /************************************************************************** /
   * G - Hallinto
   ***************************************************************************/

  ...castToTupleArrays([
    ['internalInstructions', '2', null, true],
    ['groupInstruction', '2', null, true],
    ['boardMeetingMinutes', '2', null, true],
    ['insurancePolicies', '2', null, true],
    ['affiliationRegister', '2', null, true],
    ['innerCircle', '2', null, true],
  ]).map(toAuditingProcedure(Batch['G-management'], store)),

  /************************************************************************** /
   * J1 - Liikevaihto
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod-J1', '1', true, true],
    ['processMappingWtAndMainAccountingPrinciples', '1', true, null],
    ['matchingOfSalesToInvoicing', '1', null, true],
    ['reviewOfRevenueRecognition', '1', null, true],
    ['reviewOfIndividualTransactions', '1', true, null],
    ['innerCircle', '1', true, null],
    ['accountingEstimates-J1', '1', null, null],
  ]).map(toAuditingProcedure(Batch['J1-turnover'], store)),

  /************************************************************************** /
   * JB - Liiketoiminnan muut tuotot
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod-JB', '1', null, null],
    ['individualTransactionInspection', '1', null, true],
    ['breakCheck', '1', null, null],
    ['innerCircle', '1', null, null],
  ]).map(toAuditingProcedure(Batch['JB-otherOperatingIncome'], store)),

  /************************************************************************** /
   * J2 - Myyntisaamiset
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod-J2', '1', null, null],
    ['accountsReceivableReconciliation', '1', null, true],
    ['mathematicalAccuracy', '1', null, true],
    ['recheckingAccountsReceivable', '1', null, true],
    ['payments', '1', null, true],
    ['balanceConfirmation', '1', null, null],
    ['paymentConfirmation', '1', null, true],
    ['classificationAndPresentation', '2', null, null],
    ['accountingEstimates-J2', '1', null, null],
  ]).map(toAuditingProcedure(Batch['J2-accountsReceivable'], store)),

  /************************************************************************** /
   * K - Materiaalit ja palvelut
   ***************************************************************************/

  ...castToTupleArrays([
    ['reviewOfIndividualTransactions', '1', true, null],
    ['riskAnalysisReview', '1', true, null],
    ['analysisOfProfitMargins', '1', true, true],
    ['dataAnalysis', '1', true, null],
    ['matchingOfAccountsPayableListToGeneralLedger', '1', true, null],
    ['classificationOfPurchases', '1', true, null],
    ['verificationOfPurchaseCutoff', '1', null, true],
    ['controlTesting', '2', true, null],
    ['processMappingWTAndKeyAccountingPolicies', '2', true, null],
    ['pivotingOfSuppliersAndReviewOfTheList', '1', true, null],
  ]).map(toAuditingProcedure(Batch['K-materialsAndServices'], store)),

  /************************************************************************** /
   * K1 - Materiaalit ja palvelut
   ***************************************************************************/
  ...castToTupleArrays([
    ['analyticalInspectionOfPurchases', '1', null, true],
    ['marginAnalysis', '1', null, true],
    ['inspectionOfInterruption', '1', null, null],
    ['materialInspection', '1', null, true],
    ['innerCircle', '1', null, null],
  ]).map(toAuditingProcedure(Batch['K1-materialsAndServices'], store)),

  /************************************************************************** /
   * K2 - Ostovelat
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod', '1', null, null],
    ['reconciliationOfThePurchase', '1', null, true],
    ['mathematicalCorrectness', '1', null, true],
    ['reviewOfAccounts', '1', null, true],
    ['breakInExpenses', '1', null, true],
    ['breakInExpensesEvents', '1', null, true],
    ['existenceOfAccounts', '1', null, null],
    ['classification', '1', null, null],
    ['accountingEstimates', '1', null, null],
  ]).map(toAuditingProcedure(Batch['K2-tradeCreditors'], store)),

  /************************************************************************** /
   * L - Henkilöstökulut
   ***************************************************************************/

  ...castToTupleArrays([
    //['describePeriod', '1', null, true],
    ['analyticalReviewOfWages', '1', null, true],
    ['analyticalAuditOfWages', '1', null, true],
    ['analyticalExaminationOfSideCosts', '1', null, true],
    ['analyticalAuditOfVacationPayAccruals', '1', null, true],
    ['reconciliationOfSalaries', '1', null, true],
    ['inspectionOfPersonnelExpenseAccurals', '1', null, true],
    ['managementSalaryAudit', '1', null, true]
  ]).map(toAuditingProcedure(Batch['L-personelCosts'], store)),

  /************************************************************************** /
   * KB - Liiketoiminnan muut kulut
   ***************************************************************************/

  ...castToTupleArrays([
    ['analyticalReview', '1', null, true],
    ['interruptionExpenses', '1', null, null],
    ['materialInspection', '1', null, true],
    ['rentalExpensesReview', '1', null, null],
    ['legalFees', '1', null, true],
    ['innerCircle', '1', null, null],
    /*     ['classificationOfExpenses', '1', null, true],
    ['legalExpenses', '1', true, null], */
  ]).map(toAuditingProcedure(Batch['KB-otherOperatingExpenses'], store)),

  /************************************************************************** /
   * M - Valtionosuudet ja verotulot
   ***************************************************************************/

  ...castToTupleArrays([
    ['reliabilityOfGovernmentSharesStudentInformation', '2', true, null],
    ['reliabilityOfGovernmentSharesBasicServices', '2', true, true],
    ['settlingForGovernmentSharesStudentInformation', '2', true, null],
    ['settlingForGovernmentSharesBasicServices', '2', true, null],
    ['taxReconciliationAccountingVersusTaxAuthority', '1', null, true],
    ['govermentSharesReconciliationStudentInformation', '1', null, true],
    ['govermentSharesReconciliationBasicServices', '1', null, true],
  ]).map(toAuditingProcedure(Batch['M-governmentTransfersAndTaxRevenues'], store)),

  /************************************************************************** /
   * M - Valtionosuudet
   ***************************************************************************/

  ...castToTupleArrays([
    ['municipalitiesAndWellbeingServicesFinancialReporting', '2', null, true],
    ['studentStatistics', '2', null, true],
    ['governmentTransfersDecision', '2', null, true],
  ]).map(toAuditingProcedure(Batch['M-governmentTransfers'], store)),

  /************************************************************************** /
   * T1 - Muut velat ja siirtovelat
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod-T1', '1', null, true],
    ['inspectionOfLoans', '1', null, null],
    ['auditOfOtherDebts', '1', null, null],
    ['auditOfAccruedLiabilities', '1', null, null],
    ['inspectionOfTheClassification', '1', null, null],
    ['completenessOfOther', '1', null, null],
    ['checkingOtherDebts', '1', null, true],
    ['innerCircle-T1', '1', null, null],
  ]).map(toAuditingProcedure(Batch['T1-otherLiabilities'], store)),

  /************************************************************************** /
   * XJ - Rahoitustuotot
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod-XJ', '1', null, null],
    ['auditFinancialIncome', '1', null, true],
    ['inspectionOfPersonnelExpenseAccurals', '1', null, true],
    ['checkingDividendIncome', '1', null, null],
    ['analyticalExamination-XJ', '1', null, null],
    ['analyticalExaminationOfFinancialExpenses-XJ', '1', null, null],
    ['reconciliationOfFinancialExpenses', '1', null, null],
    ['examinationOfImpairments', '1', null, null],
  ]).map(toAuditingProcedure(Batch['XJ-financialIncome'], store)),

  /************************************************************************** /
   * XK - Rahoituskulut
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod-XK', '1', null, null],
    /*
    ['inspectionOfPersonnelExpenseAccurals', '1', null, null],
    ['auditFinancialIncome', '1', null, true],
    */
    ['examinationOfFinancialExpenses', '1', null, true],
    ['reconciliationOfInterestExpenses', '1', null, null],
    ['analyticalExamination', '1', null, null],
    ['analyticalExaminationOfFinancialExpenses', '1', null, null],
    ['examinationOfPermanentPriceDecrease', '1', null, null],
    ['examinationOfChangingAssets', '1', null, null],
    ['analyticalExaminationFinancialExpenses', '1', null, null],
  ]).map(toAuditingProcedure(Batch['XK-financialExpenses'], store)),

  /************************************************************************** /
   * O1 - Käyttöomaisuus
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod-O1', '1', null, true],
    ['accountingReconciliation', '1', null, null],
    ['verifyingRemovals', '1', null, true],
    ['removedDifferencesCheck', '1', null, null],
    ['additionsCheck', '1', null, null],
    ['removalsCheck', '1', null, null],
    ['fixedAssetsAppreciation', '1', null, null],
    ['decreasedValueOfFixedAssetsCheck', '1', null, null],
    ['checkingAdditionsAndSubtractions', '1', null, null],
    ['accountingEstimates-O1', '1', null, true],
  ]).map(toAuditingProcedure(Batch['O1-fixedAssets'], store)),

  /************************************************************************** /
   * O3 - Sijoitukset
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod-O3', '1', null, true],
    ['reconciliationOfInvestments', '1', null, true],
    ['checkingAndVerifyingInvestments', '1', null, null],
    ['appreciationOfInvestments', '1', null, true],
    ['investmentsClassification', '1', null, true],
    ['appreciationOfSubsidiariesInvestments', '1', null, null],
    ['accountingEstimates-O3', '1', null, true]
  ]).map(toAuditingProcedure(Batch['O3-investments'], store)),

  /************************************************************************** /
   * P - Vaihto-omaisuus
   ***************************************************************************/

  ...castToTupleArrays([
    ['participationInInventory', '1', true, null],
    ['matchingOfInventoryChanges', '1', null, true],
    ['valuationAndPricingAuditOfInventory', '1', null, true],
    ['matchingOfInventoryListToLedger', '1', null, true],
    [
      'auditOfDifferencesBetweenFinalInventoryAndInventoryList',
      '1',
      null,
      true,
    ],
    ['dataAnalysis', '1', null, true],
    ['processMappingWTAndKeyAccountingPrinciples', '2', true, null],
    ['analysisOfInventoryResults', '2', null, true],
    ['analysisOfSlowMovingItems', '2', null, true],
  ]).map(toAuditingProcedure(Batch['P-inventories'], store)),

  /************************************************************************** /
   * Q1 - Saamiset
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod-Q1', '1', null, true],
    ['verificationOfReceivables', '1', null, null],
    ['checkingMovedReceivables', '1', null, null],
    ['classificationAndPresentation', '1', null, null],
    ['perfectionOfOtherReceivables', '1', null, null],
    ['inspectionOtherReceivables', '1', null, true],
    ['accountingEstimates-Q1', '1', null, null],
    ['innerCircle', '1', null, null],
  ]).map(toAuditingProcedure(Batch['Q1-otherReceivables'], store)),

  /************************************************************************** /
   * Q2 - Rahat ja pankkisaamiset
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod-Q2', '1', null, true],
    ['balanceConfirmation-Q2', '1', null, true],
    ['accountReconciliation', '1', null, true],
    ['balanceConfirmationThroughBank', '1', null, null],
    ['debetAndCreditConfirmation', '1', null, true],
    ['cashCounterConfirmation', '1', null, null],
    ['manualPaymentsConfirmation', '1', null, true],
    ['cashFlowClassificationLUO', '1', null, null],
  ]).map(toAuditingProcedure(Batch['Q2-cashEquivalents'], store)),

  /************************************************************************** /
   * R1 - Oma pääoma
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod-R1', '1', null, true],
    ['equityAudit', '1', null, true],
    uncheckIfPublicSector(['reconciliationOfShareCapital', '1', null, true]),
    uncheckIfPublicSector(['inspectionOfEquity', '1', null, true]),
    uncheckIfPublicSector(['freeEquityAndDistributable', '1', null, true]),
    ['appropratenessOfProcessing', '1', null, true],
    ['isOwnedCapitalSufficient', '1', null, true],
    ['checkTheContinuity', '1', null, null],
    ['assessmentOfTheAppropriateness', '1', null, null],
  ]).map(toAuditingProcedure(Batch['R1-ownCapital'], store)),

  /************************************************************************** /
   * S1 - Lainat rahoituslaitoksilta
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod-S1', '1', null, true],
    ['loanReconciliationForBalance', '1', null, true],
    ['reconciliationOfLoans', '1', null, true],
    ['inspectionOfLoanBalance', '1', null, true],
    ['inspectionOfLoanClassification', '1', null, true],
    ['reviewingLoanAgreements', '1', null, true],
    ['inspectionOfCovenants', '1', null, true],
  ]).map(toAuditingProcedure(Batch['S1-financialInstitutions'], store)),

  /************************************************************************** /
   * T - Muut velat
   ***************************************************************************/

  ...castToTupleArrays([
    ['existenceClassificationAndMatchingOfOtherLiabilities', '1', null, true],
    ['existenceClassificationAndMatchingOfAccruedExpenses', '1', null, true],
    ['examinationOfIndividualTransactions', '1', null, true],
  ]).map(toAuditingProcedure(Batch['T-otherCreditors'], store)),

  /************************************************************************** /
   * N - Verot
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod', '4', null, null],
    ['lastSeasonTaxesCheck', '1', null, true],
    ['checkingOfTaxes', '1', null, true],
  ]).map(toAuditingProcedure(Batch['N-taxes'], store)),

  /************************************************************************** /
   * O4 - Rahoitusomaisuus
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod', '1', null, true],
    ['fundingAppreciation', '1', true, null],
    ['fundingInspection', '4', true, null],
    ['fundingClassificationInspection', '4', true, null],
    ['liquidAssetsValuation', '4', true, null],
    ['accountingEstimates-O4', '4', true, null],
  ]).map(toAuditingProcedure(Batch['O4-funding'], store)),

  /************************************************************************** /
   * Q3 - Lainasaamiset
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod-Q3', '1', null, true],
    ['loanAgreementsAndConditions', '1', null, true],
    ['reviewOfLoanReceivables', '1', null, true],
    ['loanReceivablesReconciliationToBalance', '1', null, null],
    ['classificationAndPresentationOfReceivables', '1', null, null],
    ['accountingEstimates-Q3', '1', null, null],
    ['innerCircle', '1', null, null],
  ]).map(toAuditingProcedure(Batch['Q3-loanReceivables'], store)),

  /************************************************************************** /
   * T2 - Saadut Ennakot
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod-T2', '1', null, true],
    ['receivedAdvancesCheck', '1', null, true],
    ['classificationAndPresentationOfAdvances', '1', null, null],
    ['perfectionOfAdvances', '1', null, null],
  ]).map(toAuditingProcedure(Batch['T2-advancePayments'], store)),

  /************************************************************************** /
   * V - Varaukset
   ***************************************************************************/

  ...castToTupleArrays([
    ['describePeriod-V', '1', null, true],
    ['mandatoryReservationCheck', '1', null, true],
    ['auditOfVoluntaryReservations', '1', null, null],
    ['checkOfReservation', '1', null, null],
    ['accountingEstimates-V', '1', null, null],
  ]).map(toAuditingProcedure(Batch['V-reserves'], store)),

  /************************************************************************** /
   * R2 - Pääomalainat
   ***************************************************************************/
  ...castToTupleArrays([
    ['describePeriod-R2', '1', null, null],
    ['reviewingOfCapitalLoan', '1', null, true],
    ['loanReconciliation', '1', null, null],
    ['reconciliationOfLoans-R2', '1', null, true],
    ['interestRatesOnCapitalLoans', '1', null, true],
  ]).map(toAuditingProcedure(Batch['R2-capitalLoans'], store)),
];
};

export const makeEmptyThresholdTestRow = (
  thresholdTestRow?: Partial<ThresholdTestRow>
): ThresholdTestRow => {
  const id = uuid();
  return {
    id,
    key: `${id}-custom`,
    value: null,
    ...thresholdTestRow,
  };
};

/**
 * Returns the value of the threshold test row item if it is set to use custom value.
 * Otherwise returns the given default value or the default value of the row item itself.
 */
export const getValueOrDefaultValue = (
  rowItem?: ThresholdTestRow,
  defaultValue?: ThresholdTestRowValue
) => {
  const value = rowItem?.useCustomValue
    ? rowItem?.value
    : defaultValue ?? rowItem?.defaultValue;
  return value ?? 0;
};
