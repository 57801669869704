import { IncomeStatementAndBalanceComputeds } from '../../../../components/analyzesAndScoping/interim/IncomeStatementAndBalance.ComputedsTable';
import { MaterialitiesTable } from '../../../../components/auditing/Materiality.MaterialitiesTable';
import { FormFieldBuilder } from '../../FormFieldBuilder';
import { useCopyFromIncomeStatementAndBalanceScoping } from './copyFunctions';
import { AnalyticStatementAndBalanceTable } from '../../../../components/analyzesAndScoping/interim/AnalyticStatementAndBalance';


type SectionFormType = concludingAnalyticForm;

const sectionKey: AuditingSectionKey = 'concludingAnalytic';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = {
  incomeStatement: [],
  balanceAssets: [],
  balanceLiabilities: [],

  conclusionCheckboxGroup: null,
  additionalProceduresComments: '',
  comments: '',

  concludingAnalyticGoals: 'Tarkastellaan lukuja analyyttisesti. Tarkastelun tavoitteena on havaita poikkeavat muutokset ja saldot vertailujaksoon nähden ja sellaiset muutokset / saldot, jotka eivät ole linjassa asiakkaan kanssa käytyjen keskustelujen kanssa.',
  concludingAnalyticComments: 'Kommentit'
};

const plainTitle = 'Tarkastuksen ajanjakso';

const formFields: FormFields<SectionFormType> = ({ auditing, formState }) => [
  formBuilder.group({
    items: [
      formBuilder.checkboxGroup({
        accessor: 'conclusionCheckboxGroup',
        options: [
          'noAdditionalProceduresNeeded',
          'additionalProceduresNeeded',
          'comments'
        ],
      }),
      formBuilder.textArea({
        accessor: 'additionalProceduresComments',
        hidden: !formState.conclusionCheckboxGroup?.additionalProceduresNeeded,
      }),
      formBuilder.textArea({
        accessor: 'comments',
        hidden: !formState.conclusionCheckboxGroup?.comments,
      }),
      formBuilder.textArea({
        accessor: 'concludingAnalyticGoals',
        plainLabel: 'Tavoitteet ja tehty työ',
      }),
      formBuilder.textArea({
        accessor: 'concludingAnalyticComments',
        plainLabel: 'Tarkastushavainnot ja kommentit',
      }),
    ]
  }),

  formBuilder.accordionGroup({
    open: true,
    titleKey: 'incomeStatement',
    items: [
      formBuilder.groupRow({
        items: [
          formBuilder.textInput({
            accessor: 'dateIncome',
            plainLabel: plainTitle,
            disabled: true,
          }),
        ],
      }),
      formBuilder.custom(props => {
        useCopyFromIncomeStatementAndBalanceScoping(props);
        return (
          <AnalyticStatementAndBalanceTable
            targetKey="incomeStatement"
            sectionKey={sectionKey}
            {...props}
          />
        )
      }),
    ],
  }),

  formBuilder.accordionGroup({
    open: true,
    titleKey: 'balanceAssets',
    items: [
      formBuilder.groupRow({
        items: [
          formBuilder.textInput({
            accessor: 'dateBalance',
            plainLabel: plainTitle,
            disabled: true,
          }),
        ],
      }),
      formBuilder.custom(props => (
        <AnalyticStatementAndBalanceTable
          targetKey="balanceAssets"
          sectionKey={sectionKey}
          {...props}
        />
      )),
    ],
  }),

  formBuilder.accordionGroup({
    open: true,
    titleKey: 'balanceLiabilities',
    items: [
      formBuilder.groupRow({
        items: [
          formBuilder.textInput({
            accessor: 'dateLiabilities',
            plainLabel: plainTitle,
            disabled: true,
          }),
        ],
      }),
      formBuilder.custom(props => (
        <AnalyticStatementAndBalanceTable
          targetKey="balanceLiabilities"
          sectionKey={sectionKey}
          {...props}
        />
      )),
    ],
  }),

  formBuilder.custom(props => (
    <IncomeStatementAndBalanceComputeds {...props} />
  )),

  formBuilder.custom(props => {
    const formState = auditing?.materiality?.form;
    if (!formState) return;
    return <MaterialitiesTable {...props} formState={formState} readonly />;
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  depending: ['accountMapScoping', 'generalLedgerScoping', 'incomeStatementAndBalanceScoping'],
};

export default section;
