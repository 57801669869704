import React, { FC, ReactNode, useState } from 'react';
import Icon, { IconType, IconColor } from './Icon';
import { Button } from './inputs';
import Modal from './Modal';
import { observer } from 'mobx-react-lite';

interface Props {
  buttonText: string;
  title: string;
  icon?: IconType;
  iconColor?: IconColor;
  children?: ReactNode;
}

/**
 * Return a button which when clicked, will open a modal.
 * Can be used to display helpful information to the user.
 * 
 * @param {string} buttonText Text to display on the button 
 * @param {string} title Title for the modal
 * @param {IconType} icon Icon to display on the button
 * @param {IconColor} iconColor The icon's color
 * @param {ReactNode} children Anything you want to display on the modal
 * @returns {React.FC<Props>} React Component
 */
const InstructionBox: FC<Props> = ({
  buttonText,
  title,
  icon = 'Question',
  iconColor = 'background',
  children
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Button
        icon={<Icon type={icon} color={iconColor} />}
        text={buttonText}
        onClick={() => setOpen(true)}
      />
      {isOpen &&
        <Modal
          isInitiallyOpen
          title={title}
          onClose={() => setOpen(false)}
        >
          {children}
        </Modal>}
    </div>
  )
}

export default observer(InstructionBox);