import React, { useMemo } from "react"
import { Checkbox, TextArea } from "../inputs"
import { t } from "i18next";
import styled from "styled-components";
import { OnEditProps, TableColumnDefs } from "../table/Table";
import AuditingTable from "../table/AuditingTable";
import { confirmDelete, useDelayedAction, uuid } from "../../utils";
import Divider from "../Divider";

const sectionKey: AuditingSectionKey = 'presentationAndContent';
const tBase = `auditing:form.${sectionKey}`;
const tableTBase = `${tBase}.F3_1_tables`;

const Container = styled.div`
  margin-bottom: 1rem;
`;

const makeRow = (parentTable: IotCTable) => {
  return {
    id: uuid(),
    isPresent: null,
    comment: '',
    parentTable: parentTable,
    version: 1,
  }
}

const makeLabelRows = (parentTable: IotCTable, labels: string[]): IotCAuditingTableRow[] => {
  return labels.map(label => {
    return {
      ...makeRow(parentTable),
      label: label
    }
  });
}

const financialStatementsNotes: IotCAuditingTableRow[] = [
  {
    ...makeRow('financialStatementsNotes'),
    requirementLabel: t(`${tableTBase}.financialStatementsNotes.requirementLabel1`),
    label: t(`${tableTBase}.financialStatementsNotes.label1`),
  },
  ...makeLabelRows('financialStatementsNotes', [
    t(`${tableTBase}.financialStatementsNotes.label2`),
    t(`${tableTBase}.financialStatementsNotes.label3`),
    t(`${tableTBase}.financialStatementsNotes.label4`),
    t(`${tableTBase}.financialStatementsNotes.label5`),
    t(`${tableTBase}.financialStatementsNotes.label6`),
    t(`${tableTBase}.financialStatementsNotes.label7`)
  ])
];

const presentationOfFinancialStatements: IotCAuditingTableRow[] = [
  {
    ...makeRow('presentationOfFinancialStatements'),
    requirementLabel: t(`${tableTBase}.presentationOfFinancialStatements.requirementLabel`),
    label: t(`${tableTBase}.presentationOfFinancialStatements.label1`),
  },
  ...makeLabelRows('presentationOfFinancialStatements', [
    t(`${tableTBase}.presentationOfFinancialStatements.label2`),
    t(`${tableTBase}.presentationOfFinancialStatements.label3`),
    t(`${tableTBase}.presentationOfFinancialStatements.label4`),
    t(`${tableTBase}.presentationOfFinancialStatements.label5`)
  ])
];

const profitLossNotes: IotCAuditingTableRow[] = [
  {
    ...makeRow('profitLossNotes'),
    requirementLabel: t(`${tableTBase}.profitLossNotes.requirementLabel`),
    label: t(`${tableTBase}.profitLossNotes.label1`),
  },
  ...makeLabelRows('profitLossNotes', [
    t(`${tableTBase}.profitLossNotes.label2`),
    t(`${tableTBase}.profitLossNotes.label3`),
    t(`${tableTBase}.profitLossNotes.label4`),
    t(`${tableTBase}.profitLossNotes.label5`),
    t(`${tableTBase}.profitLossNotes.label6`),
    t(`${tableTBase}.profitLossNotes.label7`),
    t(`${tableTBase}.profitLossNotes.label8`),
    t(`${tableTBase}.profitLossNotes.label9`),
    t(`${tableTBase}.profitLossNotes.label10`),
  ])
];

// Balancesheet notes are split into two tables to make adding new rows easier.
const balanceSheetNotes1: IotCAuditingTableRow[] = [
  {
    ...makeRow('balanceSheetNotes1'),
    requirementLabel: t(`${tableTBase}.balanceSheetNotes.requirementLabel`),
    label: t(`${tableTBase}.balanceSheetNotes.label1`),
  },
  ...makeLabelRows('balanceSheetNotes1', [
    t(`${tableTBase}.balanceSheetNotes.label2`),
    t(`${tableTBase}.balanceSheetNotes.label3`),
    t(`${tableTBase}.balanceSheetNotes.label4`),
    t(`${tableTBase}.balanceSheetNotes.label5`),
    t(`${tableTBase}.balanceSheetNotes.label6`),
    t(`${tableTBase}.balanceSheetNotes.label7`),
    t(`${tableTBase}.balanceSheetNotes.label8`),
    t(`${tableTBase}.balanceSheetNotes.label9`),
    t(`${tableTBase}.balanceSheetNotes.label10`),
  ])
];
const balanceSheetNotes2: IotCAuditingTableRow[] = [
  ...makeLabelRows('balanceSheetNotes2', [
    t(`${tableTBase}.balanceSheetNotes.label11`),
    t(`${tableTBase}.balanceSheetNotes.label12`),
    t(`${tableTBase}.balanceSheetNotes.label13`),
    t(`${tableTBase}.balanceSheetNotes.label14`),
    t(`${tableTBase}.balanceSheetNotes.label15`),
    t(`${tableTBase}.balanceSheetNotes.label16`),
    t(`${tableTBase}.balanceSheetNotes.label17`),
  ])
];

const balanceSheetLiabilitiesNotes: IotCAuditingTableRow[] = [
  {
    ...makeRow('balanceSheetLiabilitiesNotes'),
    requirementLabel: t(`${tableTBase}.balanceSheetLiabilitiesNotes.requirementLabel`),
    label: t(`${tableTBase}.balanceSheetLiabilitiesNotes.label1`),
  },
  ...makeLabelRows('balanceSheetLiabilitiesNotes', [
    t(`${tableTBase}.balanceSheetLiabilitiesNotes.label2`),
    t(`${tableTBase}.balanceSheetLiabilitiesNotes.label3`),
    t(`${tableTBase}.balanceSheetLiabilitiesNotes.label4`),
    t(`${tableTBase}.balanceSheetLiabilitiesNotes.label5`),
    t(`${tableTBase}.balanceSheetLiabilitiesNotes.label6`),
    t(`${tableTBase}.balanceSheetLiabilitiesNotes.label7`),
    t(`${tableTBase}.balanceSheetLiabilitiesNotes.label8`),
    t(`${tableTBase}.balanceSheetLiabilitiesNotes.label9`),
    t(`${tableTBase}.balanceSheetLiabilitiesNotes.label10`),
    t(`${tableTBase}.balanceSheetLiabilitiesNotes.label11`),
    t(`${tableTBase}.balanceSheetLiabilitiesNotes.label12`),
    t(`${tableTBase}.balanceSheetLiabilitiesNotes.label13`),
    t(`${tableTBase}.balanceSheetLiabilitiesNotes.label14`),
    t(`${tableTBase}.balanceSheetLiabilitiesNotes.label15`),
    t(`${tableTBase}.balanceSheetLiabilitiesNotes.label16`)
  ])
];

const offSheetLiabilitiesNotes: IotCAuditingTableRow[] = [
  {
    ...makeRow('offSheetLiabilitiesNotes'),
    requirementLabel: t(`${tableTBase}.offSheetLiabilitiesNotes.requirementLabel`),
    label: t(`${tableTBase}.offSheetLiabilitiesNotes.label1`),
  },
  ...makeLabelRows('offSheetLiabilitiesNotes', [
    t(`${tableTBase}.offSheetLiabilitiesNotes.label2`),
    t(`${tableTBase}.offSheetLiabilitiesNotes.label3`),
    t(`${tableTBase}.offSheetLiabilitiesNotes.label4`),
    t(`${tableTBase}.offSheetLiabilitiesNotes.label5`),
    t(`${tableTBase}.offSheetLiabilitiesNotes.label6`),
    t(`${tableTBase}.offSheetLiabilitiesNotes.label7`)
  ])
];

const esgNotes: IotCAuditingTableRow[] = [
  {
    ...makeRow('esgNotes'),
    requirementLabel: t(`${tableTBase}.esgNotes.requirementLabel`),
    label: t(`${tableTBase}.esgNotes.label1`),
    comment: t(`${tableTBase}.esgNotes.comment1`)
  },
  {
    ...makeRow('esgNotes'),
    label: t(`${tableTBase}.esgNotes.label2`),
    comment: t(`${tableTBase}.esgNotes.comment2`)
  },
  ...makeLabelRows('esgNotes', [
    t(`${tableTBase}.esgNotes.label3`),
    t(`${tableTBase}.esgNotes.label4`),
    t(`${tableTBase}.esgNotes.label5`),
    t(`${tableTBase}.esgNotes.label6`),
    t(`${tableTBase}.esgNotes.label7`),
    t(`${tableTBase}.esgNotes.label8`)
  ])
];

const esgDiscretinaryNotes: IotCAuditingTableRow[] = [
  {
    ...makeRow('esgDiscretinaryNotes'),
    requirementLabel: t(`${tableTBase}.esgDiscretinaryNotes.requirementLabel`),
    label: t(`${tableTBase}.esgDiscretinaryNotes.label1`),
  },
  ...makeLabelRows('esgDiscretinaryNotes', [
    t(`${tableTBase}.esgDiscretinaryNotes.label2`),
    t(`${tableTBase}.esgDiscretinaryNotes.label3`),
    t(`${tableTBase}.esgDiscretinaryNotes.label4`),
  ])
];

const personnelNotes: IotCAuditingTableRow[] = [
  {
    ...makeRow('personnelNotes'),
    requirementLabel: t(`${tableTBase}.personnelNotes.requirementLabel`),
    label: t(`${tableTBase}.personnelNotes.label1`),
  },
  ...makeLabelRows('personnelNotes', [
    t(`${tableTBase}.personnelNotes.label2`),
    t(`${tableTBase}.personnelNotes.label3`),
    t(`${tableTBase}.personnelNotes.label4`),
    t(`${tableTBase}.personnelNotes.label5`),
  ])
];

const enterpriseNotes: IotCAuditingTableRow[] = [
  {
    ...makeRow('enterpriseNotes'),
    label: t(`${tableTBase}.enterpriseNotes.label1`),
  }
];

const consolidatedFinancialStatementsNotes: IotCAuditingTableRow[] = [
  {
    ...makeRow('consolidatedFinancialStatementsNotes'),
    requirementLabel: t(`${tableTBase}.consolidatedFinancialStatementsNotes.requirementLabel`),
    label: t(`${tableTBase}.consolidatedFinancialStatementsNotes.label1`),
  },
  ...makeLabelRows('consolidatedFinancialStatementsNotes', [
    t(`${tableTBase}.consolidatedFinancialStatementsNotes.label2`),
    t(`${tableTBase}.consolidatedFinancialStatementsNotes.label3`),
    t(`${tableTBase}.consolidatedFinancialStatementsNotes.label4`),
    t(`${tableTBase}.consolidatedFinancialStatementsNotes.label5`),
    t(`${tableTBase}.consolidatedFinancialStatementsNotes.label6`),
  ])
];

const otherConsolidatedFinancialStatementsNotes: IotCAuditingTableRow[] = [
  {
    ...makeRow('otherConsolidatedFinancialStatementsNotes'),
    requirementLabel: t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.requirementLabel`),
    label: t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.label1`),
  },
  ...makeLabelRows('otherConsolidatedFinancialStatementsNotes', [
    t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.label2`),
    t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.label3`),
    t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.label4`),
    t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.label5`),
    t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.label6`),
    t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.label7`),
    t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.label8`),
    t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.label9`),
    t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.label10`),
    t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.label11`),
    t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.label12`),
    t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.label13`),
    t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.label14`),
    t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.label15`),
    t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.label16`),
    t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.label17`)
  ])
];

// Every row in every table is stored here. Their parent table is identified by the parentTable key.
// This simplifies the process of storing and modifying the form state.
const masterTable = [
  ...financialStatementsNotes,
  ...presentationOfFinancialStatements,
  ...profitLossNotes,
  ...balanceSheetNotes1,
  ...balanceSheetNotes2,
  ...balanceSheetLiabilitiesNotes,
  ...offSheetLiabilitiesNotes,
  ...esgNotes,
  ...esgDiscretinaryNotes,
  ...personnelNotes,
  ...enterpriseNotes,
  ...consolidatedFinancialStatementsNotes,
  ...otherConsolidatedFinancialStatementsNotes
];

const IotCTextArea = ({
  formState,
  patchFormState,
  textAreaAccessor,
  label
}: IotCTextAreaProps) => {
  const originalTextArea = formState[textAreaAccessor]
  const [textArea, setTextArea] = React.useState<string>(
    formState.inspectionOfTheConsolidatedEnterpriseNotesTextArea
  );

  useDelayedAction(() => {
    if (textArea !== originalTextArea) {
      patchFormState({
        ...formState,
        inspectionOfTheConsolidatedEnterpriseNotesTextArea: textArea
      });
    }
  }, 500)

  return (
    <TextArea
      label={label}
      value={textArea}
      onChange={(event) => setTextArea(event.currentTarget.value)}
      placeholder={t(`${tableTBase}.enterpriseNotes.commentFieldValue`)}
      fullWidth
    />
  )
};

export const InspectionOfTheConsolidatedAuditingTables = ({
  formState,
  patchFormState
}: FormFieldProps<PresentationAndContentForm>): React.ReactElement => {

  const data = formState.inspectionOfTheConsolidatedTables.length > 0
    ? formState.inspectionOfTheConsolidatedTables
    : masterTable;

  const makeColumns = (requirementHeader: string, labelHeader?: string) => {
    if (!labelHeader) {
      labelHeader = t(`${tableTBase}.labelHeader`);
    }
    const columns: TableColumnDefs<IotCAuditingTableRow> = [
      {
        id: 'requirementLabel',
        accessorKey: 'requirementLabel',
        header: requirementHeader,
        className: 'width-30'
      },
      {
        id: 'label',
        accessorKey: 'label',
        header: labelHeader,
        className: 'width-40',
        onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps)
      },
      {
        id: 'isPresent',
        accessorKey: 'isPresent',
        header: t(`${tableTBase}.isPresent`),
        selectProps: {
          options: ['yes', 'no', 'notApplicable'],
          displayValue: (option: string) => t(`${tableTBase}.isPresentOptions.${option}`),
          setValue: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
        },
        showRemark: (cell: IotCAuditingTableRow) => cell.isPresent === 'no',
        className: 'width-min'
      },
      {
        id: 'comment',
        accessorKey: 'comment',
        header: t(`${tableTBase}.comment`),
        onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps)
      }
    ];

    return columns;
  };

  const handleEdit = <T,>({
    itemId,
    rowIndex,
    columnId,
    value
  }: OnEditProps<T>): void => {
    const patch = data.map(
      (row, index) => {
        if (row.id === itemId) {
          return {
            ...row,
            [columnId]: value
          }
        }
        else {
          return row;
        }
      }
    );

    const newFormState = {
      ...formState,
      inspectionOfTheConsolidatedTables: patch
    };

    patchFormState(newFormState);
  };

  const handleAddNewRow = (parentTable: IotCTable) => {
    const newRow = {
      ...makeRow(parentTable),
      label: ''
    }

    const newFormState = {
      ...formState,
      inspectionOfTheConsolidatedTables: [
        ...data,
        newRow
      ]
    };

    patchFormState(newFormState);
  };

  const handleRowDelete = (row: IotCAuditingTableRow) => {
    if (confirmDelete(row.label)) {
      const newFormState = {
        ...formState,
        inspectionOfTheConsolidatedTables: data.filter(item => item.id !== row.id)
      };

      patchFormState(newFormState);
    }
  };

  // Memoized table rows for each table. This probably doesn't make a huge difference in performance
  const filteredTableRows = useMemo(() => {
    return {
      financialStatementsNotes: data.filter(row => row.parentTable === 'financialStatementsNotes'),
      presentationOfFinancialStatements: data.filter(row => row.parentTable === 'presentationOfFinancialStatements'),
      profitLossNotes: data.filter(row => row.parentTable === 'profitLossNotes'),
      balanceSheetNotes1: data.filter(row => row.parentTable === 'balanceSheetNotes1'),
      balanceSheetNotes2: data.filter(row => row.parentTable === 'balanceSheetNotes2'),
      balanceSheetLiabilitiesNotes: data.filter(row => row.parentTable === 'balanceSheetLiabilitiesNotes'),
      offSheetLiabilitiesNotes: data.filter(row => row.parentTable === 'offSheetLiabilitiesNotes'),
      esgNotes: data.filter(row => row.parentTable === 'esgNotes'),
      esgDiscretinaryNotes: data.filter(row => row.parentTable === 'esgDiscretinaryNotes'),
      personnelNotes: data.filter(row => row.parentTable === 'personnelNotes'),
      enterpriseNotes: data.filter(row => row.parentTable === 'enterpriseNotes'),
      consolidatedFinancialStatementsNotes: data.filter(row => row.parentTable === 'consolidatedFinancialStatementsNotes'),
      otherConsolidatedFinancialStatementsNotes: data.filter(row => row.parentTable === 'otherConsolidatedFinancialStatementsNotes')
    }
  }, [data]);

  return (
    <div>
      <Container>
        <p>
          <strong>{t(`${tableTBase}.noteRequirements`)}</strong>
          {t(`${tableTBase}.noteRequirementsText`)}
        </p>
      </Container>
      <Checkbox
        plainLabel={t(`${tBase}.useReadyMadeList`)}
        checked={formState.inspectionOfTheConsolidatedUseAuditingTable}
        onChange={() =>
          patchFormState(
            {
              ...formState,
              inspectionOfTheConsolidatedUseAuditingTable: !formState.inspectionOfTheConsolidatedUseAuditingTable
            }
          )}
      />
      {formState.inspectionOfTheConsolidatedUseAuditingTable && (
        <Container>
          {/* Tilinpäätöksen laatimista koskevat liitetiedot */}
          <Container>
            <AuditingTable<IotCAuditingTableRow>
              columns={makeColumns(t(`${tableTBase}.financialStatementsNotes.requirementHeader`))}
              data={filteredTableRows.financialStatementsNotes}
              showGlobalFilter={false}
              disableSort
              onAddNewRow={() => handleAddNewRow('financialStatementsNotes')}
              onRowDelete={handleRowDelete}
            />
          </Container>

          <Divider />

          {/* Kunnan tilinpäätöksen esittämistapaa koskevat liitetiedot */}
          <Container>
            <AuditingTable<IotCAuditingTableRow>
              columns={makeColumns(t(`${tableTBase}.presentationOfFinancialStatements.requirementHeader`))}
              data={filteredTableRows.presentationOfFinancialStatements}
              showGlobalFilter={false}
              disableSort
              onAddNewRow={() => handleAddNewRow('presentationOfFinancialStatements')}
              onRowDelete={handleRowDelete}
            />
          </Container>

          <Divider />

          {/* Tuloslaskelmaa koskevat liitetiedot */}
          <Container>
            <AuditingTable<IotCAuditingTableRow>
              columns={makeColumns(t(`${tableTBase}.profitLossNotes.requirementHeader`))}
              data={filteredTableRows.profitLossNotes}
              showGlobalFilter={false}
              disableSort
              onAddNewRow={() => handleAddNewRow('profitLossNotes')}
              onRowDelete={handleRowDelete}
            />
          </Container>

          <Divider />

          {/* Taseen vastaavia koskevat liitetiedot */}
          <Container>
            <AuditingTable<IotCAuditingTableRow>
              columns={makeColumns(t(`${tableTBase}.balanceSheetNotes.requirementHeader`))}
              data={filteredTableRows.balanceSheetNotes1}
              showGlobalFilter={false}
              disableSort
              onAddNewRow={() => handleAddNewRow('balanceSheetNotes1')}
              onRowDelete={handleRowDelete}
            />
            <AuditingTable<IotCAuditingTableRow>
              columns={makeColumns(t(`${tableTBase}.balanceSheetNotes.requirementHeader`))}
              data={filteredTableRows.balanceSheetNotes2}
              showGlobalFilter={false}
              disableSort
              hideHeader
              onAddNewRow={() => handleAddNewRow('balanceSheetNotes2')}
              onRowDelete={handleRowDelete}
            />
          </Container>

          <Divider />

          {/* Taseen vastattavia koskevat liitetiedot */}
          <Container>
            <AuditingTable<IotCAuditingTableRow>
              columns={makeColumns(t(`${tableTBase}.balanceSheetLiabilitiesNotes.requirementHeader`))}
              data={filteredTableRows.balanceSheetLiabilitiesNotes}
              showGlobalFilter={false}
              disableSort
              onAddNewRow={() => handleAddNewRow('balanceSheetLiabilitiesNotes')}
              onRowDelete={handleRowDelete}
            />
          </Container>

          <Divider />

          {/* Vakuuksia, vastuusitoumuksia ja taseen ulkopuolisia järjestelyjä koskevat liitetiedot */}
          <Container>
            <AuditingTable<IotCAuditingTableRow>
              columns={makeColumns(t(`${tableTBase}.offSheetLiabilitiesNotes.requirementHeader`))}
              data={filteredTableRows.offSheetLiabilitiesNotes}
              showGlobalFilter={false}
              disableSort
              onAddNewRow={() => handleAddNewRow('offSheetLiabilitiesNotes')}
              onRowDelete={handleRowDelete}
            />
          </Container>

          <Divider />

          {/* Ympäristöasioita koskevat liitetiedot */}
          <Container>
            <AuditingTable<IotCAuditingTableRow>
              columns={makeColumns(t(`${tableTBase}.esgNotes.requirementHeader`))}
              data={filteredTableRows.esgNotes}
              showGlobalFilter={false}
              disableSort
              onAddNewRow={() => handleAddNewRow('esgNotes')}
              onRowDelete={handleRowDelete}
            />
          </Container>

          <Divider />

          {/* Ympäristöasioita koskevat harkinnanvaraiset tiedot */}
          <Container>
            <AuditingTable<IotCAuditingTableRow>
              columns={makeColumns(t(`${tableTBase}.esgDiscretinaryNotes.requirementHeader`))}
              data={filteredTableRows.esgDiscretinaryNotes}
              showGlobalFilter={false}
              disableSort
              onAddNewRow={() => handleAddNewRow('esgDiscretinaryNotes')}
              onRowDelete={handleRowDelete}
            />
          </Container>

          <Divider />

          {/* Henkilöstöä, tilintarkastajan palkkioita ja intressitahotapahtumia koskevat liitetiedot */}
          <Container>
            <AuditingTable<IotCAuditingTableRow>
              columns={makeColumns(t(`${tableTBase}.personnelNotes.requirementHeader`))}
              data={filteredTableRows.personnelNotes}
              showGlobalFilter={false}
              disableSort
              onAddNewRow={() => handleAddNewRow('personnelNotes')}
              onRowDelete={handleRowDelete}
            />
          </Container>

          <Divider />

          {/* Liikelaitoksen liitetiedot */}
          <Container>
            <AuditingTable<IotCAuditingTableRow>
              columns={makeColumns(t(`${tableTBase}.enterpriseNotes.requirementHeader`))}
              data={filteredTableRows.enterpriseNotes}
              showGlobalFilter={false}
              disableSort
              onAddNewRow={() => handleAddNewRow('enterpriseNotes')}
              onRowDelete={handleRowDelete}
            />
            <IotCTextArea
              formState={formState}
              patchFormState={patchFormState}
              textAreaAccessor={'inspectionOfTheConsolidatedEnterpriseNotesTextArea'}
              label={t(`${tableTBase}.enterpriseNotes.commentFieldLabel`)}
            />
          </Container>

          <Divider />

          {/* Konsernitilinpäätöksen laatimista koskevat liitetiedot */}
          <Container>
            <AuditingTable<IotCAuditingTableRow>
              columns={makeColumns(t(`${tableTBase}.consolidatedFinancialStatementsNotes.requirementHeader`))}
              data={filteredTableRows.consolidatedFinancialStatementsNotes}
              showGlobalFilter={false}
              disableSort
              onAddNewRow={() => handleAddNewRow('consolidatedFinancialStatementsNotes')}
              onRowDelete={handleRowDelete}
            />
          </Container>

          <Divider />

          {/* Konsernitilinpäätöksen muut liitetiedot */}
          <Container>
            <AuditingTable<IotCAuditingTableRow>
              columns={makeColumns(t(`${tableTBase}.otherConsolidatedFinancialStatementsNotes.requirementHeader`))}
              data={filteredTableRows.otherConsolidatedFinancialStatementsNotes}
              showGlobalFilter={false}
              disableSort
              onAddNewRow={() => handleAddNewRow('otherConsolidatedFinancialStatementsNotes')}
              onRowDelete={handleRowDelete}
            />
          </Container>

        </Container>
      )}
    </div>
  )
};