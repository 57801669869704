import styled from "styled-components";
import AuditingTable from "../table/AuditingTable";
import { TableColumnDefs } from "../table/Table";
import { uuid } from "../../utils";
import { formatCurrency, formatPercent } from "../../utils";

interface Props extends FormFieldProps<GovernmentTransfersForm> {
  accountMapping: GovernmentTransfersAccountMapping;
  reconciliation?: boolean;
}

const Container = styled.div``;

export const AccountMappings = ({ formState, store, accountMapping, reconciliation }: Props) => {
  const rangeToString = (acc: AccountMapRow) => `${acc.start}-${acc.end}`;

  const calculateCurrentAndPriorYearChange = (acc: GroupedGeneralLedgerItem) => {
    const currentYear = acc.currentYear ?? 0;
    const priorYear = acc.priorYear ?? 0;
    return currentYear - priorYear;
  };

  const calculateCurrentAndPriorYearChangePercent = (acc: GroupedGeneralLedgerItem) => {
    const currentYear = acc.currentYear ?? 0;
    const priorYear = acc.priorYear ?? 0;
    return currentYear === 0 ? 0 : ((currentYear - priorYear) / currentYear) * 100;
  };

  const formatAmount = (amount: number) => {
    if (amount >= 0) {
      return (
        <span style={{ color: "green" }}>
          {formatCurrency(amount)}
        </span>
      );
    }
    return (
      <span style={{ color: "red" }}>
        {`(${formatCurrency(Math.abs(amount))})`}
      </span>
    );
  };

  const formatPercentColor = (value: number) => {
    if (value === undefined) return undefined;

    return (
      <span style={{ color: value >= 0 ? "green" : "red" }}>
        {formatPercent(value)}
      </span>
    );
  };

  // Haetaan ensin tilinpäätöksestä. Jos tilinpäätöstä ei ole, haetaan kaudenaikaisesta pääkirjasta.
  const generalLedger = store?.auditingStore.auditing?.generalLedgerScoping?.form.groupedGeneralLedger
    ?? store?.auditingStore.auditing?.generalLedger?.form.groupedGeneralLedger;

  if (!generalLedger) {
    return (
      <div>
        <strong>Tilien hakeminen pääkirjalta epäonnistui!</strong>
      </div>
    );
  };

  const data = [
    // AccountMapRow containing the range of accounts
    ...Object.entries(accountMapping.accountRange).map(([key, value]) => {
      const accountsOnLedger = generalLedger.filter(gl => {
        if (!value) return false;
        return gl.account >= value.start && gl.account <= value.end;
      });

      // Sum account ranges
      const currentYear = accountsOnLedger.reduce((acc, curr) => acc + (curr.currentYear ?? 0), 0);
      const priorYear = accountsOnLedger.reduce((acc, curr) => acc + (curr.priorYear ?? 0), 0);

      return (
        {
          id: uuid(),
          type: 'range',
          accountName: accountMapping.title,
          accountNumber: value ? rangeToString(value) : 'Tietoa ei löytynyt',
          currentYear: currentYear,
          priorYear: priorYear,
          change: currentYear - priorYear,
          changePercent: currentYear === 0 ? 0 : ((currentYear - priorYear) / currentYear) * 100,
        } as GovernmentTransfersAccountTableRow)
    }),

    // Single accounts
    ...accountMapping.accounts.map(acc => {
      const accountOnLedger = generalLedger.find(gl => gl.account === acc);
      return (
        {
          id: uuid(),
          type: 'normal',
          accountName: accountOnLedger?.accountName ?? `Tiliä ${acc} ei löytynyt`,
          accountNumber: accountOnLedger?.account ?? '',
          currentYear: accountOnLedger?.currentYear ?? 0,
          priorYear: accountOnLedger?.priorYear ?? 0,
          change: accountOnLedger ? calculateCurrentAndPriorYearChange(accountOnLedger) : 0,
          changePercent: accountOnLedger ? calculateCurrentAndPriorYearChangePercent(accountOnLedger) : 0,
        } as GovernmentTransfersAccountTableRow)
    })
  ]

  const columns: TableColumnDefs<GovernmentTransfersAccountTableRow> = [
    {
      id: 'name',
      header: 'Tilin nimi',
      accessorFn: row => row.accountName,
      className: 'width-30',
      cell: props => props.row.original.type === 'range'
        ? <span className='text-bold'>{props.cell.getValue()}</span>
        : <span className='pull-right'>{props.cell.getValue()}</span>
    },
    {
      id: 'range',
      header: 'Tilinumero',
      className: 'width-10',
      accessorFn: row => row.accountNumber,
    },
    {
      id: 'tamu',
      header: 'Ta+Tamu',
      className: 'width-10',
      accessorFn: row => row.tamu,
    },
    {
      id: 'cy',
      header: 'CY',
      className: 'width-15',
      accessorFn: row => row.currentYear,
      cell: row => formatAmount(row.cell.getValue()),
    },
    {
      id: 'py',
      header: 'PY',
      className: 'width-15',
      accessorFn: row => row.priorYear,
      cell: row => formatAmount(row.cell.getValue()),
    },
    {
      id: 'change',
      header: 'Muutos (€)',
      className: 'nowrap',
      accessorFn: row => row.change,
      cell: row => formatAmount(row.cell.getValue()),
    },
    {
      id: 'changePercent',
      header: 'Muutos (%)',
      className: 'width-10',
      accessorFn: row => row.changePercent,
      cell: row => formatPercentColor(row.cell.getValue()),
    }
  ];

  return (
    <Container>
      <AuditingTable
        columns={columns}
        data={data}
        disableSort
        showGlobalFilter={false}
      />
    </Container>
  )
}