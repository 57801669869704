import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import { SamplingTableColumn } from '../../../components/auditing/turnOver.SamplingTable';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { hidePartBasedOnItAndProcesses } from '../../../utils';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { TurnoverProcessDescriptionTable } from './notReUsables/turnOverProcess';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';
import { t } from '../../../i18n';
import {
  innerCircleElement,
  innerCircleElementDFS,
} from './reUsables/innerCircle';
import { AuditingHelpInfoBox } from '../../../components/auditing/AuditingHelpInfoBox';

type SectionFormType = UseInnerCircle<TurnOverForm>;

const sectionKey: AuditingSectionKey = 'turnOver';

export const tBase = `auditing:form.${sectionKey}`;

const tSamplingTable = 'auditing:form.materialsAndServices.samplingTable';
const samplingLargestUnits = t(`${tSamplingTable}.largestUnits`);
const samplingRandomUnits = t(`${tSamplingTable}.randomUnits`);
const monetarySampling = t(`${tSamplingTable}.monetary`);
const otherSamplingProcedure = t(`${tSamplingTable}.otherSamplingProcedure`);

const batchString = 'J1-turnover';

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  procedureAccountsData: {},
  procedureAccountsManualData: [
    {
      currentYear: 0,
      priorYear: 0,
      accountName: '',
      comment: '',
    },
  ],
  secondTableColumnData: [
    {
      left: '',
      right: '',
    },
  ],
  samplingTable: [
    {
      id: '1',
      label: samplingLargestUnits,
      one: false,
      more: false,
    },
    {
      id: '2',
      label: samplingRandomUnits,
      one: false,
      more: false,
    },
    {
      id: '3',
      label: monetarySampling,
      one: false,
      more: false,
    },
    {
      id: '4',
      label: otherSamplingProcedure,
      one: false,
      more: false,
    },
  ],
  tableColumnData: [
    {
      left: 'Liikevaihdoksi kirjattu myynti on toteutunut ja kuuluu yhteisölle.',
      right: auditingJson.TAP,
    },
    {
      left: 'Kaikki tapahtumat, jotka olisi tullut kirjata liikevaihtoon on kirjattu.',
      right: auditingJson.TAY,
    },
    {
      left: 'Liikevaihto on kirjattu oikeassa määrässä.',
      right: auditingJson.OIK_ARV,
    },
    {
      left: 'Tilikaudelle on kirjattu vain suoriteperusteisesti sille kuuluva myynti.',
      right: auditingJson.KAT,
    },
    {
      left: 'Liikevaihtoon on luokiteltu ainoastaan sinne kuuluvia eriä.',
      right: auditingJson.LUOK,
    },
    {
      left: 'Liikevaihtotapahtumat on yhdistelty ja jaoteltu oikein / ne on kuvattu selvästi, ja/tai niihin liittyvät tiedot tilinpäätöksessä ovat relevantteja ja ymmärrettäviä sovellettavan tilinpäätösnormiston mukaan.',
      right: auditingJson.ESI,
    },
  ],
  conclusion:
    'Suoritetuilla tarkastustoimenpiteillä on saatu riittävä evidenssi liikevaihdon oikeellisuudesta ja tarkastuksen tavoitteet on saavutettu. Suoritetun tarkastuksen perusteella liikevaihto on olennaisin osin oikein',

  // Liikevaihdon analyyttinen tarkastus
  useSheet: null,
  sheetdata1: [
    {
      cellData: [],
    },
  ],

  // Hyvityslaskujen tarkastus
  useSheet4: null,
  sheetdata4: [
    {
      cellData: [],
    },
  ],

  // Liikevaihdon katko
  useSheet3: null,
  sheetdata3: [
    {
      cellData: [],
    },
  ],

  // Liikevaihdon aineistotarkastus
  useSheet2: null,
  sheetdata2: [
    {
      cellData: [],
    },
  ],
  largestUnitsTextField:
    'Valitaan tarkastukseen olennaiset, yli PM:n ylittävät yksittäiset liikevaihdon tapahtumat. Tarkastukseen valitut tapahtumat kattavat yhteensä XX eur eli X % koko populaatiosta.',
  monetaryTextField:
    'Tarkastettavat yksiköt on valittu monetary unit samplingilla. Käytetyllä otantamenetelmällä suuremmilla laskuilla on suurempi todennäköisyys tulla valituiksi. Viite tiedostoon, jossa otanta määritetty esim. J1.',
  otherSamplingProcedureTextField:
    'Tarkastettavat yksiköt on valittu X-menetelmällä. Viite tiedostoon, jossa otanta määritetty esim. J1.',

  processText: '',
  processDate: '',
  processValue: '',
  useSheetTab: null,
  ...innerCircleElementDFS,
});

const formFields: FormFields<SectionFormType> = ({
  formState,
  auditing,
  store,
}) => [
  formBuilder.custom(
    <AuditingHelpInfoBox/>
  ),
  formBuilder.group({
    items: [
      formBuilder.boolean({
        accessor: 'useSheetTab',
        plainLabel: 'Käytä laskenta taulukko osiota',
      }),
      formBuilder.custom(props => (
        <ProcedureAccountsElement
          {...props}
          auditing={auditing}
          batchString={batchString}
          addCommentField
        />
      )),
      formBuilder.custom(props => (
        <TableColumn
          title="Mahdollinen virheellisyyden riski liikevaihdossa"
          headerText="Olennaisen virheellisyyden riskin kannanotto, johon tarkastustoimenpiteillä vastataan (ISA 315.11)"
          {...props}
        />
      )),
      formBuilder.textArea({
        accessor: 'conclusion',
        plainLabel: auditingJson.conclusionTurnover,
      }),
    ],
  }),

  // It ja prosessit
  formBuilder.accordionGroup({
    title: auditingJson.form.itAndProcesses.processDescript,
    items: [
      formBuilder.custom(props => (
        <TurnoverProcessDescriptionTable
          processText=""
          processDate={''}
          processValue=""
          {...props}
        />
      )),
    ],
    hidden: hidePartBasedOnItAndProcesses(auditing, 'turnover'),
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      // Liikevaihdon analyyttinen tarkastus
      accordionId: 'processMappingWtAndMainAccountingPrinciples',
      lowerElements: [
        formBuilder.boolean({
          plainLabel: auditingJson.useSheet,
          accessor: 'useSheet',
        }),
        formBuilder.custom(props =>
          formState.useSheet ? <SpreadSheet id="sheetdata1" {...props} /> : null
        ),
      ],
    },
    {
      // Liikevaihdon aineistotarkastus
      accordionId: 'matchingOfSalesToInvoicing',
      upperElements: [
        formBuilder.custom(props => <SamplingTableColumn {...props} />),
        formBuilder.textArea({
          accessor: 'largestUnitsTextField',
          hidden: !formState.samplingTable.some(
            item =>
              item.label === samplingLargestUnits && (item.one || item.more)
          ),
        }),
        formBuilder.textArea({
          accessor: 'randomUnitsTextField',
          hidden: !formState.samplingTable.some(
            item =>
              item.label === samplingRandomUnits && (item.one || item.more)
          ),
        }),
        formBuilder.textArea({
          accessor: 'otherSamplingProcedureTextField',
          hidden: !formState.samplingTable.some(
            item =>
              (item.label === monetarySampling ||
                item.label === otherSamplingProcedure) &&
              (item.one || item.more)
          ),
        }),
      ],
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet2',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet2 ? (
            <SpreadSheet id="sheetdata2" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Hyvityslaskujen tarkastus
      accordionId: 'reviewOfRevenueRecognition',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet4',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet4 ? (
            <SpreadSheet id="sheetdata4" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Liikevaihdon katko
      accordionId: 'reviewOfIndividualTransactions',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet3',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet3 ? (
            <SpreadSheet id="sheetdata3" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Lähipiiri
      useDefault: false,
      accordionId: 'innerCircle',
      upperElements: innerCircleElement(formBuilder),
    },
  ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
