import { round } from '../../utils';
import { AccountKey } from './planning/accountMapKeys';

/**
 * Builds depending remark rule
 */
const makeDependingRemarkRule = ([
  accessor,
  remarkTriggers,
  sectionKey,
]: DependingRemarkRuleTuple): DependingRemarkRule => ({
  sectionKey,
  accessor,
  remarkTriggers,
});

/**
 * Builds basic remark rule
 */
export const makeRemarkRule =
  (sectionKey: AuditingSectionKey) =>
  ({ rule, accessorKey, depends }: AuditingSectionRule): RemarkRule => {
    const [accessor, remarkTriggers] = rule;
    return {
      sectionKey,
      accessor,
      accessorKey: typeof accessor === 'string' ? accessor : accessorKey,
      remarkTriggers,
      depends: (depends ?? []).map(makeDependingRemarkRule),
    };
  };

/**
 * Utility function to create a rule accessor for a given field and value.
 * @param fieldName The name of the field.
 * @param value The value to check ('yes' or 'no').
 * @returns The accessor string for the rule.
 */
export function createRuleAccessor(
  fieldName: string,
  value: 'yes' | 'no'
): string {
  return `${fieldName}.${value}`;
}

/**
 * Custom accessor function to define if auditor information
 * (in permanent data section) should trigger remark or not.
 */
export const auditorInformationAccessor = (
  auditingSection?: AuditingSection<PermanentDataForm>
) => {
  const { auditorInformation } = auditingSection?.form ?? {};
  const notUpToDate = ({ upToDate }: AuditorInformationItem) =>
    upToDate === false;
  return !!auditorInformation?.some(notUpToDate);
};

/**
 * Custom accessor function to access income statement's wages & salaries information
 */
export const hasWagesAndSalariesAccessor: AuditingSectionFormAccessorFunction =
  (
    auditingSection?: AuditingSection<
      IncomeStatementAndBalanceForm | IncomeStatementAndBalanceScopingForm
    >
  ) => {
    const wagesItem = auditingSection?.form.incomeStatement?.find(
      ({ key }) => key === AccountKey.wagesAndSalaries
    );
    return !!wagesItem?.currentYear;
  };

/**
 * Custom accessor function to check if balance assets/liabilities match
 */
export const assetsLiabilitiesDifferenceAccessor: AuditingSectionFormAccessorFunction =
  (
    auditingSection?: AuditingSection<
      IncomeStatementAndBalanceForm | IncomeStatementAndBalanceScopingForm
    >
  ) => {
    const keyMatchTo =
      (key: AccountKey | AccountKey) =>
      (item: { key: AccountKey | AccountKey }) =>
        item.key === key;

    const { balanceAssets, balanceLiabilities } = auditingSection?.form ?? {};

    const [assets, liabilities] = [
      balanceAssets?.find(keyMatchTo(AccountKey.balanceAssets))?.currentYear,
      balanceLiabilities?.find(keyMatchTo(AccountKey.balanceLiabilities))
        ?.currentYear,
      balanceAssets?.find(keyMatchTo(AccountKey.balanceAssets))?.currentYear,
      balanceLiabilities?.find(keyMatchTo(AccountKey.balanceLiabilities))
        ?.currentYear,
    ];

    const difference = round(Number(assets) - Number(liabilities));

    return !isNaN(difference) && difference !== 0;
  };

/**
 * Helper for essential notes accessors
 */
const essentialNotesItemHasRemark = (tableData?: EssentialNotesItem[]) =>
  tableData?.some(obj => obj.mustBeNoted === true) ?? false;

/**
 * Custom accessor function to check if (management) detailed information table has
 * values that should be listed as remarks
 */
export const reviewOfPriorityBoardsNotesAccessor: AuditingSectionFormAccessorFunction =
  (auditingSection?: AuditingSection<ManagementForm>) => {
    return essentialNotesItemHasRemark(
      auditingSection?.form.reviewOfPriorityBoardsNotes
    );
  };

export const reviewOfPriorityOfficialsNotesAccessor: AuditingSectionFormAccessorFunction =
  (auditingSection?: AuditingSection<ManagementForm>) => {
    return essentialNotesItemHasRemark(
      auditingSection?.form.reviewOfPriorityOfficialsNotes
    );
  };

export const councilMeetingMinutesNotesAccessor: AuditingSectionFormAccessorFunction =
  (auditingSection?: AuditingSection<ManagementForm>) => {
    return essentialNotesItemHasRemark(
      auditingSection?.form.councilMeetingMinutesNotes
    );
  };

export const boardMeetingMinutesNotesAccessor: AuditingSectionFormAccessorFunction =
  (auditingSection?: AuditingSection<ManagementForm>) => {
    return essentialNotesItemHasRemark(
      auditingSection?.form.boardMeetingMinutesNotes
    );
  };
