import { FormFieldBuilder } from '../FormFieldBuilder';
import auditingJson from '../../../i18n/locales/fi/auditing.json';

type SectionFormType = EvaluationAssignmentForm;

const sectionKey: AuditingSectionKey = 'EvaluationAssignment';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  isUpToDate: null,
  isOverThreeYears: null,
  inspectionConclusion: '',

  AcceptingOrContinuing: '',
  upToDate: '',
  prepareNewOne: 'Ohjeet, koska päivitettävä.',
  prepareNewOne2:
    'Koska toimeksiantokirjeen laadinnasta on aikaa yli kolme vuotta, päivitetään toimeksiantokirje.',
  referenceField: 'A6.1',

  isEvaluationAssignment: null,
  isEvaluationAssignmentComment: '',

  isMattersThatAffectAssignment: null,
  mattersThatAffectAssignment: '',
});

const formFields: FormFields<SectionFormType> = ({ formState }) => [
  formBuilder.group({
    items: [
      formBuilder.boolean({
        accessor: 'AcceptingOrContinuing',
        options: ['accepting', 'continuing'],
      }),

      formBuilder.boolean({
        accessor: 'isUpToDate',
        options: ['yes', 'no'],
      }),
      formBuilder.textArea({
        accessor: 'prepareNewOne',
        plainLabel:
          'Toimeksiannon ehdoista sovittu kirjallisesti ja ehdot lisätietoa',
        hidden: !formState.isUpToDate,
      }),

      formBuilder.group({
        items: [
          formBuilder.boolean({
            accessor: 'isOverThreeYears',
            options: ['no', 'yes'],
          }),
          formBuilder.textArea({
            accessor: 'prepareNewOne2',
            plainLabel: 'Toimeksiantokirjeen laatimisesta lisätietoa',
            hidden: !formState.isOverThreeYears,
          }),
        ]
      }),

      formBuilder.checkboxGroup({
        plainLabel:
          'Onko toimeksiannon hyväksymisen arviointi -lomake täydennetty (index A6.2)?',
        accessor: 'isEvaluationAssignment',
        options: Object.keys(auditingJson.yesNoOther),
        optionDisplayValue: (key: string) =>
          auditingJson.yesNoOther[key as keyof typeof auditingJson.yesNoOther],
      }),
      formBuilder.textArea({
        accessor: 'isEvaluationAssignmentComment',
        plainLabel: 'Lisätietoa',
        hidden: !formState.isEvaluationAssignment?.other,
      }),

      formBuilder.boolean({
        accessor: 'isMattersThatAffectAssignment',
        showContextInfo: 'bottom-left',
        options: ['no', 'yes'],
        detailsAccessor: 'mattersThatAffectAssignment',
        detailsHidden: !formState.isMattersThatAffectAssignment,
      }),

      formBuilder.checkboxGroup({
        plainLabel: auditingJson.riskValue,
        accessor: 'inspectionConclusion',
        options: Object.keys(auditingJson.riskValueOptions),
        optionDisplayValue: (key: string) =>
          auditingJson.riskValueOptions[
            key as keyof typeof auditingJson.riskValueOptions
          ],
      }),

      formBuilder.textArea({
        accessor: 'referenceField',
        plainLabel: 'Viitekenttä',
        //hidden: !formState.isOverThreeYears
      }),
    ],
  }),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
