import { formatDateTime, localizedDate } from './date';
import { tabSettings } from '../projectConfig.js';
import {
  AccountClassKey,
  AccountKey,
} from '../views/auditingSections/planning/accountMapKeys';
import { Risk } from '../views/auditingSections/analyzesAndScoping/interim/incomeStatementAndBalance';
export enum AuditingTemplate {
  private = 'private',
  public = 'public',
}

export type AuditingTemplateKey = keyof typeof AuditingTemplate;

type OmittedSections = {
  [AuditingTemplate.private]: AuditingSectionKey[];
  [AuditingTemplate.public]: AuditingSectionKey[];
};

/**
 * OMMITTED_SECTIONS: (
 * Public = Julkishallinto
 * Private = Osakeyhtiö
 * )
 */
// List what to show depending on the template
export const OMITTED_SECTIONS: OmittedSections = Object.freeze({
  [AuditingTemplate.private]: ['governmentTransfersAndTaxRevenues', 'governmentTransfers'],
  [AuditingTemplate.public]: [
    //'tradeCreditors',
  ],
});

export const sortAuditings = (a: Auditing, b: Auditing) => {
  return (
    localizedDate(b.startDate).toMillis() -
    localizedDate(a.startDate).toMillis()
  );
};

export const financialYear = (auditing?: Auditing) => {
  if (!auditing) return '';

  const format = 'D';
  const startDate = formatDateTime(auditing.startDate, { format });
  const endDate = formatDateTime(auditing.endDate, { format });
  return `${startDate}-${endDate}`;
};

export const getClassNameForFinancialStatementRow = (
  headingLevel?: AccountRowHeadingLevel
) =>
  headingLevel === 1
    ? 'text-bold text-uppercase'
    : headingLevel && headingLevel >= 2
    ? 'text-bold'
    : 'pull-right';

/**
 * Parses a comma separated list of account ranges.
 *
 * @param accountRanges A comma separated list of account ranges, e.g. "1000-1999,3000,4000-4499,6000"
 * @returns An array of account ranges, e.g. [[1000, 1999], [3000, 3000], [4000, 4499], [6000, 6000]]
 */
const parseAccountRanges = (accountRanges: string): number[][] =>
  accountRanges
    // Strip everything except numbers, commas and dashes
    .replace(/[^0-9,-]/g, '')
    // Split into ranges
    .split(',')
    // Split each range into start and end (end is optional)
    .map(item => item.split('-').map(Number));

/**
 * Sums up the current and prior year values of a list of grouped general ledger items.
 * @param generalLedgerItems A list of grouped general ledger items
 * @returns An object containing the summed up current and prior year values
 */
const sumCurrentAndPriorYearValues = (
  generalLedgerItems: GroupedGeneralLedgerItem[]
) =>
  generalLedgerItems.reduce(
    (acc, { currentYear, priorYear }) => ({
      currentYear: acc.currentYear + (currentYear ?? 0),
      priorYear: acc.priorYear + (priorYear ?? 0),
    }),
    { currentYear: 0, priorYear: 0 }
  );

export const getSummedAccountItems = (
  groupedGeneralLedger: GroupedGeneralLedger | undefined | null,
  accountMappings: AccountMappings
): SummedAccountItem[] => {
  return Object.keys(accountMappings).map(key => {
    const accountRanges = parseAccountRanges(accountMappings[key]);

    // Filter out all rows that don't match the account range
    const matchingAccountRows = (groupedGeneralLedger ?? []).filter(
      ({ account }) => {
        return accountRanges.some(([start, end]) => {
          if (start && end) return account >= start && account <= end;
          if (start) return account === start;
          return false;
        });
      }
    );

    // Sum up the current and prior year values
    const { currentYear, priorYear } =
      sumCurrentAndPriorYearValues(matchingAccountRows);

    return {
      id: key,
      accountKey: key,
      accountName: key,
      currentYear,
      priorYear,
      accountRange: accountMappings[key],
    };
  });
};

export const getAccountMappingValues = (
  groupedGeneralLedger: GroupedGeneralLedger | undefined | null,
  accountMappings: AccountMappings
): AccountMappingValues => {
  const accountMappingValues = getSummedAccountItems(
    groupedGeneralLedger,
    accountMappings
  );

  return accountMappingValues.reduce(
    (acc, { accountKey, currentYear, priorYear }) => ({
      ...acc,
      [accountKey]: {
        cy: currentYear ?? 0,
        py: priorYear ?? 0,
      },
    }),
    {}
  );
};

/**
 * Helper function to hide or show 'scoping' auditing route based on internalControlAndOperatingEnvironment's 'isInterimAudit' boolean.
 * false = hide, true = show
 */
export function shouldShowAnalyzesRoute(isInterimAudit: any): boolean {
  return isInterimAudit !== false;
}

/**
 * Helper for getting 'isInterimAudit' boolean value from internalControlAndOperatingEnvironment.
 * @param auditing any
 * @returns boolean
 */
export function getIsInterimAudit(isInterimAudit: any): boolean {
  return isInterimAudit;
}

/**
 * Determines the correct dependency based on the 'isInterimAudit' value.
 * @param isInterimAudit boolean
 * @param interimSections (keyof AuditingSections)[]
 * @param notInterimSections (keyof AuditingSections)[]
 * @returns (keyof AuditingSections)[]
 */
export function getDependingSections(
  isInterimAudit: boolean,
  interimSections: (keyof AuditingSections)[],
  notInterimSections: (keyof AuditingSections)[]
): (keyof AuditingSections)[] {
  return isInterimAudit ? interimSections : notInterimSections;
}

const incomeStatementAndBalanceItemHasNoRisks =
  (auditing?: Auditing) =>
  (...incomeStatementAndBalanceKeys: string[]) => {
    const { incomeStatement, balanceAssets, balanceLiabilities } =
      // Takes from incomeStatementAndBalance form (Tuloslaskelma ja tase - Tarkastus) Risk
      auditing?.incomeStatementAndBalance?.form ?? {};
    // Add a new incomeStatementAndBalanceScoping form (Tuloslaskelma ja tase - Päätös) Risk
    const {
      incomeStatement: incomeStatementScoping,
      balanceAssets: balanceAssetsScoping,
      balanceLiabilities: balanceLiabilitiesScoping,
    } = auditing?.incomeStatementAndBalanceScoping?.form ?? {};

    const keyValueHasSomeRisk = (item: IncomeStatementAndBalanceItem) =>
      incomeStatementAndBalanceKeys.includes(
        item.classKey ? AccountClassKey[item.classKey] : AccountKey[item.key]
      ) &&
      item.risk &&
      [Risk.significant, Risk.limitedRisk].includes(item.risk);

    const itemsHavingSomeRisk = [
      ...(incomeStatement ?? []).filter(keyValueHasSomeRisk),
      ...(balanceAssets ?? []).filter(keyValueHasSomeRisk),
      ...(balanceLiabilities ?? []).filter(keyValueHasSomeRisk),
      ...(incomeStatementScoping ?? []).filter(keyValueHasSomeRisk),
      ...(balanceAssetsScoping ?? []).filter(keyValueHasSomeRisk),
      ...(balanceLiabilitiesScoping ?? []).filter(keyValueHasSomeRisk),
    ];
    const noRisksFound = !itemsHavingSomeRisk.length;

    return noRisksFound;
  };

/**
 * Filter auditing proceduder that should be visible in "auditing" & "reporting" phases
 */
const pruneAuditingProcedures =
  (auditing?: Auditing) => (auditingProcedure: AuditingProcedure) => {
    const { batch, tp, period, inExcel } = auditingProcedure;

    const batchHasSomeRisks =
      !incomeStatementAndBalanceItemHasNoRisks(auditing)(batch);

    const isValidBatch = batchHasSomeRisks;

    return (
      (isValidBatch && ((tp !== null && tp) || (period !== null && period))) ||
      (isValidBatch && inExcel)
    );
  };

/**
 * Helper function to hide or show tabs in detailedplan.
 * @param auditing any
 * @param batchString any
 * @param category Array<string>
 * @returns true if the tab is chosen in detailedplan, otherwise false
 */
export function isTabChosenInDetailedPlan(
  auditing: Auditing | undefined,
  batchString: string,
  category: Array<string>
): boolean {
  if (!auditing) return false;

  if (tabSettings.displayAllParts) return true;

  let partsToAlwaysDisplay = tabSettings.partsToDisplayDespiteDetailedPlan;

  const updatePartsToAlwaysDisplay = (key: string, section: string) => {
    if (!auditing) return;
    if (
      !hidePartBasedOnDetailedPlan(auditing, key, section) &&
      !partsToAlwaysDisplay.includes(key)
    ) {
      partsToAlwaysDisplay.push(key);
    } else {
      partsToAlwaysDisplay = partsToAlwaysDisplay.filter(
        (item: any) => item !== key
      );
    }
  };

  updatePartsToAlwaysDisplay('innerCircleTab', 'G-management');
  updatePartsToAlwaysDisplay('manualExports', 'F-financialStatement');
  updatePartsToAlwaysDisplay('continuityOfOperation', 'F-financialStatement');

  if (
    !hidePartBasedOnDetailedPlan(
      auditing,
      'assignmentsFirstTime',
      'F-financialStatement'
    ) ||
    auditing?.permanentData?.form.isFirstAuditing
  )
    updatePartsToAlwaysDisplay('assignmentsFirstTime', 'F-financialStatement');

  if (partsToAlwaysDisplay.includes(batchString)) return true;

  if (!category.includes('auditing')) return true;

  const auditingProcedures =
    auditing.auditingProcedures?.form.auditingProcedures?.filter(
      pruneAuditingProcedures(auditing)
    ) || [];

  return auditingProcedures.some(({ batch }) => {
    const classKey = batch.includes('-')
      ? batch.substring(batch.indexOf('-') + 1).toLowerCase()
      : batch.toLowerCase();

    return classKey === batchString.toLowerCase();
  });
}

/**
 * Helper function to hide or show parts in/of ItAndProcesses.
 * @param auditing any
 * @param target any
 * @returns true to show in a auditing part, otherwise show in ItAndProcesses.
 */
export function hidePartBasedOnItAndProcesses(auditing: any, target: any) {
  /*
    If in itAndProcesses, toDocument is true, we display the part. otherwise we hide it
    The part is also hidden if the user removes the part from itAndProcesses.
    example: if target = "purchases", we display this part in K1-materialsAndServices batch
  */
  if (
    auditing.hasOwnProperty('itAndProcesses') &&
    auditing.itAndProcesses.form.hasOwnProperty('businessProcesses')
  ) {
    const businessProcesses =
      auditing.itAndProcesses.form.businessProcesses.filter(
        (item: { kind: BusinessProcess }) => item.kind === target
      );
    const thisItem = businessProcesses.find(
      (item: { kind: BusinessProcess }) => item.kind === target
    );

    if (thisItem && thisItem.toDocument === true) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

export function hidePartBasedOnDetailedPlan(
  auditing: any,
  target: any,
  batchString: string
): boolean {
  if (
    !auditing ||
    !auditing.auditingProcedures ||
    !auditing.auditingProcedures.form
  )
    return true;

  const auditingProcedures =
    auditing.auditingProcedures.form.auditingProcedures;
  if (!auditingProcedures) return true;

  const filteredProcedures = auditingProcedures.filter(
    (item: { batch: string }) => item.batch === batchString
  );

  const targetProcedure = filteredProcedures.find(
    (item: { actionKey: any }) => item.actionKey === target
  );

  if (!targetProcedure) return true;

  return !(targetProcedure.tp || targetProcedure.period);
}

export function hidePartBasedOnUseSheetSelect(auditing: any, batchString: any) {
  if (
    auditing.hasOwnProperty(batchString) &&
    auditing[batchString].hasOwnProperty('form') &&
    auditing[batchString].form.hasOwnProperty('useSheetTab')
  ) {
    return auditing[batchString].form.useSheetTab;
  } else return false;
}

export function hidePartBasedOnShouldShowAnalytic(
  auditing: any,
  batchString: any
) {
  const auditingPlanningForm = auditing['auditingPlanning']?.form;
  switch (batchString) {
    case 'preliminaryAnalytic':
      if (
        auditingPlanningForm?.interimAuditCheckboxGroup?.hasOwnProperty(
          'isDocumentedInInterimAudit'
        )
      ) {
        return !auditingPlanningForm.interimAuditCheckboxGroup
          .isDocumentedInInterimAudit;
      } else {
        return true;
      }
    case 'concludingAnalytic':
      if (
        auditingPlanningForm?.concludingAnalyticCheckboxGroup?.hasOwnProperty(
          'isDocumentedInAudit'
        )
      ) {
        return !auditingPlanningForm.concludingAnalyticCheckboxGroup
          .isDocumentedInAudit;
      } else {
        return true;
      }
  }
  return false;
}

/**
 * Function to create a field identifier.
 * @param batchString string
 * @param one string
 * @param two string
 * @returns string
 */
export function createFieldIdentifier(
  batchString: string,
  one: string,
  two: string
) {
  return (batchString + one + two) as any;
}
