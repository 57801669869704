import { useTranslation } from 'react-i18next';
import {
  AuditingTemplate,
  confirmDelete,
  formatCurrency,
  uuid,
} from '../../utils';
import { AccountKey } from '../../views/auditingSections/planning/accountMapKeys';
import {
  getAverageOfLiabilitiesAndRevenues,
  getCombinedRevenues,
} from '../../views/auditingSections/planning/materialityUtils';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';
import { formatNumber } from './CapitalLoans';
import styled from 'styled-components';

const Container = styled.div`
  .additional-info {
    font-style: italic;
    color: ${p => p.theme.color.grey400};
    text-align: right;
  }
`;

const sectionKey: AuditingSectionKey = 'materiality';

export const AlternativeMaterialities = ({
  formState,
  patchFormState,
  store,
}: FormFieldProps<MaterialityForm>) => {
  const { t } = useTranslation();

  const {
    auditingStore: {
      materialityComputeds,
      getFinancialNumberScoping,
      auditingTemplate,
    },
  } = store;

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}`;

  const tableColumns = [
    {
      accessorKey: 'name',
      header: () => t(`${tBase}.alternatives.name`),
    },
    {
      accessorKey: 'currentYear',
      header: () => t(`auditing:form.incomeStatementAndBalance.currentYear`),
      className: 'text-center width-min nowrap',
    },
    {
      accessorKey: 'priorYear',
      header: () => t(`auditing:form.incomeStatementAndBalance.priorYear`),
      className: 'text-center width-min nowrap',
    },
    {
      accessorKey: 'materialityPercent',
      onNumberEdit: ({ itemId, value }: OnEditProps<number>) => {
        const alternatives = formState.alternatives;

        const updateValue = (targetRowId: string) => {
          if (targetRowId === itemId) return value;
          else {
            switch (targetRowId) {
              case 'turnover':
                return alternatives.turnoverMaterialityPercent;
              case 'profitOrLossBeforeTransfersAndTaxes':
                return alternatives.profitOrLossBeforeTransfersAndTaxesMaterialityPercent;
              case 'equity':
                return alternatives.equityMaterialityPercent;
              case 'liabilities':
                return alternatives.liabilitiesMaterialityPercent;
              case 'combinedRevenues':
                return alternatives.combinedRevenuesMaterialityPercent;
              case 'averageOfLiabilitiesAndRevenues':
                return alternatives.averageOfLiabilitiesAndRevenuesMaterialityPercent;
              case 'annualContributionMargin':
                return alternatives.annualContributionMarginMaterialityPercent;
              default:
                return null;
            }
          }
        };

        patchFormState({
          ...formState,
          alternatives: {
            turnoverMaterialityPercent: updateValue('turnover'),
            profitOrLossBeforeTransfersAndTaxesMaterialityPercent: updateValue(
              'profitOrLossBeforeTransfersAndTaxes'
            ),
            equityMaterialityPercent: updateValue('equity'),
            liabilitiesMaterialityPercent: updateValue('liabilities'),
            combinedRevenuesMaterialityPercent: updateValue('combinedRevenues'),
            averageOfLiabilitiesAndRevenuesMaterialityPercent: updateValue(
              'averageOfLiabilitiesAndRevenues'
            ),
            annualContributionMarginMaterialityPercent: updateValue(
              'annualContributionMargin'
            ),
          },
        });
      },
      header: () => t(`${tBase}.alternatives.materialityPercent`),
      className: 'text-center width-min nowrap',
    },
    {
      accessorKey: 'materiality',
      header: () => t(`${tBase}.alternatives.materiality`),
      className: 'text-center width-min nowrap',
    },
  ];

  const percents = formState?.alternatives ?? [];
  const materialities = materialityComputeds(formState).alternatives;

  const isTemplate = (...templates: AuditingTemplate[]) =>
    auditingTemplate && templates.includes(auditingTemplate);

  const rows = [
    {
      id: 'turnover',
      name: t(`${tBase}.materialityBasisOptions.turnover`),
      currentYear: formatCurrency(
        getFinancialNumberScoping(AccountKey.turnover)
      ),
      priorYear: formatCurrency(
        getFinancialNumberScoping(AccountKey.turnover, 'priorYear')
      ),
      materialityPercent: percents.turnoverMaterialityPercent,
      materiality: formatCurrency(materialities.turnoverMateriality),
      hidden: isTemplate(AuditingTemplate.public),
    },
    {
      id: 'profitOrLossBeforeTransfersAndTaxes',
      name: t(
        `${tBase}.materialityBasisOptions.profitOrLossBeforeTransfersAndTaxes`
      ),
      currentYear: formatCurrency(
        getFinancialNumberScoping(
          AccountKey.profitOrLossBeforeTransfersAndTaxes
        )
      ),
      priorYear: formatCurrency(
        getFinancialNumberScoping(
          AccountKey.profitOrLossBeforeTransfersAndTaxes,
          'priorYear'
        )
      ),
      materialityPercent:
        percents.profitOrLossBeforeTransfersAndTaxesMaterialityPercent,
      materiality: formatCurrency(
        materialities.profitOrLossBeforeTransfersAndTaxesMateriality
      ),
      hidden: isTemplate(AuditingTemplate.public),
    },
    {
      id: 'equity',
      name: t(`${tBase}.materialityBasisOptions.equity`),
      currentYear: formatCurrency(
        getFinancialNumberScoping(AccountKey.equity)
      ),
      priorYear: formatCurrency(
        getFinancialNumberScoping(AccountKey.equity, 'priorYear')
      ),
      materialityPercent: percents.equityMaterialityPercent,
      materiality: formatCurrency(materialities.equityMateriality),
      hidden: isTemplate(AuditingTemplate.public),
    },
    {
      id: 'liabilities',
      name: t(`${tBase}.materialityBasisOptions.balanceLiabilities`),
      currentYear: formatCurrency(
        getFinancialNumberScoping(AccountKey.balanceLiabilities)
      ),
      priorYear: formatCurrency(
        getFinancialNumberScoping(
          AccountKey.balanceLiabilities,
          'priorYear'
        )
      ),
      materialityPercent: percents.liabilitiesMaterialityPercent,
      materiality: formatCurrency(materialities.liabilitiesMateriality),
    },
    {
      id: 'combinedRevenues',
      name: t(`${tBase}.materialityBasisOptions.combinedRevenues`),
      currentYear: formatCurrency(getCombinedRevenues(store)),
      priorYear: formatCurrency(getCombinedRevenues(store, 'priorYear')),
      materialityPercent: percents.combinedRevenuesMaterialityPercent,
      materiality: formatCurrency(materialities.combinedRevenuesMateriality),
      hidden: isTemplate(AuditingTemplate.private),
    },
    {
      id: 'averageOfLiabilitiesAndRevenues',
      name: t(
        `${tBase}.materialityBasisOptions.averageOfLiabilitiesAndRevenues`
      ),
      currentYear: formatCurrency(getAverageOfLiabilitiesAndRevenues(store)),
      priorYear: formatCurrency(
        getAverageOfLiabilitiesAndRevenues(store, 'priorYear')
      ),
      materialityPercent:
        percents.averageOfLiabilitiesAndRevenuesMaterialityPercent,
      materiality: formatCurrency(
        materialities.averageOfLiabilitiesAndRevenuesMateriality
      ),
      hidden: isTemplate(AuditingTemplate.private),
    },
    {
      id: 'annualContributionMargin',
      name: t(`${tBase}.materialityBasisOptions.annualContributionMargin`),
      currentYear: formatCurrency(
        getFinancialNumberScoping(AccountKey.annualContributionMargin)
      ),
      priorYear: formatCurrency(
        getFinancialNumberScoping(
          AccountKey.annualContributionMargin,
          'priorYear'
        )
      ),
      materialityPercent: percents.annualContributionMarginMaterialityPercent,
      materiality: formatCurrency(
        materialities.annualContributionMarginMateriality
      ),
      hidden: isTemplate(AuditingTemplate.private),
    },
  ].filter(row => !row.hidden);

  return (
    <AuditingTable
      sectionKey={sectionKey}
      title={t(`${tBase}.alternatives.title`)}
      data={rows}
      columns={tableColumns}
      showGlobalFilter={false}
      disableSort
    />
  );
};

export const MaterialityTable = ({
  formState,
  patchFormState,
}: FormFieldProps<MaterialityForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.materialityTable`;

  const data = formState.materialityTableColumn ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.materialityTableColumn.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...row, [columnId]: value };
        if (
          columnId === 'materialityBasedOn' ||
          columnId === 'materialityPercent'
        ) {
          let materialityBasedOnValue = updatedRow.materialityBasedOn || 0;
          let materialityPercentValue = updatedRow.materialityPercent || 0;
          // Calculate materialityEuro ( BasedOn * Percent / 100 )
          let materialityEuro =
            (materialityBasedOnValue * materialityPercentValue) / 100;
          updatedRow.materialityEuro = materialityEuro;
          // FormatNumber
          formatNumber(updatedRow.materialityEuro);
        }
        return updatedRow;
      }
      return row;
    });
    const newFormState = { ...formState, materialityTableColumn: patch };
    patchFormState(newFormState);
  }

  let tableColumns: TableColumnDefs<MaterialityTableColumn> = [
    {
      accessorKey: 'label',
      header: () => t(`${tBase}.label`),
      className: 'text-left width-middle',
      placeholder: '',
      /*           onEdit: (editProps: OnEditProps<string>) => {
              handleEdit<string>(editProps);
          }, */
    },
    {
      accessorKey: 'materialityBasedOn',
      header: () => t(`${tBase}.materialityBasedOn`) + ' €',
      className: 'text-center width-min nowrap',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.materialityBasedOn} €</div>,
    },
    {
      accessorKey: 'materialityPercent',
      header: () => t(`${tBase}.materialityPercent`),
      className: 'text-center width-min nowrap',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.materialityPercent} %</div>,
    },
    {
      accessorKey: 'materialityEuro',
      header: () => t(`${tBase}.materialityEuro`),
      className: 'text-center width-min nowrap',
      accessorFn: (row: any) => {
        return formatCurrency(row.materialityEuro);
      },
      cell: ({ cell }) => (
        <div>{formatNumber(cell.row.original.materialityEuro)} €</div>
      ),
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      materialityTableColumn: [
        ...(formState.materialityTableColumn ?? []),
        {
          label: '',
          id: uuid(), //Math.floor(Math.random()*100).toString(),
          materialityBasedOn: 0,
          materialityPercent: 0,
          materialityEuro: 0,
        },
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: MaterialityTableColumn) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.materialityTableColumn ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, materialityTableColumn: patch });
    }
  };

  return (
    <>
      <AuditingTable<MaterialityTableColumn>
        sectionKey={sectionKey}
        title="Vaihtoehtoiset Olennaisuudet"
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
      />
      <Container>
        <div className="additional-info">Käytettävä luku € * Olennaisuus %</div>
      </Container>
    </>
  );
};

export const MaterialitySecondTable = ({
  formState,
  patchFormState,
}: FormFieldProps<MaterialityForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.materialityTable`;

  const data = formState.materialitySecondTableColumn ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.materialitySecondTableColumn.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...row, [columnId]: value };
        if (
          columnId === 'materialityBasedOn' ||
          columnId === 'materialityPercent'
        ) {
          let materialityBasedOnValue = updatedRow.materialityBasedOn || 0;
          let materialityPercentValue = updatedRow.materialityPercent || 0;
          // Calculate materialityEuro ( BasedOn * Percent / 100 )
          let materialityEuro =
            (materialityBasedOnValue * materialityPercentValue) / 100;
          updatedRow.materialityEuro = materialityEuro;
          // FormatNumber
          formatNumber(updatedRow.materialityEuro);
        }
        return updatedRow;
      }
      return row;
    });
    const newFormState = { ...formState, materialitySecondTableColumn: patch };
    patchFormState(newFormState);
  }

  let tableColumns: TableColumnDefs<MaterialitySecondTableColumn> = [
    {
      accessorKey: 'label',
      header: () => t(`${tBase}.label`),
      className: 'text-left width-middle',
      placeholder: '',
      /*           onEdit: (editProps: OnEditProps<string>) => {
              handleEdit<string>(editProps);
          }, */
    },
    {
      accessorKey: 'materialityBasedOn',
      header: () => t(`${tBase}.materialityBasedOn`) + ' €',
      className: 'text-center width-min nowrap',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.materialityBasedOn} €</div>,
    },
    {
      accessorKey: 'materialityPercent',
      header: () => t(`${tBase}.materialityPercent`),
      className: 'text-center width-min nowrap',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.materialityPercent} %</div>,
    },
    {
      accessorKey: 'materialityEuro',
      header: () => t(`${tBase}.materialityEuro`),
      className: 'text-center width-min nowrap',
      accessorFn: (row: any) => {
        return formatCurrency(row.materialityEuro);
      },
      cell: ({ cell }) => (
        <div>{formatNumber(cell.row.original.materialityEuro)} €</div>
      ),
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      materialitySecondTableColumn: [
        ...(formState.materialitySecondTableColumn ?? []),
        {
          label: '',
          id: uuid(), //Math.floor(Math.random()*100).toString(),
          materialityBasedOn: 0,
          materialityPercent: 0,
          materialityEuro: 0,
        },
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: MaterialitySecondTableColumn) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.materialitySecondTableColumn ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, materialitySecondTableColumn: patch });
    }
  };

  return (
    <>
      <AuditingTable<MaterialitySecondTableColumn>
        sectionKey={sectionKey}
        title="Vaihtoehtoiset Olennaisuudet"
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
      />
      <Container>
        <div className="additional-info">Käytettävä luku € * Olennaisuus %</div>
      </Container>
    </>
  );
};

export const MaterialityManualTable = ({
  formState,
  patchFormState,
}: FormFieldProps<MaterialityForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.materialityTable`;

  const data = formState.materialityManualTableColumn ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.materialityManualTableColumn.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...row, [columnId]: value };
        if (
          columnId === 'materialityBasedOn' ||
          columnId === 'materialityPercent'
        ) {
          let materialityBasedOnValue = updatedRow.materialityBasedOn || 0;
          let materialityPercentValue = updatedRow.materialityPercent || 0;
          // Calculate materialityEuro ( BasedOn * Percent / 100 )
          let materialityEuro =
            (materialityBasedOnValue * materialityPercentValue) / 100;
          updatedRow.materialityEuro = materialityEuro;
          // FormatNumber
          formatNumber(updatedRow.materialityEuro);
        }
        return updatedRow;
      }
      return row;
    });
    const newFormState = { ...formState, materialityManualTableColumn: patch };
    patchFormState(newFormState);
  }

  let tableColumns: TableColumnDefs<MaterialityManualTableColumn> = [
    {
      accessorKey: 'label',
      header: () => t(`${tBase}.label`),
      className: 'text-left width-middle',
      placeholder: '',
      /*           onEdit: (editProps: OnEditProps<string>) => {
              handleEdit<string>(editProps);
          }, */
    },
    {
      accessorKey: 'materialityBasedOn',
      header: () => t(`${tBase}.materialityBasedOn`) + ' €',
      className: 'text-center width-min nowrap',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.materialityBasedOn} €</div>,
    },
    {
      accessorKey: 'materialityPercent',
      header: () => t(`${tBase}.materialityPercent`),
      className: 'text-center width-min nowrap',
      onNumberEdit: (editProps: OnEditProps<number>) =>
        handleEdit<number>(editProps),
      cell: ({ cell }) => <div>{cell.row.original.materialityPercent} %</div>,
    },
    {
      accessorKey: 'materialityEuro',
      header: () => t(`${tBase}.materialityEuro`),
      className: 'text-center width-min nowrap',
      accessorFn: (row: any) => {
        return formatCurrency(row.materialityEuro);
      },
      cell: ({ cell }) => (
        <div>{formatNumber(cell.row.original.materialityEuro)} €</div>
      ),
    },
  ];

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      materialityManualTableColumn: [
        ...(formState.materialityManualTableColumn ?? []),
        {
          label: '',
          id: uuid(), //Math.floor(Math.random()*100).toString(),
          materialityBasedOn: 0,
          materialityPercent: 0,
          materialityEuro: 0,
        },
      ],
    });
  };

  const handleDeleteRow = ({ id, label }: MaterialityManualTableColumn) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.materialityManualTableColumn ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, materialityManualTableColumn: patch });
    }
  };

  return (
    <>
      <AuditingTable<MaterialityManualTableColumn>
        sectionKey={sectionKey}
        title="Vaihtoehtoiset olennaisuudet ( manuaalinen )"
        onAddNewRow={handleAddNewRow}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
      />
      <Container>
        <div className="additional-info">Käytettävä luku € * Olennaisuus %</div>
      </Container>
    </>
  );
};
