import { useMemo } from "react";
import { TableColumnDefs } from "../../../../components/table/Table"
import { OnEditProps } from "../../../../components/table/TableInputs"
import { RentCalculationTables } from './otherOperatingExpenses.rentCalculationTables';
import { addNewTables, deleteTable, exampleData, handleEdit, toggleShowChildrenForTable } from "./otherOperatingExpenses.tablesUtils";
import AuditingTable from "../../../../components/table/AuditingTable";
import styled from "styled-components";
import { Button, Checkbox } from "../../../../components/inputs";
import { t } from "../../../../i18n";

const sectionKey: AuditingSectionKey = 'otherOperatingExpenses';
const tBase = `auditing:form.${sectionKey}.rentTables`;

const Container = styled.div`
  padding: 5px;
  margin-top: 15px;
  border: solid 2px #a0a0a0;
  border-radius: 5px;
`;

export const RentTable = ({ formState, patchFormState, store, replaceFormState }: FormFieldProps<OtherOperatingExpensesForm>, useCalculationTables: boolean
) => {
  const data = useMemo(() => formState.rentTablesData ?? exampleData, [formState]);

  const tableColumns: TableColumnDefs<rentTableRow> = [
    {
      accessorKey: 'label',
      className: 'text-left nowrap width-min text-bold',
      header: () => 'Vuokra'
    },
    {
      accessorKey: 'value',
      className: 'text-left nowrap width-min',
      header: () => '',
      onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps,
        formState,
        patchFormState,
        data,
      ),
      // Disable calculated rows
      disabled: (row: rentTableRow) => row.type === 'difference' || row.type === 'cumulativeRentalCostExpectation'
    },
    {
      accessorKey: 'comment',
      className: 'text-left nowrap width-min',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps,
        formState,
        patchFormState,
        data,
      ),
      header: () => ''
    }
  ]

  const baseTables = data.filter((table: rentTable) => table.type === 'base');

  const renderBaseTable = (table: rentTable) => {
    return (
      <AuditingTable
        key={`${table.id}-table`}
        sectionKey={sectionKey}
        data={table.table}
        columns={tableColumns}
        disableSort
        showGlobalFilter={false}
        variant="default"
      />
    )
  }

  return (
    <>
      {baseTables.map((baseTable) => {
        return (
          <Container>
            {renderBaseTable(baseTable)}
            <div className="flex flex-row">
              <Checkbox
                label="Laske indeksikorotus / % korotus"
                checked={baseTable.showChildren ?? false}
                onChange={() => toggleShowChildrenForTable(
                  formState,
                  patchFormState,
                  baseTable.id)}
              />
              <Button
                text={t(`${tBase}.deleteTable`)}
                variant="link"
                icon="Delete"
                onClick={() => deleteTable(formState, patchFormState, baseTable)}
                />
            </div>
            {
              baseTable.showChildren &&
              <RentCalculationTables
                key={`${baseTable.id}-children`}
                formState={formState}
                patchFormState={patchFormState}
                store={store}
                replaceFormState={replaceFormState}
                parentTableId={baseTable.id}
              />
            }
          </Container>
        )
      })
      }
      <Button
        variant="link"
        text={t(`${tBase}.addNewTable`)}
        onClick={() => addNewTables(formState, patchFormState)}
      />
    </>
  )
}