import { PlanningItems } from '../../../../components/analyzesAndScoping/interim/DetailedPlan.BatchActionsTable';
import InstructionBox from '../../../../components/InstructionBox';
import { t } from '../../../../i18n';
import { tabSettings } from '../../../../projectConfig';
import { getDependingSections } from '../../../../utils';
import {
  AuditingProcedureResult,
  batchName,
  defaultAuditingProcedures,
  FinancialStatementBatch,
  incomeStatementAndBalanceItemHasNoRisks,
  isDefaultBatch,
  transformRemarkToAuditingProcedure,
} from '../../auditing/auditingProceduresUtils';
import { FormFieldBuilder } from '../../FormFieldBuilder';

/**
 * Please note:
 * This section doesn't have its own form, since it's manipulating form data defined for auditingProcedures section!
 */

// NOTE: This section is using other section's form
type SectionFormType = AuditingProceduresForm;

const sectionKey: AuditingSectionKey = 'detailedPlan';
const formSectionKey: AuditingSectionKey = 'auditingProcedures';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ store, auditing }) => {
  const auditingProcedures = [
    ...store.auditingStore
      .getAuditingRemarks()
      .map(transformRemarkToAuditingProcedure),
    ...defaultAuditingProcedures(store, auditing),
  ];

  const iCAOE = store.auditingStore.getAuditingSection(
    'internalControlAndOperatingEnvironment'
  );
  const isInterimAudit = iCAOE?.form.isInterimAudit;
  if (isInterimAudit) {
    const batchActionKeyMap = {
      'J1-turnover': [
        'processMappingWtAndMainAccountingPrinciples',
        'matchingOfSalesToInvoicing',
        'reviewOfCreditInvoices',
      ],
      'K1-materialsAndServices': [
        'analyticalInspectionOfPurchases',
        'materialInspection',
      ],
      'N-taxes': ['lastSeasonTaxesCheck'],
      'O1-fixedAssets': ['checkingAdditionsAndSubtractions'],
      'G-management': ['groupInstruction', 'boardMeetingMinutes'],
    };
    auditingProcedures.forEach(procedure => {
      const actionKeys =
        batchActionKeyMap[procedure.batch as keyof typeof batchActionKeyMap];
      if (actionKeys && actionKeys.includes(procedure.actionKey)) {
        procedure.period = true;
      }
    });
  }

  const isAuditingSectionFinished =
    store.auditingStore.isAuditingSectionFinished('detailedPlan');
  const communicationAndReporting = store.auditingStore.getAuditingSection(
    'communicationAndReporting'
  );
  const receivedConfirmationNotificationLetter =
    communicationAndReporting?.form.receivedConfirmationNotificationLetter;
  if (isAuditingSectionFinished) {
    auditingProcedures.forEach(procedure => {
      if (
        procedure.batch === FinancialStatementBatch['B-commonActions'] &&
        ['discussionsWithManagement', 'auditPlan', 'dataRequests'].includes(
          procedure.actionKey
        )
      ) {
        procedure.auditingResult = AuditingProcedureResult.treatedGood;
      }
    });
  }
  if (receivedConfirmationNotificationLetter) {
    auditingProcedures.forEach(procedure => {
      if (
        procedure.batch === FinancialStatementBatch['B-commonActions'] &&
        [
          'confirmationLetter',
          'reportingInterimReportAndOtherPossibleReports',
        ].includes(procedure.actionKey)
      ) {
        procedure.auditingResult = AuditingProcedureResult.treatedGood;
      }
    });
  }

  return {
    auditingProcedures,
    detailedPlanHelp: false,
  };
};

const formFields: FormFields<SectionFormType> = ({ auditing }) => {
  const hasNoRisks = incomeStatementAndBalanceItemHasNoRisks(auditing);

  // Helper to build financial statement batches' accordion groups
  const batchAccordion = (
    batch: FinancialStatementBatch,
    props: { open?: boolean; hidden?: boolean } = {}
  ) =>
    formBuilder.accordionGroup({
      open: props?.open,
      title: batchName(batch),
      hidden: tabSettings.displayAllParts ? false : props?.hidden ?? (!isDefaultBatch(batch) && hasNoRisks(batch)),
      items: [
        formBuilder.custom(props => (
          <PlanningItems batchKey={batch} {...props} />
        )),
        /*
        // Excel download button
        formBuilder.custom(props => {
          const procedures: AuditingProcedure[] = formState.auditingProcedures.filter(
            p => p.batch === batch
          );
          const isExcelProcedures = procedures.filter(procedure => hasInExcel(procedure));
          const actionKeys = isExcelProcedures.map(procedure => procedure.actionKey);
          return (
            <>
              {isExcelProcedures.length > 0 && (
                <ExcelComponent
                  batchKey={batch}
                  inExcel={true}
                  //batchValue={batchName(batch)}
                  batchValue={batch}
                  actionKeys={actionKeys}
                  {...props}
                />
              )}
            </>
          );
        }),
        */
      ],
    });

  return [
    formBuilder.custom(
      <InstructionBox
        buttonText='Katso ohje!'
        title='Ohje'>
        <div style={{whiteSpace: 'pre-line'}}>
          {t('auditing:form.detailedPlan.detailedPlanHelpInfo')}
        </div>
      </InstructionBox>
    ),
    batchAccordion(FinancialStatementBatch['B-commonActions']),
    batchAccordion(FinancialStatementBatch['F-financialStatement']),
    batchAccordion(FinancialStatementBatch['F3-presentationAndContent']),
    batchAccordion(FinancialStatementBatch['G-management']),
    batchAccordion(FinancialStatementBatch['J-operatingIncome']),
    batchAccordion(FinancialStatementBatch['JB-otherOperatingIncome']),
    batchAccordion(FinancialStatementBatch['J1-turnover']),
    batchAccordion(FinancialStatementBatch['J2-accountsReceivable']),
    batchAccordion(FinancialStatementBatch['K-materialsAndServices']),
    batchAccordion(FinancialStatementBatch['KB-otherOperatingExpenses']),
    batchAccordion(FinancialStatementBatch['K1-materialsAndServices']),
    batchAccordion(FinancialStatementBatch['K2-tradeCreditors']),
    batchAccordion(FinancialStatementBatch['L-personelCosts']),
    batchAccordion(
      FinancialStatementBatch['M-governmentTransfersAndTaxRevenues']
    ),
    batchAccordion(FinancialStatementBatch['M-governmentTransfers']),
    batchAccordion(FinancialStatementBatch['N-taxes']),
    batchAccordion(FinancialStatementBatch['O1-fixedAssets']),
    batchAccordion(FinancialStatementBatch['O3-investments']),
    batchAccordion(FinancialStatementBatch['O4-funding']),
    batchAccordion(FinancialStatementBatch['P-inventories']),
    batchAccordion(FinancialStatementBatch['P1-warehouses']),
    batchAccordion(FinancialStatementBatch['Q1-otherReceivables']),
    batchAccordion(FinancialStatementBatch['Q2-cashEquivalents']),
    batchAccordion(FinancialStatementBatch['Q3-loanReceivables']),
    batchAccordion(FinancialStatementBatch['R1-ownCapital']),
    batchAccordion(FinancialStatementBatch['R2-capitalLoans']),
    batchAccordion(FinancialStatementBatch['S1-financialInstitutions']),
    batchAccordion(FinancialStatementBatch['T-otherCreditors']),
    batchAccordion(FinancialStatementBatch['T1-otherLiabilities']),
    batchAccordion(FinancialStatementBatch['T2-advancePayments']),
    batchAccordion(FinancialStatementBatch['U-assignmentAssetsAndLiabilities']),
    batchAccordion(FinancialStatementBatch['V-reserves']),
    batchAccordion(FinancialStatementBatch['XJ-financialIncome']),
    batchAccordion(FinancialStatementBatch['XK-financialExpenses']),
  ];
};

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  formSectionKey,
  defaultFormState,
  formFields,
  depending: auditing => {
    const isInterimAudit =
      auditing.internalControlAndOperatingEnvironment?.form.isInterimAudit;
    const interimSections: (keyof AuditingSections)[] = [
      'incomeStatementAndBalance',
    ];
    const notInterimSections: (keyof AuditingSections)[] = [
      'incomeStatementAndBalanceScoping',
    ];
    return getDependingSections(
      !!isInterimAudit,
      interimSections,
      notInterimSections
    );
  },
};

export default section;
