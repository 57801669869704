import { formatCurrency } from "../../utils";
import AuditingTable from "../table/AuditingTable";
import { TableColumnDefs } from "../table/Table";
import styled from "styled-components";
import { t } from "i18next";

const tBase = 'auditing:form.ownCapital';

const Container = styled.div``;

export const SurplusAndDeficit = ({
  store
}: FormFieldProps<OwnCapitalForm>) => {

  const auditingGeneralLedgerScoping =
    store?.auditingStore?.auditing?.generalLedgerScoping?.form.groupedGeneralLedger;

  const py = auditingGeneralLedgerScoping?.find(({ account }) => account === 2040)?.currentYear ?? NaN;
  const cy = auditingGeneralLedgerScoping?.find(({ account }) => account === 2050)?.currentYear ?? NaN;
  const data = [
    {
      id: 'py',
      label: t(`${tBase}.surplusAndDeficit.table.py`),
      value: py
    },
    {
      id: 'cy',
      label: t(`${tBase}.surplusAndDeficit.table.cy`),
      value: cy
    },
    {
      id: 'difference',
      label: t(`${tBase}.surplusAndDeficit.table.difference`),
      value: py + cy
    }
  ]

  const columns: TableColumnDefs<SurplusAndDeficitTableRow> = [
    {
      id: "label",
      accessorKey: "label",
    },
    {
      id: "value",
      accessorKey: "value",
      cell: row => formatCurrency(row.cell.getValue()),
    }
  ]

  return (
    <Container>
      <AuditingTable
        data={data}
        columns={columns}
        disableSort
        hideHeader
        showGlobalFilter={false}
      />
    </Container>
  )
}; 