import Todo from '../../../components/Todo';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { AccountKey } from '../planning/accountMapKeys';
import accountMap from '../planning/accountMapPublicSector';
import { AccountMappings } from '../../../components/auditing/GovernmentTransfers.AccountMappings';
import { t } from 'i18next';
import { TableColumn } from './reUsables/TableColumn';
import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';

type SectionFormType = GovernmentTransfersForm;

const sectionKey: AuditingSectionKey = 'governmentTransfers';
const batchString = 'M-governmentTransfers';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = () => ({
  accountMappingsBasicServices: {
    title: t('auditing:accountKey.governmentTransfers_municipalitysBasicServices'),
    accountRange: {
      basicServicesGovernmentShare: accountMap.incomeStatementAccountMap.find(acc => acc.key === AccountKey.governmentTransfers_municipalitysBasicServices)
    },
    accounts: [5501, 5502, 5510]
  },
  accountMappingsEducationalAndCultural: {
    title: t('auditing:accountKey.governmentTransfers_educationalAndCulturalActivity'),
    accountRange: {
      financingOfEducationalAndCulturalActivities: accountMap.incomeStatementAccountMap.find(acc => acc.key === AccountKey.governmentTransfers_educationalAndCulturalActivity)
    },
    accounts: [5701]
  },
  accountMappingsOtherGovernmentShares: {
    title: t('auditing:accountKey.governmentTransfers_other'),
    accountRange: {
      otherGovernmentShares: accountMap.incomeStatementAccountMap.find(acc => acc.key === AccountKey.governmentTransfers_other)
    },
    accounts: [5850]
  },
  accountMappingsTaxRevenueLossesCompensation: {
    title: t('auditing:accountKey.compensationForLossOfTaxRevenue'),
    accountRange: {
      taxRevenueLossesCompensation: accountMap.incomeStatementAccountMap.find(acc => acc.key === AccountKey.compensationForLossOfTaxRevenue)
    },
    accounts: [5890]
  },

  procedureAccountsData: {},

  tableColumnData: [
    {
      left: 'Kaikki tiedot (oppilastiedot, talous- ja toimintatilasto) on kirjattu ja ilmoitettu',
      right: t('auditing:TAY'),
    },
    {
      left: 'Tiedot ovat todellisia ja tallella',
      right: t('auditing:OLE'),
    },
    {
      left: 'Tapahtumat on oikein rekisteröity',
      right: t('auditing:LUOK'),
    }
  ],

  // Kuntien ja hyvinvointialueiden talousraportointi
  dataCollectionIsAppropritateRadioGroup: null,
  dataCollectionIsAppropritateComment: t('auditing:form.governmentTransfers.municipalitiesAndWellbeingServicesFinancialReporting.dataCollectionIsAppropriateComment'),
  organizationOfDataCollection: '',
  costAllocation: '',
  handlingOfSharedCosts: '',
  linkingCostsToAuditing: '',
  reconciliations: '',
  handlingErrorReports: '',

  // Oppilastilastot
  studentDataCollectionIsAppropritateRadioGroup: null,
  studentDataCollectionIsAppropritateComment: t('auditing:form.governmentTransfers.studentStatistics.dataCollectionIsAppropriateComment'),
  studentOrganizationOfDataCollection: '',
  sampleTaken: '',
  publicOfficialDecisions: '',
  archivalAndConfidentiality: '',

  // Päätös valtionosuuksiin tyytymisestä
  governmentTransfersDecisionConclusionRadioGroup1: null,
  governmentTransfersDecisionConclusionComment1: '',
  governmentTransfersDecisionConclusionRadioGroup2: null,
  governmentTransfersDecisionConclusionComment2: '',
})

const formFields: FormFields<SectionFormType> = ({ formState, store, auditing }) => [
  formBuilder.custom(<Todo />),

  formBuilder.custom(props =>
    <ProcedureAccountsElement
      auditing={auditing}
      batchString='M-governmentTransfersAndTaxRevenues'
      {...props}
    />),

  formBuilder.group({
    items: [
      formBuilder.subtitle({ title: "Valtionosuudet" }),
      formBuilder.custom(props =>
        <AccountMappings
          accountMapping={formState.accountMappingsBasicServices}
          {...props}
        />
      ),
      formBuilder.custom(props =>
        <AccountMappings
          accountMapping={formState.accountMappingsEducationalAndCultural}
          {...props}
        />
      ),
      formBuilder.custom(props =>
        <AccountMappings
          accountMapping={formState.accountMappingsOtherGovernmentShares}
          {...props}
        />
      ),
      formBuilder.custom(props =>
        <AccountMappings
          accountMapping={formState.accountMappingsTaxRevenueLossesCompensation}
          {...props}
        />
      ),
    ]
  }),

  formBuilder.group({
    items: [
      formBuilder.subtitle({ title: "Valtionosuuksien perusteet" }),
      formBuilder.text({ text: "Saada riittävää tarkastusevidenssiä siitä, että" }),
      formBuilder.custom(props => <TableColumn {...props} />),
      formBuilder.text({ text: "Tarkastuksessa huomioidaan StAkatemian suositus valtionosuuksien tarkastukseen (päivitetty 9/2022)" })
    ]
  }),

  formBuilder.group({
    items: [
      formBuilder.subtitle({ title: "Täsmäytys" }),
      formBuilder.custom(<Todo />),
    ]
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      // Kuntien ja hyvinvointialueiden talousraportointi
      accordionId: 'municipalitiesAndWellbeingServicesFinancialReporting',
      upperElements: [
        formBuilder.custom(() => (
          <div>
            <span><strong>{t('auditing:form.governmentTransfers.financialInformationService.label')}</strong></span>
            <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
              <li>{t('auditing:form.governmentTransfers.financialInformationService.line1')}</li>
              <li>{t('auditing:form.governmentTransfers.financialInformationService.line2')}</li>
              <li>{t('auditing:form.governmentTransfers.financialInformationService.line3')}</li>
            </ul>
          </div>
        )),

        formBuilder.radioGroup({
          plainLabel: t('auditing:conclusion'),
          accessor: 'dataCollectionIsAppropritateRadioGroup',
          detailsAccessor: 'dataCollectionIsAppropritateComment',
          options: Object.keys(t(
            'auditing:form.governmentTransfers.dataCollectionIsAppropritateRadioGroupOptions',
            { returnObjects: true }
          )),
          optionDisplayValue: (option: string) =>
            t(`auditing:form.governmentTransfers.dataCollectionIsAppropritateRadioGroupOptions.${option}`)
        }),

        formBuilder.group({
          items: [
            formBuilder.custom(() => <strong style={{ color: "#333333" }}>Havainnot</strong>),
            formBuilder.textArea({
              accessor: 'organizationOfDataCollection'
            }),
            formBuilder.textArea({
              accessor: 'costAllocation'
            }),
            formBuilder.textArea({
              accessor: 'handlingOfSharedCosts'
            }),
            formBuilder.textArea({
              accessor: 'linkingCostsToAuditing'
            }),
            formBuilder.textArea({
              accessor: 'reconciliations'
            }),
            formBuilder.textArea({
              accessor: 'handlingErrorReports'
            }),
          ]
        }),
      ],
    },
    // Oppilastilastot
    {
      accordionId: 'studentStatistics',
      upperElements: [
        formBuilder.custom(() => (
          <div>
            <span><strong>{t('auditing:form.governmentTransfers.studentStatistics.label')}</strong></span>
            <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
              <li>{t('auditing:form.governmentTransfers.studentStatistics.line1')}</li>
              <li>{t('auditing:form.governmentTransfers.studentStatistics.line2')}</li>
              <li>{t('auditing:form.governmentTransfers.studentStatistics.line3')}</li>
              <li>{t('auditing:form.governmentTransfers.studentStatistics.line4')}</li>
              <li>{t('auditing:form.governmentTransfers.studentStatistics.line5')}</li>
              <li>{t('auditing:form.governmentTransfers.studentStatistics.line6')}</li>
              <li>{t('auditing:form.governmentTransfers.studentStatistics.line7')}</li>
              <li>{t('auditing:form.governmentTransfers.studentStatistics.line8')}</li>
            </ul>
          </div>
        )),

        formBuilder.radioGroup({
          plainLabel: t('auditing:conclusion'),
          accessor: 'studentDataCollectionIsAppropritateRadioGroup',
          detailsAccessor: 'studentDataCollectionIsAppropritateComment',
          options: Object.keys(t(
            'auditing:form.governmentTransfers.dataCollectionIsAppropritateRadioGroupOptions',
            { returnObjects: true }
          )),
          optionDisplayValue: (option: string) =>
            t(`auditing:form.governmentTransfers.dataCollectionIsAppropritateRadioGroupOptions.${option}`)
        }),

        formBuilder.group({
          items: [
            formBuilder.custom(() => <strong style={{ color: "#333333" }}>Havainnot</strong>),
            formBuilder.textArea({
              accessor: 'studentOrganizationOfDataCollection'
            }),
            formBuilder.textArea({
              accessor: 'sampleTaken'
            }),
            formBuilder.textArea({
              accessor: 'publicOfficialDecisions'
            }),
            formBuilder.textArea({
              accessor: 'archivalAndConfidentiality'
            }),
          ]
        })
      ]
    },
    // Päätös valtionosuuksiin tyytymisestä
    {
      accordionId: 'governmentTransfersDecision',
      upperElements: [
        formBuilder.custom(() => (
          <div>
            <span><strong>{t('auditing:form.governmentTransfers.governmentTransfersDecisionGoalsLabel')}</strong></span>
            <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
              <li>{t('auditing:form.governmentTransfers.governmentTransfersDecisionGoalsValue')}</li>
            </ul>
          </div>
        )),
        
        formBuilder.radioGroup({
          plainLabel: t('auditing:form.governmentTransfers.governmentTransfersDecisionConclusionRadioGroup1'),
          accessor: 'governmentTransfersDecisionConclusionRadioGroup1',
          detailsAccessor: 'governmentTransfersDecisionConclusionComment1',
          options: Object.keys(t(
            'auditing:form.governmentTransfers.governmentTransfersDecisionConclusionRadioGroupOptions',
            { returnObjects: true }
          )),
          optionDisplayValue: (option: string) =>
            t(`auditing:form.governmentTransfers.governmentTransfersDecisionConclusionRadioGroupOptions.${option}`)
        }),

        formBuilder.radioGroup({
          plainLabel: t('auditing:form.governmentTransfers.governmentTransfersDecisionConclusionRadioGroup2'),
          accessor: 'governmentTransfersDecisionConclusionRadioGroup2',
          detailsAccessor: 'governmentTransfersDecisionConclusionComment2',
          options: Object.keys(t(
            'auditing:form.governmentTransfers.governmentTransfersDecisionConclusionRadioGroupOptions',
            { returnObjects: true }
          )),
          optionDisplayValue: (option: string) =>
            t(`auditing:form.governmentTransfers.governmentTransfersDecisionConclusionRadioGroupOptions.${option}`)
        }),
      ]
    }
  ])
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
