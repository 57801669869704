import { AuditingHelpInfoBox } from '../../../components/auditing/AuditingHelpInfoBox';
import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import { t } from '../../../i18n';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { getWindowDimensions } from '../../../utils';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';
import { exampleSecuritiesTableData, SecuritiesTable } from './investments.securitiesTable';

type SectionFormType = InvestmentsForm;

const sectionKey: AuditingSectionKey = 'investments';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });
const batchString = 'O3-investments';

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  procedureAccountsManualData: [
    {
      currentYear: 0,
      priorYear: 0,
      accountName: '',
      comment: '',
    },
  ],
  procedureAccountsData: {},
  tableColumnData: [
    {
      left: 'Taseelle merkityt sijoitukset ovat olemassa',
      right: t('auditing:OLE'),
    },
    {
      left: 'Yhteisö hallitsee sijoituksia tai sillä on määräysvalta niitä koskeviin oikeuksiin',
      right: 'Tytäryhtiöden osakkeiden arvostus',
    },
    {
      left: 'Kaikki varat ja velat, jotka olisi tullut kirjata on kirjattu ja niihin liittyvät liitetiedot on esitetty tilinpäätöksellä',
      right: t('auditing:TAY')
    },
    {
      left: 'Sijoitukset sisältyvät taseelle asianmukaisessa rahamäärässä',
      right: t('auditing:OIK_ARV')
    },
    {
      left: 'Sijoitukset on luokiteltu oikein ja niiden jaottelu on asianmukainen. Tilinpäätöksellä esitettävät tiedot ovat riittäviä ja relevantteja',
      right: t('auditing:ESI_LUO')
    }
  ],

  conclusionText: auditingJson.defaultConclusionValue,
  confirmations: null,
  confirmationComment: '',
  useSheet: null,
  sheetdata1: [
    {
      cellData: [
        {
          row: 0,
          column: 0,
          cellData: {
            contains:
              'Lisää tähän tarvittaessa olennaiset kaavat(esim WACC, DCF)',
          },
        },
      ],
      row: 15,
      column: 15,
    },
  ],

  useSheetTab: null,

  useSheet1: null,
  sheetdata1_second: [
    {
      cellData: [],
    },
  ],
  useSheet2: null,
  sheetdata2: [
    {
      cellData: [],
    },
  ],
  useSheet3: null,
  sheetdata3: [
    {
      cellData: [],
    },
  ],
  useSheet4: null,
  sheetdata4: [
    {
      cellData: [],
    },
  ],

  // Sijoitusten tarkastus - täsmäytys saldovahvistukselle
  useSecuritiesTable: false,
  securitiesTable: exampleSecuritiesTableData
});

const formFields: FormFields<SectionFormType> = ({
  formState,
  auditing,
  store,
}) => [
    formBuilder.custom(
      <AuditingHelpInfoBox />
    ),
    formBuilder.group({
      items: [
        formBuilder.boolean({
          accessor: 'useSheetTab',
          plainLabel: 'Käytä laskenta taulukko osiota',
        }),
        formBuilder.custom(props => (
          <ProcedureAccountsElement
            {...props}
            auditing={auditing}
            batchString={batchString}
            showChange
            addCommentField
          />
        )),
        formBuilder.subtitle({ titleKey: 'tableColumnTitle' }),
        formBuilder.custom(props => <TableColumn {...props} />),
        formBuilder.textArea({
          accessor: 'conclusionText',
          plainLabel: auditingJson.inspectionConclusion,
        }),
        formBuilder.boolean({
          accessor: 'useSheet',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet ? (
            <SpreadSheet
              customHeight={getWindowDimensions().height * 0.5}
              id="sheetdata1"
              {...props}
            />
          ) : null
        ),
      ],
    }),

    ...displayAuditingProcedures(store, batchString, formBuilder, [
      {
        accordionId: 'reconciliationOfInvestments',
        upperElements: [
          formBuilder.group({
            items: [
              formBuilder.boolean({
                accessor: 'confirmations',
                options: [
                  'askedConfirmationOptions.one',
                  'askedConfirmationOptions.two',
                ]
              }),
              formBuilder.textArea({
                accessor: 'confirmationComment',
                plainLabel: auditingJson.form.investments.confirmationsLabel,
                hidden: !formState.confirmations
              }),
            ]
          })
        ],
        lowerElements: [
          formBuilder.boolean({
            accessor: 'useSecuritiesTable',
            plainLabel: auditingJson.useCalculation
          }),
          formBuilder.custom(props => (
            formState.useSecuritiesTable
              ? (<SecuritiesTable
                {...props}
              />)
              : null
          ))
        ]
      },
      {
        accordionId: 'checkingAndVerifyingInvestments',
        lowerElements: [
          formBuilder.boolean({
            accessor: 'useSheet1',
            plainLabel: auditingJson.useSheet,
          }),
          formBuilder.custom(props =>
            formState.useSheet1 ? (
              <SpreadSheet id="sheetdata1" {...props} />
            ) : null
          ),
        ],
      },
      {
        accordionId: 'appreciationOfInvestments',
        lowerElements: [
          formBuilder.boolean({
            accessor: 'useSheet2',
            plainLabel: auditingJson.useSheet,
          }),
          formBuilder.custom(props =>
            formState.useSheet2 ? (
              <SpreadSheet id="sheetdata2" {...props} />
            ) : null
          ),
        ],
      },
      {
        accordionId: 'investmentsClassification',
        lowerElements: [
          formBuilder.boolean({
            accessor: 'useSheet3',
            plainLabel: auditingJson.useSheet,
          }),
          formBuilder.custom(props =>
            formState.useSheet3 ? (
              <SpreadSheet id="sheetdata3" {...props} />
            ) : null
          ),
        ],
      },
      {
        accordionId: 'appreciationOfSubsidiariesInvestments',
        lowerElements: [
          formBuilder.boolean({
            accessor: 'useSheet4',
            plainLabel: auditingJson.useSheet,
          }),
          formBuilder.custom(props =>
            formState.useSheet4 ? (
              <SpreadSheet id="sheetdata4" {...props} />
            ) : null
          ),
        ],
      },
    ]),
  ];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
