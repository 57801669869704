import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { confirmDelete } from '../../utils';
import {
  AccountingEstimateKind,
  emptyAccountingEstimate,
  getDefaultRiskValues,
  Risk,
} from '../../views/auditingSections/planning/risksUtils';
import Divider from '../Divider';
import { Button, Select } from '../inputs';
import Popover from '../Popover';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';
import { FinancialStatementBatch } from '../../views/auditingSections/auditing/auditingProceduresUtils';

const sectionKey: AuditingSectionKey = 'risks';

const tBase = `auditing:form.${sectionKey}.accountingEstimate`;

export const AccountingEstimates = ({
  formState,
  patchFormState,
  store,
}: FormFieldProps<RisksForm>) => {
  const { t } = useTranslation();

  const { auditingLocked, isAuditingSectionFinished, auditing } =
    store.auditingStore;

  const [addNewKind, setAddNewKind] = useState<AccountingEstimateKind>();

  const handleAddNewRow = useCallback(
    (kind?: AccountingEstimateKind) => {
      const risks = getDefaultRiskValues(auditing);
      patchFormState({
        ...formState,
        accountingEstimates: [
          ...(formState.accountingEstimates ?? []),
          emptyAccountingEstimate({ kind, risks }),
        ],
      });
    },
    [formState, patchFormState, auditing]
  );

  useEffect(() => {
    if (addNewKind) {
      handleAddNewRow(addNewKind);
      setAddNewKind(undefined);
    }
  }, [addNewKind, handleAddNewRow]);

  if (!formState) return null;

  const data = formState.accountingEstimates ?? [];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.accountingEstimates.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, accountingEstimates: patch });
  }

  const monetizationRiskStatementKeys: MonetizationRiskStatementKey[] = [
    'occurence',
    'existence',
    'completeness',
    'accuracyAndValuation',
    'cutOff',
    'classification',
    'presentation',
  ];

  const composeRiskSelectColumn = (
    key: 'typicalRisk' | 'controlRisk' | 'totalRisk'
  ) => ({
    accessorKey: key,
    className: 'text-center width-min',
    header: () => t(`auditing:form.${sectionKey}.${key}`),
    selectProps: {
      options: Object.values(Risk),
      displayValue: (option: string) =>
        t(`auditing:form.${sectionKey}.riskOptions.${option}`),
      setValue: (editProps: OnEditProps<string>) =>
        handleEdit<string>(editProps),
    },
  });

/**
 * Added new value called 'batch' to AccountingEstimate interface
 * Need to use '(enum) FinancialStatementBatch' type from 'auditingProceduresUtils.ts' file
 * to get the correct type for 'batch' property (can be null, string or FinancialStatementBatch)
 * user can select the batch from the dropdown (dont create the dropdown here)
 * need to be filtered example: 'F-financialStatement' is 'F' in the dropdown. so filter after the '-'
 * 
 * in AccountingEstimate interface:
 * batch?: FinancialStatementBatch (<- imported) | string | null;
 */

  const tableColumns: TableColumnDefs<AccountingEstimate> = [
    {
      accessorKey: 'batch',
      header: () => t(`${tBase}.batch`),
      className: 'text-center width-super-min',
      selectProps: {
        options: Object.values(FinancialStatementBatch).map(batch => batch.split('-')[0]),
        displayValue: (option: string) => option,
        setValue: (editProps: OnEditProps<string>) =>
          handleEdit<string>(editProps),
      }
    },
    {
      accessorKey: 'label',
      header: () => t(`${tBase}.label`),
      className: 'width-min nowrap',
      disabled: (item: AccountingEstimate) => {
        const isCustomField = item.kind && item.kind?.includes('custom');
        return !isCustomField;
      },
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'relatedStatement',
      className: 'text-center width-min',
      header: () => t(`${tBase}.relatedStatement`),
      selectProps: {
        options: [...monetizationRiskStatementKeys, 'all'],
        displayValue: (option: string) =>
          t(`auditing:form.${sectionKey}.monetizationRiskStatements.${option}`),
        setValue: (editProps: OnEditProps<string>) =>
          handleEdit<string>(editProps),
      },
    },
    composeRiskSelectColumn('typicalRisk'),
    composeRiskSelectColumn('controlRisk'),
    composeRiskSelectColumn('totalRisk'),
    {
      accessorKey: 'comments',
      className: 'text-center width-max',
      header: () => t(`${tBase}.comments`),
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
      placeholder: 'N/A',
    },
  ];

  const handleDeleteRow = ({ id, label }: AccountingEstimate) => {
    if (confirmDelete(label ?? '')) {
      const patch = (formState.accountingEstimates ?? []).filter(
        row => row.id !== id
      );
      patchFormState({ ...formState, accountingEstimates: patch });
    }
  };

  return (
    <>
      <AuditingTable<AccountingEstimate>
        sectionKey={sectionKey}
        data={data}
        columns={tableColumns}
        onRowDelete={handleDeleteRow}
        showGlobalFilter={false}
        disableSort
      />

      <Divider size="sm" noBorder />

      {!(auditingLocked || isAuditingSectionFinished(sectionKey)) && (
        <Popover
          button={<Button text={'+ ' + t('action:addNewRow')} variant="link" />}
        >
          {({ close }) => (
            <Select
              autocomplete
              fullWidth
              label={t(`${tBase}.label`)}
              options={[...Object.values(AccountingEstimateKind)]}
              displayValue={option => t(`${tBase}.kinds.${option}`)}
              value={addNewKind}
              setValue={(
                value?: React.SetStateAction<AccountingEstimateKind | undefined>
              ) => {
                setAddNewKind(value);
                close();
              }}
            />
          )}
        </Popover>
      )}
    </>
  );
};
