import { AuditingHelpInfoBox } from '../../../components/auditing/AuditingHelpInfoBox';
import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../FormFieldBuilder';
import {
  innerCircleElement,
  innerCircleElementDFS,
} from './reUsables/innerCircle';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import {
  ReusableCalculationForm,
  ReusableCalculationFormWithDoubleCalculators,
} from './reUsables/ReusableTableColumn';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = UseInnerCircle<CashEquivalentsForm>;

const sectionKey: AuditingSectionKey = 'cashEquivalents';
export const tBase = `auditing:form.${sectionKey}`;
const batchString = 'Q2-cashEquivalents';
const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });
const jsonVocab = auditingJson.form.cashEquivalents;

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  calculationFormData: {
    // Tarvitaan CalculationFormia varten
    '1': [
      {
        firstField: '1900 XXX',
        secondField: 0,
        thirdField: 0,
        calculatedAmount: 0,
        comment: '',
      },
    ],
    '2': [
      {
        firstField: '1900 XXX',
        secondField: 0,
        thirdField: 0,
        calculatedAmount: 0,
        comment: '',
      },
    ],
    '3': [
      {
        firstField: '1900 XXX',
        secondField: 0,
        thirdField: 0,
        calculatedAmount: 0,
        comment: '',
      },
    ],
    '4': [
      {
        firstField: '1900 XXX',
        secondField: 0,
        thirdField: 0,
        calculatedAmount: 0,
        secondField2: 0,
        thirdField2: 0,
        calculatedAmount2: 0,
        comment: '',
      },
    ],
  },
  secondTableColumnData: [
    {
      left: '',
      right: '',
    },
  ],
  purchaseLedgerData: [],
  procedureAccountsData: {},
  procedureAccountsManualData: [
    {
      currentYear: 0,
      priorYear: 0,
      accountName: '',
      comment: '',
    },
  ],
  tableColumnData: [
    {
      right: auditingJson.OLE,
      left: 'Taseelle merkityt varat / velat  ovat olemassa',
    },
    {
      right: auditingJson.OVE,
      left: 'Yhteisö hallitsee varoja tai sillä on määräysvalta niitä koskeviin oikeuksiin, ja velat ovat yhteisön velvoitteita',
    },
    {
      right: auditingJson.TAY,
      left: 'Kaikki varat ja velat, jotka olisi tullut kirjata on kirjattu ja niihin liittyvät liitetiedot on esitetty tilinpäätöksellä',
    },
    {
      right: auditingJson.OIK_ARV,
      left: 'Varat / velat sisältyvät taseelle asianmukaisessa rahamäärässä',
    },
    {
      right: auditingJson.ESI_LUO,
      left: 'Varat / velat on luokiteltu oikein ja niiden jaottelu on asianmukainen. Tilinpäätöksellä esitettävät tiedot ovat riittäviä ja relevantteja - luokittelu, esittämistapa',
    },
  ],
  reasonableEvidenceFound: jsonVocab.reasonableEvidenceFoundValue,

  // balanceConfirmation
  receivedConfirmations: null,
  useCalculation: null,
  moreInfo: auditingJson.defaultMoreDetailsAndPlannedActionsValue,

  // accountReconciliation
  useCalculation2: null,

  // balanceConfirmationThroughBank
  useCalculation3: null,

  // debetAndCreditConfirmation
  useCalculation4: null,

  processText: '',
  processDate: '',
  processValue: '',

  useSheet1: null,
  sheetdata1: [
    {
      cellData: [],
    },
  ],
  useSheet2: null,
  sheetdata2: [
    {
      cellData: [],
    },
  ],
  useSheet3: null,
  sheetdata3: [
    {
      cellData: [],
    },
  ],
  useSheet4: null,
  sheetdata4: [
    {
      cellData: [],
    },
  ],
  useSheet5: null,
  sheetdata5: [
    {
      cellData: [],
    },
  ],
  useSheet6: null,
  sheetdata6: [
    {
      cellData: [],
    },
  ],
  useSheet7: null,
  sheetdata7: [
    {
      cellData: [],
    },
  ],
  useSheetTab: null,
  ...innerCircleElementDFS,
});

const formFields: FormFields<SectionFormType> = ({
  formState,
  auditing,
  store,
}) => [
  formBuilder.custom(
    <AuditingHelpInfoBox/>
  ),
  formBuilder.group({
    items: [
      formBuilder.boolean({
        accessor: 'useSheetTab',
        plainLabel: 'Käytä laskenta taulukko osiota',
      }),
      formBuilder.custom(props => (
        <ProcedureAccountsElement
          {...props}
          auditing={auditing}
          batchString={batchString}
          addCommentField
        />
      )),
      formBuilder.custom(props => <TableColumn {...props} />),
      formBuilder.textArea({ accessor: 'reasonableEvidenceFound' }),
    ],
  }),

  /*formBuilder.accordionGroup({
    title: auditingJson.form.itAndProcesses.processDescript,
    items: [
      formBuilder.custom(props => <CashEquivalentsProcessDescriptionTable processText={''} processDate={null} processValue={''} {...props} />),
    ],
    hidden: hidePartBasedOnItAndProcesses(auditing, "paymentServices"),
  }),*/

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      accordionId: 'debetAndCreditConfirmation',
      lowerElements: [
        formBuilder.boolean({
          plainLabel: auditingJson.useCalculation,
          accessor: 'useCalculation4',
        }),
        formBuilder.custom(props =>
          props.formState.useCalculation4 === true ? (
            <ReusableCalculationFormWithDoubleCalculators
              sectionKey={sectionKey}
              batchString={batchString}
              fieldTitles={['Pankkitilit', 'Debet', 'Panot', 'Kredit', 'Otot']}
              {...props}
              id="4"
            />
          ) : null
        ),
        formBuilder.boolean({
          accessor: 'useSheet1',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet1 ? (
            <SpreadSheet id="sheetdata1" {...props} />
          ) : null
        ),
      ],
    },
    {
      accordionId: 'balanceConfirmationThroughBank',
      lowerElements: [
        formBuilder.boolean({
          plainLabel: auditingJson.useCalculation,
          accessor: 'useCalculation3',
        }),
        formBuilder.custom(props =>
          props.formState.useCalculation3 === true ? (
            <ReusableCalculationForm
              sectionKey={sectionKey}
              fieldTitles={[
                'Pankkitilit',
                'Saldo pääkirjanpidossa',
                'Saldo pankkiohjelmassa',
              ]}
              addCommentField={true}
              batchString={batchString}
              {...props}
              id="3"
            />
          ) : null
        ),
        formBuilder.boolean({
          accessor: 'useSheet2',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet2 ? (
            <SpreadSheet id="sheetdata2" {...props} />
          ) : null
        ),
      ],
    },
    {
      accordionId: 'accountReconciliation',
      lowerElements: [
        formBuilder.boolean({
          plainLabel: auditingJson.useCalculation,
          accessor: 'useCalculation2',
        }),
        formBuilder.custom(props =>
          props.formState.useCalculation2 === true ? (
            <ReusableCalculationForm
              sectionKey={sectionKey}
              batchString={batchString}
              fieldTitles={[
                'Pankkitilit',
                'Saldo pääkirjanpidossa',
                'Saldo tiliotteella',
              ]}
              addCommentField={true}
              {...props}
              id="2"
            />
          ) : null
        ),
        formBuilder.boolean({
          accessor: 'useSheet3',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet3 ? (
            <SpreadSheet id="sheetdata3" {...props} />
          ) : null
        ),
      ],
    },
    {
      accordionId: 'balanceConfirmation',
      upperElements: [
        formBuilder.checkboxGroup({
          accessor: 'receivedConfirmations',
          options: Object.keys(jsonVocab.receivedConfirmationsOptions),
        }),
      ],
      lowerElements: [
        formBuilder.boolean({
          plainLabel: auditingJson.useCalculation,
          accessor: 'useCalculation',
        }),
        formBuilder.custom(props =>
          props.formState.useCalculation === true ? (
            <ReusableCalculationForm
              sectionKey={sectionKey}
              batchString={batchString}
              fieldTitles={[
                'Pankkitilit',
                'Saldo pääkirjanpidossa',
                'Saldo vahvistuksella',
              ]}
              addCommentField={true}
              {...props}
              id="1"
            />
          ) : null
        ),
        formBuilder.boolean({
          accessor: 'useSheet4',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet4 ? (
            <SpreadSheet id="sheetdata4" {...props} />
          ) : null
        ),
      ],
    },
    // Quick fix for elements not showing up after accordionId change
    // TODO: something cleaner
    {
      accordionId: 'balanceConfirmation-Q2',
      upperElements: [
        formBuilder.checkboxGroup({
          accessor: 'receivedConfirmations',
          options: Object.keys(jsonVocab.receivedConfirmationsOptions),
        }),
      ],
      lowerElements: [
        formBuilder.boolean({
          plainLabel: auditingJson.useCalculation,
          accessor: 'useCalculation',
        }),
        formBuilder.custom(props =>
          props.formState.useCalculation === true ? (
            <ReusableCalculationForm
              sectionKey={sectionKey}
              batchString={batchString}
              fieldTitles={[
                'Pankkitilit',
                'Saldo pääkirjanpidossa',
                'Saldo vahvistuksella',
              ]}
              addCommentField={true}
              {...props}
              id="1"
            />
          ) : null
        ),
        formBuilder.boolean({
          accessor: 'useSheet4',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet4 ? (
            <SpreadSheet id="sheetdata4" {...props} />
          ) : null
        ),
      ],
    },
    {
      accordionId: 'cashCounterConfirmation',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet5',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet5 ? (
            <SpreadSheet id="sheetdata5" {...props} />
          ) : null
        ),
      ],
    },
    {
      accordionId: 'manualPaymentsConfirmation',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet6',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet6 ? (
            <SpreadSheet id="sheetdata6" {...props} />
          ) : null
        ),
      ],
    },
    {
      accordionId: 'cashFlowClassificationLUO',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet7',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet7 ? (
            <SpreadSheet id="sheetdata7" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Lähipiiri
      useDefault: false,
      accordionId: 'innerCircle',
      upperElements: innerCircleElement(formBuilder),
    },
  ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
