import { OnEditProps, TableColumnDefs } from '../../../components/table/Table';
import AuditingTable from '../../../components/table/AuditingTable';
import { uuid } from '../../../utils';
import { t } from '../../../i18n';


const sectionKey: AuditingSectionKey = 'investments';
const tBase = `auditing:form.${sectionKey}.securitiesTable`;

const ledgerTableItems: LedgerTableRow[] = [
  {
    id: uuid(),
    label: t(`${tBase}.investmentsOnLedger`),
    value: 0,
    type: 'investmentsOnLedger'
  },
  {
    id: uuid(),
    label: t(`${tBase}.lowestValue`),
    value: 0,
    type: 'lowestValue'
  },
  {
    id: uuid(),
    label: t(`${tBase}.difference`),
    value: 0,
    type: 'difference'
  }
]

const securitiesTableItems: SecuritiesTableRow[] = [
  {
    id: uuid(),
    label: 'Osake Z',
    type: 'default',
    acquisitionCost: 100.00,
    marketValue: 90.50,
    lowestValue: 90.50,
    currentValuation: -9.50
  },
  {
    id: uuid(),
    label: 'Osake Y',
    type: 'default',
    acquisitionCost: 400.96,
    marketValue: 9001,
    lowestValue: 400.96,
    currentValuation: 8600.04
  },
  {
    id: uuid(),
    label: t(`${tBase}.total`),
    type: 'total',
    acquisitionCost: 500.96,
    marketValue: 591.46,
    lowestValue: 491.46,
    currentValuation: -9.50
  }
]

export const exampleSecuritiesTableData: SecuritiesTable = {
  id: uuid(),
  version: 1,
  table: securitiesTableItems,
  ledgerTable: ledgerTableItems
}

const calculateLedgerTableDifference = (ledgerRows: LedgerTableRow[], securitiesRows: SecuritiesTableRow[]) => {
  const lowestValueSum = securitiesRows.find((row) => row.type === 'total')?.lowestValue ?? 0;
  const findLedgerRow = (type: LedgerRowType) => ledgerRows.find((row) => row.type === type)?.value ?? 0;
  return Number(
    (findLedgerRow('investmentsOnLedger') - lowestValueSum).toFixed(2)
  );
}

const calculateTableTotals = (
  securitiesRows: SecuritiesTableRow[]) => {

  const securitiesTableSumColumn = (columnName: keyof SecuritiesTableRow) => {
    const rows = securitiesRows.filter((row) => row.type !== 'total');
    return (
      Number(
        (columnName === 'currentValuation'
          ? rows.filter((row) => row[columnName] < 0)
          : rows)
          .reduce((accumulator, row) => accumulator + Number(row[columnName]), 0)
          .toFixed(2)
      ))
  }

  return {
    'totalAcquisitionCost': securitiesTableSumColumn('acquisitionCost'),
    'totalMarketValue': securitiesTableSumColumn('marketValue'),
    'totalLowestValue': securitiesTableSumColumn('lowestValue'),
    'totalCurrentValuation': securitiesTableSumColumn('currentValuation')
  }
}

export const SecuritiesTable = ({
  formState,
  patchFormState
}: FormFieldProps<InvestmentsForm>) => {

  const data = formState.securitiesTable;

  const handleEdit = <T,>({
    itemId,
    rowIndex,
    columnId,
    value
  }: OnEditProps<T>) => {

    const getNewRows = () => {
      const updateChangedValue = <T extends SecuritiesTableRow | LedgerTableRow>
        (item: T[]): T[] => item.map((row, index) => {
          return (
            (row.id === itemId && index === rowIndex)
              ? { ...row, [columnId]: value }
              : row
          )
        });
      // Update lowestValue & currentValuation columns
      const newSecuritiesRows: SecuritiesTableRow[] = updateChangedValue(data.table).map(row =>
        row.type === 'total'
          ? row
          : {
            ...row,
            'lowestValue': Number(Math.min(row.acquisitionCost, row.marketValue).toFixed(2)),
            'currentValuation': Number((row.marketValue - row.acquisitionCost).toFixed(2))
          }
      );
      const newLedgerRows = updateChangedValue(data.ledgerTable);
      return {
        ...data,
        table: newSecuritiesRows,
        ledgerTable: newLedgerRows
      }
    };

    const newTables: SecuritiesTable = getNewRows();
    const calculatedValues = calculateTableTotals(newTables.table);

    const table: SecuritiesTableRow[] = newTables.table.map((row) => {
      return (
        row.type === 'total'
          ? {
            ...row,
            'acquisitionCost': calculatedValues.totalAcquisitionCost,
            'marketValue': calculatedValues.totalMarketValue,
            'lowestValue': calculatedValues.totalLowestValue,
            'currentValuation': calculatedValues.totalCurrentValuation
          }
          : row
      )
    });

    const ledgerTable: LedgerTableRow[] = newTables.ledgerTable.map((row) => {
      if (row.type === 'difference') {
        return { ...row, value: calculateLedgerTableDifference(newTables.ledgerTable, table) }
      }
      else if (row.type === 'lowestValue') {
        return {
          ...row,
          'value': calculatedValues.totalLowestValue
        }
      }
      else {
        return row;
      }
    });

    const patch: SecuritiesTable = {
      ...newTables,
      table: table,
      ledgerTable: ledgerTable
    };

    const newFormState = {
      ...formState,
      securitiesTable: patch
    };

    patchFormState(newFormState);
  }

  const securitiesTableColumns: TableColumnDefs<SecuritiesTableRow> = [
    {
      accessorKey: 'label',
      header: t(`${tBase}.security`),
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
      disabled: (row: SecuritiesTableRow) => row.type === 'total'
    },
    {
      accessorKey: 'acquisitionCost',
      header: t(`${tBase}.acquisitionCost`),
      onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
      disabled: (row: SecuritiesTableRow) => row.type === 'total'
    },
    {
      accessorKey: 'marketValue',
      header: t(`${tBase}.marketValue`),
      onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
      disabled: (row: SecuritiesTableRow) => row.type === 'total'
    },
    {
      accessorKey: 'lowestValue',
      header: t(`${tBase}.lowestMonetaryValue`),
    },
    {
      accessorKey: 'currentValuation',
      header: t(`${tBase}.currentValuation`)
    }
  ];


  const ledgerTableColumns: TableColumnDefs<LedgerTableRow> = [
    {
      accessorKey: 'label',
      header: t(`${tBase}.investments`),
    },
    {
      accessorKey: 'value',
      onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps),
      disabled: (row) => row.type !== 'investmentsOnLedger',
      header: ''
    },
  ];

  const handleAddNewRow = () => {
    const newRow: SecuritiesTableRow = {
      ...securitiesTableItems[0],
      id: uuid()
    }
    // If the table is empty, add a totals row
    const totals: SecuritiesTableRow = data.table.pop() ?? securitiesTableItems[2];
    const patch: SecuritiesTableRow[] = data.table.concat(newRow).concat(totals)

    const newFormState = {
      ...formState,
      securitiesTable: {
        ...formState.securitiesTable,
        table: patch
      }
    }

    patchFormState(newFormState);
  };

  const handleRowDelete = ({ id, type }: SecuritiesTableRow) => {
    // Can't delete this!
    if (type === 'total') return null;

    const patch = data.table.filter((row) => row.id !== id);
    const newFormState = {
      ...formState,
      securitiesTable: {
        ...formState.securitiesTable,
        table: patch
      }
    };

    patchFormState(newFormState);
  };

  const ledgerTable = (
    <AuditingTable<LedgerTableRow>
      data={data.ledgerTable}
      columns={ledgerTableColumns}
      showGlobalFilter={false}
      variant="default"
      disableSort
    />
  );
  const securitiesTable = (
    <AuditingTable<SecuritiesTableRow>
      data={data.table}
      columns={securitiesTableColumns}
      showGlobalFilter={false}
      variant="default"
      onAddNewRow={handleAddNewRow}
      onRowDelete={handleRowDelete}
      disableSort
    />
  );
  return (
    <div>
      {ledgerTable}
      {securitiesTable}
    </div>
  )
}