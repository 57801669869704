import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useStore } from '../../store/RootContext';
import {
  AuditingProcedureResult,
  FinancialStatementBatchKey,
} from '../../views/auditingSections/auditing/auditingProceduresUtils';
import { Checkbox, Select, TextArea } from '../inputs';
import { ProcedureResult } from '../Accordion';
import { RootStore } from '../../store/RootStore';
import { FormFieldBuilder } from '../../views/auditingSections/FormFieldBuilder';
import { AuditingTemplate, AuditingTemplateKey } from '../../utils';

const tBase = 'auditing:form.auditingProcedures';

const Container = styled.section`
  > * {
    :not(:last-child) {
      margin-bottom: ${p => p.theme.spacing.lg};
    }
  }
`;

interface Props extends PropsWithChildren {
  auditingProcedure: AuditingProcedure;
}

export enum AuditingProcedureFields {
  auditingResult = 'auditingResult',
  auditingReference = 'auditingReference',
  auditingProcedureGoals = 'auditingProcedureGoals',
  auditingComments = 'auditingComments',
}

enum ControlIsAutomaticField {
  automatic = 'automatic',
  manual = 'manual',
  SemiAutomatic = 'SemiAutomatic',
}
enum ControlRepeatField {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  threeMonthly = 'threeMonthly',
  yearly = 'yearly',
}
// Update these values to auditingProcedures in the database (like auditingResult)
enum ControlWorksEfficientlyField {
  notStarted = 'notStarted', // Aloittamatta
  worksEfficiently = 'worksEfficiently', // ei yli AMPT olevia virheitä
  issuesInControl = 'issuesInControl', // yli PM tai olennaisia virheitä
}
// Transfer ControlWorksEfficientlyField to AuditingProcedureResult
const ControlWorksEfficientlyFieldToAuditingProcedureResult = {
  notStarted: AuditingProcedureResult.notStarted,
  worksEfficiently: AuditingProcedureResult.treatedGood,
  issuesInControl: AuditingProcedureResult.shortcomings,
};

function TextAreaSaveComponent({
  label,
  auditingProcedure,
  idKey,
  saveTextToDatabase,
  disabled,
}: {
  /*
    Renders text area
  */
  label: string;
  auditingProcedure: any;
  idKey: string;
  saveTextToDatabase: Function;
  disabled?: boolean;
}) {
  const [textValue, setTextValue] = useState(
    auditingProcedure[idKey] ? `${auditingProcedure[idKey]}` : ''
  );
  const [oldTextValue, setOldTextValue] = useState(
    auditingProcedure[idKey] ? `${auditingProcedure[idKey]}` : ''
  );
  const [loadingDots, setLoadingDots] = useState(''); // we display this as animation
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null); // use this to save after stopped typing for 2 sec

  useEffect(() => {
    if (textValue !== oldTextValue) {
      // if textinput changed
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // remove old timeout since new letter added
      }

      timeoutRef.current = setTimeout(() => {
        // add new timeout and try to save in 2 sec
        saveTextToDatabase(idKey, textValue);
        setOldTextValue(textValue);
      }, 2000);
    }

    return () => {
      if (timeoutRef.current) {
        // clear timeout
        clearTimeout(timeoutRef.current);
      }
    };
  }, [textValue, oldTextValue, idKey, saveTextToDatabase]);

  useEffect(() => {
    /*
      Changes loadingDots "." -> ".." -> "..." -> "" -> and repeat
    */
    const interval = setInterval(() => {
      setLoadingDots(prev => (prev === '...' ? '' : prev + '.'));
    }, 300);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <TextArea
      label={
        label +
        (textValue === oldTextValue ? '' : ` (Tallennetaan${loadingDots})`)
      } // dispalys saving animation
      value={textValue}
      fullWidth
      setValue={setTextValue}
      disabled={disabled}
    />
  );
}

const AuditingProcedureItemFields = ({
  fields,
  auditingProcedure,
  saveTextToDatabase,
  handleSelectChange,
  isFinished,
}: {
  fields: Array<keyof AuditingProcedure>;
  auditingProcedure: AuditingProcedure;
  saveTextToDatabase: (key: keyof AuditingProcedure, newValue: any) => void;
  handleSelectChange: (
    key: keyof AuditingProcedure
  ) => (
    value?: React.SetStateAction<AuditingProcedureResult | undefined>
  ) => void;
  isFinished: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {fields.map(field =>
        field === 'auditingResult' ? (
          <Select<AuditingProcedureResult>
            key={`${field}-select-component`}
            label={t(`${tBase}.conclusionOfInspection`)}
            value={auditingProcedure.auditingResult ?? undefined}
            options={Object.values(AuditingProcedureResult)}
            displayValue={option =>
              t(`${tBase}.auditingResultOptionsNew.${option}`)
            }
            setValue={handleSelectChange('auditingResult')}
            disabled={isFinished}
          />
        ) : (
          <TextAreaSaveComponent
            key={`${field}-save-component`}
            auditingProcedure={auditingProcedure}
            idKey={field}
            saveTextToDatabase={saveTextToDatabase}
            label={t(`${tBase}.${field}`)}
            disabled={isFinished}
          />
        )
      )}
    </>
  );
};

const AuditingProcedureItem: React.FC<
  Props & {
    hideFields?: Array<keyof AuditingProcedure>;
    customOrder?: Array<keyof AuditingProcedure>;
  }> = observer(
  ({
    auditingProcedure,
    children,
    hideFields = [],
  }: Props & { hideFields?: Array<keyof AuditingProcedure> }) => {
    const {
      auditingStore: {
        auditing,
        getAuditingSection,
        editEntity,
        isAuditingSectionFinished,
      },
    } = useStore();

    // Public sector has a different order for the fields.
    const fields: Array<keyof AuditingProcedure> =
      auditing?.template === AuditingTemplate.public
        ? [
            'auditingProcedureGoals',
            'auditingComments',
            'auditingReference',
            'auditingResult',
          ]
        : [
            'auditingResult',
            'auditingReference',
            'auditingProcedureGoals',
            'auditingComments',
          ];

    const filteredFields = fields.filter(field => !hideFields.includes(field));

    const sectionKey: AuditingSectionKey =
      auditingProcedure.batch as unknown as AuditingSectionKey;
    const isFinished = isAuditingSectionFinished(sectionKey);

    const handleSave = (newAuditingProcedure: AuditingProcedure) => {
      /*
        saves auditingProcedures
      */
      const auditingProcedures = getAuditingSection('auditingProcedures');

      if (!auditing?.id || !auditingProcedures) return;

      const auditingProceduresBatch: AuditingProcedure[] =
        auditingProcedures.form.auditingProcedures.map(procedure =>
          procedure.id === newAuditingProcedure.id
            ? newAuditingProcedure
            : procedure
        );

      const auditingPatch: Partial<Auditing> = {
        auditingProcedures: {
          ...auditingProcedures,
          form: {
            ...auditingProcedures.form,
            auditingProcedures: auditingProceduresBatch,
          },
        },
      };

      editEntity({ id: auditing.id, data: auditingPatch });
    };

    const handleSelectChange = // saves select fields

        (key: keyof AuditingProcedure) =>
        (value?: React.SetStateAction<AuditingProcedureResult | undefined>) => {
          if (typeof value === 'string') {
            handleSave({ ...auditingProcedure, [key]: value });
          }
        };

    const saveTextToDatabase = // saves text to database
      (key: keyof AuditingProcedure, newValue: any) => {
        handleSave({ ...auditingProcedure, [key]: newValue });
      };

    return (
      <Container>
        <AuditingProcedureItemFields
          fields={filteredFields}
          auditingProcedure={auditingProcedure}
          saveTextToDatabase={saveTextToDatabase}
          handleSelectChange={handleSelectChange}
          isFinished={isFinished}
        />

        {children}
      </Container>
    );
  }
);

// Controls
const AuditingControlItem: React.FC<Props> = observer(
  ({ auditingProcedure, children }) => {
    const { t } = useTranslation();

    const {
      auditingStore: {
        auditing,
        getAuditingSection,
        editEntity,
        isAuditingSectionFinished,
      },
    } = useStore();

    const sectionKey: AuditingSectionKey = 'auditingProcedures';
    const isFinished = isAuditingSectionFinished(sectionKey);

    const handleSave = (newAuditingProcedure: AuditingProcedure) => {
      /*
        saves auditingProcedures
      */
      const auditingProcedures = getAuditingSection('auditingProcedures');

      if (!auditing?.id || !auditingProcedures) return;

      const auditingProceduresBatch: AuditingProcedure[] =
        auditingProcedures.form.auditingProcedures.map(procedure =>
          procedure.id === newAuditingProcedure.id
            ? newAuditingProcedure
            : procedure
        );

      const auditingPatch: Partial<Auditing> = {
        auditingProcedures: {
          ...auditingProcedures,
          form: {
            ...auditingProcedures.form,
            auditingProcedures: auditingProceduresBatch,
          },
        },
      };

      editEntity({ id: auditing.id, data: auditingPatch });
    };

    const handleAutomaticSelectChange = // save automatic select field

        (key: keyof AuditingProcedure) =>
        (value?: React.SetStateAction<ControlIsAutomaticField | undefined>) => {
          if (typeof value === 'string') {
            handleSave({ ...auditingProcedure, [key]: value });
          }
        };
    const handleRepeatSelectChange = // save repeat select field

        (key: keyof AuditingProcedure) =>
        (value?: React.SetStateAction<ControlRepeatField | undefined>) => {
          if (typeof value === 'string') {
            handleSave({ ...auditingProcedure, [key]: value });
          }
        };
    const handleWorksEfficientlySelectChange = // save works efficiently select field

        (key: keyof AuditingProcedure) =>
        (
          value?: React.SetStateAction<ControlWorksEfficientlyField | undefined>
        ) => {
          if (typeof value === 'string') {
            const auditingResult =
              ControlWorksEfficientlyFieldToAuditingProcedureResult[value];
            handleSave({ ...auditingProcedure, [key]: value, auditingResult });
          }
        };

    const saveTextToDatabase = // saves text to database
      (key: keyof AuditingProcedure, newValue: any) => {
        handleSave({ ...auditingProcedure, [key]: newValue });
      };

    const renderTextInputModal = (
      key: keyof AuditingProcedure,
      { showContextInfo }: { showContextInfo?: boolean } = {}
    ) => (
      <TextAreaSaveComponent
        auditingProcedure={auditingProcedure}
        idKey={key}
        saveTextToDatabase={saveTextToDatabase}
        label={t(`${tBase}.${key}`)}
        disabled={isFinished}
      />
    );

    return (
      <Container>
        {renderTextInputModal('controlTitle')}
        {
          <Select<ControlWorksEfficientlyField>
            label={t(`${tBase}.controlWorksEfficiently`)}
            value={auditingProcedure.controlWorksEfficiently ?? undefined}
            options={Object.values(ControlWorksEfficientlyField)}
            displayValue={option =>
              t(`${tBase}.controlWorksEfficientlyOptions.${option}`)
            }
            setValue={handleWorksEfficientlySelectChange(
              'controlWorksEfficiently'
            )}
            disabled={isFinished}
          />
        }
        {renderTextInputModal('referenceC')}
        {renderTextInputModal('descriptionOfControl')}
        {
          <Select<ControlIsAutomaticField>
            label={t(`${tBase}.controlIsAutomatic`)}
            value={auditingProcedure.controlIsAutomatic ?? undefined}
            options={Object.values(ControlIsAutomaticField)}
            displayValue={option =>
              t(`${tBase}.controlIsAutomaticOptions.${option}`)
            }
            setValue={handleAutomaticSelectChange('controlIsAutomatic')}
            disabled={isFinished}
          />
        }
        {
          <Select<ControlRepeatField>
            label={t(`${tBase}.controlRepeats`)}
            value={auditingProcedure.controlRepeats ?? undefined}
            options={Object.values(ControlRepeatField)}
            displayValue={option =>
              t(`${tBase}.controlRepeatsOptions.${option}`)
            }
            setValue={handleRepeatSelectChange('controlRepeats')}
            disabled={isFinished}
          />
        }
        {renderTextInputModal('descriptionOfPlannedActions')}
        {renderTextInputModal('ideasGoalsAndCompletedWorkControl')}
        {renderTextInputModal('notesAndComments')}

        {children}
      </Container>
    );
  }
);

// Kirjanpidolliset arviot
export const AccountingEstimatesItem: React.FC<Props> = observer(
  ({ auditingProcedure, children }) => {
    const { t } = useTranslation();

    const {
      auditingStore: {
        auditing,
        getAuditingSection,
        editEntity,
        isAuditingSectionFinished,
      },
    } = useStore();

    const sectionKey: AuditingSectionKey = 'auditingProcedures';
    const isFinished = isAuditingSectionFinished(sectionKey);

    const handleSave = (newAuditingProcedure: AuditingProcedure) => {
      /*
        saves auditingProcedures
      */
      const auditingProcedures = getAuditingSection('auditingProcedures');

      if (!auditing?.id || !auditingProcedures) return;

      const auditingProceduresBatch: AuditingProcedure[] =
        auditingProcedures.form.auditingProcedures.map(procedure =>
          procedure.id === newAuditingProcedure.id
            ? newAuditingProcedure
            : procedure
        );

      const auditingPatch: Partial<Auditing> = {
        auditingProcedures: {
          ...auditingProcedures,
          form: {
            ...auditingProcedures.form,
            auditingProcedures: auditingProceduresBatch,
          },
        },
      };

      editEntity({ id: auditing.id, data: auditingPatch });
    };

    const handleSelectChange = // saves select fields

        (key: keyof AuditingProcedure) =>
        (value?: React.SetStateAction<AuditingProcedureResult | undefined>) => {
          if (typeof value === 'string') {
            handleSave({ ...auditingProcedure, [key]: value });
          }
        };

    const saveTextToDatabase = // saves text to database
      (key: keyof AuditingProcedure, newValue: any) => {
        handleSave({ ...auditingProcedure, [key]: newValue });
      };

    const renderTextInputModal = (
      key: keyof AuditingProcedure,
      { showContextInfo }: { showContextInfo?: boolean } = {}
    ) => (
      <TextAreaSaveComponent
        auditingProcedure={auditingProcedure}
        idKey={key}
        saveTextToDatabase={saveTextToDatabase}
        label={t(`${tBase}.${key}`)}
        disabled={isFinished}
      />
    );

    return (
      <Container>
        <Select<AuditingProcedureResult>
          label={t(`${tBase}.conclusionOfInspection`)}
          value={auditingProcedure.auditingResult ?? undefined}
          options={Object.values(AuditingProcedureResult)}
          displayValue={option =>
            t(`${tBase}.auditingResultOptionsNew.${option}`)
          }
          setValue={handleSelectChange('auditingResult')}
          disabled={isFinished}
        />

        {renderTextInputModal('auditingReference')}
        {renderTextInputModal('inspectionDescription')}
        {renderTextInputModal('inspectionBackground')}
        {renderTextInputModal('auditingProcedureGoals')}
        {renderTextInputModal('auditingComments')}

        {children}
      </Container>
    );
  }
);

// Käsityksen muodostaminen
const DescribePeriodItem: React.FC<Props> = observer(
  /*
    This component renders only forming an opinion text box.
  */
  ({ auditingProcedure, children }) => {
    const { t } = useTranslation();

    const {
      auditingStore: {
        auditing,
        getAuditingSection,
        editEntity,
        isAuditingSectionFinished,
      },
    } = useStore();

    const sectionKey: AuditingSectionKey = 'auditingProcedures';
    const isFinished = isAuditingSectionFinished(sectionKey);

    const handleSave = (newAuditingProcedure: AuditingProcedure) => {
      /*
        saves auditingProcedures
      */
      const auditingProcedures = getAuditingSection('auditingProcedures');

      if (!auditing?.id || !auditingProcedures) return;

      const auditingProceduresBatch: AuditingProcedure[] =
        auditingProcedures.form.auditingProcedures.map(procedure =>
          procedure.id === newAuditingProcedure.id
            ? newAuditingProcedure
            : procedure
        );

      const auditingPatch: Partial<Auditing> = {
        auditingProcedures: {
          ...auditingProcedures,
          form: {
            ...auditingProcedures.form,
            auditingProcedures: auditingProceduresBatch,
          },
        },
      };

      editEntity({ id: auditing.id, data: auditingPatch });
    };

    const saveTextToDatabase = // saves text to database
      (key: keyof AuditingProcedure, newValue: any) => {
        handleSave({ ...auditingProcedure, [key]: newValue });
      };

    const renderTextInputModal = (
      key: keyof AuditingProcedure,
      { showContextInfo }: { showContextInfo?: boolean } = {}
    ) => (
      <TextAreaSaveComponent
        auditingProcedure={auditingProcedure}
        idKey={key}
        saveTextToDatabase={saveTextToDatabase}
        label={t(`${tBase}.${key}New`)}
        disabled={isFinished}
      />
    );

    return (
      <Container>
        {renderTextInputModal('auditingProcedurePerceptions')}
        {children}
      </Container>
    );
  }
);
// Lähipiiri
const InnerCircleItem: React.FC<Props> = observer(
  ({ auditingProcedure, children }) => {
    const { t } = useTranslation();

    const {
      auditingStore: {
        auditing,
        getAuditingSection,
        editEntity,
        isAuditingSectionFinished,
      },
    } = useStore();

    const sectionKey: AuditingSectionKey = 'auditingProcedures';
    const isFinished = isAuditingSectionFinished(sectionKey);

    const [inspectionConclusionValid, setInspectionConclusionValid] = useState(
      auditingProcedure.innerCircleAuditingResult
        ? auditingProcedure.innerCircleAuditingResult
        : {}
    );

    const handleSave = (newAuditingProcedure: AuditingProcedure) => {
      /*
        saves auditingProcedures
      */
      const auditingProcedures = getAuditingSection('auditingProcedures');

      if (!auditing?.id || !auditingProcedures) return;

      const auditingProceduresBatch: AuditingProcedure[] =
        auditingProcedures.form.auditingProcedures.map(procedure =>
          procedure.id === newAuditingProcedure.id
            ? newAuditingProcedure
            : procedure
        );

      const auditingPatch: Partial<Auditing> = {
        auditingProcedures: {
          ...auditingProcedures,
          form: {
            ...auditingProcedures.form,
            auditingProcedures: auditingProceduresBatch,
          },
        },
      };

      editEntity({ id: auditing.id, data: auditingPatch });
    };

    const saveTextToDatabase = // saves text to database
      (key: keyof AuditingProcedure, newValue: any) => {
        handleSave({ ...auditingProcedure, [key]: newValue });
      };

    const saveInspectionResultToDatabase = // saves text to database
      (key: string, newValue: boolean | string) => {
        console.log(newValue);

        if (!auditingProcedure.innerCircleAuditingResult) {
          return;
        }

        const newInspectionConclusionValue = { ...inspectionConclusionValid };
        console.log(newInspectionConclusionValue);
        newInspectionConclusionValue[key] = newValue;
        setInspectionConclusionValid(newInspectionConclusionValue);
        handleSave({
          ...auditingProcedure,
          innerCircleAuditingResult: {
            [key]: newValue,
            ...auditingProcedure.innerCircleAuditingResult[key],
          },
        });
      };

    const renderTextInputModal = (
      key: keyof AuditingProcedure,
      { showContextInfo }: { showContextInfo?: boolean } = {}
    ) => (
      <TextAreaSaveComponent
        auditingProcedure={auditingProcedure}
        idKey={key}
        saveTextToDatabase={saveTextToDatabase}
        label={t(`${tBase}.${key}`)}
        disabled={isFinished}
      />
    );

    return (
      <Container>
        <Container>
          <p>
            <small>
              <b>Lähipiiritapahtumien käsittely kirjanpidossa</b>
            </small>
          </p>
          <Checkbox
            label={t(`${tBase}.innerCircleValues.handlingInAccounting.true`)}
            setValue={newValue =>
              saveInspectionResultToDatabase(
                'handlingInAccounting',
                newValue as boolean
              )
            }
            checked={inspectionConclusionValid.handlingInAccounting}
            disabled={isFinished}
          />
          <Checkbox
            label={t(`${tBase}.innerCircleValues.handlingInAccounting.false`)}
            setValue={newValue =>
              saveInspectionResultToDatabase(
                'handlingInAccounting',
                !newValue as boolean
              )
            }
            checked={inspectionConclusionValid.handlingInAccounting === false}
            disabled={isFinished}
          />
        </Container>

        <Container>
          <hr />
          <p>
            <small>
              <b>
                Tilinpäätöksellä ja soveltuvin osin toimintakertomuksella
                esitetyt tiedot lähipiirisuhteista ja -liiketoimista
              </b>
            </small>
          </p>
          <Checkbox
            label={t(`${tBase}.innerCircleValues.demonstratedData.one`)}
            setValue={() =>
              saveInspectionResultToDatabase('demonstratedData', 'one')
            }
            checked={inspectionConclusionValid.demonstratedData === 'one'}
            disabled={isFinished}
          />
          <Checkbox
            label={t(`${tBase}.innerCircleValues.demonstratedData.two`)}
            setValue={() =>
              saveInspectionResultToDatabase('demonstratedData', 'two')
            }
            checked={inspectionConclusionValid.demonstratedData === 'two'}
            disabled={isFinished}
          />
          <Checkbox
            label={t(`${tBase}.innerCircleValues.demonstratedData.three`)}
            setValue={() =>
              saveInspectionResultToDatabase('demonstratedData', 'three')
            }
            checked={inspectionConclusionValid.demonstratedData === 'three'}
            disabled={isFinished}
          />
        </Container>

        <Container>
          <hr />

          <p>
            <small>
              <b>
                Lähipiiritapahtumien vaikutus tilinpäätöksen antamaan oikeaan ja
                riittävään kuvaan
              </b>
            </small>
          </p>
          <Checkbox
            label={t(`${tBase}.innerCircleValues.effectOnPicture.true`)}
            setValue={newValue =>
              saveInspectionResultToDatabase(
                'effectOnPicture',
                newValue as boolean
              )
            }
            checked={inspectionConclusionValid.effectOnPicture}
            disabled={isFinished}
          />
          <Checkbox
            label={t(`${tBase}.innerCircleValues.effectOnPicture.false`)}
            setValue={newValue =>
              saveInspectionResultToDatabase(
                'effectOnPicture',
                !newValue as boolean
              )
            }
            checked={inspectionConclusionValid.effectOnPicture === false}
            disabled={isFinished}
          />
        </Container>

        <Container>
          <hr />
          <p>
            <small>
              <b>Väärinkäytösriskit</b>
            </small>
          </p>
          <Checkbox
            label={t(`${tBase}.innerCircleValues.abuseRisks.true`)}
            setValue={newValue =>
              saveInspectionResultToDatabase('abuseRisks', newValue as boolean)
            }
            checked={inspectionConclusionValid.abuseRisks}
            disabled={isFinished}
          />
          <Checkbox
            label={t(`${tBase}.innerCircleValues.abuseRisks.false`)}
            setValue={newValue =>
              saveInspectionResultToDatabase('abuseRisks', !newValue as boolean)
            }
            checked={inspectionConclusionValid.abuseRisks === false}
            disabled={isFinished}
          />
        </Container>
        <hr />

        {renderTextInputModal('auditingReference')}
        {renderTextInputModal('auditingProcedureGoals')}
        {renderTextInputModal('auditingComments')}

        {children}
      </Container>
    );
  }
);

type CustomSetting<T> = {
  /**
   * ID of the accordion we are creating/extending.
   * This must match a valid actionKey.
   */
  accordionId: string;

  /**
   * What type of procedure we are dealing with
   * See procedureType
   */
  procedureType?: procedureType;
  /**
   * These elements are always placed above the generic auditing section form textarea inputs.
   */
  upperElements?: Array<FormField<T>>;

  /**
   * These elements are always placed below the generic auditing section form textarea inputs.
   */
  lowerElements?: Array<FormField<T>>;

  /**
   * This field determines which AuditingProcedure fields should be hidden.
   * i.e:
   * ```
   * const hiddenElements: keyof AuditingProcedure = ['auditingComments']
   * ```
   */
  hide?: Array<keyof AuditingProcedure>;

  /**
   * This field determines which AuditingTemplate should hide this custom setting.
   */
  hiddenInTemplate?: AuditingTemplateKey;

  /**
   * Whether to use default component or not. Used for some reusable components.
   */
  useDefault?: boolean;

  /**
   * Customize order of text fields
  */
  customOrder?: Array<keyof AuditingProcedure>;
};

/**
 *
 * @param store Root store for localStorage
 * @param batchString Key of the auditing category
 * @param formBuilder Form builder
 * @param customSettings The custom form fields/custom render callbacks you want to pass
 * @returns An array of accordionGroups as form fields.
 */
export function displayAuditingProcedures<T>(
  store: RootStore,
  batchString: FinancialStatementBatchKey,
  formBuilder: FormFieldBuilder<T>,
  customSettings?: Array<CustomSetting<T>>
): Array<FormField<T>> {
  /*
    Creates an element for each auditingProcedure + adds custom elements defined in customSettings
  */
  const auditingProceduresOfThisBatch =
    store.auditingStore.getAuditingProceduresBatch(batchString);

  // The exclusion array
  const exclusions = [
    {
      batchString: 'G-management',
      actionKeys: ['innerCircle'],
    },
    // Add more exclusions here
  ];

  const elements = auditingProceduresOfThisBatch
    .map((procedure: AuditingProcedure) => {
      let setting: CustomSetting<T> = {
        accordionId: procedure.actionKey,
        procedureType: procedure.type,
        upperElements: [],
        lowerElements: [],
        hide: [],
        useDefault: true,
        customOrder: undefined,
      };

      const customSettingOfProcedure = customSettings?.find(
        setting => setting.accordionId === procedure.actionKey
      );
      setting = customSettingOfProcedure ?? setting;

      if (setting.hiddenInTemplate === store.auditingStore.auditingTemplate)
        return null;

      // Check if the procedure should be excluded
      const shouldExclude = exclusions.some(
        exclusions =>
          exclusions.batchString === batchString &&
          exclusions.actionKeys.includes(procedure.actionKey)
      );
      if (shouldExclude) {
        return null;
      }

      const customElementMapping: Record<string, JSX.Element> = {
        describePeriod: <DescribePeriodItem auditingProcedure={procedure} />,
        accountingEstimates: (
          <AccountingEstimatesItem auditingProcedure={procedure} />
        ),
        innerCircle: <InnerCircleItem auditingProcedure={procedure} />,
        control: <AuditingControlItem auditingProcedure={procedure} />,
      };

      const elementMappingKey =
        setting.procedureType && setting.procedureType !== 'default'
          ? setting.procedureType
          : procedure.actionKey.startsWith('control-')
          ? 'control'
          : procedure.actionKey;

      // Accordions with the id describePeriod will be displayed with just one text box(describePeriod text box)
      const customElement =
        setting.useDefault && customElementMapping[elementMappingKey] ? (
          customElementMapping[elementMappingKey]
        ) : (
          <AuditingProcedureItem
            auditingProcedure={procedure}
            hideFields={setting.hide}
            customOrder={setting.customOrder}
          />
        );

      return formBuilder.accordionGroup({
        items: [
          ...(setting.upperElements ?? []),
          formBuilder.custom(() => customElement),
          ...(setting.lowerElements ?? []),
        ],
        title: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {procedure.actionKey !== 'describePeriod' &&
              procedure.type !== 'describePeriod' && (
                <ProcedureResult auditingResult={procedure.auditingResult}>
                  <span>{procedure.action}</span>
                </ProcedureResult>
              )}
            {(procedure.actionKey === 'describePeriod' ||
              procedure.type === 'describePeriod') && (
              <span>{procedure.action}</span>
            )}
          </div>
        ),
      });
    })
    .filter((element): element is FormField<T> => element !== null);

  return elements;
}
