import styled from 'styled-components';
import {
  IndicatorsClassTable,
  IndicatorsTable,
} from '../../../../components/analyzesAndScoping/financialStatement/Indicators.Table';
import { getDependingSections } from '../../../../utils/auditing';
import { FormFieldBuilder } from '../../FormFieldBuilder';

type SectionFormType = IndicatorsForm;

const sectionKey: AuditingSectionKey = 'indicators';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  return {
    indicators: [],
    indicatorsClassTable: [
      {
        id: '1',
        companyImplementation: '',
        lastBenchmark: '',
        industry: '',
        estimate: '',
        boolean: null,
      },
    ],
    tableDataPushed: false,
  };
};

const WarningDiv = styled.div`
  background-color: #FFDE59;
  padding: 5px;
  max-width: 350px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center
`;

const formFields: FormFields<SectionFormType> = ({ formState, auditing }) => [
  formBuilder.custom(
    <WarningDiv>HUOM! Osio on kehitysvaiheessa ja kaavat eivät kaikilta osin laske oikein.</WarningDiv>
  ),
  formBuilder.custom(props => <IndicatorsClassTable {...props} />),

  // Kannattavuus/tulos taulukko
  formBuilder.custom(props => <IndicatorsTable {...props} />),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  // if 'internalControlAndOperatingEnvironment' form's 'isInterimAudit' is true, show this section, depending on 'generalLedger' otherwise 'generalLedgerScoping'
  depending: auditing => {
    const isInterimAudit =
      auditing.internalControlAndOperatingEnvironment?.form.isInterimAudit;
    const interimSections: (keyof AuditingSections)[] = ['generalLedger'];
    const notInterimSections: (keyof AuditingSections)[] = [
      'generalLedgerScoping',
    ];
    return getDependingSections(
      !!isInterimAudit,
      interimSections,
      notInterimSections
    );
  },
};

export default section;
