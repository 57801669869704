import styled from "styled-components";

type NoticeProps = {
  text: string;
  color: string;
}

const Container = styled.div`
  margin-top: 10px;
`;

export const ColoredNoticeText = (props: NoticeProps) => {
  return (
    <Container style={{ color: props.color }}>
      {props.text}
    </Container>
  )
}