import React from 'react';
import AuditingTable from '../../../../components/table/AuditingTable';
import {
  OnEditProps,
  TableColumnDefs,
} from '../../../../components/table/Table';
import { formatCurrency } from '../../../../utils';

interface Props {
  accounts: GroupedGeneralLedgerItem[];
  addCommentField?: boolean;
  addReferenceField?: boolean;
  formState?: any;
  patchFormState?: Function;
  showChange?: boolean;
  showSum?: boolean;
  customAccountTitle?: string;
  showTitles?: boolean;
  elementTitle?: string;
}

const ProcedureAccounts: React.FC<Props> = ({
  accounts,
  addCommentField,
  addReferenceField,
  formState,
  patchFormState,
  showChange,
  showSum,
  customAccountTitle,
  showTitles,
  elementTitle
}) => {
  /*
    Using this function requires you to create a variable named procedureAccountsData in the defaultFormState of where you use it, like this:
    
      const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
        procedureAccountsData: {},
      })
  */

  let procedureAccountRows = [];

  // Filter the general ledger to only include rows that match the account map rows

  for (var x = 0; x < accounts.length; x++) {
    let objectx = accounts[x];

    if (objectx.accountName?.includes(objectx.account.toString())) {
      objectx.accountName = objectx.accountName.replace(
        objectx.account.toString(),
        ''
      );
    }

    if (formState?.procedureAccountsData[x]) {
      objectx.commentText = formState?.procedureAccountsData[x];
    }
    if (formState?.procedureAccountsData.reference) {
      objectx.referenceText = formState?.procedureAccountsData.reference[x] ?? '';
    }
    if (showChange) {
      const undefinedHandler = (item: number | undefined) => {
        if (item === undefined || item === null) {
          return 0;
        } else return item;
      };
      objectx.changeInNumbers =
        undefinedHandler(objectx.priorYear) -
        undefinedHandler(objectx.currentYear);
      objectx.changeInPercent = Math.round(
        ((undefinedHandler(objectx.currentYear) -
          undefinedHandler(objectx.priorYear)) /
          undefinedHandler(objectx.priorYear)) *
          100
      );
      if (
        objectx.currentYear === undefined ||
        objectx.priorYear === undefined
      ) {
        objectx.changeInPercent = undefined;
      } else {
        objectx.changeInPercent = Math.round(
          ((objectx.currentYear - objectx.priorYear) / objectx.priorYear) * 100
        );
      }
    }
    procedureAccountRows.push(objectx);
  }

  if (procedureAccountRows.length === 0) return null;

  function handleComment<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = {
      ...formState?.procedureAccountsData,
      [rowIndex]: value,
    };
    patchFormState &&
      patchFormState({
        ...formState,
        procedureAccountsData: patch,
      });
  }

   /**
   * Because account info is retrieved from the account map and is not editable, only comments are stored in the form state.
   * Currently, the procedureAccountsData looks like this:
   * {
   *  0: 'Comment 1',
   *  1: 'Comment 2',
   *  ...
   * }
   * To include references without breaking the current structure (and comments), we do this:
   * {
   *  0: 'Comment 1',
   *  1: 'Comment 2',
   *  reference: {
   *   0: 'Reference 1',
   * }
   */
   function handleReference<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    console.log(formState?.procedureAccountsData);
    console.log('handleComment', { itemId, rowIndex, columnId, value });
    const patch = {
      ...formState?.procedureAccountsData,
      reference: {
        ...formState?.procedureAccountsData.reference,
        [rowIndex]: value,
      },
    };
    patchFormState &&
      patchFormState({
        ...formState,
        procedureAccountsData: patch,
      });
  }

  var tableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'account',
      className: 'width-min',
      header: () => (!showTitles ? null : 'Tilinumero'),
    },
    {
      accessorKey: 'accountName',
      header: () => (!showTitles ? null : 'Tilinnimi'),
    },
    {
      accessorKey: 'currentYear',
      header: () => 'CY',
      className: 'width-min text-center nowrap',
      accessorFn: (row: any) => formatCurrency(row.currentYear),
    },
    {
      accessorKey: 'priorYear',
      header: () => 'PY',
      className: 'width-min text-center nowrap',
      accessorFn: (row: any) => formatCurrency(row.priorYear),
    },
  ];
  if (showChange) {
    tableColumns.push({
      accessorKey: 'changeInNumbers',
      header: () => 'Muutos',
      accessorFn: (row: any) => formatCurrency(row.changeInNumbers),

      className: 'width-middle text-center nowrap',
    });
    tableColumns.push({
      accessorKey: 'changeInPercent',
      header: () => 'Muutos %',
      accessorFn: (row: any) =>
        row.changeInPercent !== undefined
          ? row.changeInPercent + ' %'
          : 'Ei laskettavissa',
      className: 'width-middle text-center nowrap',
    });
  }
  if (addReferenceField) {
    tableColumns.push({
      accessorKey: 'referenceText',
      header: () => 'Viite',
      className: 'width-middle text-center nowrap',
      onEdit: (editProps: OnEditProps<string>) => {
        handleReference<string>(editProps);
      },
    });
  }
  if (addCommentField) {
    tableColumns.push({
      accessorKey: 'commentText',
      header: () =>
        customAccountTitle !== undefined ? customAccountTitle : 'Muuta',
      className: 'width-middle text-center nowrap',
      onEdit: (editProps: OnEditProps<string>) => {
        handleComment<string>(editProps);
      },
    });
  }

  var combinedCy = 0;
  var combinedPy = 0;
  if (showSum) {
    for (var l = 0; l < procedureAccountRows.length; l++) {
      if (procedureAccountRows[l].currentYear !== undefined) {
        combinedCy += procedureAccountRows[l].currentYear ?? 0;
      }
      if (procedureAccountRows[l].priorYear !== undefined) {
        combinedPy += procedureAccountRows[l].priorYear ?? 0;
      }
    }
  }

  return (
    <>
      <AuditingTable
        title={elementTitle ?? "Tilit, joita työpaperi koskee"}
        data={procedureAccountRows}
        columns={tableColumns}
        showGlobalFilter={false}
        variant="default"
      />
      {showSum ? (
        <AuditingTable
          showGlobalFilter={false}
          hideHeader
          columns={
            [
              {
                accessorKey: 'label',
                className: 'width-min',
                header: () => null,
              },
              {
                accessorKey: 'cy',
                className: 'width-min',
                header: () => null,
              },
              {
                accessorKey: 'py',
                className: 'width-min',
                header: () => null,
              },
              showChange && {
                accessorKey: 'changeInNumbers',
                className: 'width-min',
                header: () => null,
              },
            ] as TableColumnDefs<any>
          }
          data={[
            {
              label: 'Yhteensä',
              cy: 'CY: ' + formatCurrency(combinedCy),
              py: 'PY: ' + formatCurrency(combinedPy),
              changeInNumbers:
                'Muutos: ' + formatCurrency(combinedCy - combinedPy),
            },
          ]}
        />
      ) : null}
    </>
  );
};

export default ProcedureAccounts;
