import React, { useState } from 'react';
import AuditingTable from '../../../../components/table/AuditingTable';
import {
  OnEditProps,
  TableColumnDefs,
} from '../../../../components/table/Table';
import { useStore } from '../../../../store/RootContext';
import { formatCurrency } from '../../../../utils';

import styled from 'styled-components';
import { Button } from '../../../../components/inputs';
import {
  filterGeneralLedgerByClassKeyAndAccount,
  getAccountMap,
  getGeneralLedger,
} from '../../analyzesAndScoping/generalLedgerUtils';

const Container = styled.div`
  position: relative;
  > button {
    position: absolute;
    right: 0;
    top: -${p => p.theme.spacing.lg};
  }
`;

interface ReusableProps {
  formState: any;
  patchFormState: Function;
  id: string;
  sectionKey: any;
  addCommentField?: boolean;
  fieldTitles: Array<string>;
  batchString: string;
}

interface ReusableRow {
  firstField: string;
  secondField: number;
  thirdField: number;
  comment: string;
  calculatedAmount?: number;
}
interface ReusableRowWithDoubleCalculators {
  firstField: string;
  secondField: number;
  thirdField: number;
  comment: string;
  calculatedAmount?: number;

  secondField2: number;
  thirdField2: number;
  calculatedAmount2?: number;
}

export const ReusableCalculationForm = ({
  formState,
  patchFormState,
  id,
  sectionKey,
  fieldTitles,
  addCommentField,
  batchString,
}: ReusableProps) => {
  const [loading, setLoading] = useState(false);

  const {
    auditingStore: { auditing },
  } = useStore();
  if (!formState || !formState?.calculationFormData) return null;

  const generalLedger = getGeneralLedger(auditing);

  if (!auditing || !generalLedger || generalLedger.length === 0) return null;

  const accountMap = getAccountMap(auditing);

  if (!accountMap) return null;

  const filteredGeneralLedger = filterGeneralLedgerByClassKeyAndAccount(accountMap, generalLedger, batchString);

  function handleEdit<T>({ rowIndex, columnId, value }: OnEditProps<T>) {
    const dataWithUpdatedColumn = formState.calculationFormData[id].map(
      (item: any, index: number) => {
        if (rowIndex === index) {
          return {
            ...item,
            [columnId]: value,
          };
        }

        return item;
      }
    );

    patchFormState({
      ...formState,
      calculationFormData: {
        ...formState.calculationFormData,
        [id]: dataWithUpdatedColumn,
      },
    });
  }

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      calculationFormData: {
        ...formState.calculationFormData,
        [id]: [
          ...formState.calculationFormData[id],
          {
            firstField: 'Uusi asiakas',
            secondField: 0,
            thirdField: 0,
          },
        ],
      },
    });
  };

  const handleDeleteRow = async (data: any) => {
    setLoading(true);
    const newDataList = [...formState.calculationFormData[id]].filter(
      (item: { firstField: any }) => item.firstField !== data.firstField
    );
    if (newDataList.length > 0) {
      patchFormState({
        ...formState,
        calculationFormData: {
          ...formState.calculationFormData,
          [id]: newDataList,
        },
      });
    } else {
      alert('Et voi poistaa kaikkia osakkeita.');
    }
    setTimeout(() => setLoading(false), 1);
  };

  const resultColumns: TableColumnDefs<ReusableRow> = [
    {
      accessorKey: 'firstField',
      header: () => fieldTitles[0],
      onEdit: handleEdit,
      className: 'text-left width-middle nowrap',
    },
    {
      accessorKey: 'secondField',
      header: () => fieldTitles[1],
      onNumberEdit: handleEdit,
      className: 'text-left width-min nowrap',
    },
    {
      accessorKey: 'thirdField',
      header: () => fieldTitles[2],
      onNumberEdit: handleEdit,
      className: 'text-left width-min nowrap',
    },
    {
      accessorKey: 'calculatedAmount',
      header: () => 'Erotus',
      className: 'text-left width-middle nowrap',
      accessorFn: row => formatCurrency(row.calculatedAmount),
    },
  ];

  if (addCommentField) {
    resultColumns.push({
      accessorKey: 'comment',
      header: () => 'Muuta',
      onEdit: handleEdit,
      className: 'text-left width-middle nowrap',
    });
  }


  const tableData = formState.calculationFormData[id].map((dataObject: any) => ({
    firstField: dataObject.firstField,
    secondField: dataObject.secondField,
    thirdField: dataObject.thirdField,
    calculatedAmount: dataObject.thirdField - dataObject.secondField,
    comment: dataObject.comment,
  }));

  const undefinedHandler = (item: number | undefined) => {
    if (item === undefined || item === null) {
      return 0;
    } else return item;
  };
  // Function for the button to set filteredGeneralLedger to tableData
  const handleSetData = () => {
    const existingData = formState.calculationFormData[id] || [];
    const existingAccountNames = new Set(
      existingData.map((item: any) => item.firstField)
    );

    const newData =
      filteredGeneralLedger
        ?.map((item: any) => {
          return {
            firstField: item.accountName ?? '',
            secondField: undefinedHandler(item.currentYear),
            thirdField: undefinedHandler(item.priorYear),
            calculatedAmount:
              undefinedHandler(item.priorYear) -
              undefinedHandler(item.currentYear),
            comment: '',
          };
        })
        .filter((item: any) => !existingAccountNames.has(item.firstField)) ||
      [];

    const combinedData = [...existingData, ...newData];

    patchFormState({
      ...formState,
      calculationFormData: {
        ...formState.calculationFormData,
        [id]: combinedData,
      },
    });
  };

  return !loading ? (
    <>
      <Container>
        <span style={{float: 'right'}}>
          <Button text="Hae tiedot pääkirjalta" onClick={handleSetData} />
        </span>
        <AuditingTable<ReusableRow>
          sectionKey={sectionKey}
          data={tableData}
          columns={resultColumns}
          showGlobalFilter={false}
          disableSort
          onRowDelete={handleDeleteRow}
          onAddNewRow={handleAddNewRow}
        />
      </Container>
    </>
  ) : null;
};

export const ReusableCalculationFormWithDoubleCalculators: React.FC<
  ReusableProps
> = ({
  formState,
  patchFormState,
  id,
  sectionKey,
  fieldTitles,
  addCommentField,
  batchString,
}: ReusableProps) => {
  const [loading, setLoading] = useState(false);
  const {
    auditingStore: { auditing },
  } = useStore();
  if (!formState || !formState?.calculationFormData) return null;

  const generalLedger = getGeneralLedger(auditing); 

  if (!auditing || !generalLedger || generalLedger.length === 0) return null;

  const accountMap = getAccountMap(auditing);

  if (!accountMap) return null;

  const filteredGeneralLedger = filterGeneralLedgerByClassKeyAndAccount(accountMap, generalLedger, batchString);

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      calculationFormData: {
        ...formState.calculationFormData,
        [id]: [
          ...formState.calculationFormData[id],
          {
            firstField: 'Uusi asiakas',
            secondField: 0,
            thirdField: 0,
          },
        ],
      },
    });
  };

  function handleEdit<T>({ rowIndex, columnId, value }: OnEditProps<T>) {
    const duplicateData = [...formState.calculationFormData[id]];
    duplicateData[rowIndex][columnId] = value;

    patchFormState({
      ...formState,
      calculationFormData: {
        ...formState.calculationFormData,
        [id]: duplicateData,
      },
    });
  }

  const handleDeleteRow = async (data: any) => {
    setLoading(true);
    const newDataList = [...formState.calculationFormData[id]].filter(
      (item: { firstField: any }) => item.firstField !== data.firstField
    );
    if (newDataList.length > 0) {
      patchFormState({
        ...formState,
        calculationFormData: {
          ...formState.calculationFormData,
          [id]: newDataList,
        },
      });
    } else {
      alert('Et voi poistaa kaikkia osakkeita.');
    }
    setTimeout(() => setLoading(false), 1);
  };

  const resultColumns: TableColumnDefs<ReusableRowWithDoubleCalculators> = [
    {
      accessorKey: 'firstField',
      header: () => fieldTitles[0],
      onEdit: handleEdit,
      className: 'text-left width-middle nowrap',
    },
    {
      accessorKey: 'secondField',
      header: () => fieldTitles[1],
      onNumberEdit: handleEdit,
      className: 'text-center width-min nowrap',
    },
    {
      accessorKey: 'thirdField',
      header: () => fieldTitles[2],
      onNumberEdit: handleEdit,
      className: 'text-center width-min nowrap',
    },
    {
      accessorKey: 'calculatedAmount',
      header: () => 'Erotus',
      className: 'text-left width-middle nowrap',
      accessorFn: row => formatCurrency(row.calculatedAmount),
    },

    {
      accessorKey: 'secondField2',
      header: () => fieldTitles[3],
      onNumberEdit: handleEdit,
      className: 'text-center width-min nowrap',
    },
    {
      accessorKey: 'thirdField2',
      header: () => fieldTitles[4],
      onNumberEdit: handleEdit,
      className: 'text-center width-min nowrap',
    },
    {
      accessorKey: 'calculatedAmount2',
      header: () => 'Erotus',
      className: 'text-left width-middle nowrap',
      accessorFn: row => formatCurrency(row.calculatedAmount2),
    },
  ];

  if (addCommentField) {
    resultColumns.push({
      accessorKey: 'comment',
      header: () => 'Muuta',
      onEdit: handleEdit,
      className: 'text-left width-middle nowrap',
    });
  }

  const data = [];
  for (var i = 0; i < formState.calculationFormData[id].length; i++) {
    const dataObject = formState.calculationFormData[id][i];
    data.push({
      firstField: dataObject.firstField,
      secondField: dataObject.secondField,
      thirdField: dataObject.thirdField,
      calculatedAmount: dataObject.thirdField - dataObject.secondField,

      secondField2: dataObject.secondField2,
      thirdField2: dataObject.thirdField2,
      calculatedAmount2: dataObject.thirdField2 - dataObject.secondField2,

      comment: dataObject.comment,
    });
  }

  // Function for the button to set filteredGeneralLedger to tableData
  const handleSetData = () => {
    const existingData = formState.calculationFormData[id] || [];
    const existingAccountNames = new Set(
      existingData.map((item: any) => item.firstField)
    );

    const newData =
      filteredGeneralLedger
        ?.map((item: any) => {
          return {
            firstField: item.accountName ?? '',
            secondField: 0,
            thirdField: 0,
            calculatedAmount:
              existingData.thirdField - existingData.secondField,

            secondField2: 0,
            thirdField2: 0,
            calculatedAmount2:
              existingData.thirdField2 - existingData.secondField2,

            comment: '',
          };
        })
        .filter((item: any) => !existingAccountNames.has(item.firstField)) ||
      [];

    const combinedData = [...existingData, ...newData];

    patchFormState({
      ...formState,
      calculationFormData: {
        ...formState.calculationFormData,
        [id]: combinedData,
      },
    });
  };

  return !loading ? (
    <>
      <Container>
        <span style={{float: 'right'}}>
          <Button text="Hae tiedot pääkirjalta" onClick={handleSetData} />
        </span>
        <AuditingTable<ReusableRowWithDoubleCalculators>
          sectionKey={sectionKey}
          data={data}
          columns={resultColumns}
          showGlobalFilter={false}
          disableSort
          onRowDelete={handleDeleteRow}
          onAddNewRow={handleAddNewRow}
        />
      </Container>
    </>
  ) : null;
};
