import { FallbackProps } from "react-error-boundary";
import styled, { keyframes } from "styled-components";
import Icon from "./Icon";
import { useState } from "react";

const Container = styled.div``;
const DetailsButton = styled.button`
  background-color: white;
  width: 300px;
  padding: 3px;
  border-radius: 2px;
  border: 1px solid;
  transition: background-color 0.2s;

  :hover {
    background-color: #f0f0f0;
  }
`;
const openAnimation = keyframes``;

export const ErrorFallback = ({ error }: FallbackProps) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Container>
      <h1><Icon size="2rem" type="ExclamationTriangle" color="error" />Virhe!</h1>
      <div>
        <DetailsButton onClick={() => setOpen(!isOpen)}>Näytä lisätiedot</DetailsButton>
        {isOpen && (<div>
          {/* .stack is a non-standard V8 property */}
          {error.hasOwnProperty('stack')
            ?
            (<div style={{ whiteSpace: 'pre-line' }}>
              {error.stack}
            </div>)
            : <div>{error?.toString()}</div>}
          {error.cause && <div>{error.cause}</div>}
        </div>)}
      </div>
    </Container>
  );
};