import { AuditingHelpInfoBox } from '../../../components/auditing/AuditingHelpInfoBox';
import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import { ColoredNoticeText } from '../../../components/auditing/ColoredNoticeText';
import { CheckContinuityTable } from '../../../components/auditing/OwnCapitalCheckContinuityTable';
import { SurplusAndDeficit } from '../../../components/auditing/OwnCapitalSurplusAndDeficitTable';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { AuditingTemplate } from '../../../utils';
import { FormFieldBuilder } from '../FormFieldBuilder';
import {
  FreeEquityTable,
  OsingoTable,
  OtherCapitalTable,
  OwnCapitalTable,
  SecondOwnCapitalTable,
  ShareTable,
} from './notReUsables/ownCapitalForms';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = OwnCapitalForm;

const sectionKey: AuditingSectionKey = 'ownCapital';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });
const batchString = 'R1-ownCapital';
const jsonVocab = auditingJson.form.ownCapital;

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  //const incomeStatement: IncomeStatementAndBalanceScopingItem[] = auditing?.incomeStatementAndBalanceScoping?.form?.incomeStatement || [];
  //const balanceAssets: IncomeStatementAndBalanceScopingItem[] = auditing?.incomeStatementAndBalanceScoping?.form?.balanceAssets || [];
  const balanceLiabilities: IncomeStatementAndBalanceScopingItem[] =
    auditing?.incomeStatementAndBalanceScoping?.form?.balanceLiabilities || [];
  const notBalanceLiabilities: IncomeStatementAndBalanceItem[] =
    auditing?.incomeStatementAndBalance?.form?.balanceLiabilities || [];
  // Multiple values in one
  const ownCapitalContinuityValue =
    (balanceLiabilities.find(item => item.key === 'subscribedEquity')
      ?.priorYear ?? 0) +
    (balanceLiabilities.find(item => item.key === 'sharePremiumAccount')
      ?.priorYear ?? 0) +
    (balanceLiabilities.find(item => item.key === 'revaluationReserve')
      ?.priorYear ?? 0) +
    (balanceLiabilities.find(item => item.key === 'otherReserves')?.priorYear ??
      0) +
    (balanceLiabilities.find(item => item.key === 'svop')?.priorYear ?? 0) +
    (balanceLiabilities.find(item => item.key === 'emissionOfShares')
      ?.priorYear ?? 0);
  const ownCapitalContinuity2Value =
    (notBalanceLiabilities.find(item => item.key === 'subscribedEquity')
      ?.priorYear ?? 0) +
    (notBalanceLiabilities.find(item => item.key === 'sharePremiumAccount')
      ?.priorYear ?? 0) +
    (notBalanceLiabilities.find(item => item.key === 'revaluationReserve')
      ?.priorYear ?? 0) +
    (notBalanceLiabilities.find(item => item.key === 'otherReserves')?.priorYear ??
      0) +
    (notBalanceLiabilities.find(item => item.key === 'svop')?.priorYear ?? 0) +
    (notBalanceLiabilities.find(item => item.key === 'emissionOfShares')
      ?.priorYear ?? 0);
  return {
    procedureAccountsManualData: [
      {
        currentYear: 0,
        priorYear: 0,
        accountName: '',
        comment: '',
      },
    ],
    procedureAccountsData: {},
    tableColumnData: [
      {
        left: 'Oman pääoman erät ovat olemassa.',
        right: auditingJson.OLE,
      },
      {
        left: auditing.template === AuditingTemplate.private
          ? 'Yhteisö hallitsee varoja tai sillä on määräysvalta niitä koskeviin oikeuksiin ja velat ovat yhteisön velvoitteita.'
          : 'Kunta hallitsee varoja tai sillä on määräysvalta niitä koskeviin oikeuksiin ja velat ovat yhteisön velvoitteita.',
        right: auditingJson.OVE,
      },
      {
        left: 'Oman pääoman erät arvostettu oikean suuruisina ja tarpeelliset oikaisut on tehty.',
        right: auditingJson.OIK_ARV,
      },
      {
        left: 'Kaikki omaan pääomaan kuuluvat erät on kirjattu.',
        right: auditingJson.TAY,
      },
      {
        left: 'Omaan pääomaan on kirjattu eriä, jotka sinne luonteensa puolesta kuuluvat.',
        right: auditingJson.LUOK,
      },
      {
        left: 'Omaan pääomaan liittyvät tilinpäätöksessä esitettävät tiedot ovat relevantteja ja ymmärrettäviä sovellettavan tilinpäätösnormiston mukaan.',
        right: auditingJson.ESI,
      },
    ],

    ownCapitalTable: [
      {
        id: '0',
        label: 'Osakepääoma / osuus / muu.vast pääoma',
        cy:
          balanceLiabilities.find(item => item.key === 'subscribedEquity')
            ?.currentYear ?? 0,
        py:
          balanceLiabilities.find(item => item.key === 'subscribedEquity')
            ?.priorYear ?? 0,
        change: 0,
        comment: '',
      },
      {
        id: '1',
        label: 'Osakeanti',
        cy:
          balanceLiabilities.find(item => item.key === 'emissionOfShares')
            ?.currentYear ?? 0,
        py:
          balanceLiabilities.find(item => item.key === 'emissionOfShares')
            ?.priorYear ?? 0,
        change: 0,
        comment: '',
      },
      {
        id: '2',
        label: 'Ylikurssirahasto',
        cy:
          balanceLiabilities.find(item => item.key === 'sharePremiumAccount')
            ?.currentYear ?? 0,
        py:
          balanceLiabilities.find(item => item.key === 'sharePremiumAccount')
            ?.priorYear ?? 0,
        change: 0,
        comment: '',
      },
      {
        id: '3',
        label: 'Arvonkorotusrahasto',
        cy:
          balanceLiabilities.find(item => item.key === 'revaluationReserve')
            ?.currentYear ?? 0,
        py:
          balanceLiabilities.find(item => item.key === 'revaluationReserve')
            ?.priorYear ?? 0,
        change: 0,
        comment: '',
      },
      {
        id: '4',
        label: 'Muut rahastot',
        cy:
          balanceLiabilities.find(item => item.key === 'otherReserves')
            ?.currentYear ?? 0,
        py:
          balanceLiabilities.find(item => item.key === 'otherReserves')
            ?.priorYear ?? 0,
        change: 0,
        comment: '',
      },
      {
        id: '5',
        label: 'SVOP',
        cy:
          balanceLiabilities.find(item => item.key === 'svop')?.currentYear ??
          0,
        py:
          balanceLiabilities.find(item => item.key === 'svop')?.priorYear ?? 0,
        change: 0,
        comment: '',
      },
      {
        id: '6',
        label: 'Edellisten tilikausien voitto / tappio',
        cy:
          balanceLiabilities.find(item => item.key === 'retainedProfitOrLoss')
            ?.currentYear ?? 0,
        py:
          balanceLiabilities.find(item => item.key === 'retainedProfitOrLoss')
            ?.priorYear ?? 0,
        change: 0,
        comment: '',
      },
      {
        id: '7',
        label: 'Tilikauden voitto / tappio',
        cy:
          balanceLiabilities.find(
            item => item.key === 'freeEquity_profitOrLoss'
          )?.currentYear ?? 0,
        py:
          balanceLiabilities.find(
            item => item.key === 'freeEquity_profitOrLoss'
          )?.priorYear ?? 0,
        change: 0,
        comment: '',
      },
    ],
    ownCapitalTogether: [
      {
        id: '0',
        label: 'Oma pääoma yhteensä',
        cy: 0,
        py: 0,
        change: 0,
        comment: '',
      },
    ],
    continuityTable: [
      {
        id: '0',
        label:
          'Edellisen tilikauden voittovarat ennen vertailukauden tuloksen siirtoa',
        value:
          balanceLiabilities.find(item => item.key === 'retainedProfitOrLoss')
            ?.priorYear ?? 0,
        comment: '',
      },
      {
        id: '1',
        label: 'Edellisen tilikauden tulos',
        value:
          balanceLiabilities.find(
            item => item.key === 'freeEquity_profitOrLoss'
          )?.priorYear ?? 0,
        comment: '',
      },
    ],
    ownCapitalContinuity: [
      {
        id: '0',
        label: 'Tilikauden tulos',
        value:
          balanceLiabilities.find(
            item => item.key === 'freeEquity_profitOrLoss'
          )?.currentYear ?? 0,
        comment: '',
      },
      {
        id: '1',
        label: 'Muut oman pääoman erät tilikauden alussa',
        value: ownCapitalContinuityValue,
        comment: '',
      },
      {
        id: '2',
        label: 'Edellisten tilikausien voitto / tappio',
        value:
          balanceLiabilities.find(item => item.key === 'retainedProfitOrLoss')
            ?.currentYear ?? 0,
        comment: '',
      },
    ],

    shareTable: [
      {
        id: '1',
        label: 'Osakepääoma kaupparekisterillä',
        value: 0,
        comment: '',
      },
      {
        id: '2',
        label: 'Osakepääoma kirjanpidossa',
        value: 0,
        comment: '',
      },
      {
        id: '3',
        label: 'Ero',
        value:
          balanceLiabilities.find(item => item.key === 'subscribedEquity')
            ?.currentYear ?? 0,
        comment: '',
      },
    ],

    freeEquityTable: [
      {
        id: '0',
        label: 'Edellisten tilikausien voitto/tappio',
        cy: 0,
        py: 0,
      },
      {
        id: '1',
        label: 'Tilikauden voitto/tappio',
        cy: 0,
        py: 0,
      },
      {
        id: '2',
        label: 'Sijoitetun vapaan pääoman rahasto',
        cy: 0,
        py: 0,
      },
    ],
    calculatedFreeEquity: [
      {
        id: '0',
        label: 'Vapaa oma pääoma yhteensä',
        cy: 0,
        py: 0,
      },
    ],
    secondFreeEquityTable: [
      {
        id: '0',
        label: 'Aktivoidut kehittämismenot',
        cy: 0,
        py: 0,
      },
      {
        id: '1',
        label: 'Pääomalainojen kirjaamaton korko',
        cy: 0,
        py: 0,
      },
      {
        id: '2',
        label: 'Muut jakokelvottomat erät',
        cy: 0,
        py: 0,
      },
    ],
    calculatedSecondFreeEquity: [
      {
        id: '0',
        label: 'Jakokelpoinen varat yhteensä',
        cy: 0,
        py: 0,
      },
    ],

    otherCapitalTable: [
      {
        id: '0',
        label: 'Omapääoma',
        value:
          (balanceLiabilities.find(item => item.key === 'equity')
            ?.currentYear ?? 0) +
          (balanceLiabilities.find(item => item.key === 'equity')?.priorYear ??
            0),
      },
      {
        id: '1',
        label: 'Pääomalainat',
        value:
          (balanceLiabilities.find(
            item => item.key === 'freeEquity_subordinatedLoans'
          )?.currentYear ?? 0) +
          (balanceLiabilities.find(
            item => item.key === 'freeEquity_subordinatedLoans'
          )?.priorYear ?? 0),
      },
      {
        id: '2',
        label: 'Tilinpäätössiirrot (poistoero ja vapaaehtoiset varaukset)',
        value: 0,
      },
      {
        id: '3',
        label: 'Omaisuuserän arvonnousu (käypä hinta - taseen hankintameno)',
        value: 0,
      },
      {
        id: '4',
        label: 'Yhteensä',
        value: 0,
      },
    ],

    additionalParts: {},

    useShareTable: true,
    useTables: true,
    useCalculation: true,

    equityComment: '',
    equityContinuityCheck: '',
    ownedStocksMatchRegister: '',

    useSheetTab: false,
    useSheet: null,
    sheetdata1: [
      {
        cellData: [],
      },
    ],
    useSheet2: null,
    sheetdata2: [
      {
        cellData: [],
      },
    ],
    useSheet3: null,
    sheetdata3: [
      {
        cellData: [],
      },
    ],
    useSheet4: null,
    sheetdata4: [
      {
        cellData: [],
      },
    ],
    useSheet5: null,
    sheetdata5: [
      {
        cellData: [],
      },
    ],

    newlyCalculatedFundsMatchReference: null,
    reasonableEvidence: jsonVocab.reasonableEvidenceValue,

    conclusionSelect: '',
    forConclusionSelect: '',

    numberReportedInReference: 0,

    useCalculation2: '',
    forConclusion2Select: '',
    calculationComment: '',
    calculationCommentBoolean: true,

    duringTheAccounting: '',
    duringTheAccountingComment: '',
    forTheAccountingOptions: '',
    theAccountingComment: '',
    secondTheAccountingOptions: '',
    secondTheAccountingComment: '',

    previousFinancialYear: '',
    forFinancialYearOptions: '',
    financialYearText: '',

    ownCapitalIsPositiveComment: '',
    // if 'equity' is more than 0, then 'ownCapitalIsPositiveSelect''s first option is true
    ownCapitalIsPositiveSelect: '',

    useCalculation3: true,

    //
    conclusionSelect2: '',
    equityContinuityCheck2: '',
    forConclusionSelect2: '',
    useTables2: true,
    ownCapitalTable2: [
      {
        id: '0',
        label: 'Osakepääoma / osuus / muu.vast pääoma',
        cy:
          notBalanceLiabilities.find(item => item.key === 'subscribedEquity')
            ?.currentYear ?? 0,
        py:
          notBalanceLiabilities.find(item => item.key === 'subscribedEquity')
            ?.priorYear ?? 0,
        change: 0,
        comment: '',
      },
      {
        id: '1',
        label: 'Osakeanti',
        cy:
          notBalanceLiabilities.find(item => item.key === 'emissionOfShares')
            ?.currentYear ?? 0,
        py:
          notBalanceLiabilities.find(item => item.key === 'emissionOfShares')
            ?.priorYear ?? 0,
        change: 0,
        comment: '',
      },
      {
        id: '2',
        label: 'Ylikurssirahasto',
        cy:
          notBalanceLiabilities.find(item => item.key === 'sharePremiumAccount')
            ?.currentYear ?? 0,
        py:
          notBalanceLiabilities.find(item => item.key === 'sharePremiumAccount')
            ?.priorYear ?? 0,
        change: 0,
        comment: '',
      },
      {
        id: '3',
        label: 'Arvonkorotusrahasto',
        cy:
          notBalanceLiabilities.find(item => item.key === 'revaluationReserve')
            ?.currentYear ?? 0,
        py:
          notBalanceLiabilities.find(item => item.key === 'revaluationReserve')
            ?.priorYear ?? 0,
        change: 0,
        comment: '',
      },
      {
        id: '4',
        label: 'Muut rahastot',
        cy:
          notBalanceLiabilities.find(item => item.key === 'otherReserves')
            ?.currentYear ?? 0,
        py:
          notBalanceLiabilities.find(item => item.key === 'otherReserves')
            ?.priorYear ?? 0,
        change: 0,
        comment: '',
      },
      {
        id: '5',
        label: 'SVOP',
        cy:
          notBalanceLiabilities.find(item => item.key === 'svop')
            ?.currentYear ?? 0,
        py:
          notBalanceLiabilities.find(item => item.key === 'svop')?.priorYear ??
          0,
        change: 0,
        comment: '',
      },
      {
        id: '6',
        label: 'Edellisten tilikausien voitto / tappio',
        cy:
          notBalanceLiabilities.find(
            item => item.key === 'retainedProfitOrLoss'
          )?.currentYear ?? 0,
        py:
          notBalanceLiabilities.find(
            item => item.key === 'retainedProfitOrLoss'
          )?.priorYear ?? 0,
        change: 0,
        comment: '',
      },
      {
        id: '7',
        label: 'Tilikauden voitto / tappio',
        cy:
          notBalanceLiabilities.find(
            item => item.key === 'freeEquity_profitOrLoss'
          )?.currentYear ?? 0,
        py:
          notBalanceLiabilities.find(
            item => item.key === 'freeEquity_profitOrLoss'
          )?.priorYear ?? 0,
        change: 0,
        comment: '',
      },
    ],
    ownCapitalTogether2: [
      {
        id: '0',
        label: 'Oma pääoma yhteensä',
        cy: 0,
        py: 0,
        change: 0,
        comment: '',
      },
    ],
    continuityTable2: [
      {
        id: '0',
        label:
          'Edellisen tilikauden voittovarat ennen vertailukauden tuloksen siirtoa',
        value:
          notBalanceLiabilities.find(
            item => item.key === 'retainedProfitOrLoss'
          )?.priorYear ?? 0,
        comment: '',
      },
      {
        id: '1',
        label: 'Edellisen tilikauden tulos',
        value:
          notBalanceLiabilities.find(
            item => item.key === 'freeEquity_profitOrLoss'
          )?.priorYear ?? 0,
        comment: '',
      },
      {
        id: '2',
        label: 'Ed tilikaudelta jaettu osinko (-syötä negatiivisena)',
        value: 0,
        comment: '',
      },
      {
        id: '3',
        label: 'Muut voittovaroihin tehdyt oikaisut',
        value: 0,
        comment: '',
      },
      {
        id: '4',
        label: 'Yhteensä',
        value: 0,
        comment: '',
      },
      {
        id: '5',
        label: 'Edellisten tilikausien voitto/tappio taseella',
        value: 0,
        comment: '',
      },
      {
        id: '6',
        label: 'Erotus taseen voittovaroihin',
        value: 0,
        comment: '',
      },
    ],
    ownCapitalContinuity2: [
      {
        id: '0',
        label: 'Tilikauden tulos',
        value: notBalanceLiabilities.find(
          item => item.key === 'freeEquity_profitOrLoss'
        )?.currentYear ?? 0,
        comment: '',
      },
      {
        id: '1',
        label: 'Muut oman pääoman erät tilikauden alussa',
        value: ownCapitalContinuity2Value,
        comment: '',
      },
      {
        id: '2',
        label: 'Muut omaan pääomaan oikaisut / muutokset ',
        value: 0,
        comment: '',
      },
      {
        id: '3',
        label: 'Tase yht',
        value: 0,
        comment: '',
      },
      {
        id: '4',
        label: 'Erotus taseelle',
        value: 0,
        comment: '',
      },
    ],

    conclusionSelect3: '',
    conclusion3Comment: '',
    forConclusionSelect3: '',
    useSheet6: null,
    sheetdata6: [
      {
        cellData: [],
      },
    ],
    useTables3: null,

    osingoTable: [
      {
        id: '0',
        label: 'Hallituksen osingonjakoehdotus',
        value: 0,
      },
      {
        id: '1',
        label: 'Osuus omasta pääomasta',
        value: 0,
      },
      {
        id: '2',
        label: 'Osingonjako ehdotus on tehty jakokelpoisten varojen puitteissa',
        value: 0,
        dropdown: null,
      },
    ],

    surplusAndDeficitTable: [],
    surplusAndDeficitCoverageObligation: null,
    operationalProgrammeCheckboxGroup: null,
    operationalProgrammeComment: '',
    hasFundsCheckboxGroup: null,
    hasFundsComment: '',

    checkContinuityTable: [],
    assesmentOfTheAppropriatenessConclusion: null,
    assesmentOfTheAppropriatenessConclusionDetails: '',
    lastPeriodWasHandledAppropriately: null,
    lastPeriodWasHandledAppropriatelyDetails: '',
  };
};

const formFields: FormFields<SectionFormType> = ({
  formState,
  auditing,
  store,
}) => [
    formBuilder.custom(
      <>
        <AuditingHelpInfoBox />
        <ColoredNoticeText
          color="#4382b6"
          text={jsonVocab.auditingNotice}
        />
      </>
    ),
    formBuilder.group({
      items: [
        formBuilder.boolean({
          accessor: 'useSheetTab',
          plainLabel: 'Käytä laskenta taulukko osiota',
        }),
        formBuilder.custom(props => (
          <ProcedureAccountsElement
            customAccountTitle="Kommentit"
            {...props}
            auditing={auditing}
            batchString={batchString}
            addReferenceField={auditing?.template === AuditingTemplate.public}
            addCommentField={true}
            elementTitle={auditing?.template === AuditingTemplate.public
              ? 'Tilit, joita työpaperi koskee / analyyttinen tarkastus'
              : undefined}
            showChange={auditing?.template === AuditingTemplate.public}
          />
        )),
        formBuilder.custom(props => <TableColumn {...props} />),
        formBuilder.textArea({
          accessor: 'reasonableEvidence',
          plainLabel: auditingJson.conclusion,
        }),
      ],
    }),

    ...displayAuditingProcedures(store, batchString, formBuilder, [
      {
        // Oman pääoman tarkastus
        accordionId: 'equityAudit',
        customOrder: auditing?.template === AuditingTemplate.public
          ? ['auditingProcedureGoals', 'auditingComments', 'auditingReference', 'auditingResult']
          : undefined,
      },
      {
        accordionId: 'reconciliationOfShareCapital',
        customOrder: auditing?.template === AuditingTemplate.public
          ? ['auditingProcedureGoals', 'auditingComments', 'auditingReference', 'auditingResult']
          : undefined,
        upperElements: [
          formBuilder.group({
            items: auditing?.template === AuditingTemplate.private
              ? [
                formBuilder.checkboxGroup({
                  plainLabel: auditingJson.conclusionOfInspection,
                  accessor: 'ownedStocksMatchRegister',
                  options: Object.keys(jsonVocab.ownedStocksMatchRegisterOptions),
                  optionDisplayValue: (key: string) =>
                    jsonVocab.ownedStocksMatchRegisterOptions[
                    key as keyof typeof jsonVocab.ownedStocksMatchRegisterOptions
                    ],
                }),
                formBuilder.textArea({
                  accessor: 'equityComment',
                  plainLabel: 'Kommentit',
                  hidden:
                    formState.ownedStocksMatchRegister?.three === true
                      ? false
                      : true,
                }),
              ]
              : [],
          }),
        ],
        lowerElements: auditing?.template === AuditingTemplate.private
          ? [
            // Simple table
            formBuilder.group({
              items: [
                formBuilder.boolean({
                  accessor: 'useShareTable',
                  plainLabel: 'Käytä valmista laskenta taulukkoa',
                }),
                ...(formState.useShareTable
                  ? [formBuilder.custom(props => <ShareTable {...props} />)]
                  : []),
              ],
            }),
          ]
          : [],
      },
      {
        accordionId: 'inspectionOfEquity',
        customOrder: auditing?.template === AuditingTemplate.public
          ? ['auditingProcedureGoals', 'auditingComments', 'auditingReference', 'auditingResult']
          : undefined,
        upperElements: [
          formBuilder.group({
            items: [
              formBuilder.checkboxGroup({
                plainLabel: auditingJson.conclusionOfInspection,
                accessor: 'conclusionSelect',
                options: Object.keys(jsonVocab.conclusion1Options),
                optionDisplayValue: (key: string) =>
                  jsonVocab.conclusion1Options[
                  key as keyof typeof jsonVocab.conclusion1Options
                  ],
              }),
              formBuilder.textArea({
                accessor: 'equityContinuityCheck',
                plainLabel: 'Kommentit',
                hidden: formState.conclusionSelect?.three === true ? false : true,
              }),
              ...(formState.conclusionSelect?.two === true
                ? [
                  formBuilder.checkboxGroup({
                    plainLabel: 'Havaittu puutteet',
                    accessor: 'forConclusionSelect',
                    options: Object.keys(jsonVocab.forConclusionSelectOptions),
                    optionDisplayValue: (key: string) =>
                      jsonVocab.forConclusionSelectOptions[
                      key as keyof typeof jsonVocab.forConclusionSelectOptions
                      ],
                  }),
                ]
                : []),
            ],
          }),
        ],
        lowerElements: [
          formBuilder.boolean({
            accessor: 'useTables',
            plainLabel: 'Käytä laskentataulukoita',
          }),
          ...(formState.useTables
            ? [formBuilder.custom(props => <OwnCapitalTable {...props} />)]
            : []),
          formBuilder.boolean({
            accessor: 'useSheet',
            plainLabel: 'Spreadsheet taulukko',
          }),
          formBuilder.custom(props =>
            formState.useSheet ? <SpreadSheet id="sheetdata1" {...props} /> : null
          ),
        ],
      },
      {
        accordionId: 'freeEquityAndDistributable',
        customOrder: auditing?.template === AuditingTemplate.public
          ? ['auditingProcedureGoals', 'auditingComments', 'auditingReference', 'auditingResult']
          : undefined,
        upperElements: [
          formBuilder.group({
            items: [
              formBuilder.checkboxGroup({
                accessor: 'useCalculation2',
                plainLabel: auditingJson.conclusionOfInspection,
                options: Object.keys(jsonVocab.conclusion2Options),
                optionDisplayValue: (key: string) =>
                  jsonVocab.conclusion2Options[
                  key as keyof typeof jsonVocab.conclusion2Options
                  ],
              }),
              ...(formState.useCalculation2?.two === true
                ? [
                  formBuilder.checkboxGroup({
                    plainLabel: 'Havaittu puutteet',
                    accessor: 'forConclusionSelect',
                    options: Object.keys(jsonVocab.forConclusionSelect2Options),
                    optionDisplayValue: (key: string) =>
                      jsonVocab.forConclusionSelect2Options[
                      key as keyof typeof jsonVocab.forConclusionSelect2Options
                      ],
                  }),
                ]
                : []),
            ],
          }),
        ],
        lowerElements: [
          formBuilder.group({
            items: [
              /* formBuilder.boolean({accessor: 'calculationCommentBoolean', plainLabel: 'Kommentti'}),
              formBuilder.textArea({accessor: 'calculationComment', plainLabel: 'Kommentit', hidden: formState.calculationCommentBoolean === true ? false : true}), */
              formBuilder.boolean({
                accessor: 'useCalculation',
                plainLabel: 'Käytä laskentataulukoita',
              }),
              ...(formState.useCalculation
                ? [
                  formBuilder.custom(props =>
                    formState.useCalculation ? (
                      <FreeEquityTable {...props} />
                    ) : null
                  ),
                  formBuilder.checkboxGroup({
                    accessor: 'newlyCalculatedFundsMatchReference',
                    options: Object.keys(
                      jsonVocab.newlyCalculatedFundsMatchReferenceOptions
                    ),
                    optionDisplayValue: (key: string) =>
                      jsonVocab.newlyCalculatedFundsMatchReferenceOptions[
                      key as keyof typeof jsonVocab.newlyCalculatedFundsMatchReferenceOptions
                      ],
                  }),
                  formBuilder.group({
                    items: [
                      formBuilder.numberInput({
                        accessor: 'numberReportedInReference',
                        plainLabel: 'Liitetiedoissa ilmoitettu määrä',
                      }),
                      formBuilder.custom(() => {
                        const calculatedSecondData = formState.calculatedSecondFreeEquity;
                        const divisibleAssets =
                          calculatedSecondData[0]?.cy - formState.numberReportedInReference;

                        console.log(calculatedSecondData[0]);

                        const number = divisibleAssets;
                        return (
                          <>
                            <p>Erotus uudelleen lasketaan:</p>
                            <p>{number}</p>
                          </>
                        );
                      }),
                    ],
                    hidden:
                      formState.newlyCalculatedFundsMatchReference?.one |
                        formState.newlyCalculatedFundsMatchReference?.two |
                        formState.newlyCalculatedFundsMatchReference?.three
                        ? false
                        : true,
                  }),
                ]
                : []),
              formBuilder.boolean({
                accessor: 'useSheet2',
                plainLabel: 'Spreadsheet taulukko',
              }),
              formBuilder.custom(props =>
                formState.useSheet2 ? (
                  <SpreadSheet id="sheetdata2" {...props} />
                ) : null
              ),
            ],
          }),
        ],
      },
      {
        accordionId: 'appropratenessOfProcessing',
        customOrder: auditing?.template === AuditingTemplate.public
          ? ['auditingProcedureGoals', 'auditingComments', 'auditingReference', 'auditingResult']
          : undefined,
        upperElements: [
          formBuilder.group({
            items: [
              formBuilder.checkboxGroup({
                accessor: 'previousFinancialYear',
                options: Object.keys(jsonVocab.previousFinancialYearOptions),
                plainLabel: auditingJson.conclusionOfInspection,
                optionDisplayValue: (key: string) =>
                  jsonVocab.previousFinancialYearOptions[
                  key as keyof typeof jsonVocab.previousFinancialYearOptions
                  ],
              }),
              ...(formState.previousFinancialYear?.two === true
                ? [
                  formBuilder.checkboxGroup({
                    plainLabel: 'Havaittu puutteet',
                    accessor: 'forFinancialYearOptions',
                    options: Object.keys(jsonVocab.forFinancialYearOptions),
                    optionDisplayValue: (key: string) =>
                      jsonVocab.forFinancialYearOptions[
                      key as keyof typeof jsonVocab.forFinancialYearOptions
                      ],
                  }),
                ]
                : []),
              ...(formState.previousFinancialYear?.comment === true
                ? [
                  formBuilder.textArea({
                    accessor: 'financialYearText',
                    plainLabel: 'Kommentit',
                  }),
                ]
                : []),
            ],
          }),
        ],
        lowerElements: [
          formBuilder.group({
            items: [
              formBuilder.checkboxGroup({
                accessor: 'duringTheAccounting',
                options: Object.keys(jsonVocab.duringTheAccountingOptions),
                optionDisplayValue: (key: string) =>
                  jsonVocab.duringTheAccountingOptions[
                  key as keyof typeof jsonVocab.duringTheAccountingOptions
                  ],
              }),
              ...(formState.duringTheAccounting?.comment === true
                ? [
                  formBuilder.textArea({
                    accessor: 'duringTheAccountingComment',
                    plainLabel: 'Kommentit',
                  }),
                ]
                : []),

              ...(formState.duringTheAccounting?.one === true
                ? [
                  formBuilder.checkboxGroup({
                    accessor: 'forTheAccountingOptions',
                    options: Object.keys(jsonVocab.forTheAccountingOptions),
                    plainLabel: ' ',
                    optionDisplayValue: (key: string) =>
                      jsonVocab.forTheAccountingOptions[
                      key as keyof typeof jsonVocab.forTheAccountingOptions
                      ],
                  }),
                  ...(formState.forTheAccountingOptions?.comment === true
                    ? [
                      formBuilder.textArea({
                        accessor: 'theAccountingComment',
                        plainLabel: 'Kommentit',
                      }),
                    ]
                    : []),

                  formBuilder.checkboxGroup({
                    accessor: 'secondTheAccountingOptions',
                    options: Object.keys(
                      jsonVocab.secondForTheAccountingOptions
                    ),
                    plainLabel: 'Varojenjako on osakeyhtiölain mukainen',
                    optionDisplayValue: (key: string) =>
                      jsonVocab.secondForTheAccountingOptions[
                      key as keyof typeof jsonVocab.secondForTheAccountingOptions
                      ],
                  }),
                  ...(formState.secondTheAccountingOptions?.comment === true
                    ? [
                      formBuilder.textArea({
                        accessor: 'secondTheAccountingComment',
                        plainLabel: 'Kommentit',
                      }),
                    ]
                    : []),
                ]
                : []),
            ],
          }),
          formBuilder.boolean({
            accessor: 'useSheet3',
            plainLabel: 'Spreadsheet taulukko',
          }),
          formBuilder.custom(props =>
            formState.useSheet3 ? (
              <SpreadSheet id="sheetdata3" {...props} />
            ) : null
          ),
        ],
      },
      {
        accordionId: 'isOwnedCapitalSufficient',
        customOrder: auditing?.template === AuditingTemplate.public
          ? ['auditingProcedureGoals', 'auditingComments', 'auditingReference', 'auditingResult']
          : undefined,
        upperElements: auditing?.template === AuditingTemplate.private
          ? [
            formBuilder.checkboxGroup({
              accessor: 'ownCapitalIsPositiveSelect',
              plainLabel: auditingJson.inspectionConclusion,
              options: Object.keys(jsonVocab.ownCapitalIsPositiveSelectOptions),
            }),
            formBuilder.textArea({
              accessor: 'ownCapitalIsPositiveComment',
              plainLabel: 'Kommentit',
              hidden: formState.ownCapitalIsPositiveSelect?.five ? false : true,
            }),
          ] :
          [],
        lowerElements: auditing?.template === AuditingTemplate.private
          ? [
            formBuilder.boolean({
              accessor: 'useCalculation3',
              plainLabel: jsonVocab.useCalculation2,
            }),
            formBuilder.custom(props =>
              formState.useCalculation3 === true ? (
                <OtherCapitalTable {...props} />
              ) : null
            ),
            formBuilder.boolean({
              accessor: 'useSheet4',
              plainLabel: 'Spreadsheet taulukko',
            }),
            formBuilder.custom(props =>
              formState.useSheet4 ? (
                <SpreadSheet id="sheetdata4" {...props} />
              ) : null
            ),
          ]
          : [
            formBuilder.custom(props => <SurplusAndDeficit {...props} />),
            formBuilder.radioGroup({
              accessor: 'surplusAndDeficitCoverageObligation',
              plainLabel: 'Kattamisvelvoite',
              options: Object.keys(jsonVocab.coverageObligationOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.coverageObligationOptions[key as keyof typeof jsonVocab.coverageObligationOptions],
            }),

            formBuilder.checkboxGroup({
              accessor: 'operationalProgrammeCheckboxGroup',
              plainLabel: jsonVocab.operationalProgramme.question1,
              options: Object.keys(jsonVocab.operationalProgrammeCheckboxGroupOptions),
              detailsAccessor: 'operationalProgrammeComment',
              detailsHidden: !formState.operationalProgrammeCheckboxGroup?.comments,
              hidden: formState.surplusAndDeficitCoverageObligation !== 'yes'
            }),

            formBuilder.checkboxGroup({
              accessor: 'hasFundsCheckboxGroup',
              plainLabel: jsonVocab.operationalProgramme.question2,
              options: Object.keys(jsonVocab.operationalProgrammeCheckboxGroupOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.operationalProgrammeCheckboxGroupOptions[key as keyof typeof jsonVocab.operationalProgrammeCheckboxGroupOptions],
              detailsAccessor: 'hasFundsComment',
              detailsHidden: !formState.hasFundsCheckboxGroup?.comments,
              hidden: formState.surplusAndDeficitCoverageObligation !== 'yes'
            })
          ],
      },
      {
        accordionId: 'checkTheContinuity',
        customOrder: auditing?.template === AuditingTemplate.public
          ? ['auditingProcedureGoals', 'auditingComments', 'auditingReference', 'auditingResult']
          : undefined,
        upperElements: auditing?.template === AuditingTemplate.private
          ? [
            formBuilder.group({
              items: [
                formBuilder.checkboxGroup({
                  plainLabel: auditingJson.conclusionOfInspection,
                  accessor: 'conclusionSelect2',
                  options: Object.keys(jsonVocab.conclusion1Options),
                  optionDisplayValue: (key: string) =>
                    jsonVocab.conclusion1Options[
                    key as keyof typeof jsonVocab.conclusion1Options
                    ],
                }),
                formBuilder.textArea({
                  accessor: 'equityContinuityCheck',
                  plainLabel: 'Kommentit',
                  hidden:
                    formState.conclusionSelect2?.three === true ? false : true,
                }),
                ...(formState.conclusionSelect2?.two === true
                  ? [
                    formBuilder.checkboxGroup({
                      plainLabel: 'Havaittu puutteet',
                      accessor: 'forConclusionSelect',
                      options: Object.keys(jsonVocab.forConclusionSelectOptions),
                      optionDisplayValue: (key: string) =>
                        jsonVocab.forConclusionSelectOptions[
                        key as keyof typeof jsonVocab.forConclusionSelectOptions
                        ],
                    }),
                  ]
                  : []),
              ],
            }),
          ]
          : [],
        lowerElements: auditing?.template === AuditingTemplate.private
          ? [
            formBuilder.boolean({
              accessor: 'useTables2',
              plainLabel: 'Käytä laskentataulukoita',
            }),
            ...(formState.useTables2
              ? [formBuilder.custom(props => <SecondOwnCapitalTable {...props} />)]
              : []),
            formBuilder.boolean({
              accessor: 'useSheet5',
              plainLabel: 'Spreadsheet taulukko',
            }),
            formBuilder.custom(props =>
              formState.useSheet5 ? (
                <SpreadSheet id="sheetdata5" {...props} />
              ) : null
            ),
          ]
          : [
            formBuilder.custom(props => <CheckContinuityTable {...props} />),
          ],
      },
      {
        accordionId: 'assessmentOfTheAppropriateness',
        customOrder: auditing?.template === AuditingTemplate.public
          ? ['auditingProcedureGoals', 'auditingComments', 'auditingReference', 'auditingResult']
          : undefined,
        upperElements: auditing?.template === AuditingTemplate.private
          ? [
            formBuilder.group({
              items: [
                formBuilder.checkboxGroup({
                  plainLabel: auditingJson.conclusionOfInspection,
                  accessor: 'conclusionSelect3',
                  options: Object.keys(jsonVocab.conclusion3Options),
                  optionDisplayValue: (key: string) =>
                    jsonVocab.conclusion3Options[
                    key as keyof typeof jsonVocab.conclusion3Options
                    ],
                }),
                ...(formState.conclusionSelect3?.two === true
                  ? [
                    formBuilder.checkboxGroup({
                      plainLabel: 'Havaittu puutteet',
                      accessor: 'forConclusionSelect3',
                      options: Object.keys(jsonVocab.forConclusion3Options),
                      optionDisplayValue: (key: string) =>
                        jsonVocab.forConclusion3Options[
                        key as keyof typeof jsonVocab.forConclusion3Options
                        ],
                    }),
                  ]
                  : []),
                formBuilder.textArea({
                  accessor: 'conclusion3Comment',
                  plainLabel: 'Kommentit',
                  hidden:
                    formState.conclusionSelect3?.comment === true ? false : true,
                }),
              ],
            }),
          ]
          : [],
        lowerElements: auditing?.template === AuditingTemplate.private
          ? [
            formBuilder.boolean({
              accessor: 'useTables3',
              plainLabel: 'Käytä taulukkoon osingonjakoehdotuksen osuus OPOsta',
            }),
            ...(formState.useTables3 === true
              ? [formBuilder.custom(props => <OsingoTable {...props} />)]
              : []),
          ]
          : [
            formBuilder.checkboxGroup({
              plainLabel: jsonVocab.lastPeriodWasHandledAppropriately,
              accessor: 'lastPeriodWasHandledAppropriately',
              options: Object.keys(jsonVocab.lastPeriodWasHandledAppropriatelyOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.lastPeriodWasHandledAppropriatelyOptions[
                key as keyof typeof jsonVocab.lastPeriodWasHandledAppropriatelyOptions
                ],
              detailsAccessor: 'lastPeriodWasHandledAppropriatelyDetails',
              detailsHidden: !formState.lastPeriodWasHandledAppropriately?.comments,
            }),
            formBuilder.checkboxGroup({
              plainLabel: auditingJson.conclusionOfInspection,
              accessor: 'assesmentOfTheAppropriatenessConclusion',
              options: Object.keys(jsonVocab.assessmentOfTheAppropriatenessConclusionOptions),
              optionDisplayValue: (key: string) =>
                jsonVocab.assessmentOfTheAppropriatenessConclusionOptions[
                key as keyof typeof jsonVocab.assessmentOfTheAppropriatenessConclusionOptions
                ],
              detailsAccessor: 'assesmentOfTheAppropriatenessConclusionDetails',
              detailsHidden: !formState.assesmentOfTheAppropriatenessConclusion?.comments,
            }),
          ],
      },
    ]),
  ];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
