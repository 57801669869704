import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  formatCurrency,
  formatPercent,
  getClassNameForFinancialStatementRow,
} from '../../../utils';
import AuditingTable from '../../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../../table/Table';

const Container = styled.div`
  position: relative;
  > button {
    position: absolute;
    right: 0;
    top: -${p => p.theme.spacing.xl};
  }
`;
const colorRed = '#b30000';
const colorGreen = '#009900';


interface Props extends FormFieldProps<IncomeStatementAndBalanceForm> {
  targetKey: 'incomeStatement' | 'balanceAssets' | 'balanceLiabilities';
  sectionKey: AuditingSectionKey;
}

/*
  This is a stripped down version of IncomeStatementAndBalanceTable component.
  Used in preliminaryAnalytic.tsx and concludingAnalytic.tsx to display the data in a read-only format.
*/
export const AnalyticStatementAndBalanceTable = observer(
  ({ targetKey, formState, patchFormState, sectionKey }: Props) => {
    const { t } = useTranslation();

    if (!formState) return null;

    const tBase = `auditing:form.${sectionKey}`;

    const data = formState[targetKey] ?? [];

    const formattedData = data.map(item => ({
      ...item,
      currentYear: formatCurrency(item.currentYear),
      priorYear: formatCurrency(item.priorYear),
      change: formatCurrency(item.change),
      changePercent: formatPercent(item.changePercent),
      className: getClassNameForFinancialStatementRow(item.heading),
    }));

    function handleEdit<T>({
      itemId,
      rowIndex,
      columnId,
      value,
    }: OnEditProps<T>) {
      const patch = (formState[targetKey] ?? []).map((row, index) => {
        const idMatch = itemId !== undefined && itemId === row.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
        if (idMatch || rowIndexMatch) {
          return { ...row, [columnId]: value };
        }
        return row;
      });
      patchFormState({ ...formState, [targetKey]: patch });
    }

    let tableColumns: TableColumnDefs<IncomeStatementAndBalanceTableItem> = [
      {
        id: 'key',
        cell: ({ row: { original: row } }) => (
          <div className={row.className}>
            {row.key ? t(`auditing:accountKey.${row.key}`) : '-'}
          </div>
        ),
        className: 'text-left width-30',
        header: () => t(`${tBase}.item`),
      },
      {
        accessorKey: 'currentYear',
        className: 'text-right small-text width-min nowrap text-bold',
        header: () => t(`${tBase}.currentYear`),
      },
      {
        accessorKey: 'priorYear',
        className: 'text-right small-text width-min nowrap',
        header: () => t(`${tBase}.priorYear`),
      },
      {
        accessorKey: 'change',
        className: `text-right small-text width-min nowrap`,
        header: () => t(`${tBase}.change`),
        cell: ({ row: { original: row } }) => {
          const { change } = row;
          // Remove existing thousands separators and convert to a number
          const changeValue = parseFloat(
            (change || '0').replace(/\s+/g, '').replace(',', '.')
          );
          // Format the number as currency
          const formattedChange = formatCurrency(changeValue, {
            currency: '€',
            decimalSeparator: ',',
            thousandsSeparator: ' ',
            hideCurrencySymbol: false,
            zeroIsUndefined: true,
          });
          // Determine color based on the value
          const color = changeValue > 0 ? colorGreen : colorRed;
          return <div style={{ color }}>{formattedChange}</div>;
        },
      },
      {
        accessorKey: 'changePercent',
        className: 'text-right small-text width-min nowrap',
        header: () => t(`${tBase}.changePercent`),
        cell: ({ row: { original: row } }) => {
          const { changePercent } = row;

          // Remove existing percent symbol and any thousands separators
          const cleanedChangePercent = (changePercent || '0').replace(
            /[^0-9.-]/g,
            ''
          );

          // Convert to a number and round to one decimal place
          const changePercentValue = parseFloat(cleanedChangePercent);
          const roundedChangePercent = (changePercentValue / 10).toFixed(1);

          // Replace the decimal point with a comma
          const formattedChangePercent = `${roundedChangePercent.replace(
            '.',
            ','
          )} %`;

          // Determine color based on the value
          const color = changePercentValue > 0 ? colorGreen : colorRed;

          return <div style={{ color }}>{formattedChangePercent}</div>;
        },
      },
      {
        accessorKey: 'statementDescription',
        header: () => t(`${tBase}.statementDescription`),
        hidden: (item: IncomeStatementAndBalanceTableItem) => !item.classKey,
        onEdit: (editProps: OnEditProps<string>) =>
          handleEdit<string>(editProps),
      },
    ];


    return (
      <>
        <Container>
          <div
            style={{
              position: 'relative',
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'flex-end',
              alignContent: 'space-between',
              marginLeft: 10,
            }}
          >

          </div>
          <AuditingTable<IncomeStatementAndBalanceTableItem>
            /* Readonly is only used by preliminaryAnalytic.
               Change sectionKey to allow editing comments
            */
            sectionKey={sectionKey}
            data={formattedData}
            columns={tableColumns}
            showGlobalFilter={false}
            disableSort
            animationDisabled={false}
          />
        </Container>
      </>
    );
  }
);
