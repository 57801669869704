import { AuditingHelpInfoBox } from '../../../components/auditing/AuditingHelpInfoBox';
import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { innerCircleElement, innerCircleElementDFS } from './reUsables/innerCircle';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { ReusableCalculationForm } from './reUsables/ReusableTableColumn';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';

type SectionFormType = UseInnerCircle<LoanReceivablesForm>;

const sectionKey: AuditingSectionKey = 'loanReceivables';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });
const jsonVocab = auditingJson.form.loanReceivables;
const batchString = 'Q3-loanReceivables';

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  procedureAccountsManualData: [
    {
      currentYear: 0,
      priorYear: 0,
      accountName: '',
      comment: '',
    },
  ],
  procedureAccountsData: {},
  debtInstallments: [
    {
      id: '1',
      ledgerBalance: 0,
      backgroundMaterial1: 0,
      backgroundMaterial2: 0,
      difference: 0,
      backgroundMaterial: '',
      other: '',
      hidden: true,
      addNewMaterial: false,
    },
  ],
  tableColumnData: [
    {
      left: 'Taseelle merkityt saamiset ovat olemassa',
      right: auditingJson.OLE,
    },
    {
      left: 'Yhteisö hallitsee varoja tai sillä on määräysvalta niitä koskeviin oikeuksiin',
      right: auditingJson.OVE,
    },
    {
      left: 'Saamiset on kirjattu oikeassa arvossa ja mahdolliset alaskirjaustarpeet on tunnistettu',
      right: auditingJson.OIK_ARV
    },
    {
      left: 'Kaikki saamiset, jotka olisi tullut kirjata on kirjattu',
      right: auditingJson.TAY
    },
    {
      left: 'Saamisiin on kirjattu eriä, jotka sinne luonteensa puolesta kuuluvat',
      right: auditingJson.LUOK
    },
    {
      left: 'Saamisiin liittyvät tilinpäätöksessä esitettävät tiedot ovat relevantteja ja ymmärrettäviä sovellettavan tilinpäätösnormiston mukaan',
      right: auditingJson.ESI
    }
  ],
  calculationFormData: {
    // Tarvitaan CalculationFormia varten
    '1': [
      {
        firstField: 'XXXXX',
        secondField: 0,
        thirdField: 0,
        calculatedAmount: 0,
        comment: '',
      },
    ],
  },

  // Käsityksen muodostaminen muista saamisista ja siirtosaamisista
  conclusion: jsonVocab.conclusionValue,

  // Lainasopimusten ja -ehtojen läpikäynti
  conclusionOfAudit: null,
  reference: jsonVocab.referenceValue1,
  ideasGoalsAndCompletedWork: jsonVocab.ideasGoalsAndCompletedWorkValue1,
  findingsAndComments: jsonVocab.notesAndCommentsValue1,

  // Lainasaamisten tarkastus
  AMPT: null,
  reference2: jsonVocab.referenceValue2,
  ideasGoalsAndCompletedWork2:
    auditingJson.defaultIdeasGoalsAndCompletedWorkValue,
  findingsAndComments2: auditingJson.defaultFindingsAndCommentsValue,

  // Lainasaamisten täsmäytys saldovahvistukselle
  AMPT2: null,
  reference3: jsonVocab.referenceValue3,
  ideasGoalsAndCompletedWork3:
    auditingJson.defaultIdeasGoalsAndCompletedWorkValue,
  findingsAndComments3: auditingJson.defaultFindingsAndCommentsValue,
  receivedRequestedInfo: null,
  notReceivedRequestedInfo: null,
  useCalculation: null,
  moreDetailsAndPlannedActions:
    auditingJson.defaultMoreDetailsAndPlannedActionsValue,

  spreadsheet1: [
    {
      cellData: [],
    },
  ],
  spreadsheet2: [
    {
      cellData: [],
    },
  ],
  spreadsheet3: [
    {
      cellData: [],
    },
  ],
  spreadsheet4: [
    {
      cellData: [],
    },
  ],
  // Sheets
  useSheetTab: null,
  useSheet1: null,
  useSheet2: null,
  useSheet3: null,
  useSheet4: null,
  ...innerCircleElementDFS
});

const formFields: FormFields<SectionFormType> = ({
  formState,
  auditing,
  store,
}) => [
  formBuilder.custom(
    <AuditingHelpInfoBox/>
  ),
  formBuilder.group({
    items: [
      formBuilder.boolean({
        accessor: 'useSheetTab',
        plainLabel: 'Käytä laskenta taulukko osiota',
      }),
      formBuilder.custom(props => (
        <ProcedureAccountsElement
          {...props}
          auditing={auditing}
          batchString={batchString}
          addCommentField
        />
      )),
      formBuilder.custom(props => <TableColumn {...props} />),
      formBuilder.textArea({
        accessor: 'conclusion',
        plainLabel: auditingJson.conclusion,
      }),
    ],
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      accordionId: 'loanAgreementsAndConditions',
      upperElements: [
        formBuilder.checkboxGroup({
          plainLabel: auditingJson.inspectionConclusion,
          accessor: 'conclusionOfAudit',
          options: Object.keys(jsonVocab.loanAgreementsOptions),
          optionDisplayValue: (key: string) =>
            jsonVocab.loanAgreementsOptions[
              key as keyof typeof jsonVocab.loanAgreementsOptions
            ],
        }),
      ],
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet1',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet1 ? (
            <SpreadSheet id="spreadsheet1" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Lainasaamisen tarkastus
      accordionId: 'reviewOfLoanReceivables',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet2',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet2 ? (
            <SpreadSheet id="spreadsheet2" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Lainasaamisten täsmäytys saldovahvistukselle
      accordionId: 'loanReceivablesReconciliationToBalance',
      upperElements: [
        formBuilder.checkboxGroup({
          accessor: 'receivedRequestedInfo',
          options: Object.keys(jsonVocab.receivedRequestedInfoOptions),
          optionDisplayValue: (key: string) =>
            jsonVocab.receivedRequestedInfoOptions[
              key as keyof typeof jsonVocab.receivedRequestedInfoOptions
            ],
          plainLabel: auditingJson.haveConfirmationsBeenReceived,
        }),

        formBuilder.textArea({
          accessor: 'moreDetailsAndPlannedActions',
          plainLabel: auditingJson.plannedActions,
          hidden: formState.receivedRequestedInfo
            ? formState.receivedRequestedInfo.requestedConfirmationsReceived ===
              false
            : true,
        }),
      ],
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useCalculation',
          plainLabel: auditingJson.useCalculation,
        }),
        formBuilder.custom(props =>
          formState.useCalculation ? (
            <ReusableCalculationForm
              sectionKey={sectionKey}
              batchString={batchString}
              fieldTitles={[
                'Laina',
                'Saldo pääkirjalla',
                'Saldo vahvistuksella',
              ]}
              {...props}
              id="1"
              addCommentField
            />
          ) : null
        ),
        formBuilder.boolean({
          accessor: 'useSheet3',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet3 ? (
            <SpreadSheet id="spreadsheet3" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Saamisten luokittelun ja esittäminen tarkoitus
      accordionId: 'classificationAndPresentationOfReceivables',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet4',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet4 ? (
            <SpreadSheet id="spreadsheet4" {...props} />
          ) : null
        ),
      ],
    },
    {
      accordionId: 'innerCircle',
      useDefault: false,
      upperElements: innerCircleElement(formBuilder)
    }
  ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
