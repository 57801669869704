import styled from "styled-components";
import { OnEditProps, TableColumnDefs } from "../table/Table";
import { formatCurrency } from "../../utils";
import AuditingTable from "../table/AuditingTable";
import { t } from "i18next";

const tBase = 'auditing:form.ownCapital';

const Container = styled.div``;

const formatAmount = (amount: number) => {
  if (amount >= 0) {
    return (
      <span style={{ color: "green" }}>
        {formatCurrency(amount)}
      </span>
    );
  }
  return (
    <span style={{ color: "red" }}>
      {`(${formatCurrency(Math.abs(amount))})`}
    </span>
  );
};

export const CheckContinuityTable = ({ store, formState, patchFormState }: FormFieldProps<OwnCapitalForm>) => {
  const auditingGeneralLedgerScoping =
    store?.auditingStore?.auditing?.generalLedgerScoping?.form.groupedGeneralLedger;

  const pyAll = auditingGeneralLedgerScoping?.find(({ account }) => account === 2040)?.currentYear ?? NaN;
  const py = auditingGeneralLedgerScoping?.find(({ account }) => account === 2050)?.priorYear ?? NaN;
  const cy = auditingGeneralLedgerScoping?.find(({ account }) => account === 2050)?.currentYear ?? NaN;

  const tableComments = formState.checkContinuityTable;

  const handleEdit = <T,>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) => {
    const patch = {
      ...formState,
      checkContinuityTable: [
        ...formState.checkContinuityTable.filter((item) => item.id !== itemId),
        {
          ...formState.checkContinuityTable[rowIndex],
          ...(itemId !== undefined && { id: itemId }),
          [columnId]: value,
        },
      ]
    }
    patchFormState(patch);
  };

  const data = [
    {
      id: 'pyAll',
      label: t(`${tBase}.checkContinuity.table.pyAll`),
      value: pyAll,
      comment: tableComments.find(({ id }) => id === 'pyAll')?.comment ?? ''
    },
    {
      id: 'py',
      label: t(`${tBase}.checkContinuity.table.py`),
      value: py,
      comment: tableComments.find(({ id }) => id === 'py')?.comment ?? ''
    },
    {
      id: 'cy',
      label: t(`${tBase}.checkContinuity.table.cy`),
      value: cy,
      comment: tableComments.find(({ id }) => id === 'cy')?.comment ?? ''
    },
    {
      id: 'difference',
      label: t(`${tBase}.checkContinuity.table.difference`),
      value: pyAll - py - cy,
      comment: tableComments.find(({ id }) => id === 'difference')?.comment ?? ''
    },
  ]

  const columns: TableColumnDefs<CheckContinuityTableRow> = [
    {
      id: "label",
      header: '',
      accessorKey: "label",
      className: "width-30"
    },
    {
      id: "value",
      header: '',
      accessorKey: "value",
      cell: row => formatAmount(row.cell.getValue()),
    },
    {
      id: "comment",
      header: "Kommentti",
      accessorKey: "comment",
      className: "width-40",
      onEdit: handleEdit,
    }
  ]

  return (
    <Container>
      <AuditingTable
        data={data}
        columns={columns}
        disableSort
        showGlobalFilter={false}
      />
    </Container>
  )
};
