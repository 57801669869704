import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AuditingTable from '../../../../components/table/AuditingTable';
import {
  OnEditProps,
  TableColumnDefs,
} from '../../../../components/table/Table';
import { formatPercent, uuid } from '../../../../utils';
import { AccountKey } from '../../planning/accountMapKeys';

const Container = styled.div`
  .additional-info {
    font-style: italic;
    font-weight: bold;
    color: ${p => p.theme.color.error};
    text-align: left;
  }
`;

function formatZeroDigitNumber(num: any) {
  if (num === null || num === undefined) return '0';
  return num.toLocaleString('fi-FI', { minimumFractionDigits: 0 });
}

const sectionKey: AuditingSectionKey = 'ownCapital';

export const OwnCapitalTable = ({
  formState,
  patchFormState,
}: FormFieldProps<OwnCapitalForm>) => {
  if (!formState) return null;

  const data: Array<{ cy: number; py: number; change?: number }> =
    Object.values(formState?.ownCapitalTable || {});
  const continuityTableData: Array<{ value: number }> = Object.values(
    formState?.continuityTable || {}
  );
  const addToContinuityData = [
    {
      id: '2',
      label: 'Ed tilikaudelta jaettu osinko (-syötä negatiivisena)',
      value: 0,
      comment: '',
    },
    {
      id: '3',
      label: 'Muut voittovaroihin tehdyt oikaisut',
      value: 0,
      comment: '',
    },
    {
      id: '4',
      label: 'Yhteensä',
      value: 0,
      comment: '',
    },
    {
      id: '5',
      label: 'Edellisten tilikausien voitto/tappio taseella',
      value: 0,
      comment: '',
    },
    {
      id: '7',
      label: 'Erotus taseen voittovaroihin',
      value: 0,
      comment: '',
    },
  ];
  const ownCapitalContinuityData: Array<{ value: number }> = Object.values(
    formState?.ownCapitalContinuity || {}
  );
  const addToOwnCapitalData = [
    {
      id: '3',
      label: 'Muut omaan pääomaan oikaisut / muutokset ',
      value: 0,
      comment: '',
    },
    {
      id: '4',
      label: 'Tase yht',
      value: 0,
      comment: '',
    },
    {
      id: '5',
      label: 'Erotus taseelle',
      value: 0,
      comment: '',
    },
  ];
  // Check before adding the data to the table
  addToOwnCapitalData.forEach((item: any) => {
    if (!ownCapitalContinuityData.some((dataItem: any) => dataItem.id === item.id)) {
      ownCapitalContinuityData.push(item);
    }
  });
  addToContinuityData.forEach((item: any) => {
    if (!continuityTableData.some((dataItem: any) => dataItem.id === item.id)) {
      continuityTableData.push(item);
    }
  });

  const calculateChange = (row: any) => {
    if (row) {
      row.change = row.cy - row.py;
    }
  };

  const totalCY = data.reduce((sum, row: any) => sum + (row.cy || 0), 0);
  const totalPY = data.reduce((sum, row: any) => sum + (row.py || 0), 0);

  const togetherData = [
    {
      ...formState.ownCapitalTogether[0], // keep other fields the same (like 'comment')
      cy: totalCY,
      py: totalPY,
      change: totalCY - totalPY,
    },
  ];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = data.map((item: any, index: number) => {
      const idMatch = itemId !== undefined && itemId === item.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...item, [columnId]: value };

        switch (columnId) {
          case 'cy':
          case 'py':
            calculateChange(updatedRow);
            break;
        }

        return updatedRow;
      }
      return item;
    });

    const newFormState = {
      ...formState,
      ownCapitalTable: patch,
    };
    patchFormState(newFormState);
  }

  function handleEditContinuity<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const continuityPatch = continuityTableData.map(
      (item: any, index: number) => {
        const idMatch = itemId !== undefined && itemId === item.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
        if (idMatch || rowIndexMatch) {
          const updatedRow = { ...item, [columnId]: value };

          return updatedRow;
        }
        return item;
      }
    );

    // Calculate the sum of the first three rows and set it to the fourth row's value
    const sum = continuityPatch.slice(0, 4).reduce((sum, row: any) => sum + (row.value || 0), 0);
    const updatedContinuityPatch = continuityPatch.map((item: any) => {
      if (item.label === 'Yhteensä') {
        return { ...item, value: sum };
      }
      return item;
    });

    // Calculate the CY of ownCapitalTable's row 6 and minus it from continuityTable's row 4
    const ownCapitalCY =
    formState.ownCapitalTable.find((item: any) => item.id === '6')?.cy || 0;
    const yhteensaValue =
      updatedContinuityPatch.find((item: any) => item.label === 'Yhteensä')
        ?.value || 0;
    const updatedContinuityPatchRowFour = updatedContinuityPatch.find(
      (item: any) => item.id === '5'
    );
    if (updatedContinuityPatchRowFour) {
      updatedContinuityPatchRowFour.value = yhteensaValue - ownCapitalCY;
    }

    // Set continuityTableData's item with id: 5 to ownCapitalSix value
    const ownCapitalSix = formState.ownCapitalTable.find((item: any) => item.id === '6')?.cy || 0;
    const updatedContinuityPatchRowFive = updatedContinuityPatch.find(
      (item: any) => item.id === '5'
    );
    if (updatedContinuityPatchRowFive) {
      updatedContinuityPatchRowFive.value = ownCapitalSix;
    }

    // if the table's number 7 is not disabled, it cannot save the value/it doesnt change the value because it tries to change the value all the time
    // need to fix this (cannot just disable the number/id 7)
    const updatedContinuityPatchRowSix = updatedContinuityPatch.find(
      (item: any) => item.id === '7'
    );
    if (updatedContinuityPatchRowSix) {
      const newValue = (yhteensaValue ?? 0) - (updatedContinuityPatchRowFive.value ?? 0);
      if (updatedContinuityPatchRowSix.value !== newValue) {
        updatedContinuityPatchRowSix.value = newValue;
      }
    }

    const newFormState = {
      ...formState,
      continuityTable: updatedContinuityPatch,
    };
    patchFormState(newFormState);
  }

  function handleEditOwnCapitalContinuity<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const ownCapitalContinuityPatch = ownCapitalContinuityData.map(
      (item: any, index: number) => {
        const idMatch = itemId !== undefined && itemId === item.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
        if (idMatch || rowIndexMatch) {
          const updatedRow = { ...item, [columnId]: value };
          return updatedRow;
        }
        return item;
      }
    );

    const ownCapitalContinuitySum = ownCapitalContinuityPatch
      .slice(0, 4)
      .reduce((sum, row: any) => sum + (row.value || 0), 0);
    const updatedOwnCapitalContinuity = ownCapitalContinuityPatch.map(
      (item: any) => {
        if (item.id === '4') {
          return { ...item, value: ownCapitalContinuitySum };
        }
        return item;
      }
    );

    // Calculate the CY of togetherData and minus it from ownCapitalContinuity's row 3
    const togetherCY = togetherData[0].cy || 0;
    const ownCapitalContinuityRowFour = updatedOwnCapitalContinuity.find(
      (item: any) => item.id === '4'
    );
    if (ownCapitalContinuityRowFour) {
      ownCapitalContinuityRowFour.value = togetherCY - ownCapitalContinuitySum;
    }

    const newFormState = {
      ...formState,
      ownCapitalContinuity: updatedOwnCapitalContinuity,
    };
    patchFormState(newFormState);
  }

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      ownCapitalTable: [
        ...(formState.ownCapitalTable ?? []),
        {
          label: '',
          id: uuid(),
          cy: 0,
          py: 0,
          change: 0,
          comment: '',
        },
      ],
    });
  };

  const tableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      header: '',
      className: 'text-left nowrap width-half text-bold',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'cy',
      header: 'CY',
      className: 'text-center nowrap width-min',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
    },
    {
      accessorKey: 'py',
      header: 'PY',
      className: 'text-center nowrap width-min',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
    },
    {
      accessorKey: 'change',
      header: 'Muutos',
      className: 'text-center nowrap width-min',
      cell: ({ cell }) => (
        <div>{formatZeroDigitNumber(cell.row.original.change)} €</div>
      ),
    },
    {
      accessorKey: 'comment',
      header: 'Kommentti',
      className: 'text-center nowrap width-half',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  const tableTogetherColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      header: '',
      className: 'text-left nowrap width-min text-bold',
    },
    {
      accessorKey: 'cy',
      header: 'CY',
      className: 'text-center nowrap width-min',
      cell: ({ cell }) => (
        <div>{formatZeroDigitNumber(cell.row.original.cy)} €</div>
      ),
    },
    {
      accessorKey: 'py',
      header: 'PY',
      className: 'text-center nowrap width-min',
      cell: ({ cell }) => (
        <div>{formatZeroDigitNumber(cell.row.original.py)} €</div>
      ),
    },
    {
      accessorKey: 'change',
      header: 'Muutos',
      className: 'text-center nowrap width-min',
      cell: ({ cell }) => (
        <div>{formatZeroDigitNumber(cell.row.original.change)} €</div>
      ),
    },
    {
      accessorKey: 'comment',
      header: 'Kommentti',
      className: 'text-center nowrap width-min',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  let disabledID = ['4', '5', '7'];
  const continuityTableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      header: '',
      className: 'text-left nowrap width-min text-bold',
    },
    {
      accessorKey: 'value',
      header: '',
      className: 'text-center nowrap width-min',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEditContinuity<number>(editProps);
      },
      disabled: (row: any) => disabledID.includes(row.id),
      cell: ({ cell }) => (
        <div>{formatZeroDigitNumber(cell.row.original.value)} €</div>
      ),
    },
    {
      accessorKey: 'comment',
      header: 'Kommentti',
      className: 'text-center nowrap width-min',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEditContinuity<string>(editProps);
      },
    },
  ];

  let otherDisabledID = ['4', '5'];
  const ownCapitalContinuityTableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      header: '',
      className: 'text-left nowrap width-min text-bold',
    },
    {
      accessorKey: 'value',
      header: '',
      className: 'text-center nowrap width-min',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEditOwnCapitalContinuity<number>(editProps);
      },
      disabled: (row: any) => otherDisabledID.includes(row.id),
      cell: ({ cell }) => (
        <div>{formatZeroDigitNumber(cell.row.original.value)} €</div>
      ),
    },
    {
      accessorKey: 'comment',
      header: 'Kommentti',
      className: 'text-center nowrap width-min',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEditOwnCapitalContinuity<string>(editProps);
      },
    },
  ];

  return (
    <>
      <Container>
        <div className="additional-info">
          Huomio: Jos taulukoissa ei näy tiedot, vaihda numeroita tai kirjoita kommenttikenttään.
        </div>
      </Container>
      <AuditingTable
        title="Oma pääoma"
        sectionKey={sectionKey}
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
        variant="default"
        onAddNewRow={handleAddNewRow}
      />
      <AuditingTable
        sectionKey={sectionKey}
        data={togetherData}
        columns={tableTogetherColumns}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
      <br />
      <AuditingTable
        title="Voittovarojen jatkuvuus"
        sectionKey={sectionKey}
        data={continuityTableData}
        columns={continuityTableColumns}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
      <br />
      <AuditingTable
        title="Oman pääoman jatkuvuus"
        sectionKey={sectionKey}
        data={ownCapitalContinuityData}
        columns={ownCapitalContinuityTableColumns}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
    </>
  );
};

export const ShareTable = ({
  formState,
  patchFormState,
}: FormFieldProps<OwnCapitalForm>) => {
  if (!formState) return null;

  const data = Object.values(formState?.shareTable || {});

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = data.map((item: any, index: number) => {
      const idMatch = itemId !== undefined && itemId === item.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...item, [columnId]: value };
        return updatedRow;
      }
      return item;
    });

    const rowOne = patch.find(row => row.id === '1');
    const rowTwo = patch.find(row => row.id === '2');
    const rowThree = patch.find(row => row.id === '3');
    // Need to calculate the minus, item.id 1's value - item.id 2's value = item.id 3's value
    if (rowOne && rowTwo && rowThree) {
      const rowThreeValue = rowOne.value - rowTwo.value;
      rowThree.value = rowThreeValue;
    }

    const newFormState = {
      ...formState,
      shareTable: patch,
    };
    patchFormState(newFormState);
  }

  // Disable edit on the third column
  let disabledID = '3';
  const tableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      className: 'text-left nowrap width-min text-bold',
      header: () => '',
    },
    {
      accessorKey: 'value',
      className: 'text-left nowrap width-min',
      header: () => '',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
      disabled: (row: any) => row.id === disabledID,
    },
    {
      accessorKey: 'comment',
      className: 'text-left nowrap width-min',
      header: () => '',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  return (
    <>
      <AuditingTable
        sectionKey={sectionKey}
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
    </>
  );
};

export const FreeEquityTable = ({
  formState,
  patchFormState,
}: FormFieldProps<OwnCapitalForm>) => {
  if (!formState) return null;

  const data: Array<{ cy: number; py: number }> = Object.values(
    formState?.freeEquityTable || {}
  );
  const secondData: Array<{ cy: number; py: number }> = Object.values(
    formState?.secondFreeEquityTable || {}
  );

  const totalCY = data.reduce((sum, row: any) => sum + (row.cy || 0), 0);
  const totalPY = data.reduce((sum, row: any) => sum + (row.py || 0), 0);

  const secondTotalCY = secondData.reduce(
    (sum, row: any) => sum + (row.cy || 0),
    0
  );
  const secondTotalPY = secondData.reduce(
    (sum, row: any) => sum + (row.py || 0),
    0
  );

  const calculatedData = [
    {
      ...formState.calculatedFreeEquity[0], // keep other fields the same (like 'comment')
      cy: totalCY,
      py: totalPY,
    },
  ];

  const calculatedSecondData = [
    {
      ...formState.calculatedSecondFreeEquity[0], // keep other fields the same (like 'comment')
      cy: secondTotalCY + totalCY,
      py: secondTotalPY + totalPY,
    },
  ];

  function handleDataEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = data.map((item: any, index: number) => {
      const idMatch = itemId !== undefined && itemId === item.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...item, [columnId]: value };
        return updatedRow;
      }
      return item;
    });
    const newFormState = {
      ...formState,
      freeEquityTable: patch,
    };
    patchFormState(newFormState);
  }

  function handleSecondDataEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = secondData.map((item: any, index: number) => {
      const idMatch = itemId !== undefined && itemId === item.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...item, [columnId]: value };
        return updatedRow;
      }
      return item;
    });
    const newFormState = {
      ...formState,
      secondFreeEquityTable: patch,
      calculatedSecondFreeEquity: calculatedSecondData,
    };
    patchFormState(newFormState);
  }

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      freeEquityTable: [
        ...(formState.freeEquityTable ?? []),
        {
          label: '',
          id: uuid(),
          cy: 0,
          py: 0,
        },
      ],
    });
  };

  const dataTableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      className: 'text-left nowrap width-min text-bold',
      header: () => '',
      onEdit: (editProps: OnEditProps<string>) => {
        handleDataEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'cy',
      className: 'text-left nowrap width-min text-bold',
      header: () => '',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleDataEdit<number>(editProps);
      },
    },
    {
      accessorKey: 'py',
      className: 'text-left nowrap width-min text-bold',
      header: () => '',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleDataEdit<number>(editProps);
      },
    },
  ];
  const calculatedDataTableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      className: 'text-left nowrap width-min text-bold',
      header: () => '',
    },
    {
      accessorKey: 'cy',
      className: 'text-left nowrap width-min text-bold',
      header: () => '',
    },
    {
      accessorKey: 'py',
      className: 'text-left nowrap width-min text-bold',
      header: () => '',
    },
  ];

  const secondDataTableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      className: 'text-left nowrap width-min text-bold',
      header: () => '',
    },
    {
      accessorKey: 'cy',
      className: 'text-left nowrap width-min',
      header: () => '',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleSecondDataEdit<number>(editProps);
      },
    },
    {
      accessorKey: 'py',
      className: 'text-left nowrap width-min',
      header: () => '',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleSecondDataEdit<number>(editProps);
      },
    },
  ];
  const calculatedSecondDataTableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      className: 'text-left nowrap width-min text-bold',
      header: () => '',
    },
    {
      accessorKey: 'cy',
      className: 'text-left nowrap width-min text-bold',
      header: () => '',
    },
    {
      accessorKey: 'py',
      className: 'text-left nowrap width-min text-bold',
      header: () => '',
    },
  ];

  return (
    <>
      <AuditingTable
        title="Vapaa omapääoma"
        sectionKey={sectionKey}
        data={data}
        columns={dataTableColumns}
        showGlobalFilter={false}
        disableSort
        variant="default"
        onAddNewRow={handleAddNewRow}
      />
      <AuditingTable
        sectionKey={sectionKey}
        data={calculatedData}
        columns={calculatedDataTableColumns}
        showGlobalFilter={false}
        disableSort
        variant="default"
        hideHeader
      />
      <br />
      <AuditingTable
        sectionKey={sectionKey}
        data={secondData}
        columns={secondDataTableColumns}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
      <AuditingTable
        sectionKey={sectionKey}
        data={calculatedSecondData}
        columns={calculatedSecondDataTableColumns}
        showGlobalFilter={false}
        disableSort
        variant="default"
        hideHeader
      />
    </>
  );
};

export const OtherCapitalTable = ({
  formState,
  patchFormState,
}: FormFieldProps<OwnCapitalForm>) => {
  if (!formState) return null;

  const data = Object.values(formState?.otherCapitalTable || {});

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = data.map((item: any, index: number) => {
      const idMatch = itemId !== undefined && itemId === item.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...item, [columnId]: value };
        return updatedRow;
      }
      return item;
    });

    // Calculate the sum of the first three rows and set it to the fourth row's value
    const sum = patch
      .slice(0, 4)
      .reduce((sum, row: any) => sum + (row.value || 0), 0);
    const updatedData = patch.map((item: any) => {
      if (item.label === 'Yhteensä') {
        return { ...item, value: sum };
      }
      return item;
    });

    const newFormState = {
      ...formState,
      otherCapitalTable: updatedData,
    };
    patchFormState(newFormState);
  }

  let disabledID = ['4'];
  const tableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      header: '',
      className: 'text-left nowrap width-min text-bold',
    },
    {
      accessorKey: 'value',
      header: '',
      className: 'text-center nowrap width-min',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
      disabled: (row: any) => disabledID.includes(row.id),
    },
  ];

  return (
    <>
      <AuditingTable
        sectionKey={sectionKey}
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
    </>
  );
};

export const SecondOwnCapitalTable = ({
  formState,
  patchFormState,
}: FormFieldProps<OwnCapitalForm>) => {
  if (!formState) return null;

  const data: Array<{ cy: number; py: number; change?: number }> =
    Object.values(formState?.ownCapitalTable2 || {});
  const continuityTableData: Array<{ value: number }> = Object.values(
    formState?.continuityTable2 || {}
  );
  const ownCapitalContinuityData: Array<{ value: number }> = Object.values(
    formState?.ownCapitalContinuity2 || {}
  );

  const calculateChange = (row: any) => {
    if (row) {
      row.change = row.cy - row.py;
    }
  };

  const totalCY = data.reduce((sum, row: any) => sum + (row.cy || 0), 0);
  const totalPY = data.reduce((sum, row: any) => sum + (row.py || 0), 0);

  const togetherData = [
    {
      ...formState.ownCapitalTogether2[0], // keep other fields the same (like 'comment')
      cy: totalCY,
      py: totalPY,
      change: totalCY - totalPY,
    },
  ];

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = data.map((item: any, index: number) => {
      const idMatch = itemId !== undefined && itemId === item.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...item, [columnId]: value };

        switch (columnId) {
          case 'cy':
          case 'py':
            calculateChange(updatedRow);
            break;
        }

        return updatedRow;
      }
      return item;
    });

    const newFormState = {
      ...formState,
      ownCapitalTable2: patch,
    };
    patchFormState(newFormState);
  }

  function handleEditContinuity<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const continuityPatch = continuityTableData.map(
      (item: any, index: number) => {
        const idMatch = itemId !== undefined && itemId === item.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
        if (idMatch || rowIndexMatch) {
          const updatedRow = { ...item, [columnId]: value };
          return updatedRow;
        }
        return item;
      }
    );

    // Calculate the sum of the first three rows and set it to the fourth row's value
    const sum = continuityPatch
      .slice(0, 4)
      .reduce((sum, row: any) => sum + (row.value || 0), 0);
    const updatedContinuityPatch = continuityPatch.map((item: any) => {
      if (item.label === 'Yhteensä') {
        return { ...item, value: sum };
      }
      return item;
    });

    // Calculate the CY of ownCapitalTable's row 6 and minus it from continuityTable's row 4
    const ownCapitalCY =
      formState.ownCapitalTable2.find((item: any) => item.id === '6')?.cy || 0;
    const yhteensaValue =
      updatedContinuityPatch.find((item: any) => item.label === 'Yhteensä')
        ?.value || 0;
    const updatedContinuityPatchRowFour = updatedContinuityPatch.find(
      (item: any) => item.id === '5'
    );
    if (updatedContinuityPatchRowFour) {
      updatedContinuityPatchRowFour.value = yhteensaValue - ownCapitalCY;
    }

    // Set continuityTableData's item with id: 5 to ownCapitalSix value
    const ownCapitalSix = formState.ownCapitalTable2.find((item: any) => item.id === '6')?.cy || 0;
    const updatedContinuityPatchRowFive = updatedContinuityPatch.find(
      (item: any) => item.id === '5'
    );
    if (updatedContinuityPatchRowFive) {
      updatedContinuityPatchRowFive.value = ownCapitalSix;
    }

    const updatedContinuityPatchRowSix = updatedContinuityPatch.find(
      (item: any) => item.id === '6'
    );
    if (updatedContinuityPatchRowSix) {
      updatedContinuityPatchRowSix.value = yhteensaValue - updatedContinuityPatchRowFive.value;
    }

    const newFormState = {
      ...formState,
      continuityTable2: updatedContinuityPatch,
    };
    patchFormState(newFormState);
  }

  function handleEditOwnCapitalContinuity<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const ownCapitalContinuityPatch = ownCapitalContinuityData.map(
      (item: any, index: number) => {
        const idMatch = itemId !== undefined && itemId === item.id;
        const rowIndexMatch = itemId === undefined && rowIndex === index;
        if (idMatch || rowIndexMatch) {
          const updatedRow = { ...item, [columnId]: value };
          return updatedRow;
        }
        return item;
      }
    );

    const ownCapitalContinuitySum = ownCapitalContinuityPatch
      .slice(0, 3)
      .reduce((sum, row: any) => sum + (row.value || 0), 0);
    const updatedOwnCapitalContinuity = ownCapitalContinuityPatch.map(
      (item: any) => {
        if (item.id === '3') {
          return { ...item, value: ownCapitalContinuitySum };
        }
        return item;
      }
    );

    // Calculate the CY of togetherData and minus it from ownCapitalContinuity's row 3
    const togetherCY = togetherData[0].cy || 0;
    const ownCapitalContinuityRowFour = updatedOwnCapitalContinuity.find(
      (item: any) => item.id === '4'
    );
    if (ownCapitalContinuityRowFour) {
      ownCapitalContinuityRowFour.value = togetherCY - ownCapitalContinuitySum;
    }

    const newFormState = {
      ...formState,
      ownCapitalContinuity2: updatedOwnCapitalContinuity,
    };
    patchFormState(newFormState);
  }

  const handleAddNewRow = () => {
    patchFormState({
      ...formState,
      ownCapitalTable2: [
        ...(formState.ownCapitalTable2 ?? []),
        {
          label: '',
          id: uuid(),
          cy: 0,
          py: 0,
          change: 0,
          comment: '',
        },
      ],
    });
  };

  const tableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      header: '',
      className: 'text-left nowrap width-half text-bold',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'cy',
      header: 'CY',
      className: 'text-center nowrap width-min',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
    },
    {
      accessorKey: 'py',
      header: 'PY',
      className: 'text-center nowrap width-min',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
    },
    {
      accessorKey: 'change',
      header: 'Muutos',
      className: 'text-center nowrap width-min',
      cell: ({ cell }) => (
        <div>{formatZeroDigitNumber(cell.row.original.change)} €</div>
      ),
    },
    {
      accessorKey: 'comment',
      header: 'Kommentti',
      className: 'text-center nowrap width-half',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  const tableTogetherColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      header: '',
      className: 'text-left nowrap width-min text-bold',
    },
    {
      accessorKey: 'cy',
      header: 'CY',
      className: 'text-center nowrap width-min',
      cell: ({ cell }) => (
        <div>{formatZeroDigitNumber(cell.row.original.cy)} €</div>
      ),
    },
    {
      accessorKey: 'py',
      header: 'PY',
      className: 'text-center nowrap width-min',
      cell: ({ cell }) => (
        <div>{formatZeroDigitNumber(cell.row.original.py)} €</div>
      ),
    },
    {
      accessorKey: 'change',
      header: 'Muutos',
      className: 'text-center nowrap width-min',
      cell: ({ cell }) => (
        <div>{formatZeroDigitNumber(cell.row.original.change)} €</div>
      ),
    },
    {
      accessorKey: 'comment',
      header: 'Kommentti',
      className: 'text-center nowrap width-min',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
  ];

  let disabledID = ['4', '5'];
  const continuityTableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      header: '',
      className: 'text-left nowrap width-min text-bold',
    },
    {
      accessorKey: 'value',
      header: '',
      className: 'text-center nowrap width-min',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEditContinuity<number>(editProps);
      },
      disabled: (row: any) => disabledID.includes(row.id),
      cell: ({ cell }) => (
        <div>{formatZeroDigitNumber(cell.row.original.value)} €</div>
      ),
    },
    {
      accessorKey: 'comment',
      header: 'Kommentti',
      className: 'text-center nowrap width-min',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEditContinuity<string>(editProps);
      },
    },
  ];

  let otherDisabledID = ['3', '4'];
  const ownCapitalContinuityTableColumns: TableColumnDefs<any> = [
    {
      accessorKey: 'label',
      header: '',
      className: 'text-left nowrap width-min text-bold',
    },
    {
      accessorKey: 'value',
      header: '',
      className: 'text-center nowrap width-min',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEditOwnCapitalContinuity<number>(editProps);
      },
      disabled: (row: any) => otherDisabledID.includes(row.id),
      cell: ({ cell }) => (
        <div>{formatZeroDigitNumber(cell.row.original.value)} €</div>
      ),
    },
    {
      accessorKey: 'comment',
      header: 'Kommentti',
      className: 'text-center nowrap width-min',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEditOwnCapitalContinuity<string>(editProps);
      },
    },
  ];

  return (
    <>
      <AuditingTable
        title="Oma pääoma"
        sectionKey={sectionKey}
        data={data}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
        variant="default"
        onAddNewRow={handleAddNewRow}
      />
      <AuditingTable
        sectionKey={sectionKey}
        data={togetherData}
        columns={tableTogetherColumns}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
      <br />
      <AuditingTable
        title="Voittovarojen jatkuvuus"
        sectionKey={sectionKey}
        data={continuityTableData}
        columns={continuityTableColumns}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
      <br />
      <AuditingTable
        title="Oman pääoman jatkuvuus"
        sectionKey={sectionKey}
        data={ownCapitalContinuityData}
        columns={ownCapitalContinuityTableColumns}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
    </>
  );
};
enum OsingoValues {
  true = 'yes',
  false = 'no',
}
export const OsingoTable = ({
  formState,
  patchFormState,
  store,
}: FormFieldProps<OwnCapitalForm>) => {
  const { t } = useTranslation();
  if (!formState) return null;

  const tBase = `auditing:form.${sectionKey}.tableOptions`;

  const data = Object.values(formState?.osingoTable || {});

  const equity = store.auditingStore.getFinancialNumberScoping(
    AccountKey.equity,
    'currentYear'
  );
  const id2Value = data.find(item => item.id === '0')?.value || 0;
  const calculatedValue = equity ? id2Value / equity : 0;

  //console.log(equity, id2Value, calculatedValue);

  const updatedData = data.map(item => {
    if (item.id === '1') {
      return { ...item, value: calculatedValue };
    }
    return item;
  });

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = updatedData.map((item: any, index: number) => {
      const idMatch = itemId !== undefined && itemId === item.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) {
        const updatedRow = { ...item, [columnId]: value };
        return updatedRow;
      }
      return item;
    });
    const newFormState = {
      ...formState,
      osingoTable: patch,
    };
    patchFormState(newFormState);
  }

  let valueHiddenID = ['2'];
  let booleanHiddenID = ['0', '1'];
  let disabledID = ['1'];
  const tableColumns: TableColumnDefs<OsingoTable> = [
    {
      accessorKey: 'label',
      header: '',
      className: 'text-left nowrap width-min text-bold',
    },
    {
      accessorKey: 'value',
      header: '',
      className: 'text-center nowrap width-min',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
      cell: ({ cell }) => <div>{formatPercent(cell.row.original.value)}</div>,
      hidden: (row: any) => valueHiddenID.includes(row.id),
      disabled: (row: any) => disabledID.includes(row.id),
    },
    {
      accessorKey: 'dropdown',
      header: '',
      className: 'text-center nowrap width-min',
      enableGlobalFilter: false,
      selectProps: {
        options: Object.values(OsingoValues),
        placeholder: 'Valitse',
        clearable: true,
        displayValue: (option: string) => t(`${tBase}.${option}`),
        setValue: (editProps: OnEditProps<string>) => {
          const value = editProps.value;
          handleEdit<any>({
            ...editProps,
            value: value === 'yes' ? true : value === 'no' ? false : null,
          });
        },
      },
      hidden: (row: any) => booleanHiddenID.includes(row.id),
    },
  ];

  return (
    <>
      <AuditingTable<OsingoTable>
        sectionKey={sectionKey}
        data={updatedData}
        columns={tableColumns}
        showGlobalFilter={false}
        disableSort
        variant="default"
      />
    </>
  );
};
