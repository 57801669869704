import { OnEditProps } from "../../../../components/table/Table";
import { t } from "../../../../i18n";
import { uuid } from "../../../../utils";

const sectionKey: AuditingSectionKey = 'otherOperatingExpenses';
const tBase = `auditing:form.${sectionKey}.rentTables`;

// Default values for tables
const exampleBaseRows = (): rentTableRow[] => [
  {
    id: uuid(),
    label: t(`${tBase}.rent`),
    type: 'rent',
    value: 100.00,
    comment: 'Kommentti...'
  },
  {
    id: uuid(),
    label: t(`${tBase}.months`),
    type: 'months',
    value: 12,
    comment: 'Kommentti...'
  },
  {
    id: uuid(),
    label: t(`${tBase}.cumulativeRentalCostExpectation`),
    type: 'cumulativeRentalCostExpectation',
    value: 1200,
    comment: 'Kommentti...'
  },
  {
    id: uuid(),
    label: t(`${tBase}.actualRentalCosts`),
    type: 'actualRentalCosts',
    value: 1000,
    comment: null
  },
  {
    id: uuid(),
    label: t(`${tBase}.difference`),
    type: 'difference',
    value: 200,
    comment: null
  }
];

const exampleRentRows = (): rentTableRow[] => [
  {
    id: uuid(),
    type: 'rent',
    label: t(`${tBase}.rent`),
    value: 100.00,
    comment: 'Kommentti...'
  },
  {
    id: uuid(),
    type: 'adjustmentInPercentage',
    label: t(`${tBase}.rentCalculationTable.adjustmentInPercentage`),
    value: 3,
    comment: 'Kommentti...'
  },
  {
    id: uuid(),
    type: 'adjusted',
    label: t(`${tBase}.rentCalculationTable.adjusted`),
    value: 103.00,
    comment: null
  }
];

const exampleIndexRows = (): rentTableRow[] => [
  {
    id: uuid(),
    type: 'rent',
    label: t(`${tBase}.rent`),
    value: 100.00,
    comment: 'Kommentti...'
  },
  {
    id: uuid(),
    type: 'baseIndex',
    label: t(`${tBase}.rentIndexCalculationTable.baseIndex`),
    value: 2238,
    comment: 'Kommentti...'
  },
  {
    id: uuid(),
    type: 'adjustmentIndex',
    label: t(`${tBase}.rentIndexCalculationTable.adjustmentIndex`),
    value: 2310,
    comment: 'Kommentti...'
  },
  {
    id: uuid(),
    type: 'adjusted',
    label: t(`${tBase}.rentIndexCalculationTable.adjusted`),
    value: 96.88,
    comment: null
  }
];

const parentUuid = uuid();

export const exampleData: rentTable[] = [
  {
    id: parentUuid,
    version: 1,
    type: 'base',
    table: exampleBaseRows(),
    showChildren: false
  },
  {
    id: uuid(),
    version: 1,
    parent: parentUuid,
    type: 'rentCalculation',
    table: exampleRentRows()
  },
  {
    id: uuid(),
    version: 1,
    parent: parentUuid,
    type: 'rentIndexCalculation',
    table: exampleIndexRows()
  }
];

const calculateTableValues = (item: rentTable): Record<string, number> => {

  const findRow = (rowType: rentRowType) =>
    item.table.find((item) => item.type === rowType)?.value ?? 0;

  const rent = findRow('rent');

  // Parent table
  if (item.type === 'base') {
    const months = findRow('months');
    const expectation = rent * months;
    const actual = findRow('actualRentalCosts');
    const difference = expectation - actual;

    return { 'cumulativeRentalCostExpectation': expectation, 'difference': difference }
  }
  // Children
  else {
    let adjusted = 0;
    // Prosenttikorotus
    if (item.type === 'rentCalculation') {
      const adjustmentInPercentage = findRow('adjustmentInPercentage');
      const coefficient = 1 + (adjustmentInPercentage / 100);
      adjusted = Number((rent * coefficient).toFixed(2));
    }

    // Indeksikorotus
    else {
      const baseIndex = findRow('baseIndex')
      const adjustmentIndex = findRow('adjustmentIndex')
      adjusted = Number((rent * baseIndex / adjustmentIndex).toFixed(2));
    }

    return { 'adjusted': adjusted }
  }
}

export const toggleShowChildrenForTable = (
  formState: OtherOperatingExpensesForm,
  patchFormState: any,
  parentTableId: UUID
) => {

  const patch: rentTable[] = formState.rentTablesData.map((item: rentTable) => {
    return (
      item.id === parentTableId
        ? { ...item, showChildren: !item.showChildren }
        : item
    )
  });

  const newFormState = {
    ...formState,
    rentTablesData: patch
  }

  patchFormState(newFormState);
}

// Deletes a table and it's children, if any.
export const deleteTable = (
  formState: OtherOperatingExpensesForm,
  patchFormState: any,
  table: rentTable
) => {
  if (!formState.rentTablesData) return null;

  const patch: rentTable[] = formState.rentTablesData.filter((item) =>
    item.id !== table.id
    // Remove child tables
  ).filter((item) => item.parent !== table.id);

  const newFormState = {
    ...formState,
    rentTablesData: cleanOrphanedTables(patch)
  }

  patchFormState(newFormState);
}

const cleanOrphanedTables = (old: rentTable[]): rentTable[] => {
  const patch: rentTable[] = old.filter((item) => {
    const parentExists = old.find((parent) => parent.id === item.parent);
    return item.type === 'base' || parentExists;
  });

  return patch;
}

// Adds a new base table and child tables for it
export const addNewTables = (
  formState: OtherOperatingExpensesForm,
  patchFormState: any
) => {
  const parentUuid = uuid();
  const newBaseTable: rentTable = {
    id: parentUuid,
    version: 1,
    type: 'base',
    table: exampleBaseRows(),
    showChildren: false
  };
  const newRentTable: rentTable = {
    id: uuid(),
    version: 1,
    type: 'rentCalculation',
    parent: parentUuid,
    table: exampleRentRows()
  };
  const newIndexTable: rentTable = {
    id: uuid(),
    version: 1,
    type: 'rentIndexCalculation',
    parent: parentUuid,
    table: exampleIndexRows()
  };

  const patch: rentTable[] = formState.rentTablesData ?? [];
  [newBaseTable, newRentTable, newIndexTable].map((table) => patch.push(table))

  const newFormState = {
    ...formState,
    rentTablesData: cleanOrphanedTables(patch)
  };

  patchFormState(newFormState);
}

// Handles edits to otherOperatingExpenses rent tables, both base table and calculation tables
export const handleEdit = <T,>({
  itemId,
  rowIndex,
  columnId,
  value,
}: OnEditProps<T>,
  formState: OtherOperatingExpensesForm,
  patchFormState: any,
  data: rentTable[],
) => {

  const patch: rentTable[] = data.map((item: rentTable): rentTable => {
    const newRows: rentTableRow[] = (
      item.table.map((row, index): rentTableRow => {
        return (
          (row.id === itemId && index === rowIndex)
            ? { ...row, [columnId]: value }
            : row
        )
      }
      )
    )
    return { ...item, table: newRows }
  });

  // Calculate new result
  const updatedData: rentTable[] = patch.map((item: rentTable): rentTable => {
    const calculatedTypes: rentRowType[] = ['adjusted', 'difference', 'cumulativeRentalCostExpectation']
    const newRows: rentTableRow[] = (
      item.table.map((row): rentTableRow => {
        if (calculatedTypes.includes(row.type)) {
          return { ...row, value: calculateTableValues(item)[row.type] }
        }
        else {
          return row
        }
      })
    );
    return { ...item, table: newRows }
  }
  );

  const newFormState = {
    ...formState,
    rentTablesData: updatedData
  }

  patchFormState(newFormState);
}