import React from "react";
import InstructionBox from "../InstructionBox";
import { t } from "i18next";
import { Trans } from "react-i18next";
import styled from "styled-components";

const helpMessageLines: string[] = [
  t('auditing:form.risks.riskSectionHelpText.line1'),
  t('auditing:form.risks.riskSectionHelpText.line2'),
  t('auditing:form.risks.riskSectionHelpText.line3'),
  t('auditing:form.risks.riskSectionHelpText.line4'),
  t('auditing:form.risks.riskSectionHelpText.line5'),
  t('auditing:form.risks.riskSectionHelpText.line6'),
  t('auditing:form.risks.riskSectionHelpText.line7')
];

const Container = styled.div`
    white-space: pre-line;
    max-height: 60vh;
    overflow: scroll;
    padding: 5px;

    strong {
      color: red;
    }
`;

export const RiskHelpInfoBox: React.FC = () => {
  return (
    <InstructionBox
      buttonText='Katso ohje!'
      title='Ohje'>
      <Container>
        <Trans>{helpMessageLines.join('\n\n')}</Trans>
      </Container>
    </InstructionBox>
  )
}