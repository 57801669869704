import styled from "styled-components";
import { t } from "../../i18n";
import { Select, TextArea } from "../inputs";
import { PropsWithChildren, useState } from "react";
import { uuid } from "../../utils";
import { OnEditProps, TableColumnDefs } from "../table/Table";
import AuditingTable from "../table/AuditingTable";

const sectionKey: AuditingSectionKey = 'presentationAndContent';
const tBase = `auditing:form.${sectionKey}`;
const tableTBase = `${tBase}.F3_1_tables`;

interface ListProps extends PropsWithChildren {
  header: string;
  additionalHeader?: string;
  list: string[];
}

const Container = styled.div`
  white-space: pre-line;

  p {
    max-width: 900px;
    word-wrap: break-word;
  }
`;

const ListContainer = styled.div`
  ul {
    padding-left: 20px;
    max-width: 900px
  }
  
  li {
    padding: 5px;
    margin-bottom: 10px;
  }

  li:nth-child(odd) {
    background-color: #f2f2f2;
    border-radius: 2px;
  }
`;

const Spacer = styled.div`
  margin: 30px
`;

const List = ({
  header,
  additionalHeader,
  list
}: ListProps) => {
  return (
    <ListContainer>
      <h4>{header}</h4>
      {additionalHeader && <p>{additionalHeader}</p>}
      <ul style={{ listStyleType: 'disc' }}>
        {list.map(
          (line, index) =>
            <li key={index}>{line}</li>)}
      </ul>
    </ListContainer>
  )
};

const SectionList = ({
  header,
  additionalHeader,
  list,
  conclusionAccessor,
  commentAccessor,
  formState,
  patchFormState }: SummaryAndContentChildListProps) => {

  const [selectValue, setSelectValue] = useState<string | undefined>(formState[conclusionAccessor]);
  const [commentValue, setCommentValue] = useState<string>(formState[commentAccessor]);

  const handleEdit = <T,>(accessor: keyof PresentationAndContentForm, value: T) => {
    if (value === undefined) return null;
    patchFormState({
      ...formState,
      [accessor]: value
    });
  };

  return (
    <div>
      <Container>
        <List
          header={header}
          additionalHeader={additionalHeader}
          list={list}
        />
        <Select
          label="Esitetty liitetiedoissa?"
          options={['yes', 'no', 'notApplicable']}
          value={selectValue}
          displayValue={(option: string) => option ? t(`${tableTBase}.isPresentOptions.${option}`) : 'Valitse'}
          setValue={(value) => {
            setSelectValue(value);
            handleEdit(conclusionAccessor, value);
          }}
        />
        <TextArea
          placeholder="Kommentit..."
          fullWidth
          value={commentValue}
          setValue={(value => {
            setCommentValue(value);
            handleEdit(commentAccessor, value);
          })}
        />
      </Container>
    </div>
  )
};

const AdditionalInfoSection = ({
  header,
  additionalHeader,
  commentAccessor,
  formState,
  patchFormState,
  listAccessor }: SummaryAndContentAdditionalInfoListProps
) => {
  const exampleList: SummaryAndContentListItem[] = [
    {
      id: uuid(),
      text: 'Lisätieto 1',
      version: 1
    },
    {
      id: uuid(),
      text: 'Lisätieto 2',
      version: 1
    }
  ];
  const data = formState[listAccessor].length > 0 ? formState[listAccessor] : exampleList;

  const [commentValue, setCommentValue] = useState<string>(formState[commentAccessor]);

  const columns: TableColumnDefs<SummaryAndContentListItem> = [
    {
      accessorKey: 'text',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps),
    }
  ];

  const handleEdit = <T,>({ itemId, value }: OnEditProps<T>) => {
    const newData = data.map(item => item.id === itemId ? { ...item, text: value as string } : item);
    patchFormState({
      ...formState,
      [listAccessor]: newData
    });
  };

  const handleAddNewRow = () => {
    const newData = [
      ...data,
      {
        id: uuid(),
        text: '',
        version: 1
      }];
    patchFormState({
      ...formState,
      [listAccessor]: newData
    });
  };

  const handleRowDelete = (row: SummaryAndContentListItem) => {
    const newData = data.filter(item => item.id !== row.id);
    patchFormState({
      ...formState,
      [listAccessor]: newData
    });
  };

  return (
    <Container>
      <h4>{header}</h4>
      <p>{additionalHeader}</p>
      <Spacer />
      <AuditingTable
        data={data}
        columns={columns}
        onAddNewRow={handleAddNewRow}
        onRowDelete={handleRowDelete}
        variant="default"
        disableSort
        showGlobalFilter={false}
        hideHeader
      />
      <Spacer />
      <TextArea
        placeholder="Kommentit..."
        value={commentValue}
        setValue={(value) => {
          setCommentValue(value);
          patchFormState({
            ...formState,
            [commentAccessor]: value
          })
        }}
        fullWidth
      />
    </Container>
  )
};

// Osio 3.2
export const SummaryAndContentList3s2 = ({
  formState,
  patchFormState
}: FormFieldProps<PresentationAndContentForm>) => {
  return (
    <>
      {/* Osio 3.2.1 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader1`)}
        list={t(`${tableTBase}.summaryAndContentTable.label1`).split('\n')}
        conclusionAccessor="conclusion3_2_1"
        commentAccessor="comments3_2_1"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.2.2 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader2`)}
        list={t(`${tableTBase}.summaryAndContentTable.label2`).split('\n')}
        conclusionAccessor="conclusion3_2_2"
        commentAccessor="comments3_2_2"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.2.3 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader3`)}
        list={t(`${tableTBase}.summaryAndContentTable.label3`).split('\n')}
        conclusionAccessor="conclusion3_2_3"
        commentAccessor="comments3_2_3"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.2.4 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader4`)}
        additionalHeader={t(`${tableTBase}.summaryAndContentTable.label4`).split('\n')[0]}
        list={t(`${tableTBase}.summaryAndContentTable.label4`).split('\n').splice(1)}
        conclusionAccessor="conclusion3_2_4"
        commentAccessor="comments3_2_4"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.2.5 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader5`)}
        list={t(`${tableTBase}.summaryAndContentTable.label5`).split('\n')}
        conclusionAccessor="conclusion3_2_5"
        commentAccessor="comments3_2_5"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.2.6 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader6`)}
        list={t(`${tableTBase}.summaryAndContentTable.label6`).split('\n')}
        conclusionAccessor="conclusion3_2_6"
        commentAccessor="comments3_2_6"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.2.7 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader7`)}
        list={t(`${tableTBase}.summaryAndContentTable.label7`).split('\n')}
        conclusionAccessor="conclusion3_2_7"
        commentAccessor="comments3_2_7"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.2.8 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader8`)}
        list={t(`${tableTBase}.summaryAndContentTable.label8`).split('\n')}
        conclusionAccessor="conclusion3_2_8"
        commentAccessor="comments3_2_8"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.2.9 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader9`)}
        list={t(`${tableTBase}.summaryAndContentTable.label9`).split('\n')}
        conclusionAccessor="conclusion3_2_9"
        commentAccessor="comments3_2_9"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.3 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader10`)}
        list={t(`${tableTBase}.summaryAndContentTable.label10`).split('\n')}
        conclusionAccessor="conclusion3_3"
        commentAccessor="comments3_3"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.4 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader11`)}
        list={t(`${tableTBase}.summaryAndContentTable.label11`).split('\n')}
        conclusionAccessor="conclusion3_4"
        commentAccessor="comments3_4"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.4.1 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader12`)}
        list={t(`${tableTBase}.summaryAndContentTable.label12`).split('\n')}
        conclusionAccessor="conclusion3_4_1"
        commentAccessor="comments3_4_1"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.4.2 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader13`)}
        list={t(`${tableTBase}.summaryAndContentTable.label13`).split('\n')}
        conclusionAccessor="conclusion3_4_2"
        commentAccessor="comments3_4_2"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.5 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader14`)}
        list={t(`${tableTBase}.summaryAndContentTable.label14`).split('\n')}
        conclusionAccessor="conclusion3_5"
        commentAccessor="comments3_5"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.6 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader15`)}
        list={t(`${tableTBase}.summaryAndContentTable.label15`).split('\n')}
        conclusionAccessor="conclusion3_6"
        commentAccessor="comments3_6"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Lisätiedot */}
      <AdditionalInfoSection
        header="Lisätiedot"
        additionalHeader="Tähän voit tarvittaessa antaa lisätietoja"
        commentAccessor="section3_2AdditionalInfoComments"
        listAccessor="section3_2AdditionalInfoList"
        formState={formState}
        patchFormState={patchFormState}
      />
    </>
  )
};

// Osio 3.7
export const SummaryAndContentList3s7 = ({
  formState,
  patchFormState
}: FormFieldProps<PresentationAndContentForm>) => {
  return (
    <>
      {/* Osio 3.7.1 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader16`)}
        list={t(`${tableTBase}.summaryAndContentTable.label16`).split('\n')}
        conclusionAccessor="conclusion3_7_1"
        commentAccessor="comments3_7_1"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.7.2 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader17`)}
        list={t(`${tableTBase}.summaryAndContentTable.label17`).split('\n')}
        conclusionAccessor="conclusion3_7_2"
        commentAccessor="comments3_7_2"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.7.3 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader18`)}
        list={t(`${tableTBase}.summaryAndContentTable.label18`).split('\n')}
        conclusionAccessor="conclusion3_7_3"
        commentAccessor="comments3_7_3"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.7.4 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader19`)}
        list={t(`${tableTBase}.summaryAndContentTable.label19`).split('\n')}
        conclusionAccessor="conclusion3_7_4"
        commentAccessor="comments3_7_4"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.7.5 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader20`)}
        list={t(`${tableTBase}.summaryAndContentTable.label20`).split('\n')}
        conclusionAccessor="conclusion3_7_5"
        commentAccessor="comments3_7_5"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      {/* Osio 3.7.6 */}
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader21`)}
        list={t(`${tableTBase}.summaryAndContentTable.label21`).split('\n')}
        conclusionAccessor="conclusion3_7_6"
        commentAccessor="comments3_7_6"
        formState={formState}
        patchFormState={patchFormState}
      />

      {/* Lisätiedot */}
      <AdditionalInfoSection
        header="Lisätiedot"
        additionalHeader="Tähän voit tarvittaessa antaa lisätietoja"
        commentAccessor="section3_7AdditionalInfoComments"
        listAccessor="section3_7AdditionalInfoList"
        formState={formState}
        patchFormState={patchFormState}
      />
    </>
  )
};

// Osio 3.8
export const SummaryAndContentList3s8 = ({
  formState,
  patchFormState
}: FormFieldProps<PresentationAndContentForm>) => {
  return (
    <>
      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader22`)}
        list={t(`${tableTBase}.summaryAndContentTable.label22`).split('\n')}
        conclusionAccessor="conclusion3_8_1"
        commentAccessor="comments3_8_1"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader23`)}
        list={t(`${tableTBase}.summaryAndContentTable.label23`).split('\n')}
        conclusionAccessor="conclusion3_8_2"
        commentAccessor="comments3_8_2"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      <SectionList
        header={t(`${tableTBase}.summaryAndContentTable.labelHeader24`)}
        list={t(`${tableTBase}.summaryAndContentTable.label24`).split('\n')}
        conclusionAccessor="conclusion3_8_3"
        commentAccessor="comments3_8_3"
        formState={formState}
        patchFormState={patchFormState}
      />

      <Spacer />

      <AdditionalInfoSection
        header="Lisätiedot"
        additionalHeader="Tähän voit tarvittaessa antaa lisätietoja"
        commentAccessor="section3_8AdditionalInfoComments"
        listAccessor="section3_8AdditionalInfoList"
        formState={formState}
        patchFormState={patchFormState}
      />
    </>
  )
};