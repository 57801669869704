// Import necessary modules and components
import { AuditingHelpInfoBox } from '../../../components/auditing/AuditingHelpInfoBox';
import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import { CalculationForm } from '../../../components/forms/CalculationForm';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';
// Define the vocabulary from an external JSON file
const fundingVocab = auditingJson.form.funding;

// Define some type aliases and constants
type SectionFormType = FundingForm; // You might want to provide the actual definition for FundingForm
const sectionKey: AuditingSectionKey = 'funding';
const batchString = 'O4-funding';
// Localization string key
export const tBase = `auditing:form.${sectionKey}`;

// Create a FormFieldBuilder for the section
const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  // Funding - SECTION: RAHOITUS_OMAISUUS
  reasonableEvidenceForAssets: fundingVocab.reasonableEvidenceForAssetsValue,

  // Funding - SECTION: FUNDING_INSPECTION
  KPL521_1: null,
  referenceTextInput: fundingVocab.referenceTextInputValue,
  thoughtsGoalsAndFinishedWork:
    auditingJson.defaultIdeasGoalsAndCompletedWorkValue,
  remarksAndComments: auditingJson.defaultFindingsAndCommentsValue,
  useCalculation: null,

  // Funding - SECTION: FUNDING_APPRECIATION_INSPECTION
  isNotReceivedConfirmations2: null,
  isReceivedConfirmations2: null,
  notReceivedConfirmations2: fundingVocab.notReceivedConfirmations,
  KPL521: null,
  referenceTextInput2: fundingVocab.referenceTextInputValue,
  thoughtsGoalsAndFinishedWork2:
    auditingJson.defaultIdeasGoalsAndCompletedWorkValue,
  remarksAndComments2: auditingJson.defaultFindingsAndCommentsValue,
  useCalculation2: null,

  AMPT: null,
  AMPT2: null,

  procedureAccountsManualData: [
    {
      currentYear: 10000,
      priorYear: 10000,
      accountName: 'esimerkki',
      comment: '',
    },
  ],

  // ProcedureAccounts
  procedureAccountsData: {},

  // CalculationForm
  currentInvestments: {
    funding_secondlast: [
      {
        investment: 'Esimerkki Osake',
        valueAtPurchase: 120,
        currentMarketValue: 640,
      },
    ],
    funding_last: [
      {
        investment: 'Esimerkki Osake',
        valueAtPurchase: 120,
        currentMarketValue: 640,
      },
    ],
  },
  investmentValueOnReport: {
    funding_secondlast: 0,
    funding_last: 0,
  },

  //fundingfirst tablecolumn
  tableColumnData: [
    {
      left: 'Taseelle merkityt varat ovat olemassa',
      right: auditingJson.OLE,
    },
    {
      left: 'Yhteisö hallitsee varoja tai sillä on määräysvalta niitä koskeviin oikeuksiin, ja velat ovat yhteisön velvoitteita',
      right: auditingJson.OVE,
    },
    {
      left: 'Kaikki varat ja velat, jotka olisi tullut kirjata on kirjattu ja niihin liittyvät liitetiedot on esitetty tilinpäätöksellä',
      right: auditingJson.TAY,
    },
    {
      left: 'Varat / velat sisältyvät taseelle asianmukaisessa rahamäärässä',
      right: auditingJson.OIK_ARV,
    },
    {
      left: 'Varat / velat on luokiteltu oikein ja niiden jaottelu on asianmukainen. Tilinpäätöksellä esitettävät tiedot ovat riittäviä ja relevantteja - luokittelu, esittämistapa',
      right: auditingJson.ESI_LUO,
    },
  ],

  additionalParts: {},

  useSheet1: null,
  sheetdata1: [
    {
      cellData: [],
    },
  ],
  useSheet2: null,
  sheetdata2: [
    {
      cellData: [],
    },
  ],
  useSheet3: null,
  sheetdata3: [
    {
      cellData: [],
    },
  ],
  useSheetTab: null,
});

// Define the form fields for the section
const formFields: FormFields<SectionFormType> = ({
  formState,
  auditing,
  store,
}) => [
  formBuilder.custom(
    <AuditingHelpInfoBox/>
  ),
  formBuilder.group({
    items: [
      formBuilder.boolean({
        accessor: 'useSheetTab',
        plainLabel: 'Käytä laskenta taulukko osiota',
      }),
      //formBuilder.custom(() => {console.log(auditing); return null}),
      formBuilder.custom(props => (
        <ProcedureAccountsElement
          {...props}
          auditing={auditing}
          batchString={batchString}
          addCommentField={true}
        />
      )),
      formBuilder.custom(props => <TableColumn {...props} />),
      formBuilder.textArea({
        accessor: 'reasonableEvidenceForAssets',
        showContextInfo: 'top-right',
      }),
    ],
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      accordionId: 'fundingAppreciation',
      upperElements: [
        formBuilder.boolean({
          accessor: 'KPL521',
          options: ['KPLOptions.KPL521Option', 'KPLOptions.KPL52aOption'],
        }),
        formBuilder.group({
          items: [
            formBuilder.boolean({ accessor: 'isReceivedConfirmations2' }),
            formBuilder.boolean({
              accessor: 'isNotReceivedConfirmations2',
              detailsAccessor: 'notReceivedConfirmations2',
              detailsHidden: !formState.isNotReceivedConfirmations2,
            }),
          ],
        }),
      ],
      lowerElements: [
        formBuilder.boolean({
          plainLabel: auditingJson.useCalculation,
          accessor: 'useCalculation2',
        }),
        formBuilder.custom(props => {
          if (formState.useCalculation2) {
            return <CalculationForm id="funding_last" {...props} />;
          }
        }),
        formBuilder.boolean({
          accessor: 'useSheet1',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet1 ? (
            <SpreadSheet id="sheetdata1" {...props} />
          ) : null
        ),
      ],
    },
    {
      accordionId: 'fundingInspection',
      upperElements: [
        formBuilder.boolean({
          accessor: 'KPL521_1',
          options: ['KPLOptions.KPL521Option', 'KPLOptions.KPL52aOption'],
          plainLabel: fundingVocab.KPL521,
        }),
      ],
      lowerElements: [
        formBuilder.boolean({
          plainLabel: auditingJson.useCalculation,
          accessor: 'useCalculation',
        }),
        formBuilder.custom(props => {
          if (formState.useCalculation) {
            return <CalculationForm id="funding_secondlast" {...props} />;
          }
        }),
        formBuilder.boolean({
          accessor: 'useSheet2',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet2 ? (
            <SpreadSheet id="sheetdata2" {...props} />
          ) : null
        ),
      ],
    },
    {
      accordionId: 'fundingClassificationInspection',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet3',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet3 ? (
            <SpreadSheet id="sheetdata3" {...props} />
          ) : null
        ),
      ],
    },
    {
      accordionId: 'liquidAssetsValuation',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet3',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet3 ? (
            <SpreadSheet id="sheetdata3" {...props} />
          ) : null
        ),
      ],
    },
  ]),
];

// Define the section for auditing
const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

// Export the section for use in other parts of the application
export default section;
