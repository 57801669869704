import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../utils';
import {
  FinancialStatementBatch,
  isRemarkBatch,
  pruneAuditingProcedures,
} from '../../views/auditingSections/auditing/auditingProceduresUtils';
import { filterAuditingProcedures } from '../../views/auditingSections/reporting/errorsUtils';
import { AccountKey } from '../../views/auditingSections/planning/accountMapKeys';
import Divider from '../Divider';
import Icon from '../Icon';
import AuditingTable from '../table/AuditingTable';
import { OnEditProps, TableColumnDefs } from '../table/Table';
import Tooltip from '../Tooltip';

type ImpactKey =
  | 'impactOnIncomeStatement'
  | 'impactOnBalanceSheet'
  | 'impactOnEquity';

interface SumTableItem {
  id: string;
  label: string | null;
  value?: number;
}

type TableItem = AuditingProcedure;

const sectionKey: AuditingSectionKey = 'errors';

const tBase = `auditing:form.auditingProcedures`;

interface Props extends FormFieldProps<AuditingProceduresForm> {
  auditing?: Auditing;
  type: ErrorsImpactType;
}

const ErrorsImpact: React.FC<Props> = ({
  formState,
  patchFormState,
  store,
  auditing,
  type,
}) => {
  const { t } = useTranslation();

  const pruneData = (row: AuditingProcedure) =>
    isRemarkBatch(row) || pruneAuditingProcedures(auditing)(row);

  const auditingProcedures = formState.auditingProcedures;

  if (!auditingProcedures) return null;

  const prunedData = auditingProcedures?.filter(pruneData);
  const data: TableItem[] = filterAuditingProcedures(prunedData, type);

  const initialImpactSums = {
    impactOnIncomeStatement: 0,
    impactOnBalanceSheet: 0,
    impactOnEquity: 0,
  };

  const getSums = () =>
    data.reduce((sums, row) => {
      const sum = (key: ImpactKey) => sums[key] + (row[key] ?? 0);

      return {
        impactOnIncomeStatement: sum('impactOnIncomeStatement'),
        impactOnBalanceSheet: sum('impactOnBalanceSheet'),
        impactOnEquity: sum('impactOnEquity'),
      };
    }, initialImpactSums);

  function handleEdit<T>({
    itemId,
    rowIndex,
    columnId,
    value,
  }: OnEditProps<T>) {
    const patch = formState.auditingProcedures?.map((row, index) => {
      const idMatch = itemId !== undefined && itemId === row.id;
      const rowIndexMatch = itemId === undefined && rowIndex === index;
      if (idMatch || rowIndexMatch) return { ...row, [columnId]: value };
      return row;
    });
    patchFormState({ ...formState, auditingProcedures: patch });
  }

  const groupingHeaderRow = [
    { colSpan: 2 },
    { colSpan: 3, label: t(`${tBase}.impact`) },
    { colSpan: 3 },
  ];

  const tableColumns: TableColumnDefs<TableItem | ErrorsSumRow> = [
    {
      accessorKey: 'batch',
      cell: ({ row: { original: row } }) => {
        if (!row.batch || row.batch === 'TOTAL_SUM') return null;
        if (row.batch === FinancialStatementBatch.REMARK) {
          return (
            <Tooltip text={t('auditing:remarks.title')}>
              <Icon type="Exclamation" color="error" />
            </Tooltip>
          );
        }
        return (
          <Tooltip text={t(`auditing:accountClass.${row.batch}`)}>
            <strong>
              {t(`auditing:accountClass.${row.batch}`).split('-')[0]}
            </strong>
          </Tooltip>
        );
      },
      header: () => t(`${tBase}.batch`),
      className: 'width-min text-center',
    },
    {
      accessorKey: 'action',
      header: () => t(`${tBase}.error`),
    },
    /*
    {
      accessorKey: 'amptValue',
      header: () => t(`${tBase}.amptValue`),
      onBooleanEdit: (editProps: OnEditProps<boolean>) => {
        handleEdit<boolean>(editProps);
      },
    },
    {
      accessorKey: 'pmValue',
      header: () => t(`${tBase}.pmValue`),
      onBooleanEdit: (editProps: OnEditProps<boolean>) => {
        handleEdit<boolean>(editProps);
      },
    },
    */
    {
      accessorKey: 'impactOnIncomeStatement',
      header: () => t(`${tBase}.impactOnIncomeStatement`),
      className: 'width-min text-center',
      disabled: (row: TableItem | ErrorsSumRow) => row.batch === 'TOTAL_SUM',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
    },
    {
      accessorKey: 'impactOnBalanceSheet',
      header: () => t(`${tBase}.impactOnBalanceSheet`),
      className: 'width-min text-center',
      disabled: (row: TableItem | ErrorsSumRow) => row.batch === 'TOTAL_SUM',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
    },
    {
      accessorKey: 'impactOnEquity',
      header: () => t(`${tBase}.impactOnEquity`),
      className: 'width-min text-center',
      disabled: (row: TableItem | ErrorsSumRow) => row.batch === 'TOTAL_SUM',
      onNumberEdit: (editProps: OnEditProps<number>) => {
        handleEdit<number>(editProps);
      },
    },
    {
      accessorKey: 'auditingReference',
      header: () => t(`${tBase}.auditingReference`),
      hidden: (row: TableItem | ErrorsSumRow) => row.batch === 'TOTAL_SUM',
      className: 'width-min text-center',
    },
    {
      accessorKey: 'conclusion',
      header: () => t(`${tBase}.conclusion`),
      className: 'width-min text-center',
      hidden: (row: TableItem | ErrorsSumRow) => row.batch === 'TOTAL_SUM',
      onEdit: (editProps: OnEditProps<string>) => {
        handleEdit<string>(editProps);
      },
    },
    {
      accessorKey: 'fixed',
      header: () => t(`${tBase}.fixed`),
      className: 'width-min text-center',
      hidden: (row: TableItem | ErrorsSumRow) => row.batch === 'TOTAL_SUM',
      disabled: () => type === 'notStarted',
      onBooleanEdit: (editProps: OnEditProps<boolean>) => {
        handleEdit<boolean>(editProps);
      },
    },
  ];

  const getSumTable = () => {
    const equityValue = store.auditingStore.getFinancialNumber(
      AccountKey.equity
    );
    const materialityValue =
      store.auditingStore.materialityComputeds().materiality;

    const equityValueScoping = store.auditingStore.getFinancialNumberScoping(
      AccountKey.equity
    );

    const sumTableData: SumTableItem[] = [
      {
        id: 'materiality',
        label: t('auditing:form.materiality.materiality'),
        value: materialityValue,
      },
      {
        id: 'equity',
        label: t(`${tBase}.equityBeforeFixes`),
        value: equityValue || equityValueScoping,
      },
      {
        id: 'equityAfterFixes',
        label: t(`${tBase}.equityAfterFixes`),
        value: equityValue
          ? equityValue + getSums().impactOnEquity
          : (equityValueScoping ?? 0) + getSums().impactOnEquity,
      },
    ];

    const sumTableColumns: TableColumnDefs<SumTableItem> = [
      {
        accessorKey: 'label',
        cell: ({ row: { original: row } }) => <strong>{row.label}</strong>,
      },
      {
        accessorKey: 'value',
        accessorFn: row => formatCurrency(row.value),
        className: 'nowrap text-center width-min',
      },
    ];

    return { sumTableData, sumTableColumns };
  };

  const showSumTable = type === 'notFixed';

  const { sumTableData, sumTableColumns } = getSumTable();

  const tableTitle = t(`auditing:form.errors.table.${type}`);

  const totalSumRow: ErrorsSumRow = {
    id: 'total-sum-row',
    batch: 'TOTAL_SUM',
    actionKey: 'total-sum-row',
    action: t(`common:label.itemsTotal`, { items: tableTitle }).toUpperCase(),
    ...getSums(),
  };

  return (
    <>
      <AuditingTable<TableItem | ErrorsSumRow>
        sectionKey={sectionKey}
        data={[...data, totalSumRow]}
        columns={tableColumns}
        groupingHeaderRow={groupingHeaderRow}
        showGlobalFilter={false}
      />

      {showSumTable && (
        <>
          <Divider noBorder size="lg" />

          <AuditingTable<SumTableItem>
            data={sumTableData}
            columns={sumTableColumns}
            showGlobalFilter={false}
            hideHeader
          />
        </>
      )}
    </>
  );
};

export default ErrorsImpact;
