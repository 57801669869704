import { RootStore } from '../../../../store/RootStore';
import { AuditingTemplate, uuid } from '../../../../utils';
import { AccountKey } from '../accountMapKeys';

export enum MaterialityBasedInThird {
  actualNumbers = 'actualNumbers',
  budgetedNumbers = 'budgetedNumbers',
}

export type MaterialityBasisThird =
  | AccountKey
  | 'combinedRevenues'
  | 'averageOfLiabilitiesAndRevenues'
  | 'other';

export const isSameMaterialityThirdForAllItems = (
  template?: AuditingTemplate
): boolean | null => {
  switch (template) {
    case AuditingTemplate.public:
      return false;
    default:
      return null;
  }
};

export const usingActualNumbersForMaterialityThird = (
  value: MaterialityBasedInThird | null
) => value === MaterialityBasedInThird.actualNumbers;

// Sum of operating income, tax revenues and government transfers
export const getCombinedRevenuesThird = (
  { auditingStore }: RootStore,
  value: 'currentYear' | 'priorYear' = 'currentYear'
) =>
  [
    AccountKey.operatingIncome,
    AccountKey.taxRevenues,
    AccountKey.governmentTransfers,
  ].reduce(
    (sum, key) =>
      sum + (auditingStore.getFinancialNumberScoping(key, value) ?? 0),
    0
  );

export const getAverageOfLiabilitiesAndRevenuesThird = (
  store: RootStore,
  value: 'currentYear' | 'priorYear' = 'currentYear'
) => {
  const { getFinancialNumberScoping } = store.auditingStore;
  const liabilities = getFinancialNumberScoping(
    AccountKey.balanceLiabilities,
    value
  );
  const revenues = getCombinedRevenuesThird(store, value);
  return liabilities && revenues ? (liabilities + revenues) / 2 : null;
};

const computeMaterialityThirdBaseNumber = ({
  materialityThirdBasedIn,
  materialityThirdBasis,
  store,
}: {
  materialityThirdBasedIn: MaterialityBasedInThird | null;
  materialityThirdBasis: MaterialityBasisThird | null;
  store: RootStore;
}) => {
  if (!usingActualNumbersForMaterialityThird(materialityThirdBasedIn))
    return null;

  const { getFinancialNumberScoping } = store.auditingStore;

  switch (materialityThirdBasis) {
    case 'combinedRevenues':
      return getCombinedRevenuesThird(store);
    case 'averageOfLiabilitiesAndRevenues':
      return getAverageOfLiabilitiesAndRevenuesThird(store);
    case 'other':
      return null;
    default:
      return getFinancialNumberScoping(materialityThirdBasis);
  }
};

export function handleMaterialityThirdBasedInChange(
  selectedValue: string | undefined,
  { formState, store, patchFormState }: FormFieldProps<MaterialityForm>
) {
  const materialityThirdBasedIn = selectedValue as MaterialityBasedInThird;

  if (materialityThirdBasedIn !== formState.materialityThirdBasedIn) {
    patchFormState({
      materialityThirdBasedIn,
      materialityThirdBaseNumber: computeMaterialityThirdBaseNumber({
        materialityThirdBasedIn,
        materialityThirdBasis: formState.materialityThirdBasis,
        store,
      }),
    });
  }
}

export const getMaterialityBasisOptionsThird = (
  template?: AuditingTemplate
): MaterialityBasisThird[] => {
  switch (template) {
    case AuditingTemplate.public:
      return [
        AccountKey.balanceLiabilities,
        'combinedRevenues',
        'averageOfLiabilitiesAndRevenues',
        AccountKey.annualContributionMargin,
        'other',
      ];
    case AuditingTemplate.private:
      return [
        AccountKey.turnover,
        AccountKey.profitOrLossBeforeTransfersAndTaxes,
        AccountKey.equity,
        AccountKey.balanceLiabilities,
        'other',
      ];
    default:
      return [];
  }
};

export function handleMaterialityThirdBasisChange(
  selectedValue: string | undefined,
  { formState, store, patchFormState }: FormFieldProps<MaterialityForm>
) {
  const materialityThirdBasis = selectedValue as MaterialityBasisThird;

  if (materialityThirdBasis !== formState.materialityThirdBasis) {
    patchFormState({
      materialityThirdBasis,
      materialityThirdBaseNumber: computeMaterialityThirdBaseNumber({
        materialityThirdBasedIn: formState.materialityThirdBasedIn,
        materialityThirdBasis,
        store,
      }),
    });
  }
}

export const emptySeparateMaterialityThird = (
  separateMaterialy?: Partial<SeparateThirdMateriality>
) => ({
  id: uuid(),
  name: '',
  methodOrBasis: '',
  materiality: null,
  workingMateriality: null,
  singleErrorMateriality: null,
  description: '',
  ...separateMaterialy,
});

export const getDefaultSeparateMaterialitiesThird = (
  template?: AuditingTemplate
) => {
  switch (template) {
    case AuditingTemplate.public:
      return [
        emptySeparateMaterialityThird({
          name: 'Olennaisuuden määrittely talousarvion noudattamisen tarkastusta varten',
          methodOrBasis:
            'Sitovuustasolla olennaisena voidaan pitää lähtökohtana vähintään 5 %:n ylitystä. Poikkeaman tulee olla myös euromääräisesti olennainen erä. Olennaisuusrajan määrittelyssä on käytetetty päävastuullisen tilintarkastajan harkintaa ja yhteisöstä muodostettua käsitystä.',
          description:
            'Kunnan toiminnassa ja taloudenhoidossa on noudatettava talousarviota (KuntaL 110 § 5 mom.). Kunnan hallintoa ei ole hoidettu lain ja valtuuston päätösten mukaisesti, jos talousarviopoikkeamat sitovuustasolla ovat olennaisia tai talousarvion määrärahoja on ylitetty tai tuloarvioita alitettu toistuvasti.',
        }),
      ];
    default:
      return [emptySeparateMaterialityThird()];
  }
};

export const utilsForThird = {
  MaterialityBasedInThird,
  isSameMaterialityThirdForAllItems,
  usingActualNumbersForMaterialityThird,
  getCombinedRevenuesThird,
  getAverageOfLiabilitiesAndRevenuesThird,
  computeMaterialityThirdBaseNumber,
  handleMaterialityThirdBasedInChange,
  getMaterialityBasisOptionsThird,
  handleMaterialityThirdBasisChange,
  emptySeparateMaterialityThird,
  getDefaultSeparateMaterialitiesThird,
};
