import { AuditingHelpInfoBox } from '../../../components/auditing/AuditingHelpInfoBox';
import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { hidePartBasedOnItAndProcesses } from '../../../utils';
import { FormFieldBuilder } from '../FormFieldBuilder';
import {
  SecondWarehousesForm,
  ThirdWarehousesForm,
  WareHousesForm,
} from './notReUsables/wareHousesForm';
import { WarehousesProcessDescriptionTable } from './notReUsables/warehousesProcess';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';
import { TwoFieldSubtractionForm } from './reUsables/twoFieldSubtractionForm';

type SectionFormType = WarehousesForm;
const sectionKey: AuditingSectionKey = 'warehouses';
export const tBase = `auditing:form.${sectionKey}`;

const batchString = 'P1-warehouses';
const vocabulary = auditingJson.form.warehouses;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  const incomeStatementAndBalance: IncomeStatementAndBalanceItem[] =
    auditing?.incomeStatementAndBalance?.form.balanceAssets ?? [];
  return {
    secondTableColumnData: [
      {
        left: '',
        right: '',
      },
    ],
    procedureAccountsData: {},
    procedureAccountsManualData: [
      {
        currentYear: 0,
        priorYear: 0,
        accountName: '',
        comment: '',
      },
    ],
    tableColumnData: [
      {
        left: 'Taseelle merkitty vaihto-omaisuus on olemassa.',
        right: auditingJson.OLE,
      },
      {
        left: 'Yhteisö hallitsee varoja tai sillä on määräysvalta niitä koskeviin oikeuksiin.',
        right: auditingJson.OVE,
      },
      {
        left: 'Kaikki vaihto-omaisuuteen kuuluvat erät, jotka olisi tullut kirjata on kirjattu.',
        right: auditingJson.TAY,
      },
      {
        left: 'Vaihto-omaisuus on kirjattu oikeassa arvossa ja mahdolliset alaskirjaustarpeet on tunnistettu.',
        right: auditingJson.OIK_ARV,
      },
      {
        left: 'Vaihto-omaisuuteen on kirjattu eriä, jotka sinne luonteensa puolesta kuuluvat.',
        right: auditingJson.LUOK
      },
      {
        left: 'Vaihto-omaisuuteen liittyvät tilinpäätöksessä esitettävät tiedot ovat relevantteja ja ymmärrettäviä sovellettavan tilinpäätösnormiston mukaan.',
        right: auditingJson.ESI,
      },
    ],
    // Varastolistan täsmäytys kirjanpitoon
    useCalculation: null,
    TwoFieldSubtractionFormData: {
      '1': [],
      '2': [],
    },
    useCalculationAgain: null,

    // Varaston muutoksen täsmäytys tuloksen ja taseen välillä
    WareHousesFormData: {
      '1': [
        {
          left: 'Varaston saldo tilikauden alussa',
          right:
            incomeStatementAndBalance.find(item => item.key === 'stocks')
              ?.priorYear ?? 0,
          id: '1',
        },
        {
          left: 'Varaston saldo tilikauden päättyessä',
          right:
            incomeStatementAndBalance.find(item => item.key === 'stocks')
              ?.currentYear ?? 0,
          id: '2',
        },
        {
          left: 'Varaston muutos tuloksella',
          right:
            incomeStatementAndBalance.find(item => item.key === 'stocks')
              ?.change ?? 0,
          id: '3',
        },
      ],
      '2': [
        {
          left: 'Varaston saldo tilikauden alussa',
          right:
            incomeStatementAndBalance.find(item => item.key === 'stocks')
              ?.priorYear ?? 0,
          id: '1',
        },
        {
          left: 'Varaston saldo tilikauden päättyessä',
          right:
            incomeStatementAndBalance.find(item => item.key === 'stocks')
              ?.currentYear ?? 0,
          id: '2',
        },
        {
          left: 'Varaston muutos tuloksella ',
          right:
            incomeStatementAndBalance.find(item => item.key === 'stocks')
              ?.change ?? 0,
          id: '3',
        },
      ],
    },
    SecondWareHousesFormData: {
      '1': [
        {
          label: 'Nimike',
          warehouseId: 0,
          pricePerItem: 0,
          billNumber: 'XXX',
          salePrice: 0,
          billDate: 'XXX',
          comments: '',
        },
      ],
    },

    ThirdWarehousesFormData: {
      '1': [
        {
          label: 'Nimike',
          warehouseId: 0,
          pricePerItem: 0,
          billNumber: 'XXX',
          salePrice: 0,
          billDate: 'XXX',
          comments: '',
        },
      ],
    },
    useCalculation2: null,
    useCalculation2Again: null,
    conclusion:
      'Suoritetuilla tarkastustoimenpiteillä on saatu riittävä evidenssi vaihto-omaisuuden oikeellisuudesta ja tarkastuksen tavoitteet on saavutettu. Suoritetun tarkastuksen perusreella vaihtoomaisuus olennaisin osin oikein',
    //Käsityksen muodostaminen
    describePeriod:
      'Kuvaa tässä esimerkiksi erää yleisellä tasolla , esim. Mistä erät muodostuvat Esimerkiksi vaihto-omaisuus koostuu valmiista tuotteista ja tarvikevarastosta. Kuvaa arvostusperiaatteet',
    // Varastolistan täsmäytys kirjanpitoon
    inspectionConclusion: 'ampt',
    reference: vocabulary.referenceValue,
    statements:
      'Kuvaa kannanotot ja tavoitteet, joihin toimenpiteillä vastataan sekä suoritettu työ',
    inspectionObservations: vocabulary.remarksAndCommentsValue,
    // Varaston muutoksen täsmäytys tuloksen ja taseen välillä
    inspectionConclusion1: 'ampt',
    reference1: vocabulary.referenceValue,
    statements1:
      'Tarkastuksen tavoitteena on varmistua siitä, että tulokselle merkitty varaston muutos vastaa taseen varaston muutosta.',
    inspectionObservations1: vocabulary.remarksAndCommentsValue,
    // Varaston arvostus: hankintamenon testaus
    inspectionConclusion2: 'ampt',
    reference2: vocabulary.referenceValue,
    statements2:
      'Tarkastuksen tavoitteena on varmistua siitä, että varasto on arvostettu hankitamenoon',
    inspectionObservations2: vocabulary.remarksAndCommentsValue,
    useCalculation3: null,
    // Varaston arvostus: varastoarvon vertaaminen myyntihintaan
    inspectionConclusion3: 'ampt',
    reference3: vocabulary.referenceValue,
    statements3:
      'Tarkastuksen tavoitteena on varmistua siitä, että myyntihinta ei ylitä varaston arvoa.',
    inspectionObservations3: vocabulary.remarksAndCommentsValue,
    useCalculation4: null,
    // Data-analyysi
    inspectionConclusion4: 'ampt',
    reference4: vocabulary.referenceValue,
    statements4: 'Kuvaa tarkastuksen tavoitteet ja tehty työ.',
    inspectionObservations4: vocabulary.remarksAndCommentsValue,
    // Inventaariin osallistuminen
    inspectionConclusion5: 'ampt',
    reference5: vocabulary.referenceValue,
    statements5:
      'Kuvaa tarkastuksen tavoitteet ja tehty työ, esim. käydään läpi varaston kiertoa. Tarkastuksen tavoitteena on  tunnistaa hitaastikiertävät nimikkeet.',
    inspectionObservations5: vocabulary.remarksAndCommentsValue,
    // Varaston kierron tarkastaminen
    inspectionConclusion6: 'ampt',
    reference6: vocabulary.referenceValue,
    statements6:
      'Kuvaa tarkastuksen tavoitteet ja tehty työ, esim. käydään läpi varaston kiertoa. Tarkastuksen tavoitteena on  tunnistaa hitaastikiertävät nimikkeet.',
    inspectionObservations6: vocabulary.remarksAndCommentsValue,

    processText: '',
    processDate: '',
    processValue: '',

    spreadsheet2: [
      {
        cellData: [],
      },
    ],
    spreadsheet3: [
      {
        cellData: [],
      },
    ],
    spreadsheet4: [
      {
        cellData: [],
      },
    ],
    spreadsheet5: [
      {
        cellData: [],
      },
    ],
    spreadsheet6: [
      {
        cellData: [],
      },
    ],
    spreadsheet7: [
      {
        cellData: [],
      },
    ],
    spreadsheet8: [
      {
        cellData: [],
      },
    ],
    // Sheets
    useSheetTab: null,
    useSheet1: null,
    useSheet2: null,
    useSheet3: null,
    useSheet4: null,
    useSheet5: null,
    useSheet6: null,
    useSheet7: null,
  };
};

const formFields: FormFields<SectionFormType> = ({
  formState,
  auditing,
  store,
}) => [
  formBuilder.custom(
    <AuditingHelpInfoBox/>
  ),
  formBuilder.group({
    items: [
      formBuilder.boolean({
        accessor: 'useSheetTab',
        plainLabel: 'Käytä laskenta taulukko osiota',
      }),
      formBuilder.custom(props => (
        <ProcedureAccountsElement
          {...props}
          auditing={auditing}
          batchString={batchString}
          addCommentField
        />
      )),
      formBuilder.custom(props => (
        <TableColumn
          title="Tarkastuksen tavoitteet ja kannantot, joihin tarkastuksella vastataan"
          {...props}
        />
      )),
      formBuilder.textArea({
        accessor: 'conclusion',
        plainLabel: auditingJson.conclusionWarehouses,
      }),
    ],
  }),

  formBuilder.accordionGroup({
    title: auditingJson.form.itAndProcesses.processDescript,
    items: [
      formBuilder.custom(props => (
        <WarehousesProcessDescriptionTable
          processText={''}
          processDate={null}
          processValue={''}
          {...props}
        />
      )),
    ],
    hidden: hidePartBasedOnItAndProcesses(auditing, 'stocks'),
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      // Varastolistan täsmäytys kirjanpitoon
      accordionId: 'reconciliationOfInventory',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useCalculation',
          plainLabel: auditingJson.useCalculation,
        }),
        formBuilder.custom(props =>
          formState.useCalculation === true ? (
            <TwoFieldSubtractionForm
              firstLabel="Saldo varastolistalla"
              secondLabel="Saldo pääkirjanpidossa"
              id="1"
              {...props}
            />
          ) : null
        ),
        formBuilder.group({
          items: [
            formBuilder.boolean({
              accessor: 'useCalculationAgain',
              plainLabel: 'Käytä valmista laskentapohjaa uudestaan',
            }),
            formBuilder.custom(props =>
              formState.useCalculationAgain === true ? (
                <TwoFieldSubtractionForm
                  firstLabel="Saldo varastolistalla"
                  secondLabel="Saldo pääkirjanpidossa"
                  id="2"
                  {...props}
                />
              ) : null
            ),
          ],
          hidden: formState.useCalculation === true ? false : true,
        }),
        formBuilder.boolean({
          accessor: 'useSheet1',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet1 ? (
            <SpreadSheet id="spreadsheet2" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Varaston muutoksen täsmäytys tuloksen ja taseen välillä
      accordionId: 'inventoryChangeReconciliation',
      lowerElements: [
        formBuilder.custom(props =>
          formState.useSheet2 ? (
            <SpreadSheet id="spreadsheet3" {...props} />
          ) : null
        ),
        formBuilder.group({
          items: [
            formBuilder.boolean({
              accessor: 'useCalculation2',
              plainLabel: auditingJson.useCalculation,
            }),
            formBuilder.custom(props =>
              formState.useCalculation2 === true ? (
                <WareHousesForm {...props} id="1" />
              ) : null
            ),

            formBuilder.group({
              items: [
                formBuilder.boolean({
                  accessor: 'useCalculation2Again',
                  plainLabel: 'Lisää taulukko uudelleen',
                }),
                formBuilder.custom(props =>
                  formState.useCalculation2Again === true ? (
                    <WareHousesForm {...props} id="2" />
                  ) : null
                ),
              ],
              hidden: formState.useCalculation2 === true ? false : true,
            }),
          ],
        }),
        formBuilder.boolean({
          accessor: 'useSheet2',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet2 ? (
            <SpreadSheet id="spreadsheet3" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Varaston arvostus: hankintamenon testaus
      accordionId: 'costTesting',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useCalculation3',
          plainLabel: auditingJson.useCalculation,
        }),
        formBuilder.custom(props =>
          formState.useCalculation3 === true ? (
            <SecondWarehousesForm {...props} id="1" />
          ) : null
        ),
        formBuilder.boolean({
          accessor: 'useSheet3',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet3 ? (
            <SpreadSheet id="spreadsheet4" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Varaston arvostus: varastoarvon vertaaminen myyntihintaan
      accordionId: 'comparingTheInventory',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useCalculation4',
          plainLabel: auditingJson.useCalculation,
        }),
        formBuilder.custom(props =>
          formState.useCalculation4 === true ? (
            <ThirdWarehousesForm {...props} id="1" />
          ) : null
        ),
        formBuilder.boolean({
          accessor: 'useSheet4',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet4 ? (
            <SpreadSheet id="spreadsheet5" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Data-analyysi
      accordionId: 'dataAnalysis',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet7',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet7 ? (
            <SpreadSheet id="spreadsheet8" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Inventaariin osallistuminen
      accordionId: 'participationInInventory',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet5',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet5 ? (
            <SpreadSheet id="spreadsheet6" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Varaston kierron tarkastaminen
      accordionId: 'checkingStock',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet6',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet6 ? (
            <SpreadSheet id="spreadsheet7" {...props} />
          ) : null
        ),
      ],
    },
  ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
