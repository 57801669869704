import React from "react";
import InstructionBox from "../InstructionBox";
import { t } from "i18next";
import { Trans } from "react-i18next";
import styled from "styled-components";

const helpMessageLines: string[] = [
  t('auditing:form.errors.helpInfoText.line1'),
  t('auditing:form.errors.helpInfoText.line2'),
];

const Container = styled.div`
    white-space: pre-line;
    max-height: 60vh;
    overflow: scroll;
    padding: 5px;

    strong {
      color: red;
    }
`;

export const ErrorsHelpInfoBox: React.FC = () => {
  return (
    <InstructionBox
      buttonText='Katso ohje!'
      title='Ohje'>
        <Container>
          <Trans>{helpMessageLines.join('\n\n')}</Trans>
        </Container>
    </InstructionBox>
  )
}