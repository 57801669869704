import { path, rt } from '../i18n/utils';
import { Role } from '../utils/constants';
import AuditingSection from '../views/AuditingSection';
import { notFoundRoute } from './routes.common';

// Helper function to compose routes
function auditingRoute<SectionForm>(
  sectionKey: AuditingSectionKey,
  category: AuditingCategory[],
  CustomSectionElement?: JSX.Element,
) {
  return {
    id: sectionKey,
    path: path(`auditing.${sectionKey}`),
    title: rt(`auditing.${sectionKey}`),
    roles: [Role.Auditor],
    category,
    Element: CustomSectionElement ?? (
      <AuditingSection<SectionForm> sectionKey={sectionKey} />
    ),
  };
}

//Tänne lisäämme auditingRoute<-''-Form>('route.json', ['kategoria']),
const auditingRoutes: Route[] = [
  // Precending routes
  auditingRoute<AuditingFrontPageForm>('auditingFrontPage', ['start']),

  // Essentials routes
  auditingRoute<MandateAuditorForm>('MandateAuditor', ['essentials']),
  auditingRoute<communityInformationForm>('communityInformation', ['essentials']),
  auditingRoute<CustomerVerificationForm>('CustomerVerification', ['essentials']),
  auditingRoute<InspectionIndependenceForm>('inspectionIndependence', ['essentials']),
  auditingRoute<EvaluationAssignmentForm>('EvaluationAssignment', ['essentials']),

  // Inspection routes
  auditingRoute<InternalControlAndOperatingEnvironmentForm>('internalControlAndOperatingEnvironment', ['inspection']),
  auditingRoute<RisksForm>('risks', ['inspection']),
  auditingRoute<AuditingPlanningForm>('auditingPlanning', ['inspection']),
  auditingRoute<ItAndProcessesForm>('itAndProcesses', ['inspection']),
  auditingRoute<MaterialityForm>('materiality', ['inspection']),

  // Analyzes routes
  auditingRoute<AccountMapForm>('accountMap', ['analyzes']),
  auditingRoute<GeneralLedgerForm>('generalLedger', ['analyzes']),
  auditingRoute<IncomeStatementAndBalanceForm>('incomeStatementAndBalance', ['analyzes']),
  auditingRoute<PreliminaryAnalyticForm>('preliminaryAnalytic', ['analyzes']),

  // Scoping routes
  auditingRoute<AccountMapScopingForm>('accountMapScoping', ['scoping']),
  auditingRoute<GeneralLedgerScopingForm>('generalLedgerScoping', ['scoping']),
  auditingRoute<IncomeStatementAndBalanceScopingForm>('incomeStatementAndBalanceScoping', ['scoping']),
  auditingRoute<AuditingProceduresForm>('detailedPlan', ['scoping']),
  auditingRoute<IndicatorsForm>('indicators', ['scoping']),
  auditingRoute<concludingAnalyticForm>('concludingAnalytic', ['scoping']),

  // Planning routes
  auditingRoute<PermanentDataForm>('permanentData', ['planning']),
  auditingRoute<IndependenceForm>('independence', ['planning']),

  // Siirretty Inspection routen sisälle
  //auditingRoute<AuditingPlanningForm>('auditingPlanning', ['planning']),
  //auditingRoute<InternalControlAndOperatingEnvironmentForm>('internalControlAndOperatingEnvironment', ['planning']),
  //auditingRoute<ItAndProcessesForm>('itAndProcesses', ['planning']),
  //auditingRoute<RisksForm>('risks', ['planning']),
  //auditingRoute<MaterialityForm>('materiality', ['planning']),

  // Order of routes/järjestys
  auditingRoute<AuditingProceduresForm>('auditingProcedures', ['auditing']),
  auditingRoute<PersonelCostsForm>('personelCosts', ['auditing']), //L
  // Uudet routet
  
  auditingRoute<AssignmentsFirstTimeForm>('assignmentsFirstTime', ['auditing']),// F1
  auditingRoute<PresentationAndContentForm>('presentationAndContent', ['auditing']), // F3
  auditingRoute<ManualExportsForm>('manualExports', ['auditing']), // F8
  auditingRoute<ContinuityOfOperationForm>('continuityOfOperation', ['auditing']), // F9
  
  auditingRoute<ManagementForm>('management', ['auditing']), // G
  auditingRoute<innerCircleTabForm>('innerCircleTab', ['auditing']), // G4.4
  auditingRoute<TurnOverForm>('turnOver', ['auditing']), //J1
  auditingRoute<TurnOverSheetForm>('turnOverSheet', ['auditing']), //J1 - Sheet
  auditingRoute<AccountsReceivableForm>('accountsReceivable', ['auditing']), //J2
  auditingRoute<AccountsReceivableSheetForm>('accountsReceivableSheet', ['auditing']), //J2 - Sheet
  auditingRoute<OtherOperatingIncomeForm>('otherOperatingIncome', ['auditing']), //JB
  auditingRoute<OtherOperatingIncomeSheetForm>('otherOperatingIncomeSheet', ['auditing']), //JB - Sheet
  auditingRoute<MaterialsAndServicesForm>('materialsAndServices', ['auditing']), //K1
  auditingRoute<MaterialsAndServicesSheetForm>('materialsAndServicesSheet', ['auditing']), //K1 - Sheet
  auditingRoute<TradeCreditorsForm>('tradeCreditors', ['auditing']), //K2
  auditingRoute<TradeCreditorsSheetForm>('tradeCreditorsSheet', ['auditing']), //K2 - Sheet
  auditingRoute<OtherOperatingExpensesForm>('otherOperatingExpenses', ['auditing']), //KB
  auditingRoute<OtherOperatingExpensesSheetForm>('otherOperatingExpensesSheet', ['auditing']), //KB - Sheet
  auditingRoute<GovernmentTransfersAndTaxRevenuesForm>('governmentTransfersAndTaxRevenues',['auditing']), // M - Valtionosuudet ja verotulot
  auditingRoute<GovernmentTransfersForm>('governmentTransfers', ['auditing']), // M - Valtionosuudet
  auditingRoute<TaxesForm>('taxes', ['auditing']), // N
  auditingRoute<TaxesSheetForm>('taxesSheet', ['auditing']), // N - Sheet
  auditingRoute<FixedAssetsForm>('fixedAssets', ['auditing']), //O1
  auditingRoute<FixedAssetsSheetForm>('fixedAssetsSheet', ['auditing']), //O1 - Sheet
  auditingRoute<InvestmentsForm>('investments', ['auditing']), //O3
  auditingRoute<InvestmentsSheetForm>('investmentsSheet', ['auditing']), //O3 - Sheet
  auditingRoute<FundingForm>('funding', ['auditing']),//O4
  auditingRoute<FundingSheetForm>('fundingSheet', ['auditing']),//O4 - Sheet
  auditingRoute<WarehousesForm>('warehouses', ['auditing']),// P1
  auditingRoute<WarehousesSheetForm>('warehousesSheet', ['auditing']),// P1 - Sheet
  auditingRoute<OtherReceivablesForm>('otherReceivables', ['auditing']), // Q1
  auditingRoute<OtherReceivablesSheetForm>('otherReceivablesSheet', ['auditing']), // Q1 - Sheet
  auditingRoute<CashEquivalentsForm>('cashEquivalents', ['auditing']), // Q2
  auditingRoute<CashEquivalentsSheetForm>('cashEquivalentsSheet', ['auditing']), // Q2 - Sheet
  auditingRoute<LoanReceivablesForm>('loanReceivables', ['auditing']), // Q3
  auditingRoute<LoanReceivablesSheetForm>('loanReceivablesSheet', ['auditing']), // Q3 - Sheet
  auditingRoute<OwnCapitalForm>('ownCapital', ['auditing']), //R1
  auditingRoute<OwnCapitalSheetForm>('ownCapitalSheet', ['auditing']), //R1 - Sheet
  auditingRoute<CapitalLoansForm>('capitalLoans', ['auditing']), // R2
  auditingRoute<CapitalLoansSheetForm>('capitalLoansSheet', ['auditing']), // R2 - Sheet
  auditingRoute<FinancialInstitutionsForm>('financialInstitutions', ['auditing']), //S1
  auditingRoute<FinancialInstitutionsSheetForm>('financialInstitutionsSheet', ['auditing']), //S1 - Sheet
  auditingRoute<OtherLiabilitiesForm>('otherLiabilities', ['auditing']), //T1
  auditingRoute<OtherLiabilitiesSheetForm>('otherLiabilitiesSheet', ['auditing']), //T1 - Sheet
  auditingRoute<AdvancePaymentsForm>('advancePayments', ['auditing']), // T2
  auditingRoute<AdvancePaymentsSheetForm>('advancePaymentsSheet', ['auditing']), // T2 - Sheet
  auditingRoute<FinancialIncomeForm>('financialIncome', ['auditing']), //XJ
  auditingRoute<FinancialIncomeSheetForm>('financialIncomeSheet', ['auditing']), //XJ - Sheet
  auditingRoute<FinancialExpensesForm>('financialExpenses', ['auditing']), //XK
  auditingRoute<FinancialExpensesSheetForm>('financialExpensesSheet', ['auditing']), //XK - Sheet
  auditingRoute<ReservesForm>('reserves', ['auditing']), //V
  auditingRoute<ReservesSheetForm>('reservesSheet', ['auditing']), //V - Sheet


  // Reporting routes
  auditingRoute<AuditingProceduresForm>('errors', ['reporting']),
  auditingRoute<ClosureForm>('closure', ['reporting']),
  auditingRoute<CommunicationAndReportingForm>('communicationAndReporting', [
    'reporting',
  ]),
  auditingRoute<ReportingForm>('reporting', ['reporting']),

  // Rest of the routes
  auditingRoute('instructions', ['end']),
  //auditingRoute('feedback', ['end']),
  notFoundRoute,
];

export default auditingRoutes;
