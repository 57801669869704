import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useStore } from '../../store/RootContext';
import {
  hidePartBasedOnUseSheetSelect,
  isTabChosenInDetailedPlan,
  useGroupedAuditingRoutes,
  shouldShowAnalyzesRoute,
  hidePartBasedOnShouldShowAnalytic,
} from '../../utils';
import AuditingSections from '../../views/auditingSections';
import Accordion from '../Accordion';
import Icon, { IconColor, IconType } from '../Icon';

const NavLinkContainer = styled.div`
  position: relative;
`;

const DIMMED_NAV_ICON_OPACITY = 0.75;

const NavIcon = styled.div<{ isActive: boolean }>`
  display: flex;
  opacity: ${p => (p.isActive ? 1 : DIMMED_NAV_ICON_OPACITY)};
  margin-right: ${p => p.theme.spacing.md};
`;

const IconBadge = styled.div<{ isActive: boolean }>`
  position: absolute;
  right: 0;
  left: -${p => p.theme.spacing.sm};
  opacity: ${p => (p.isActive ? 1 : DIMMED_NAV_ICON_OPACITY)};
`;

const SideNavAuditing: React.FC = observer(() => {
  const { t } = useTranslation('auditing');

  const {
    auditingStore: {
      auditingTemplate,
      selectedEntity,
      getAuditingSectionStatus,
      isAuditingSectionFinished,
      getAuditingRemarks,
      getLinkToAuditingSection,
      auditing,
    },
  } = useStore();

  if (!selectedEntity) return null;

  const auditingRemarks = getAuditingRemarks();

  const auditingRoutes = useGroupedAuditingRoutes(auditingTemplate);

  const renderIcon = (sectionKey: AuditingSectionKey) => {
    const status = getAuditingSectionStatus(sectionKey);

    const dependingSections = AuditingSections[sectionKey].depending ?? [];

    const resolvedDependingSections =
      typeof dependingSections === 'function' && auditing
        ? dependingSections(auditing)
        : dependingSections;

    const unfinishedDependingSections = Array.isArray(resolvedDependingSections)
      ? resolvedDependingSections.filter(
        (dependingSectionKey: keyof AuditingSections) =>
          !isAuditingSectionFinished(dependingSectionKey)
      )
      : [];

    let iconType: IconType = 'Hourglass';
    let iconColor: IconColor = 'grey300';

    if (unfinishedDependingSections.length) {
      iconType = 'Lock';
    } else {
      switch (status) {
        case 'started':
          iconColor = 'primary';
          break;
        case 'finished':
          iconType = 'Check';
          iconColor = 'success';
          break;
        case 'approved':
          iconType = 'Approved';
          iconColor = 'success';
          break;
        case 'not_started':
        default:
        // do nothing
      }
    }

    return <Icon type={iconType} color={iconColor} className="nav-icon" />;
  };

  const renderNavLink =
    ({ hasIcon = true }: { hasIcon?: boolean }) =>
      (item: Route) => {
        const sectionKey = item.id as AuditingSectionKey;

        const isMatchingRemark = (remark: AuditingRemark) =>
          remark.sectionKey === item.id;

        return (
          (item.id?.includes('Sheet')
            ? hidePartBasedOnUseSheetSelect(auditing, item.id?.replace('Sheet', ''))
            : isTabChosenInDetailedPlan(auditing, item.id!, item.category as any))
          && !hidePartBasedOnShouldShowAnalytic(auditing, item.id) &&
          <NavLinkContainer key={item.path}>
            <NavLink
              to={getLinkToAuditingSection({ sectionKey })}
              className={({ isActive }) => (isActive ? 'is-active' : '')}
            >
              {({ isActive }) => (
                <>
                  {auditingRemarks.some(isMatchingRemark) && (
                    <IconBadge isActive={isActive}>
                      <Icon type="Exclamation" color="error" size={14} />
                    </IconBadge>
                  )}
                  {hasIcon && (
                    <NavIcon isActive={isActive}>
                      {renderIcon(sectionKey)}
                    </NavIcon>
                  )}
                  {item.title}
                </>
              )}
            </NavLink>
          </NavLinkContainer>
        );
      };

  return (
    /* Tänne lisäämme sen kategorian minkä tehnyt, menee järjestyksessä. */
    <>
      {auditingRoutes.start.map(renderNavLink({ hasIcon: false }))}

      <Accordion title={t('essentials')} defaultOpen>
        {auditingRoutes.essentials.map(renderNavLink({}))}
      </Accordion>

      <Accordion title={t('inspection')} defaultOpen>
        {auditingRoutes.inspection.map(renderNavLink({}))}
      </Accordion>

      {shouldShowAnalyzesRoute(
        auditing?.internalControlAndOperatingEnvironment?.form.isInterimAudit
      ) && (
          <Accordion title={t('analyzes')} defaultOpen>
            {auditingRoutes.analyzes.map(renderNavLink({}))}
          </Accordion>
        )}

      <Accordion title={t('scoping')} defaultOpen>
        {auditingRoutes.scoping.map(renderNavLink({}))}
      </Accordion>

      {/*  
      <Accordion title={t('planning')} defaultOpen>
        {auditingRoutes.planning.map(renderNavLink({}))}
      </Accordion>
*/}
      <Accordion title={t('auditing')} defaultOpen>
        {auditingRoutes.auditing.map(renderNavLink({}))}
      </Accordion>

      <Accordion title={t('reporting')} defaultOpen>
        {auditingRoutes.reporting.map(renderNavLink({}))}
      </Accordion>

      {auditingRoutes.end.map(renderNavLink({ hasIcon: false }))}
    </>
  );
});

export default SideNavAuditing;
