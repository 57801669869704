import AuditingTable from "../../../../components/table/AuditingTable";
import { OnEditProps, TableColumnDefs } from "../../../../components/table/Table";
import { useMemo } from "react";
import { exampleData, handleEdit } from "./otherOperatingExpenses.tablesUtils";
import { t } from "../../../../i18n";

const sectionKey: AuditingSectionKey = 'otherOperatingExpenses';
const tBase = `auditing:form.${sectionKey}.rentTables`;

// Renders all child tables with parent table parentTableId
export const RentCalculationTables = ({ formState, patchFormState, parentTableId }: RentCalculationTableProps) => {
  const data = useMemo(() => formState.rentTablesData ?? exampleData, [formState]);

  const tableColumns: TableColumnDefs<rentTableRow> = [
    {
      accessorKey: 'label',
      className: 'text-left nowrap width-min text-bold',
      header: () => ''
    },
    {
      accessorKey: 'value',
      className: 'text-left nowrap width-min',
      header: () => '',
      onNumberEdit: (editProps: OnEditProps<number>) => handleEdit<number>(editProps,
        formState,
        patchFormState,
        data,
      ),
      // Disable calculated rows
      disabled: (row: rentTableRow) => row.type === 'adjusted'
    },
    {
      accessorKey: 'comment',
      className: 'text-left nowrap width-min',
      onEdit: (editProps: OnEditProps<string>) => handleEdit<string>(editProps,
        formState,
        patchFormState,
        data,
      ),
      header: () => ''
    }
  ]

  return (
    <>
      {
        // Filter out parent tables
        data.filter((table) => table.type !== 'base' && table.parent === parentTableId)
          .map((item) => {
            const title = item.type === 'rentCalculation'
              ? t(`${tBase}.rentCalculationTable.title`)
              : t(`${tBase}.rentIndexCalculationTable.title`);
            return (
              <AuditingTable
                title={title}
                key={`${item.id}-table`}
                sectionKey={sectionKey}
                data={item.table}
                columns={tableColumns}
                disableSort
                showGlobalFilter={false}
                variant="default"
              />
            )
          })}
    </>
  )
}