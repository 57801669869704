import {
  CalculationBaseColumn,
  CheckTableColumn,
  TradeTableColumn,
} from '../../../components/auditing/TradeCreditors';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { FormFieldBuilder } from '../FormFieldBuilder';
//import PurchaseLedger from '../../../components/auditing/procedures/PurchaseLedger';
import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import { ProcedureAccountsElement } from './reUsables/ProcedureAccountsElement';
import { SpreadSheet } from './reUsables/SpreadSheet';
import { TableColumn } from './reUsables/TableColumn';
import { AuditingHelpInfoBox } from '../../../components/auditing/AuditingHelpInfoBox';

type SectionFormType = TradeCreditorsForm;

const sectionKey: AuditingSectionKey = 'tradeCreditors';

// Used for accessor names, to get translations
export const tBase = `auditing:form.${sectionKey}`;

const batchString = 'K2-tradeCreditors'; // Get batch string for added parts

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => ({
  procedureAccountsManualData: [
    {
      currentYear: 0,
      priorYear: 0,
      accountName: '0000...',
      comment: '',
    },
  ],
  procedureAccountsData: {},
  purchaseLedgerData: [{}],
  tradeTable: [
    {
      id: '1',
      account: '',
      date: '',
      rekontra: 0,
      confirm: 0,
      minus: 0,
    },
  ],
  checkTable: [
    {
      id: '1',
      account: '',
      date: '',
      rekontra: 0,
      confirm: 0,
      minus: 0,
    },
  ],
  creditorsTable: [
    {
      id: '1',
      CY: 0,
      PY: 0,
    },
  ],
  calculationBaseTable: [
    {
      id: '1',
      account: '',
      rekontra: 0,
      confirm: 0,
      minus: 0,
      other: '',
    },
  ],
  tableColumnData: [
    {
      left: 'Velat, jotka taseelle on merkitty ovat olemassa.',
      right: auditingJson.OLE,
    },
    {
      left: 'Taseella esitetyt velat ovat yhtiön velvotteita.',
      right: auditingJson.OVE,
    },
    {
      left: 'Kaikki velat, jotka olisi pitänyt kirjata on kirjattu ja niihin liittyvät tiedot, jorka olisi pitänyt esittää tilinpäätöksellä on esitetty.',
      right: auditingJson.TAY,
    },
    {
      left: 'Velat on esitetty asianmukaisessa määrässä, arvostamisesta ja kohdistamisesta johtuvat oikaisut on kirjattu asianmukaisesti.',
      right: auditingJson.ARV,
    },
    {
      left: 'Velat on luokiteltu oikein.',
      right: auditingJson.LUOK,
    },
    {
      left: 'Velat on yhdistelty tai jaoteltu asianmukaisesti ja niihin liittyvät tilinpäätöksessä esitettävät tiedot ovat relevantteja.',
      right: auditingJson.ESI,
    },
  ],
  inspectionObservationsCorrectness: 'string;',

  textArea:
    'Suoritetuilla tarkastustoimenpiteillä on saatu riittävä evidenssi ostovelkojen ja katkon oikeellisuudesta. Tarkastuksen tavoitteet on saavutettu. Suoritetun tarkastuksen perusteella ostovelat ovat olennaisin osin oikein.',
  formingAnUnderstanding:
    'Kuvaa tässä esimerkiksi erää yleisellä tasolla , esim. Tarkastettava erä muodostuu, mikäli tilikohtaisia ei saa suoraan pääkirjalta oheen, voit liittää tähän excelissä tarkastettavat tilit tai antaa tilivälin.',

  // Ostoreskontran täsmäytys taseelle
  useSheet: null,
  sheetdata1: [
    {
      cellData: [],
    },
  ],
  useReadyMade: false,
  openAgain: false,

  // Ostoreskontran matemaattinen oikeellisuus
  useSheet4: null,
  sheetdata4: [
    {
      cellData: [],
    },
  ],

  // Ostovelkojen läpikäynti
  useSheet2: null,
  sheetdata2: [
    {
      cellData: [],
    },
  ],
  maturityOfAccounts: null,
  negativeBalances: null,
  detectedBalances: 'Kuvaa määrä ja johtopäätös',
  detectedBalances2: 'Kuvaa määrä ja johtopäätös',
  goingThroughThePurchase: null,

  // Kulujen katko - alkaneen kauden tapahtumat
  useSheet5: null,
  sheetdata5: [
    {
      cellData: [],
    },
  ],

  // Kulujen katko - Tilikauden viimeisen kuukauden tapahtumat
  useSheet6: null,
  sheetdata6: [
    {
      cellData: [],
    },
  ],

  // Ostovelkojen olemassaolo  - saldovahvistukset
  useSheet3: null,
  sheetdata3: [
    {
      cellData: [],
    },
  ],
  askedConfirmation: null,
  askedConfirmationInfo:
    'Tarkempi kuvaus, suunnitellut vaihtoehtoiset toimenpiteet',
  calculationBase: false,

  // Ostovelkojen luokittelu
  useSheetTab: false,
  useSheet7: null,
  sheetdata7: [
    {
      cellData: [],
    },
  ],
});

//    formBuilder.custom(props => <CreditorsTableColumn {...props} />),

const formFields: FormFields<SectionFormType> = ({
  formState,
  auditing,
  store,
}) => [
  formBuilder.custom(
    <AuditingHelpInfoBox/>
  ),
  formBuilder.group({
    items: [
      formBuilder.boolean({
        accessor: 'useSheetTab',
        plainLabel: 'Käytä laskenta taulukko osiota',
      }),
      formBuilder.custom(props => (
        <ProcedureAccountsElement
          auditing={auditing}
          {...props}
          batchString={batchString}
          addCommentField
        />
      )),
      formBuilder.custom(props => (
        <TableColumn
          title="Tarkastuksen tavoitteet ja kannantot, joihin tarkastuksella vastataan"
          {...props}
        />
      )),
      formBuilder.textArea({
        accessor: 'textArea',
      }),
    ],
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      // Ostoreskontran täsmäytys taseelle
      accordionId: 'reconciliationOfThePurchase',
      lowerElements: [
        // Käytä valmista pohjaa
        formBuilder.boolean({ accessor: 'useReadyMade' }),
        formBuilder.custom(props =>
          formState.useReadyMade ? <TradeTableColumn {...props} /> : null
        ),
        formBuilder.boolean(
          formState.useReadyMade
            ? { accessor: 'openAgain' }
            : { accessor: 'openAgain', hidden: true }
        ),
        formBuilder.custom(props =>
          formState.openAgain ? <CheckTableColumn {...props} /> : null
        ),
        formBuilder.boolean({
          accessor: 'useSheet',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet ? <SpreadSheet id="sheetdata1" {...props} /> : null
        ),
      ],
    },
    // Ostoreskontran matemaattinen oikeellisuus
    {
      accordionId: 'mathematicalCorrectness',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet4',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet4 ? (
            <SpreadSheet id="sheetdata4" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Ostovelkojen läpikäynti
      accordionId: 'reviewOfAccounts',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'maturityOfAccounts',
          options: [
            'maturityOfAccountsOptions.one',
            'maturityOfAccountsOptions.two',
          ],
        }),
        formBuilder.textArea({
          accessor: 'inspectionObservationsCorrectness',
          hidden: !formState.maturityOfAccounts,
        }),
        formBuilder.boolean({
          accessor: 'negativeBalances',
          options: [
            'negativeBalancesOptions.one',
            'negativeBalancesOptions.two',
          ],
        }),
        formBuilder.textArea({
          accessor: 'detectedBalances',
          hidden: !formState.negativeBalances,
        }),
        formBuilder.boolean({
          accessor: 'goingThroughThePurchase',
          options: [
            'goingThroughThePurchaseOptions.one',
            'goingThroughThePurchaseOptions.two',
          ],
        }),
        formBuilder.textArea({
          plainLabel: 'Poikkeavien erien tai saldojen varalta lisätietoa',
          accessor: 'detectedBalances2',
          hidden: !formState.goingThroughThePurchase,
        }),
        formBuilder.boolean({
          accessor: 'useSheet2',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet2 ? (
            <SpreadSheet id="sheetdata2" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Kulujen katko - alkaneen kauden tapahtumat
      accordionId: 'breakInExpenses',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet5',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet5 ? (
            <SpreadSheet id="sheetdata5" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Kulujen katko - Tilikauden viimeisen kuukauden tapahtumat
      accordionId: 'breakInExpensesEvents',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet6',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet6 ? (
            <SpreadSheet id="sheetdata6" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Ostovelkojen olemassaolo  - saldovahvistukset
      accordionId: 'existenceOfAccounts',
      upperElements: [
        formBuilder.boolean({
          accessor: 'askedConfirmation',
          options: [
            'askedConfirmationOptions.one',
            'askedConfirmationOptions.two',
          ],
        }),
        formBuilder.textArea({
          accessor: 'askedConfirmationInfo',
          hidden: !formState.askedConfirmation,
        }),
      ],
      lowerElements: [
        formBuilder.boolean({ accessor: 'calculationBase' }),
        formBuilder.custom(props =>
          formState.calculationBase ? (
            <CalculationBaseColumn {...props} />
          ) : null
        ),
        formBuilder.boolean({
          accessor: 'useSheet3',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet3 ? (
            <SpreadSheet id="sheetdata3" {...props} />
          ) : null
        ),
      ],
    },
    {
      // Ostovelkojen luokittelu
      accordionId: 'classification',
      lowerElements: [
        formBuilder.boolean({
          accessor: 'useSheet7',
          plainLabel: auditingJson.useSheet,
        }),
        formBuilder.custom(props =>
          formState.useSheet7 ? (
            <SpreadSheet id="sheetdata7" {...props} />
          ) : null
        ),
      ],
    },
  ]),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
};

export default section;
