import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Settings as LuxonSettings } from 'luxon';
import { DEFAULT_LANGUAGE } from '../utils';

import action from './locales/fi/action.json';
import auditFirms from './locales/fi/auditFirms.json';
import customerCompanies from './locales/fi/customerCompanies.json';
import raportit from './locales/fi/raportit.json';
import auditing from './locales/fi/auditing.json';
import common from './locales/fi/common.json';
import frontPage from './locales/fi/frontPage.json';
import login from './locales/fi/login.json';
import route from './locales/fi/route.json';
import myProfile from './locales/fi/myProfile.json';
import notification from './locales/fi/notification.json';
import users from './locales/fi/users.json';
import validate from './locales/fi/validate.json';
import _404 from './locales/fi/404.json';
import disconnect from './locales/fi/disconnect.json';
import customer from './locales/fi/customer.json';

const resources = {
  fi: {
    action,
    auditFirms,
    customerCompanies,
    raportit,
    auditing,
    common,
    frontPage,
    login,
    route,
    myProfile,
    notification,
    users,
    validate,
    '404': _404,
    disconnect,
    customer,
  }
}

LuxonSettings.defaultLocale = DEFAULT_LANGUAGE;

i18n.use(initReactI18next).init({
  fallbackLng: DEFAULT_LANGUAGE,
  lng: DEFAULT_LANGUAGE,
  returnNull: false,

  ns: ['common'],
  defaultNS: 'common',

  debug: false,

  resources,

  interpolation: {
    // React already does escaping.
    escapeValue: false,
  },
});

export const t = i18n.t;

export const i18nChangeLanguage = (language: LanguageCode) => {
  LuxonSettings.defaultLocale = language;
  i18n.changeLanguage(language, (err, t) => {
    if (err) return console.log('something went wrong loading', err);
  });
};

export default i18n;
