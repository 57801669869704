import { TableColumnDefs } from '../../../components/table/Table';
import AuditingTable from '../../../components/table/AuditingTable';
import {
  AuditingProcedureResult,
  batchName,
  isNotRemarkBatch,
} from '../auditing/auditingProceduresUtils';
import { Risk } from '../analyzesAndScoping/interim/incomeStatementAndBalance';
import { Estimate } from '../../../components/analyzesAndScoping/financialStatement/Indicators.Table';
import { useTranslation } from 'react-i18next';

interface TableItemProcedure {
  batch: string;
  actionKey: string;
  auditingResult: string;
}

interface TableItemStatement {
  key: string;
  risk: string;
}

interface TableItemIndicators {
  label: string;
  estimate: string;
}

const getAuditingResults = ({ auditingResult }: ReportingProcedure) => {
  switch (auditingResult) {
    case AuditingProcedureResult.notStarted:
      return 'Aloittamatta';
    case AuditingProcedureResult.treatedGood:
      return 'Ei yli AMPT olevia virheitä';
    case AuditingProcedureResult.treatedModerately:
      return 'Ei yli PM olevia virheitä';
    case AuditingProcedureResult.shortcomings:
      return 'Yli PM tai olennaisia virheitä';
    case AuditingProcedureResult.noEvidenceReceived:
      return 'Aineistoa tai evidenssiä ei ole saatu';
  }
};
const getRisk = ({ risk }: IncomeStatementAndBalanceItem) => {
  switch (risk) {
    case Risk.significant:
      return 'Merkittävä';
    case Risk.limitedRisk:
      return 'Rajattu riski';
    case Risk.notSignificant:
      return 'Ei merkittävä';
  }
};
const getEstimate = ({ estimate }: IndicatorsItem) => {
  switch (estimate) {
    case Estimate.Good:
      return 'Hyvä';
    case Estimate.Moderate:
      return 'Kohtalainen';
    case Estimate.Poor:
      return 'Huono';
  }
};

const sectionKey: AuditingSectionKey = 'reporting';

export const SignificantErrors = ({
  formState,
}: FormFieldProps<ReportingForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const proceduresData = formState.procedures ?? [];
  const statementsData = formState.statements ?? [];
  const indicatorsData = formState.indicators ?? [];

  const tableColumns: TableColumnDefs<TableItemProcedure> = [
    // batch from the ReportingProcedure interface
    {
      accessorKey: 'batch',
      header: 'Erä',
      className: 'text-bold text-left nowrap width-middle',
      cell: ({ row: { original: row } }) => row.batch,
    },
    // actionKey from the ReportingProcedure interface
    {
      accessorKey: 'actionKey',
      header: 'Toimenpide',
      className: 'text-center nowrap width-middle',
    },
    // auditingResult from the ReportingProcedure interface
    {
      accessorKey: 'auditingResult',
      header: 'Tarkastuksen tulos',
      className: 'text-center nowrap width-middle',
    },
  ];

  const listRowsSimplified = (procedures: ReportingProcedure[]) => {
    return procedures
      .filter(
        procedure =>
          getAuditingResults(procedure) === 'Yli PM tai olennaisia virheitä' &&
          isNotRemarkBatch(procedure)
      )
      .map(procedure => ({
        batch: batchName(procedure.batch),
        actionKey: t(
          `auditing:auditingProcedureAction.${procedure.batch}.${procedure.actionKey}`
        ),
        //actionKey: procedure.actionKey,
        auditingResult: getAuditingResults(procedure) || '', // Provide a default value here
      }));
  };

  const rows: TableItemProcedure[] = listRowsSimplified(proceduresData);

  const tableColumnsStatements: TableColumnDefs<TableItemStatement> = [
    // key from the IncomeStatementAndBalanceItem interface
    {
      accessorKey: 'key',
      header: 'Avain',
      className: 'text-bold text-center nowrap width-middle',
    },
    // risk from the IncomeStatementAndBalanceItem interface
    {
      accessorKey: 'risk',
      header: 'Riski',
      className: 'text-center nowrap width-middle',
    },
  ];

  const listRowsStatements = (statements: Statement[]) => {
    // Flatten the incomeStatement, balanceAssets, and balanceLiabilities arrays into a single array
    const flattenedStatements = statements.reduce<
      IncomeStatementAndBalanceItem[]
    >((acc, statement) => {
      return [
        ...acc,
        ...statement.incomeStatement,
        ...statement.balanceAssets,
        ...statement.balanceLiabilities,
      ];
    }, []);
    return flattenedStatements
      .filter(statement => getRisk(statement) === 'Merkittävä')
      .map(statement => ({
        key: t(`auditing:accountKey.${statement.key}`),
        risk: getRisk(statement) || '', // Provide a default value here
      }));
  };

  const statementRows: TableItemStatement[] =
    listRowsStatements(statementsData);

  const tableColumnsIndicators: TableColumnDefs<TableItemIndicators> = [
    // label from the IndicatorsItem interface
    {
      accessorKey: 'label',
      header: 'Nimi',
      className: 'text-bold text-center nowrap width-middle',
    },
    // estimate from the IndicatorsItem interface
    {
      accessorKey: 'estimate',
      header: 'Arvio',
      className: 'text-center nowrap width-middle',
    },
  ];

  const listRowsIndicators = (indicators: IndicatorsItem[]) => {
    return indicators
      .filter(indicator => getEstimate(indicator) === 'Huono')
      .map(indicator => ({
        label: t(`${indicator.label}`),
        estimate: getEstimate(indicator) || '', // Provide a default value here
      }));
  };

  const indicatorsRows: TableItemIndicators[] =
    listRowsIndicators(indicatorsData);

  return (
    <>
      <AuditingTable
        title="Olennaisimmat virheet (Yli PM tai olennaisia virheitä)"
        sectionKey={sectionKey}
        columns={tableColumns}
        data={rows}
        showGlobalFilter={false}
      />
      <AuditingTable
        title="Merkittävät riskit"
        sectionKey={sectionKey}
        columns={tableColumnsStatements}
        data={statementRows}
        showGlobalFilter={false}
      />
      <AuditingTable
        title="Tunnusluvut (Huono)"
        sectionKey={sectionKey}
        columns={tableColumnsIndicators}
        data={indicatorsRows}
        showGlobalFilter={false}
      />
    </>
  );
};

export const LimitedErrors = ({ formState }: FormFieldProps<ReportingForm>) => {
  const { t } = useTranslation();

  if (!formState) return null;

  const proceduresData = formState.procedures ?? [];
  const statementsData = formState.statements ?? [];
  const indicatorsData = formState.indicators ?? [];

  const tableColumns: TableColumnDefs<TableItemProcedure> = [
    // batch from the ReportingProcedure interface
    {
      accessorKey: 'batch',
      header: 'Erä',
      className: 'text-bold text-left nowrap width-middle',
      cell: ({ row: { original: row } }) => row.batch,
    },
    // actionKey from the ReportingProcedure interface
    {
      accessorKey: 'actionKey',
      header: 'Toimenpide',
      className: 'text-center nowrap width-middle',
    },
    // auditingResult from the ReportingProcedure interface
    {
      accessorKey: 'auditingResult',
      header: 'Tarkastuksen tulos',
      className: 'text-center nowrap width-middle',
    },
  ];

  const listRowsSimplified = (procedures: ReportingProcedure[]) => {
    return procedures
      .filter(procedure => {
        const auditingResult = getAuditingResults(procedure);

        return (
          (auditingResult === 'Aineistoa tai evidenssiä ei ole saatu' ||
            auditingResult === 'Ei yli PM olevia virheitä' ||
            auditingResult === 'Yli PM tai olennaisia virheitä') &&
          isNotRemarkBatch(procedure)
        );
      })
      .map(procedure => ({
        batch: batchName(procedure.batch),
        actionKey: t(
          `auditing:auditingProcedureAction.${procedure.batch}.${procedure.actionKey}`
        ),
        //actionKey: procedure.actionKey,
        auditingResult: getAuditingResults(procedure) || '', // Provide a default value here
      }));
  };

  const rows: TableItemProcedure[] = listRowsSimplified(proceduresData);

  const tableColumnsStatements: TableColumnDefs<TableItemStatement> = [
    // key from the IncomeStatementAndBalanceItem interface
    {
      accessorKey: 'key',
      header: 'Avain',
      className: 'text-bold text-center nowrap width-middle',
    },
    // risk from the IncomeStatementAndBalanceItem interface
    {
      accessorKey: 'risk',
      header: 'Riski',
      className: 'text-center nowrap width-middle',
    },
  ];

  const listRowsStatements = (statements: Statement[]) => {
    // Flatten the incomeStatement, balanceAssets, and balanceLiabilities arrays into a single array
    const flattenedStatements = statements.reduce<
      IncomeStatementAndBalanceItem[]
    >((acc, statement) => {
      return [
        ...acc,
        ...statement.incomeStatement,
        ...statement.balanceAssets,
        ...statement.balanceLiabilities,
      ];
    }, []);
    return flattenedStatements
      .filter(statement => getRisk(statement) === 'Merkittävä')
      .map(statement => ({
        key: t(`auditing:accountKey.${statement.key}`),
        risk: getRisk(statement) || '', // Provide a default value here
      }));
  };

  const statementRows: TableItemStatement[] =
    listRowsStatements(statementsData);

  const tableColumnsIndicators: TableColumnDefs<TableItemIndicators> = [
    // label from the IndicatorsItem interface
    {
      accessorKey: 'label',
      header: 'Nimi',
      className: 'text-bold text-center nowrap width-middle',
    },
    // estimate from the IndicatorsItem interface
    {
      accessorKey: 'estimate',
      header: 'Arvio',
      className: 'text-center nowrap width-middle',
    },
  ];

  const listRowsIndicators = (indicators: IndicatorsItem[]) => {
    return indicators
      .filter(indicator => getEstimate(indicator) === 'Kohtalainen')
      .map(indicator => ({
        label: t(`${indicator.label}`),
        estimate: getEstimate(indicator) || '', // Provide a default value here
      }));
  };

  const indicatorsRows: TableItemIndicators[] =
    listRowsIndicators(indicatorsData);

  return (
    <>
      <AuditingTable
        title="Olennaisimmat virheet (Ei yli PM olevia virheitä)"
        sectionKey={sectionKey}
        columns={tableColumns}
        data={rows}
        showGlobalFilter={false}
      />
      <AuditingTable
        title="Merkittävät riskit"
        sectionKey={sectionKey}
        columns={tableColumnsStatements}
        data={statementRows}
        showGlobalFilter={false}
      />
      <AuditingTable
        title="Tunnusluvut (Kohtalainen)"
        sectionKey={sectionKey}
        columns={tableColumnsIndicators}
        data={indicatorsRows}
        showGlobalFilter={false}
      />
    </>
  );
};
