import AuditingProcedures from '../../../components/auditing/AuditingProcedures';
import AuditingRemarks from '../../../components/auditing/AuditingProcedures.Remarks';
import { FormFieldBuilder } from '../FormFieldBuilder';
import {
  checkAndUpdateAuditingProcedures,
  defaultAuditingProcedures,
  transformRemarkToAuditingProcedure,
} from './auditingProceduresUtils';

/**
 * Please note:
 * This section doesn't have its own form, since it's manipulating form data defined for detailedPlan section!
 */

// NOTE: This section is using other section's form!
type SectionFormType = AuditingProceduresForm;

const sectionKey: AuditingSectionKey = 'auditingProcedures';

export const tBase = `auditing:form.${sectionKey}`;

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ store, auditing }) => {
  let auditingProcedures = [
    ...store.auditingStore
      .getAuditingRemarks()
      .map(transformRemarkToAuditingProcedure),
    ...defaultAuditingProcedures(store, auditing),
  ];

  // Update auditing procedures results based on conditions
  auditingProcedures = checkAndUpdateAuditingProcedures(auditingProcedures, store);

  return {
    auditingProcedures,
    auditFindings: [
      {
        id: '1',
        conclusion: '',
        perception: '',
        fixed: false,
        notEssential: false,
        essential: false,
      },
    ],
  };
};

const formFields: FormFields<SectionFormType> = ({ auditing }) => [
  formBuilder.custom(props => (
    <AuditingRemarks auditing={auditing} {...props} />
  )),

  formBuilder.custom(props => (
    <AuditingProcedures auditing={auditing} {...props} />
  )),
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields
};

export default section;
