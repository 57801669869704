import { displayAuditingProcedures } from '../../../components/auditing/AuditingProcedureItem2';
import {
  AnalyticalAuditOfVacation,
  AnalyticalExaminationTable,
  AnalyticalReviewTable,
  BusinessOverTable,
  ExaminationTable,
  ExtraExaminationTable,
  NewAnalyticalSalariesTable,
  PensionExpensesTable,
  ReconciliationSalariesTable
} from '../../../components/auditing/personelCostsTables';
import auditingJson from '../../../i18n/locales/fi/auditing.json';
import { AuditingTemplate, hidePartBasedOnItAndProcesses } from '../../../utils';
import { FormFieldBuilder } from '../FormFieldBuilder';
import { getAccountRangeFromAccountMap } from '../analyzesAndScoping/accountMapUtils';
import { AccountKey } from '../planning/accountMapKeys';
import { FinancialStatementBatch } from './auditingProceduresUtils';
import { PersonelProcessDescriptionTable } from './notReUsables/personelCostsProcess';
import { analyticalReviewOfWagesRows } from './peronelCostsUtils.analyticalReviewOfWages';
import { analyticalAuditOfPersonnelOverheadCostsRows } from './personelCostsUtils.analyticalAuditOfPersonnelOverheadCosts';
import { analyticalAuditOfVacationPayAccrualsRows } from './personelCostsUtils.analyticalAuditOfVacationPayAccruals';
import { analyticalAuditOfWagesRows } from './personelCostsUtils.analyticalAuditOfWages';
import { makeDefaultPayAccrualsComparisonRows } from './personelCostsUtils.matchingOfPayAccruals';
import { matchingOfWagesToAnnualReportRows } from './personelCostsUtils.matchingOfWagesToAnnualReport';
import {ProcedureAccountsElement} from './reUsables/ProcedureAccountsElement';
import { TableColumn } from './reUsables/TableColumn';
import { AuditingHelpInfoBox } from '../../../components/auditing/AuditingHelpInfoBox';
import { ColoredNoticeText } from '../../../components/auditing/ColoredNoticeText';
import { t } from 'i18next';

type SectionFormType = PersonelCostsForm;

const sectionKey: AuditingSectionKey = 'personelCosts';
const batch = FinancialStatementBatch['L-personelCosts'];
const batchString = 'L-personelCosts';

export const tBase = `auditing:form.${sectionKey}`;

const getTableColumnData = (template: AuditingTemplate) => {
  const risks = [
    auditingJson.TAP,
    auditingJson.TAY,
    auditingJson.OIK_ARV,
    auditingJson.KAT,
    auditingJson.LUOK,
    auditingJson.ESI,
    auditingJson.OLE,
    auditingJson.OVE
  ];

  const values = {
    [AuditingTemplate.public]: [
      'Palkkoihin/ henkilöstökuluihin kirjatut tapahtumat ovat toteutuneet ja kuuluvat kunnalle.',
      'Kaikki palkkatapahtumat ja -velat, jotka olisi tullut kirjata kirjanpitoon on kirjattu.',
      'Tapahtumat on kirjattu oikeassa määrässä.',
      'Tilikaudelle on kirjattu vain suoriteperusteisesti sille kuuluvat erät.',
      'Henkilöstökulujen alle ja taseen velkoihin on luokiteltu ainoastaan sinne kuuluvia eriä. Tapahtumat on luokiteltu oikeisiin tilinpäätöseriin.',
      'Henkilöstökulujen tapahtumat on yhdistelty ja jaoteltu oikein / ne on kuvattu selvästi, ja/tai niihin liittyvät tiedot tilinpäätöksessä ovat relevantteja ja ymmärrettäviä sovellettavan tilinpäätösnormiston mukaan.',
      'Velat, jotka taseelle on merkitty ovat olemassa.',
      'Palkkoihin liittyvät velat ovat kunnan velvoitteita.'
    ],
    [AuditingTemplate.private]: [
      'Palkkoihin/ henkilöstökuluihin kirjatut tapahtumat ovat toteutuneet ja kuuluvat yhteisölle.',
      'Kaikki palkkatapahtumat ja -velat, jotka olisi tullut kirjata kirjanpitoon on kirjattu.',
      'Tapahtumat on kirjattu oikeassa määrässä.',
      'Tilikaudelle on kirjattu vain suoriteperusteisesti sille kuuluvat erät.',
      'Henkilöstökulujen alle ja taseen velkoihin on luokiteltu ainoastaan sinne kuuluvia eriä. Tapahtumat on luokiteltu oikeisiin tilinpäätöseriin.',
      'Henkilöstökulujen tapahtumat on yhdistelty ja jaoteltu oikein / ne on kuvattu selvästi, ja/tai niihin liittyvät tiedot tilinpäätöksessä ovat relevantteja ja ymmärrettäviä sovellettavan tilinpäätösnormiston mukaan.',
      'Velat, jotka taseelle on merkitty ovat olemassa.',
      'Palkkoihin liittyvät velat ovat yhteisön velvoitteita.'
    ]
  }

  return risks.map((risk, index) => ({ left: values[template][index], right: risk }));
}

const formBuilder = new FormFieldBuilder<SectionFormType>({ sectionKey });

const defaultFormState: DefaultFormState<SectionFormType> = ({ auditing }) => {
  const getAccountRange = getAccountRangeFromAccountMap(auditing);
  const incomeStatementAndBalance: IncomeStatementAndBalanceScopingItem[] =
    auditing?.incomeStatementAndBalanceScoping?.form.incomeStatement ?? [];
  return {
    averageNumberOfPersonsCY: null,
    averageNumberOfPersonsPY: null,
    accountMappings: {
      wagesTotal: getAccountRange(AccountKey.personelCosts),
      pensionExpenses: getAccountRange(AccountKey.pensionExpences),
      tyelOrKuelAccounts: '',
      sicknessInsuranceAccounts: '',
      holidayPayWithAssociatedCostsAccount: '',
      holidayPayWithoutAssociatedCostsAccounts: '',
      capitalizedWagesOrOtherAdjustmentsAccounts: '',
      yelWagesAccounts: '',
    },

    overallAssessmentComments: '',

    processText: '',
    processDate: '',
    processValue: '',

    conclusionOnAuditPersonnelCosts:
      'Suoritetuilla tarkastustoimenpiteillä on saatu riittävä evidenssi henkilöstökulujen oikeellisuudesta ja tarkastuksen tavoitteet on saavutettu. Suoritetun tarkastuksen perusreella erät olennaisin osin oikein',
    tableColumnData: getTableColumnData(auditing.template),
    // Palkkojen analyyttinen tarkastelu
    analyticalReviewTable: {
      tableData: {
        wagesAndRemuneration: {
          label: 'Palkat ja palkkio',
          id: '1',
          cy:
            incomeStatementAndBalance.find(
              item => item.key === 'wagesAndSalaries'
            )?.currentYear ?? 0,
          cyProcent: 0,
          py:
            incomeStatementAndBalance.find(
              item => item.key === 'wagesAndSalaries'
            )?.priorYear ?? 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
        capitalizedSalaries: {
          label: 'Aktivoidut palkat',
          id: '2',
          cy: 10000,
          cyProcent: 0,
          py: 15000,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
      },
      totalWagesData: {
        totalWages: {
          label: 'Palkat yhteensä',
          id: '1',
          cy: 0,
          cyProcent: 0,
          py: 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
      },
    },
    pensionExpensesTable: {
      pensionExpensesData: {
        pensionExpenses: {
          label: 'Eläkekulut',
          id: '1',
          cy:
            incomeStatementAndBalance.find(
              item => item.key === 'pensionExpences'
            )?.currentYear ?? 0,
          cyProcent: 0,
          py:
            incomeStatementAndBalance.find(
              item => item.key === 'pensionExpences'
            )?.priorYear ?? 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
        otherPersonelSideCosts: {
          label: 'Muut henkilöstösivukulut',
          id: '2',
          cy:
            incomeStatementAndBalance.find(
              item => item.key === 'otherSocialSecurityCosts'
            )?.currentYear ?? 0,
          cyProcent: 0,
          py:
            incomeStatementAndBalance.find(
              item => item.key === 'otherSocialSecurityCosts'
            )?.priorYear ?? 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
      },
      totalPersonelSideCostsData: {
        totalPersonelSideCosts: {
          label: 'Henkilöstösivukulut yhteensä',
          id: '1',
          cy: 0,
          cyProcent: 0,
          py: 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
      },
      totalPersonelCostsData: {
        totalPersonelCosts: {
          label: 'Henkilöstökulut yhteensä',
          id: '1',
          cy: 0,
          cyProcent: 0,
          py: 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
      },
    },
    businessOverTable: {
      numberOfPersonsData: {
        personsKpl: {
          label: 'Henkilömäärä kpl',
          id: '1',
          cy: 10,
          cyProcent: 0,
          py: 15,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
        lenghtOfFinancialYear: {
          label: 'Tilikauden pituus',
          id: '2',
          cy: 12,
          cyProcent: 0,
          py: 12,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
        turnOver: {
          label: 'Liikevaihto',
          id: '3',
          cy:
            incomeStatementAndBalance.find(item => item.key === 'turnover')
              ?.currentYear ?? 0,
          cyProcent: 0,
          py:
            incomeStatementAndBalance.find(item => item.key === 'turnover')
              ?.priorYear ?? 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
      },
      averageData: {
        salaryPerson: {
          label: 'Palkka / henkilö / kk',
          id: '1',
          cy: 0,
          cyProcent: 0,
          py: 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
        turnOverPerson: {
          label: 'Liikevaihto / henkilö',
          id: '2',
          cy: 0,
          cyProcent: 0,
          py: 0,
          pyProcent: 0,
          change: 0,
          changeProcent: 0,
        },
      },
    },
    newAnalyticalSalariesTable: [],
    analyticalSalariesTable: [],
    salariesChangeTable: {
      salariesData: {
        salaries: {
          id: '1',
          label: 'Palkkojen muutos % vertailukauteen nähden',
          inputValue: 0,
        },
      },
    },
    financialYearTable: {
      financialYearData: {
        financialLength: {
          id: '1',
          label: 'Tarkastettavan tilikauden pituus',
          inputValue: 0,
        },
        financialPerson: {
          id: '2',
          label: 'Henkilömäärä tarkastettavalla tilikaudella',
          inputValue: 0,
        },
      },
    },
    expectedSalaryExpense: {
      salaryExpense: {
        expense: {
          id: '1',
          label: 'Odotusarvoinen palkkakulu',
          inputValue: 0,
        },
        realization: {
          id: '2',
          label: 'Palkkakulujen toteuma',
          inputValue: 0,
        },
        minus: {
          id: '3',
          label: 'Erotus',
          inputValue: 0,
        },
      },
    },

    analyticalExaminationBoolean: '',
    analyticalExaminationNumber: 0,
    examinationTable: [
      {
        id: '1',
        booleanValue: 'Muu',
        inputValue: 0,
      },
    ],

    analyticalExaminationTable: [
      {
        id: '1',
        label: 'Palkat yhteensä',
        inputValue:
          incomeStatementAndBalance.find(
            item => item.key === 'wagesAndSalaries'
          )?.currentYear ?? 0,
        commentField: 'Kommentoi',
      },
      {
        id: '2',
        label: 'YEL palkat (-NEGATIIVINEN)',
        inputValue: -10000,
        commentField: 'Kommentoi',
      },
      {
        id: '3',
        label: 'Muut huomioitavat erät +/-',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '4',
        label: 'Eläkemaksujen perusteena olevat palkat yhteensä',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '5',
        label: 'Odotusarvoinen eläkekulu %',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '6',
        label: 'Odotusarvoinen eläkekulu €',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '7',
        label: 'Toteuma',
        inputValue:
          incomeStatementAndBalance.find(item => item.key === 'pensionExpences')
            ?.currentYear ?? 0,
        disabled: true,
      },
      {
        id: '8',
        label: 'Toteuman ja odotusarvon erotus',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '9',
        label: 'Johtopäätös (ERO KYNNYSARVOON)',
        inputValue: 0,
        disabled: true,
        threshold: '---',
      },
      {
        id: '10',
        label: 'Palkat yhteensä',
        inputValue:
          incomeStatementAndBalance.find(
            item => item.key === 'wagesAndSalaries'
          )?.currentYear ?? 0,
        commentField: 'Kommentoi',
      },
      {
        id: '11',
        label: 'Huomioitavat erät +/-',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '12',
        label: 'Sairasvakuutusmaksun perusteena olevat palkat yhteensä',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '13',
        label: 'Odotusarvoinen sairasvakuutusmaksu %',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '14',
        label: 'Odotusarvoinen sairasvakuutusmaksut €',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '15',
        label: 'Toteuma',
        inputValue:
          incomeStatementAndBalance.find(item => item.key === 'pensionExpences')
            ?.currentYear ?? 0,
        disabled: true,
      },
      {
        id: '16',
        label: 'Toteuman ja odotusarvon erotus',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '17',
        label: 'Johtopäätös (ERO KYNNYSARVOON)',
        inputValue: 0,
        disabled: true,
        threshold: '---',
      },
    ],
    extraExaminationTable: [
      {
        id: '1',
        label: 'Palkat yhteensä',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '2',
        label: 'Huomioitavat erät +/-',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '3',
        label: 'Laskennan perusteena olevat palkat',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '4',
        label: 'Odotusarvoinen % ',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '5',
        label: 'Odotusarvoinen määrä',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '6',
        label: 'Toteuma',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '7',
        label: 'Toteuman ja odotusarvon erotus',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '8',
        label: 'Johtopäätös (ERO KYNNYSARVOON)',
        inputValue: 0,
        disabled: true,
        threshold: '---',
      },
    ],
    reconciliationSalariesTable: [
      {
        id: '1',
        label: 'Lomapalkkavelka pääkirjanpidossa',
        inputValue: 0,
      },
      {
        id: '2',
        label: 'Palkkajärjestelmän mukainen velka 31.12.',
        inputValue: 0,
      },
      {
        id: '3',
        label: 'Erotus',
        inputValue: 0,
      },
    ],
    reconciliationBooleanTable: [
      {
        id: '1',
        label: 'Kynnysarvo',
        booleanValue: null,
        inputValue: 0,
      },
    ],
    reconciliationSalariesSecondTable: [
      {
        id: '1',
        label: 'Tase-erittelyn mukaiset lomapalkat sivukuluineen',
        inputValue: 0,
      },
      {
        id: '2',
        label: 'Lomapalkat ilman sivukuluja',
        inputValue: 0,
      },
      {
        id: '3',
        label: 'Sivukulu %',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '4',
        label:
          'Pääkpidon maksettujen palkkojen mukainen laskennallinen lomapalkkavaraus ilman sivukuluja:',
        inputValue: 0,
        disabled: true,
        threshold: '',
      },
      {
        id: '5',
        label: 'Palkat yht.',
        inputValue:
          incomeStatementAndBalance.find(
            item => item.key === 'wagesAndSalaries'
          )?.currentYear ?? 0,
      },
      {
        id: '6',
        label: 'Tilikauden pituus',
        inputValue: 12,
      },
      {
        id: '7',
        label: 'Keskim. palkat per kk',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '8',
        label: 'Keskim. kertyneet lomapäivät per kk',
        inputValue: 2.5,
      },
      {
        id: '9',
        label: 'Kertyneiden lomakuukausien määrä (1.4 alkaen)',
        inputValue: 9,
      },
      {
        id: '10',
        label: 'Keskim. pitämättömät talvilomapäivät yht.',
        inputValue: 6,
      },
      {
        id: '11',
        label: 'Laskennalliset lomapalkat ilman lomarahoja',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '12',
        label: 'Laskennallinen lomapalkkavaraus ml. lomarahat ilman sivukuluja',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '13',
        label: 'Laskennallisen ja tehdyn varauksen erotus',
        inputValue: 0,
        disabled: true,
      },
      {
        id: '14',
        label: 'Johtopäätös',
        inputValue: 0,
        threshold: '---',
        disabled: true,
      },
    ],
    analyticalAuditOfVacationData: [
      {
        id: '1',
        label: 'Kirjanpidon palkat',
        inputValue:
          incomeStatementAndBalance.find(
            item => item.key === 'wagesAndSalaries'
          )?.currentYear ?? 100000,
        comment: '',
      },
      {
        id: '2',
        label: '+ Saadut sair. päivärahat yms.',
        inputValue: 5000,
        comment: '',
      },
      {
        id: '3',
        label: '+ Muut kredit-kirjaukset palkkatileille',
        inputValue: 10000,
        comment: '',
      },
      {
        id: '4',
        label: '+ Ed. tilik. Lomapalkkavelka ilman sivukuluja',
        inputValue: 30000,
        comment: '',
      },
      {
        id: '5',
        label: '+ Muut lisättävät erät',
        inputValue: 5000,
        comment: '',
      },
      {
        id: '6',
        label: '- Tilik. Lomapalkkavelka ilman sivukuluja',
        inputValue: -25000,
        comment: '',
      },
      {
        id: '7',
        label: '+ Ed. tilik. maksamattomat palkat (ei sivukuluja)',
        inputValue: 20000,
        comment: '',
      },
      {
        id: '8',
        label: '- Tilik. maksamattomat palkat (ei sivukuluja)',
        inputValue: -25000,
        comment: '',
      },
      {
        id: '9',
        label: '- Muut vähennettävät erät ',
        inputValue: -10000,
        comment: '',
      },
      {
        id: '10',
        label: 'Yhteensä',
        inputValue: 0,
        comment: '',
      },
      {
        id: '11',
        label: 'Verottajan vuosi-ilmoitus',
        inputValue: 672658,
        comment: '',
      },
      {
        id: '12',
        label: 'Erotus',
        inputValue: 0,
        comment: '',
      },
      {
        id: '13',
        label: 'Erotus % palkoista',
        inputValue: 0,
        comment: '',
      },
    ],
    useAnalyticalTable: false,

    addNewTableButton: false,
    useTableButton: false,
    useReconciliationButton: false,
    describePeriodTextArea: 'Kuvaa tässä esimerkiksi erää yleisellä tasolla, onko yhtiöllä tunti- vai kuukausipalkkaisia? Onko Tyel vai YEL palkkaisia, millaiset ovat lomapalkkakäytännöt?',

    analyticalReviewOfWages: analyticalReviewOfWagesRows(),
    analyticalAuditOfPersonnelOverheadCosts:
      analyticalAuditOfPersonnelOverheadCostsRows(),
    analyticalAuditOfWages: analyticalAuditOfWagesRows(),
    matchingOfWagesToAnnualReport: matchingOfWagesToAnnualReportRows(),
    personnelCostsPeriodization: {},
    auditOfVacationPayAccruals: {},
    matchingOfVacationPayAccruals: {
      useCalculationBase: true,
      noCalculationBaseReason: '',
      balanceComparisons: makeDefaultPayAccrualsComparisonRows(),
    },
    analyticalAuditOfVacationPayAccruals:
      analyticalAuditOfVacationPayAccrualsRows(),
    auditOfWages: {},
    auditOfIndividualTransactions: {},
    processMappingWithKeyAccountingPrinciples: {},
  };
};

const formFields: FormFields<SectionFormType> = ({
  store,
  auditing,
  formState,
}) => [
  formBuilder.custom(
    <>
      <AuditingHelpInfoBox/>
      <ColoredNoticeText
        color="#4382b6"
        text={auditingJson.form.personelCosts.auditingNotice}
      />
    </>
  ),
  formBuilder.custom(props => (
    <TableColumn
      title={t(`${tBase}.essentialInaccuracyStatement${auditing?.template === AuditingTemplate.public ? '-public' : ''}`)}
      {...props}
    />
  )),
  formBuilder.textArea({
    accessor: 'conclusionOnAuditPersonnelCosts',
  }),
  formBuilder.custom(
    <ProcedureAccountsElement batchString={batch} auditing={auditing} />
  ),

  /*
  formBuilder.custom(props => (
    <AccountMappings
      classKey={AccountClassKey[batch]}
      auditing={auditing}
      accountMappings={formState.accountMappings}
      {...props}
    />
  )),
  formBuilder.custom(props => <PersonelCostsCommonFormFields {...props} />),
  */

  formBuilder.accordionGroup({
    title: auditingJson.form.itAndProcesses.processDescript,
    items: [
      formBuilder.custom(props => (
        <PersonelProcessDescriptionTable
          processText={''}
          processDate={null}
          processValue={''}
          {...props}
        />
      )),
    ],
    hidden: hidePartBasedOnItAndProcesses(auditing, 'wages'),
  }),

  formBuilder.accordionGroup({
    hiddenInTemplate: 'public',
    title: auditingJson.form.advancePayments.describePeriod,
    items: [
      formBuilder.textArea({
        accessor: 'describePeriodTextArea'
      }),
    ],
  }),

  ...displayAuditingProcedures(store, batchString, formBuilder, [
    {
      accordionId: 'analyticalReviewOfWages',
      lowerElements: [
        formBuilder.custom(props => <AnalyticalReviewTable {...props} />),
        formBuilder.custom(props => <PensionExpensesTable {...props} />),
        formBuilder.custom(props => <BusinessOverTable {...props} />),
      ],
    },
    {
      accordionId: 'analyticalAuditOfWages',
      lowerElements: [
        formBuilder.custom(props => <NewAnalyticalSalariesTable {...props} />),
      ],
    },
    {
      accordionId: 'analyticalExaminationOfSideCosts',
      lowerElements: [
        formBuilder.custom(props => <ExaminationTable {...props} />),
        formBuilder.custom(props => <AnalyticalExaminationTable {...props} />),
        formBuilder.custom(props => (
          <>
            <input
              type="checkbox"
              onChange={() =>
                props.patchFormState({
                  addNewTableButton: !props.formState.addNewTableButton,
                })
              }
            />{' '}
            Lisää laskentataulukko
            <br />
            {props.formState.addNewTableButton && (
              <ExtraExaminationTable {...props} />
            )}
          </>
        )),
      ],
    },
    {
      accordionId: 'analyticalAuditOfVacationPayAccruals',
      lowerElements: [
        /*formBuilder.custom(props => (
          <>
            <input type='checkbox' onChange={() => props.patchFormState({ useTableButton: !props.formState.useTableButton })} /> Käytä valmista laskentataulukkoa
            <br/>
            {props.formState.useTableButton &&
              <ThresholdTestTable<SectionFormType>
                dataKey='analyticalAuditOfVacationPayAccruals'
                accountMappingValues={getAccountMappingValues(
                  auditing?.generalLedger?.form.groupedGeneralLedger,
                  props.formState.accountMappings
                )}
                thresholdComputationRules={thresholdComputationRules['reconciliationOfSalaries']}
                {...props}
              />
            }
          </>
        )),*/
        formBuilder.boolean({
          accessor: 'useAnalyticalTable',
          plainLabel: 'Käytä valmista laskentataulukkoa',
        }),
        ...(formState.useAnalyticalTable
          ? [
              formBuilder.custom(props => (
                <AnalyticalAuditOfVacation {...props} />
              )),
            ]
          : []),
      ],
    },
    {
      accordionId: 'reconciliationOfSalaries',
      lowerElements: [
        formBuilder.custom(props => (
          <>
            <input
              type="checkbox"
              onChange={() =>
                props.patchFormState({
                  useReconciliationButton:
                    !props.formState.useReconciliationButton,
                })
              }
            />{' '}
            Käytä valmista laskentataulukkoa
            <br />
            {props.formState.useReconciliationButton && (
              <ReconciliationSalariesTable {...props} />
            )}
          </>
        )),
      ],
    },
    {
      accordionId: 'inspectionOfPersonnelExpenseAccurals',
    },
    {
      accordionId: 'managementSalaryAudit',
      hiddenInTemplate: 'public'
    }
  ]),
  /*
  formBuilder.accordionGroup({
    titleKey: 'overallAssessment',
    items: [
      formBuilder.textArea({
        accessor: 'overallAssessmentComments',
        showContextInfo: 'top-right',
      }),
    ],
  }),
  */
];

const section: AuditingSectionDefinition<SectionFormType> = {
  sectionKey,
  defaultFormState,
  formFields,
  //depending: ['materiality', 'detailedPlan'],
};

export default section;
