import { InputGroup, Radio } from "../inputs";
import { t } from "i18next";

const sectionKey: AuditingSectionKey = 'auditingPlanning';
const tBase = `auditing:form.${sectionKey}`;

export const IsInterimAudit = ({
  formState,
  patchFormState,
  store
}: FormFieldProps<AuditingPlanningForm>) => {

  const internalControlIsInterimAudit = store.auditingStore.auditing?.internalControlAndOperatingEnvironment?.form.isInterimAudit;

  // On first load, copies the interim audit value from internalControlAndOperatingEnvironment form if it's set
  if (formState.isInterimAuditFirstLoad) {
    const patch: AuditingPlanningForm = {
      ...formState,
      isInterimAudit: internalControlIsInterimAudit ?? true,
      isInterimAuditFirstLoad: false
    };
    patchFormState(patch);
  }

  return (
    <InputGroup
      label={t(`${tBase}.isInterimAudit`)}
      contextInfo={{ text: t(`${tBase}.isInterimAuditInfo`) }}
    >
      <Radio
        label="Ei"
        value="false"
        checked={formState.isInterimAudit === false}
        onChange={() => patchFormState(
          {
            ...formState,
            isInterimAudit: false
          })}
      />
      <Radio
        label="Kyllä"
        value="true"
        checked={formState.isInterimAudit === true}
        onChange={() => patchFormState(
          {
            ...formState,
            isInterimAudit: true
          })}
      />
    </InputGroup>
  );
};