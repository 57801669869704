export const deepEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) return true; // Check if they are the same object
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return false; // If one is not an object or is null
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false; // Different number of properties
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false; // Recursively compare nested properties
    }
  }

  return true;
}